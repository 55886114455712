import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import Files from "../../assets/icons/Files.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import UserCtrl from "../../assets/icons/UserCtrl.svg";
import Entss from "../../assets/icons/Entss.svg";
import Props from "../../assets/icons/Props.svg";
import Reqs from "../../assets/icons/Reqs.svg";
import Reps from "../../assets/icons/Reps.svg";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  getDoc,
  deleteDoc,
  addDoc,
  doc,
  where,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { ref, getStorage, uploadBytes } from "firebase/storage";
import SubSelect from "../SubSelect";

export default function RoleDetails() {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [isEditing, setIsEditing] = useState(null);
  const [name, setName] = useState();
  const [data, setData] = useState(null);
  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);
  useEffect(() => {
    console.log(name);
  }, [name]);

  const handleAddRole = async () => {
    try {
      await setDoc(doc(db, "Roles", name), {
        ...data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    await deleteDoc(doc(db, `Roles`, roleId))
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
        navigate("/roles");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = async () => {
    setIsEditing(!isEditing);
    await setDoc(doc(db, "Roles", roleId), data);
  };

  const getRole = async () => {
    const roleRef = await getDoc(doc(db, "Roles", roleId));
    setData(roleRef.data());
  };

  const selectAll = (arr, check) => {
    setData((prevState) => {
      const newState = { ...prevState };
      arr.forEach((el) => {
        newState[el] = check;
      });
      return newState;
    });
  };

  return (
    <>
      <PageTemp
        title={roleId}
        subTitle="Role Details"
        page={
          <div>
            {data != null && (
              <form action="">
                <div className={classes.formGroup}></div>

                <div className={classes.formDetail}>
                  <div className="">
                    <div className={classes.subDetail}>
                      <label>Role Name</label>
                      <input
                        defaultValue={roleId}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="Enter Role Name"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className={classes.formDetail}>
                  <div className="">
                    <div className={classes.masterSubDetail}>
                      <div className={classes.subDetail}>
                        {/* <SubSelect> */}
                        <p className={classes.detailHeaderC}>
                          <img src={UserCtrl} />
                          User Control
                          {isEditing && (
                            <input
                              onChange={(e) => {
                                selectAll(
                                  [
                                    "addUser",
                                    "editUser",
                                    "statusControl",
                                    "manageRoles",
                                    "activityLog"
                                  ],
                                  e.target.checked
                                );
                              }}
                              className={classes.mainInpCheck}
                              type="checkbox"
                            />
                          )}
                        </p>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.addUser}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  addUser: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.addUser != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Add User ${
                            !isEditing ? `: ${data.addUser}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.editUser}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  editUser: e.target.checked,
                                });
                              }}
                              id={"UserCtrl"}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.editUser != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Edit User ${
                            !isEditing ? `: ${data.editUser}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.statusControl}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  statusControl: e.target.checked,
                                });
                              }}
                              id={"UserCtrl"}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.statusControl != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Status Control ${
                            !isEditing ? `: ${data.statusControl}` : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.activityLog}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  activityLog: e.target.checked,
                                });
                              }}
                              id={"UserCtrl"}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.activityLog != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Activity Log ${
                            !isEditing ? `: ${data.activityLog}` : ""
                          }`}</label>
                        </div>
                        

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.manageRoles}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  manageRoles: e.target.checked,
                                });
                              }}
                              id={"UserCtrl"}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.manageRoles != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Manage Roles ${
                            !isEditing ? `: ${data.manageRoles}` : ""
                          }`}</label>
                        </div>
                        {/* </SubSelect> */}
                      </div>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderC}>
                          <img src={Entss} />
                          Entries
                          {isEditing && (
                            <input
                              onChange={(e) => {
                                selectAll(
                                  [
                                    "rentCollection",
                                    "utilityCollection",
                                    "serviceChargeCollection",
                                  ],
                                  e.target.checked
                                );
                              }}
                              className={classes.mainInpCheck}
                              type="checkbox"
                            />
                          )}
                        </p>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.rentCollection}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  rentCollection: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.rentCollection != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Rent Collection ${
                            !isEditing ? `: ${data.rentCollection}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.utilityCollection}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  utilityCollection: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.utilityCollection != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Utility Collection ${
                            !isEditing ? `: ${data.utilityCollection}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.serviceChargeCollection}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  serviceChargeCollection: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.serviceChargeCollection != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Service Charge Collection ${
                            !isEditing
                              ? `: ${data.serviceChargeCollection}`
                              : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.viewDue}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  viewDue: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.viewDue != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`View Due ${
                            !isEditing ? `: ${data.viewDue}` : ""
                          }`}</label>
                        </div>


                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className={classes.formDetail}>
                  <div className="">
                    <p className={classes.detailHeaderC}>
                      <img src={Props} />
                      Properties
                      {isEditing && (
                        <input
                          onChange={(e) => {
                            selectAll(
                              [
                                "addProperty",
                                "viewProperty",
                                "editProperty",
                                "addFloor",
                                "viewFloor",
                                "editFloor",
                                "addUnit",
                                "viewUnit",
                                "editUnit",
                                "editUnitMaster",
                              ],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      )}
                    </p>
                    <div className={classes.masterSubDetail}>
                      <div className={classes.subDetail}>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.addProperty}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  addProperty: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.addProperty != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Add Property ${
                            !isEditing ? `: ${data.addProperty}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.viewProperty}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  viewProperty: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.viewProperty != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`View Property ${
                            !isEditing ? `: ${data.viewProperty}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.editProperty}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  editProperty: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.editProperty != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Edit Property Details ${
                            !isEditing ? `: ${data.editProperty}` : ""
                          }`}</label>
                        </div>
                      </div>
                      <div className={classes.subDetail}>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.addFloor}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  addFloor: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.addFloor != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Add Floors ${
                            !isEditing ? `: ${data.addFloor}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.viewFloor}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  viewFloor: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.viewFloor != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`View Floors ${
                            !isEditing ? `: ${data.viewFloor}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.editFloor}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  editFloor: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.editFloor != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Edit Floor Details ${
                            !isEditing ? `: ${data.editFloor}` : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.floorViewAccess}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  floorViewAccess: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.floorViewAccess != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Floor View Access ${
                            !isEditing ? `: ${data.floorViewAccess}` : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.floorViewEdit}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  floorViewEdit: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.floorViewEdit != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Floor View Edit ${
                            !isEditing ? `: ${data.floorViewEdit}` : ""
                          }`}</label>
                        </div>


                      </div>
                      <div className={classes.subDetail}>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.addUnit}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  addUnit: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.addUnit != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Add Units ${
                            !isEditing ? `: ${data.addUnit}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.viewUnit}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  viewUnit: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.viewUnit != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`View Units ${
                            !isEditing ? `: ${data.viewUnit}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.editUnit}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  editUnit: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.editUnit != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Edit Unit Details ${
                            !isEditing ? `: ${data.editUnit}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.editUnitMaster}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  editUnitMaster: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.editUnitMaster != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Edit Unit Master ${
                            !isEditing ? `: ${data.editUnitMaster}` : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.securityDepositAccess}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  securityDepositAccess: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.securityDepositAccess != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Security Deposit Access ${
                            !isEditing ? `: ${data.securityDepositAccess}` : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.viewUnitHistory}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  viewUnitHistory: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.viewUnitHistory != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`View Unit History ${
                            !isEditing ? `: ${data.viewUnitHistory}` : ""
                          }`}</label>
                        </div>


                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className={classes.formDetail}>
                  <div className="">
                    <p className={classes.detailHeaderC}>
                      <img src={Reqs} />
                      Requests
                      {isEditing && (
                        <input
                          onChange={(e) => {
                            selectAll(
                              [
                                "newTenantApplication",
                                "tenantComplain",
                                "unitChangeRequest",
                                "propertyComplain",
                                "otherComplain",
                                "receiveNewTenantApplication",
                                "receiveTenantComplain",
                                "receiveUnitChangeRequest",
                                "receivePropertyComplain",
                                "receiveOtherComplain",
                              ],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      )}
                    </p>
                    <div className={classes.masterSubDetail}>
                      <div className={classes.subDetail}>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.newTenantApplication}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  newTenantApplication: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.newTenantApplication != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Move In Application ${
                            !isEditing ? `: ${data.newTenantApplication}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.changeSecondaryTenant}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  changeSecondaryTenant: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.changeSecondaryTenant != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Change Secondary Tenant ${
                            !isEditing ? `: ${data.changeSecondaryTenant}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.changePositionTenant}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  changePositionTenant: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.changePositionTenant != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Change Position Tenant ${
                            !isEditing ? `: ${data.changePositionTenant}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.cancelTenantApplication}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  cancelTenantApplication: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.cancelTenantApplication != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Move Out Application ${
                            !isEditing ? `: ${data.cancelTenantApplication}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.tenantComplain}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  tenantComplain: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.tenantComplain != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Tenant Complain ${
                            !isEditing ? `: ${data.tenantComplain}` : ""
                          }`}</label>
                        </div>
                        
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.propertyComplain}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  propertyComplain: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.propertyComplain != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Property Complain ${
                            !isEditing ? `: ${data.propertyComplain}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.otherComplain}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  otherComplain: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.otherComplain != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Other Complain ${
                            !isEditing ? `: ${data.otherComplain}` : ""
                          }`}</label>
                        </div>
                      </div>
                      <div className={classes.subDetail}>
                      <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receiveNewTenantApplication}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receiveNewTenantApplication: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receiveNewTenantApplication != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Move In Application ${
                            !isEditing
                              ? `: ${data.receiveNewTenantApplication}`
                              : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receiveChangeSecondaryTenant}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receiveChangeSecondaryTenant: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receiveChangeSecondaryTenant != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Change Secondary Tenant ${
                            !isEditing
                              ? `: ${data.receiveChangeSecondaryTenant}`
                              : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receiveChangePositionTenant}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receiveChangePositionTenant: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receiveChangePositionTenant != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Change Position Tenant ${
                            !isEditing
                              ? `: ${data.receiveChangePositionTenant}`
                              : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receiveCancelTenantApplication}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receiveCancelTenantApplication: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receiveCancelTenantApplication != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Move Out Application ${
                            !isEditing
                              ? `: ${data.receiveCancelTenantApplication}`
                              : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receiveTenantComplain}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receiveTenantComplain: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receiveTenantComplain != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Tenant Complain ${
                            !isEditing ? `: ${data.receiveTenantComplain}` : ""
                          }`}</label>
                        </div>
                        
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receivePropertyComplain}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receivePropertyComplain: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receivePropertyComplain != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Property Complain ${
                            !isEditing
                              ? `: ${data.receivePropertyComplain}`
                              : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.receiveOtherComplain}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  receiveOtherComplain: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.receiveOtherComplain != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Receive Other Complain ${
                            !isEditing ? `: ${data.receiveOtherComplain}` : ""
                          }`}</label>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className={classes.formDetail}>
                  <div className="">
                    <div className={classes.masterSubDetail}>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderC}>
                          <img src={Reps} />
                          Rental Reports
                          {isEditing && (
                            <input
                              onChange={(e) => {
                                selectAll(
                                  [
                                    "rentEntryHistory",
                                    "leaseEnding",
                                    "vacantUnits",
                                  ],
                                  e.target.checked
                                );
                              }}
                              className={classes.mainInpCheck}
                              type="checkbox"
                            />
                          )}
                        </p>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.rentEntryHistory}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  rentEntryHistory: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.rentEntryHistory != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Rent Entry History ${
                            !isEditing ? `: ${data.rentEntryHistory}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.leaseEnding}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  leaseEnding: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.leaseEnding != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Lease Ending ${
                            !isEditing ? `: ${data.leaseEnding}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.vacantUnits}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  vacantUnits: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.vacantUnits != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Vacant Units ${
                            !isEditing ? `: ${data.vacantUnits}` : ""
                          }`}</label>
                        </div>
                      </div>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderC}>
                          <img src={Reps} />
                          Utility Reports
                          {isEditing && (
                            <input
                              onChange={(e) => {
                                selectAll(
                                  [
                                    "meterReadingHistory",
                                    "utilityBillCollectionStatus",
                                  ],
                                  e.target.checked
                                );
                              }}
                              className={classes.mainInpCheck}
                              type="checkbox"
                            />
                          )}
                        </p>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.meterReadingHistory}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  meterReadingHistory: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.meterReadingHistory != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Meter Reading History ${
                            !isEditing ? `: ${data.meterReadingHistory}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.utilityBillCollectionStatus}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  utilityBillCollectionStatus: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.utilityBillCollectionStatus != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Utility Entry History ${
                            !isEditing
                              ? `: ${data.utilityBillCollectionStatus}`
                              : ""
                          }`}</label>
                        </div>
                      </div>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderC}>
                          <img src={Reps} />
                          S.C Reports
                          {isEditing && (
                            <input
                              onChange={(e) => {
                                selectAll(
                                  ["serviceChargeStatus"],
                                  e.target.checked
                                );
                              }}
                              className={classes.mainInpCheck}
                              type="checkbox"
                            />
                          )}
                        </p>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.serviceChargeStatus}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  serviceChargeStatus: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.serviceChargeStatus != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`S.C Entry History ${
                            !isEditing ? `: ${data.serviceChargeStatus}` : ""
                          }`}</label>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className={classes.formDetail}>
                  <div className="">
                    <p className={classes.detailHeaderC}>
                      <img src={Reps} />
                      Financial Reports
                      {isEditing && (
                        <input
                          onChange={(e) => {
                            selectAll(
                              [
                                "fRent",
                                "fUtility",
                                "fServiceCharge",
                                "fRentCollected",
                                "fUtilityCollected",
                                "fServiceChargeCollected",
                                "fRentStatement",
                                "fUtilityStatement",
                                "fServiceChargeStatement",
                              ],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      )}
                    </p>
                    <div className={classes.masterSubDetail}>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderCC}>
                          Account Receiveable
                        </p>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fRent}
                              onChange={(e) => {
                                setData({ ...data, fRent: e.target.checked });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fRent != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Rent ${
                            !isEditing ? `: ${data.fRent}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fUtility}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fUtility: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fUtility != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Utility ${
                            !isEditing ? `: ${data.fUtility}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fServiceCharge}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fServiceCharge: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fServiceCharge != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Service Charge ${
                            !isEditing ? `: ${data.fServiceCharge}` : ""
                          }`}</label>
                        </div>
                      </div>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderCC}>Balance Sheet</p>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fRentCollected}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fRentCollected: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fRentCollected != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Rent Collected ${
                            !isEditing ? `: ${data.fRentCollected}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fUtilityCollected}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fUtilityCollected: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fUtilityCollected != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Utility Collected ${
                            !isEditing ? `: ${data.fUtilityCollected}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fServiceChargeCollected}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fServiceChargeCollected: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fServiceChargeCollected != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Service Charge Collected ${
                            !isEditing
                              ? `: ${data.fServiceChargeCollected}`
                              : ""
                          }`}</label>
                        </div>
                      </div>
                      <div className={classes.subDetail}>
                        <p className={classes.detailHeaderCC}>Statements</p>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fRentStatement}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fRentStatement: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fRentStatement != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Rent Statement ${
                            !isEditing ? `: ${data.fRentStatement}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fUtilityStatement}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fUtilityStatement: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fUtilityStatement != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Utility Statement ${
                            !isEditing ? `: ${data.fUtilityStatement}` : ""
                          }`}</label>
                        </div>
                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.fServiceChargeStatement}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  fServiceChargeStatement: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.fServiceChargeStatement != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`Service Charge Statement ${
                            !isEditing
                              ? `: ${data.fServiceChargeStatement}`
                              : ""
                          }`}</label>
                        </div>

                        <div className={classes.subDetailCheck}>
                          {isEditing && (
                            <input
                              checked={data.viewStatistics}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  viewStatistics: e.target.checked,
                                });
                              }}
                              className={classes.inpCheck}
                              type="checkbox"
                            />
                          )}
                          <label
                            style={
                              data.viewStatistics != true
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >{`View Statistics ${
                            !isEditing ? `: ${data.viewStatistics}` : ""
                          }`}</label>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>

                <br />
                <br />

                <div className="detailButtons">
                  <button
                    className="btn_edit"
                    type="button"
                    onClick={handleEdit}
                  >
                    {!isEditing ? "Edit" : "Save"}
                  </button>
                  {!isEditing && (
                    <button
                      className="btn_del"
                      type="button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  )}
                </div>

                <br />
                <br />
              </form>
            )}
          </div>
        }
      />
    </>
  );
}
