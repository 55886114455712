import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableSubheader from "../TableSubheader";
import TableRow from "../TableRow";
import TableTop from "../TableTop";
import { useState } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import TotalRow from "../TotalRow";

export default function ServiceChargeRecieveable() {
  const navigate = useNavigate();
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [sumTotal, setSumTotal] = useState(0);
  const [propertyId, setPropertyId] = useState("allProperties");
  const [floorId, setFloorId] = useState("");

  const { allProperties, allFloors, rolee } = UserAuth();
  useEffect(() => {
    if (rolee && !rolee.fServiceCharge) {
      navigate("/");
    }
  }, [rolee]);

  const [dynamic, setDynamic] = useState("");

  const getFirstFloor = async (id) => {
    const docRef = await getDocs(
      query(collection(db, "Floors"), where("PropertyId", "==", id))
    );
    console.log("um");
    const arr = [];
    docRef.forEach((doc) => {
      arr.push(doc.id);
    });
    setFloorId(arr[0]);
    setFilter((prev) => ({
      ...prev,
      Floor: arr[0],
    }));
    console.log(arr);
  };

  useEffect(() => {
    setPropertyId(Object.keys(allProperties)[0]);
  }, [allProperties]);

  useEffect(() => {
    console.log(propertyId);
    getFirstFloor(propertyId);
    fetchShops();
  }, [propertyId]);

  useEffect(() => {
    fetchShops();
  }, [floorId]);

  const loopThroughDates = (start, end) => {
    const arr = [];
    if (start == end) {
      arr.push(start);
    } else if (start > end) {
      console.log("Invalid");
    } else {
      let x = start;
      arr.push(x);
      while (x < end) {
        if (x.slice(-2) != 12) {
          x = `${x.substring(0, 4)}-${(
            "0" + String(parseInt(x.slice(-2)) + 1)
          ).slice(-2)}`;
        } else {
          x = `${parseInt(x.substring(0, 4)) + 1}-01`;
        }
        arr.push(x);
      }
    }
    return arr;
  };

  const fetchShops = async () => {
    const dates = loopThroughDates(filter.StartDate, filter.EndDate);

    const obj = {};
    try {
      let querySnapshot;
      if (propertyId == "allProperties" && floorId == "allFloors") {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                orderBy("FloorNo")
              )
            );
          } else if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "ShopNo"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                // orderBy("Block", "asc"),
                where(
                  filter.dynamicFilter[0],
                  "==",
                  parseInt(filter.dynamicFilter[1])
                )
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                // orderBy("Block", "asc"),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("FloorNo"),
              orderBy("Block", "asc"),
              orderBy("ShopNo", "asc")
            )
          );
        }
      } else if (propertyId != "allProperties" && floorId == "allFloors") {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                where("PropertyId", "==", propertyId)
                // where("floorId", "==", floorId),
              )
            );
          } else if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "ShopNo"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                // where("floorId", "==", floorId),
                where(
                  filter.dynamicFilter[0],
                  "==",
                  parseInt(filter.dynamicFilter[1])
                ),

                where("PropertyId", "==", propertyId)
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),

                where("PropertyId", "==", propertyId),
                // where("floorId", "==", floorId),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("FloorNo"),
              orderBy("Block", "asc"),
              orderBy("ShopNo", "asc"),

              where("PropertyId", "==", propertyId)
            )
          );
        }
      } else {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId)
              )
            );
          } else if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "ShopNo"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId),
                where(
                  filter.dynamicFilter[0],
                  "==",
                  parseInt(filter.dynamicFilter[1])
                )
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("Block", "asc"),
              orderBy("ShopNo", "asc"),

              where("PropertyId", "==", propertyId),
              where("floorId", "==", floorId)
            )
          );
        }
      }

      console.log(`${propertyId} ${floorId}`);
      let arr = [];
      querySnapshot.forEach((doc) => {
        if (arr.length != 0 && arr[0].Floor != doc.data().FloorNo) {
          arr = [];
        }
        if (arr.length != 0 && arr[0].Property != doc.data().PropertyName) {
          arr = [];
        }
        const subObj = {};
        subObj["Property"] = doc.data().PropertyName;
        subObj["Floor"] = doc.data().FloorNo;

        let y;
        if (doc.data().Entries.ServiceCharge[filter.EndDate] != undefined) {
          if (
            doc.data().Entries.ServiceCharge[filter.EndDate].Status == "Full"
          ) {
            y = "Paid";
          } else {
            y = "Partially Paid";
          }
        } else {
          y = "Not paid";
        }
        console.log(y);

        let sumReceiveable = 0;
        dates.map((date) => {
          if (doc.data().Entries.ServiceCharge[date] != undefined) {
            sumReceiveable += doc.data().Entries.ServiceCharge[date].Due;
          } else {
            sumReceiveable +=
              (parseInt(doc.data().UnitSpace) +
                parseInt(doc.data().CommonSpace)) *
              parseInt(doc.data().ServiceChargeRate);
          }
        });

        sumReceiveable = sumReceiveable || 0;

        console.log(sumReceiveable);
        arr.push({
          AmountDue: sumReceiveable,
          BusinessName: doc.data().BusinessName,
          id: doc.id,
          ShopNo: doc.data().ShopNo,
          Status: y,
          Month: doc.data().LastPaidServiceCharge,
          Floor: doc.data().FloorNo,
          Property: doc.data().PropertyName,
        });
        console.log(arr);
        subObj["Shops"] = arr;
        obj[`${doc.data().PropertyName} ${doc.data().FloorNo}`] = subObj;
      });
      setData(obj);
      console.log(obj);
    } catch (err) {
      console.log(err);
    }
  };

  //read filter from this state
  const [filter, setFilter] = useState({
    Property: "All Properties",
    Floor: "All Floors",
    Block: "All Blocks",
    Search: "",
  });

  useEffect(() => {
    fetchShops();
    if (filter.Property == "All Properties" && filter.Floor == "All Floors") {
    } else if (
      filter.Property != "allProperties" &&
      filter.Floor == "allFloors"
    ) {
      setPropertyId(filter.Property);
      setFloorId("allFloors");
    } else if (
      filter.Property != "allProperties" &&
      filter.Floor != "allFloors"
    ) {
      setPropertyId(filter.Property);
      setFloorId(filter.Floor);
    } else if (
      filter.Property == "allProperties" &&
      filter.Floor != "allFloors"
    ) {
      setPropertyId("allProperties");
      setFloorId("allFloors");
    } else if (
      filter.Property == "allProperties" &&
      filter.Floor == "allFloors"
    ) {
      setPropertyId("allProperties");
      setFloorId("allFloors");
    }

    filter.dynamicFilter &&
      setDynamic(
        `where("${filter.dynamicFilter[0]}"=="${filter.dynamicFilter[1]}")`
      );

    console.log(filter);
    console.log(floorId);
  }, [filter]);

  useEffect(() => {
    fetchShops();
    // console.log(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  }, []);

  useEffect(() => {
    fetchShops();
  }, [propertyId]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      Property: Object.keys(allProperties)[0],
    }));
  }, [allProperties]);

  //update this state from database
  const [data, setData] = useState({
    // "Ibs Floor2":{
    //     Property: "Ibs",
    //     Floor: "2",
    //     Shops: [
    //         {
    //             BusinessName: "Amin Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //         {
    //             BusinessName: "Mashallah Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //     ]
    // },
    // "Wallahi Floor1":{
    //     Property: "Wallahi",
    //     Floor: "1",
    //     Shops: [
    //         {
    //             BusinessName: "Amin Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //         {
    //             BusinessName: "Mashallah Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //     ]
    // }
  });

  const handleFilter = (data) => {
    setFilter(data);
    // fetchFloors();
  };

  const handleRowClick = (id) => {
    navigate(`/properties/${propertyId}/floors/${floorId}/shops/${id}`);
  };

  function sumAmountDue(data) {
    let sum = 0;
    Object.values(data).forEach(shopList => {
      shopList.Shops.forEach(shop => {
        sum += shop.AmountDue;
      });
    });
    return sum;
  }

  useEffect(()=>{
    setSumTotal(sumAmountDue(data));
  },[data])

  return (
    <>
      <PageTemp
        title="S/C Account Receiveables"
        subTitle="Report View"
        page={
          <>
            {floorId != "" && (
              <TableTop
                all={true}
                defFloor={floorId}
                dStartAndEndDates={true}
                genRep={true}
                defaultProp={propertyId}
                filter={filter}
                handleFilter={handleFilter}
                dProperty={true}
                dFloor={true}
                dBlock={true}
              />
            )}
            <Table>
              <TableHeader headings={["Unit Name", "Status", "Amount Due"]} />

              {data &&
                Object.keys(data).map((building) => {
                  return (
                    <>
                    <TableSubheader
                      property={`${data[building].Property.substring(
                        0,
                        10
                      )}...`}
                      floor={`Floor ${data[building].Floor}`}
                    >
                      {data[building].Shops &&
                        data[building].Shops.length > 0 &&
                        data[building].Shops.map((shop) => {
                          return (
                            <TableRow
                              onClick={handleRowClick}
                              id={`${shop.id}`}
                              info={[`#${shop.ShopNo}`, shop.BusinessName]}
                              status={shop.Status}
                              normalDetails={[shop.AmountDue]}
                            />
                          );
                        })}
                    </TableSubheader>
                    <TotalRow headings={["Unit Name", "Rent Status", "Amount Due"]} total={sumTotal} ></TotalRow>
                    </>
                  );
                })}

              {/* {data.map((d)=>{
                            return(
                                <TableSubheader property={d.PropertyName} floor={d.Floor}>
                                    {d.Shops.map((shop)=>{
                                        return(<TableRow info={[shop.ShopNo,shop.ShopName]} status={shop.Status} normalDetails={[shop.Month]}/>);
                                    })}
                                </TableSubheader>
                            );
                        })} */}
            </Table>
          </>
        }
      />
    </>
  );
}
