import classes from '../styles/Table.module.css'
import downIcon from '../assets/icons/down.svg'
import React, { useState } from 'react';


export default function TableSubheader({property, floor, children}){
    
    const [toggled, setToggled] = useState(true);
    
    return(
        <>
            
            {React.Children.toArray(children).length!=0 && <><tr onClick={()=>setToggled(!toggled)}>
                <td colSpan="1" className={`${classes.rowTitle} ${classes.d}`}><img src={downIcon} /> {`${property} > ${floor}`}</td>
            </tr>
            <tr onClick={()=>setToggled(!toggled)}>
                <td colSpan="1" className={`${classes.rowTitle} ${classes.m}`}><img src={downIcon} /> {`${property} > ${floor}`}</td>
            </tr></>}
            {toggled && children}
            {toggled && React.Children.toArray(children).length!=0 && <tr><td className={classes.breakTd}></td></tr>}
        </>
    );
}