import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { doc, getDoc, deleteDoc, updateDoc, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import UploadIcon from "../../assets/icons/Upload.svg"
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage"
import { useCookies } from 'react-cookie';

export default function Settings(){
    const navigate = useNavigate();
    const {userId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [ownUser, setOwnUser] = useState(false);
    const { user, logout } = UserAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [img, setImg] = useState(null);
    const [dp, setDp] = useState("https://monstar-lab.com/global/wp-content/uploads/sites/11/2019/04/male-placeholder-image.jpeg");
    const [passChanging, setPassChanging] = useState(false);
    const [passData, setPassData] = useState({});
    const [pa, setPa, removePa] = useCookies(['password']);
    // const getDp = () => {
    //     const imgRef = ref(storage, `${user.id}/dp`)
    //     getDownloadURL(imgRef).then((url)=>{
    //         setDp(url);
    //     })
    // }

    const setDpUrl = async (x) => {
        const m = await updateDoc(doc(db,"Users",user.id),{
            dp: x
        });
    }

    const uploadImg = () => {
        if (img == null) return;
        const imgRef = ref(storage, `${user.id}/dp`)
        uploadBytes(imgRef, img).then(()=>{
            console.log("Image Uploaded");
            setImg(null);
            getDownloadURL(imgRef).then((url)=>{
                setDpUrl(url);
            })
        })
        
        
        
    }

    const handleDelete = async() => {
        await deleteDoc(doc(db, "Users", data.id)).then(() => {
            console.log("Entire Document has been deleted successfully.")
            navigate('/users');
        })
        .catch(error => {
            console.log(error);
        });
            
        
    }

    const handleOnChange=(key, x)=>{

      const obj={...data}
      obj[key]=x.target.value;
      setData({...obj});
      console.log(data);
    }

    const handleEdit = async() =>{
      setIsEditing(!isEditing);
      uploadImg();
      await updateDoc(doc(db, "Users", data.id),{
        Name: data.UserName,
        Role: data.Role,
        Designation: data.Designation,
        Email: data.Email,
        Status: data.Status,
        Mobile: data.Mobile
      })
      .catch(error => {
          console.log(error);
      });
    }

    const updatePass = async()=>{
        try{
            if(passData.OldPass==user.Password){
                
                setPa('password', passData.NewPass, { path: '/' });
                console.log("yay");
                setPassChanging(false);
                await updateDoc(doc(db,"Users",user.id),{
                    Password: passData.NewPass
                })
                
            }
            else{
                console.log("Previous Password doesn't match");
                console.log(pa.password);
            }
        }
        catch(err){
            console.log(err);
        }
    }

    const getUserDetails = async(event)=>{
        console.log(user.id);
        try{
            const querySnapshot = await getDoc(doc(db, "Users", user.id));
            
            console.log(querySnapshot.data())
            setData({id: querySnapshot.id, UserName: querySnapshot.data().Name, Role: querySnapshot.data().Role, Designation: querySnapshot.data().Designation, Email: querySnapshot.data().Email, Status: querySnapshot.data().Status, Mobile: querySnapshot.data().Mobile})
            console.log(querySnapshot.data());
            console.log(querySnapshot.id);
            if(querySnapshot.id == user.id){
              setOwnUser(true);
            }
        }
        catch(err){
            console.log(err);
        }
    }

useEffect(()=>{
    console.log(user.id);
    getUserDetails();
},[])

useEffect(()=>{
    uploadImg();
},img)

  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };

    return(
        <>
            <PageTemp title="Settings" subTitle="Change your settings" page={
          <>
            <form action="">

            <div className={classes.formGroup}>
                <div className={classes.mainProfileHolder}>
                    <div>
                    <img className={classes.dp} src={user.dp ? user.dp : "https://monstar-lab.com/global/wp-content/uploads/sites/11/2019/04/male-placeholder-image.jpeg"}></img>
                    <label className={classes.dpChange} htmlFor="fileUpload">
                    <img src={UploadIcon} />
                    Change Picture
                    <input onChange={e=>setImg(e.target.files[0])} id="fileUpload" type="file" style={{display: "none"}} />
                    </label>
                    {!passChanging && <button onClick={()=>setPassChanging(!passChanging)} style={{border: "1px solid red", color: "red", backgroundColor: "#ffeded"}} className="btn_downloadFile">Change Password</button>}
                    
                    </div>
                    
                    <div className={classes.subProfileHolder}>
                        <div className={classes.names}>
                            
                            <label for="">Name</label>
                            {isEditing && <input onChange={(e)=>handleOnChange("UserName",e)} defaultValue={data.UserName}></input>}
                            <label for="">Designation</label>
                            {isEditing && <input onChange={(e)=>handleOnChange("Designation",e)} defaultValue={data.Designation}></input>}
                            <label for="">Email</label>
                            {isEditing && <input onChange={(e)=>handleOnChange("Email",e)} defaultValue={data.Email}></input>}
                            <label for="">Mobile</label>
                            {isEditing && <input onChange={(e)=>handleOnChange("Mobile",e)} defaultValue={data.Mobile}></input>}
                            <label for="">Status</label>
                            {isEditing && <select onChange={e=>setData({...data, Status: e.target.value})} defaultValue={data.Status}>
                                <option val="Active">Active</option>
                                <option val="Inactive">Inactive</option>
                            </select>}
                            
                        </div>
                        <div className={classes.values}>
                            {!isEditing && <label for="">{data.UserName}</label>}
                            {!isEditing && <label for="">{data.Designation}</label>}
                            {!isEditing && <label for="">{data.Email?.length>20 ? `${data.Email?.substring(0,20) || ''}...` : data.Email}</label>}
                            {!isEditing && <label for="">{data.Mobile}</label>}
                            {!isEditing && <label for="">{data.Status}</label>}
                        </div>
                    </div>
                
                </div>
                
            </div>
            
            {
                passChanging && <> 
                
                <label for="">Old Password</label>
                <input onChange={e=>{setPassData({...passData, OldPass: e.target.value})}} ></input>
                <label for="">New Password</label>
                <input onChange={e=>{setPassData({...passData, NewPass: e.target.value})}} ></input>
                <br></br>
                <button type="button" onClick={updatePass} className="btn_updatePassword"> Update</button>
                </>
                
            }


            {!ownUser && <button className="btn_del" type="button" onClick={handleDelete}>Delete</button>}
            
            </form>
          </>
        }/>
        </>
    );
}