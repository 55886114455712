import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import greenCircle from "../../assets/icons/greencircle.svg";
import classes from "../../styles/EntryForm.module.css";

export default function RentEntry() {
  const { user, rolee, bangla } = UserAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const { shopId, propertyId, floorId } = useParams();
  const [excess, setExcess] = useState(false);
  const [partial, setPartial] = useState();
  const [previous, setPrevious] = useState();
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState();

  const [partialData, setPartialData] = useState();

  const handleData = (d) => {
    setPartial(false);
    setExcess(false);
    setData(d);
    getShop();
  };

  const fetchEntries = async () => {
    const docRef = await getDoc(doc(db, "Shops", shopId));
    const arr = [];
    Object.keys(docRef.data().Entries["Rent"]).map((m) => {
      const obj = {};
      obj[m] = docRef.data().Entries.Rent[m];
      arr.push(obj);
    });
    arr.sort((a, b) => {
      if (Object.keys(a)[0] > Object.keys(b)[0]) {
        return 1;
      } else if (Object.keys(a)[0] < Object.keys(b)[0]) {
        return -1;
      }
      return 0;
    });
    const obj2 = {};
    arr.map((m) => {
      obj2[Object.keys(m)[0]] = m[Object.keys(m)[0]];
    });
    setEntries(obj2);
    // setData(docRef.data().Entries["Rent"]);
  };

  const [currentAmount, setCurrentAmount] = useState();

  useEffect(() => {
    console.log(data);
    // setCurrentAmount(data.Amount);
  }, [data]);

  

  useEffect(() => {
    if (
      rolee &&
      !rolee.rentCollection &&
      !rolee.utilityCollection &&
      !rolee.serviceChargeCollection
    ) {
      navigate("/");
    }
  }, [rolee]);

  const getShop = async () => {
    try {
      const docRef = await getDoc(doc(db, `Shops`, shopId));
      let x = docRef.data();

      if (x.NextPayment != data.Month) {
        setPrevious(x.NextPayment);
      } else {
        setPrevious(null);
      }

      let calc = docRef.data().UnitSpace * docRef.data().UnitPrice;
      let parr=[];
      if (x.Entries.Rent[data.Month] != undefined) {
        calc = x.Entries.Rent[data.Month].Due;
        if (x.Entries.Rent[data.Month].Status == "Partial") {
          setPartial(true);
        }
        if(x.Entries.Rent[data.Month].partialData!=undefined){
          parr=[...x.Entries.Rent[data.Month].partialData, {CollectedBy: user.Name, EntriedOn: new Date().toString(), Status: x.Entries.Rent[data.Month].Status, Amount: x.Entries.Rent[data.Month].Amount, AmountCollected: x.Entries.Rent[data.Month].AmountCollected, Due: x.Entries.Rent[data.Month].Due}];
        }
        else{
          parr=[{...x.Entries.Rent[data.Month]}];
        }
        
      }

      
      setData((prev) => ({
        ...prev,
        BusinessName: docRef.data().BusinessName,
        TenantName: docRef.data().TenantName,
        TenantType: docRef.data().TenantType,
        ShopNo: docRef.data().ShopNo,
        Property: docRef.data().PropertyName,
        Floor: docRef.data().FloorNo,
        Amount: data.Amount,
        Block: docRef.data().Block,
        CalculatedAmount: calc,
        parr: parr
      }));

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getShop();
    fetchEntries();
  }, []);

  //Add Entry Button
  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      let PaymentType;
      const obj = {};
      if(partial==true){
        obj["partialData"]=data.parr;
      }
      obj["Amount"] = data.Amount;
      if(data.BusinessName!=undefined){
        obj["BusinessName"]= data.BusinessName;
      }
      if(data.TenantName!=undefined){
        obj["TenantName"]= data.TenantName;
      }
      if(data.TenantType!=undefined){
        obj["TenantType"]= data.TenantType;
      }

      obj["EntryKind"]="Rent";
      
      
      
      obj["EntriedOn"]=new Date().toString();
      // console.log(data);
      if (data.Amount == data.CalculatedAmount) {
        PaymentType = "Full";
      } else if (data.Amount < data.CalculatedAmount) {
        PaymentType = "Partial";
      } else {
        PaymentType = "Excess";
      }
      // console.log(data.Amount)
      obj["AmountCollected"]=data.Amount;
      obj["Status"] = PaymentType;
      obj["CollectedBy"]= user.Name;
      if (data.Amount > data.CalculatedAmount) {
        obj["Due"] = parseInt(data.Amount) - parseInt(data.CalculatedAmount);
      } else if (data.Amount <= data.CalculatedAmount) {
        obj["Due"] = parseInt(data.CalculatedAmount) - parseInt(data.Amount);
      }

      const docRef = await getDoc(doc(db, "Shops", shopId));
      const currentData = docRef.data();
      let nextPay;
      if (data.Month.slice(-2) == 12) {
        nextPay = `${parseInt(data.Month.substring(0, 4)) + 1}-01`;
      } else {
        nextPay = `${data.Month.substring(0, 4)}-${String(
          "0" + (parseInt(data.Month.slice(-2)) + 1)
        ).slice(-2)}`;
      }

      if (currentData["Entries"].Rent[data.Month] != undefined) {
        obj["Amount"] =
          parseInt(data.Amount) +
          parseInt(currentData["Entries"].Rent[data.Month].Amount);
      }

      currentData["Entries"].Rent[data.Month] = obj;
      if (PaymentType == "Full") {
        currentData["NextPayment"] = nextPay;
      }

      currentData["LastPaid"] = data.Month;
      console.log(currentData);
      if (PaymentType != "Excess") {
        const snap = await updateDoc(doc(db, "Shops", shopId), currentData);
        const log = await addDoc(collection(db, "Logs"), {
          CreatedAt: serverTimestamp(),
          Message: `${user.Name} has added a Rent Entry in: ${data.BusinessName} of Month: ${data.Month}`,
        });
      } else {
        setExcess(true);
      }
      getShop();
      setPartial(false);
    } catch (err) {
      console.log(err);
    }
  };

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }

  return (
    <>
      <PageTemp
        title="Rent Collection"
        subTitle="Please enter the following details"
        page={
          <>
            <div className={classes.prevEntCont}>
              <p>{!bangla ? "Previous Entries" : "পুরোনো এন্ট্রি"}</p>
              {entries &&
                Object.keys(entries).map((entry, index) => {
                  if (Object.keys(entries).length - index < 4) {
                    return (
                      <p
                        onClick={() =>
                          navigate(
                            `/properties/${propertyId}/floors/${floorId}/shops/${shopId}/history/${entry}`
                          )
                        }
                        className={classes.prevEntD}
                      >
                        {" "}
                        {`${getMonthName(entry.slice(-2))}, ${entry.substring(
                          0,
                          4
                        )}  :`}{" "}
                        {entries[entry].Status == "Full" && (
                          <img
                            style={{ marginLeft: "1rem" }}
                            src={greenCircle}
                          ></img>
                        )}{" "}
                        {`${entries[entry].Status}`}{" "}
                      </p>
                    );
                  }
                })}
              {/* <p className={classes.prevEntD}>2022-01 : Full Paid</p>
            <p className={classes.prevEntD}>2022-02 : Full Paid</p>
            <p className={classes.prevEntD}>2022-03 : Full Paid</p> */}
            </div>
            <EntryForm
              handleSubmit={handleSubmit}
              data={data}
              handleData={handleData}
              setCurrentAmount={setCurrentAmount}
              isUtility="false"
              alreadyPaid={data.CalculatedAmount != 0}
              excess={excess}
              previous={previous}
              loading={loading}
              partial={partial}
            />
          </>
        }
      />
    </>
  );
}
