
import { db } from "../../firebase";
import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableSubheader from "../TableSubheader";
import TableRow from "../TableRow";
import TableTop from "../TableTop";
import { useState } from "react";
import { collection, getDocs, where, query, orderBy, getDoc, doc } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import classes from "../../styles/Dashboard.module.css";
import {
    Chart as ChartJs,
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar, Pie } from "react-chartjs-2";

ChartJs.register(
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
)

export default function Statistics(){

    const [month, setMonth] = useState();
    const [total, setTotal] = useState();
    const [total2, setTotal2] = useState();
    const [total3, setTotal3] = useState();
    const [rev, setRev] = useState({});
    const [rev2, setRev2] = useState({});
    const [rev3, setRev3] = useState({});
    const [revData, setRevData] = useState();
    const [dataa, setDataa] = useState();
    const [sumPaid, setSumPaid] = useState(0);
    const [sumParPaid, setSumParPaid] = useState(0);
    const [sumNotPaid, setSumNotPaid] = useState(0);
    const [sumPaidUtil, setSumPaidUtil] = useState(0);
    const [sumParPaidUtil, setSumParPaidUtil] = useState(0);
    const [sumNotPaidUtil, setSumNotPaidUtil] = useState(0);
    const [sumPaidSC, setSumPaidSC] = useState(0);
    const [sumParPaidSC, setSumParPaidSC] = useState(0);
    const [sumNotPaidSC, setSumNotPaidSC] = useState(0);

    const [monthData, setMonthData] = useState();
    const getMonths = async () => {
        let today = new Date();
        let thisMonth;
        const arr=[];
        const arr2=[];
        for(let i=0; i<5; i++){
            thisMonth = today.getMonth()-4+i;
            let date= new Date();
            date.setMonth(thisMonth);
            arr.push(`${date.toLocaleString([],{month:'short'})}, ${date.getFullYear()}`)
            arr2.push(`${date.toLocaleString([],{year:'numeric'})}-${String(`0${date.toLocaleString([],{month:'numeric'})}`).slice(-2)}`)
        }
        setMonthData(arr2);
        setMonth(arr);
        getRevenue();
    }

    

    //read filter from this state
    const [filter, setFilter] = useState(
        {
            Property: "All Properties",
            Floor: "All Floors",
            Block: "All Blocks",
            Search: "",
            RevenueType: "Rent"
        }
    )

    const getRevenue = async () => {
        let total=0;
        let total2=0;
        let total3=0;
        try{
            let rev={};
            let rev2={};
            let rev3={};
            const arr=[];
            const arr3=[];
            const arr2=[];
            const docRef = await getDocs(collection(db,"Shops"));
            docRef.forEach(doc=>{
                Object.keys(doc.data()["Entries"].Rent).length!=0 && arr.push(doc.data()["Entries"].Rent);
                Object.keys(doc.data()["Entries"].Utility).length!=0 && arr2.push(doc.data()["Entries"].Utility);
                Object.keys(doc.data()["Entries"].ServiceCharge).length!=0 && arr3.push(doc.data()["Entries"].ServiceCharge);
            })
            arr.map(a=>{
                Object.keys(a).map(o=>{
                    total+=parseInt(a[o].Amount);
                    if((rev[o])){
                        rev[o]+=parseInt(a[o].Amount);
                    }
                    else{
                        rev[o]=parseInt(a[o].Amount);
                    }
                    
                })
            })
            arr2.map(a=>{
                Object.keys(a).map(o=>{
                    total2+=parseInt(a[o].Amount);
                    if((rev2[o])){
                        rev2[o]+=parseInt(a[o].Amount);
                    }
                    else{
                        rev2[o]=parseInt(a[o].Amount);
                    }
                    
                })
            })
            arr3.map(a=>{
                Object.keys(a).map(o=>{
                    total2+=parseInt(a[o].Amount);
                    if((rev3[o])){
                        rev3[o]+=parseInt(a[o].Amount);
                    }
                    else{
                        rev3[o]=parseInt(a[o].Amount);
                    }
                    
                })
            })
            setRev(rev);
            setRev2(rev2);
            setRev3(rev3);
            // setTotal(total);
            // setTotal2(total2);
            // setTotal3(total3);
        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getMonths();
        
    },[])

    useEffect(()=>{
        const arr=[];
        monthData && monthData.map(m=>{
            arr.push(rev[m]);
        })
        setRevData(arr);
    },[rev])

    const data = {
        labels: month ? [...month] : ["Jan","Feb","Mar","Apr","May"],
        datasets: [
            {
                label: 'Total Revenue',
                data: revData ? [...revData] : ["0","0","0","0","0"],
                backgroundColor: ["#0088C2","#00C2AB"],
                borderColor: 'black',
                barPercentage: 0.7,
                categoryPercentage:0.8,
                borderRadius: 7,


            }
        ]

    }

    const data2 = {
        labels: ["Paid", "Not Paid", "Partially Paid"],
        datasets: [
            {
                label: 'Number of Shops',
                data: [filter && filter.RevenueType && filter.RevenueType=="Rent" ? sumPaid : filter.RevenueType=="Utility" ? sumPaidUtil : sumPaidSC,filter && filter.RevenueType && filter.RevenueType=="Rent" ? sumNotPaid : filter.RevenueType=="Utility" ? sumNotPaidUtil : sumNotPaidSC,filter && filter.RevenueType && filter.RevenueType=="Rent" ? sumParPaid : filter.RevenueType=="Utility" ? sumParPaidUtil : sumParPaidSC],
                backgroundColor: ["lightgreen","red","orange"],
                borderColor: "white",
                barPercentage: 0.7,
                borderWidth: 2,
                categoryPercentage:0.8,
            }
        ]

    }



    const handleFilter = (data) => {
        setFilter
        (
            data
        );
        // fetchFloors();
    }

    const [propertyId, setPropertyId] = useState("allProperties");
    const [floorId, setFloorId] = useState("");

    const {allProperties, allFloors} = UserAuth();

    const [dynamic, setDynamic] = useState("");

    const getFirstFloor = async (id) => {
        
        const docRef = await getDocs(query(collection(db, "Floors"), where("PropertyId", "==", id)));
        console.log("um");
        const arr=[];
        docRef.forEach(doc=>{
            arr.push(doc.id);
        })
        setFloorId(arr[0]);
        setFilter(prev=>({
            ...prev,
            Floor: arr[0],
          }))
        console.log(arr);
    }

    useEffect(()=>{
        setPropertyId(Object.keys(allProperties)[0]);
    },[allProperties])

    useEffect(()=>{
        console.log(propertyId);
        getFirstFloor(propertyId);
        fetchShops();
    },[propertyId])

    useEffect(()=>{
        fetchShops();
    },[floorId]);

    const loopThroughDates = (start, end) => {
        const arr=[];
        if(start==end){
            arr.push(start);
        }
        else if(start>end){
            console.log("Invalid")
        }
        else{
            let x=start;
            arr.push(x);
            while(x<end){
                
                if(x.slice(-2)!=12){
                    x=`${x.substring(0,4)}-${("0"+String(parseInt(x.slice(-2))+1)).slice(-2)}`
                }
                else{
                    x=`${parseInt(x.substring(0,4))+1}-01`
                }
                arr.push(x);
            }
        }
        return arr;
    }

    const fetchShops = async()=>{
        const dates=loopThroughDates(filter.StartDate, filter.EndDate)

        const obj={};
        try{
            
            let querySnapshot;
            if (propertyId == "allProperties" && floorId == "allFloors") {
                if(filter.dynamicFilter && filter.dynamicFilter[1]!=""){
                  if(filter.dynamicFilter && filter.dynamicFilter[0]=="BusinessName"){
                    querySnapshot = await getDocs(
                      query(
                        collection(db, "Shops"),
                        where("BusinessName", ">=", filter.dynamicFilter[1].toString()), 
                        where("BusinessName", "<=", filter.dynamicFilter[1].toString()+ '\uf8ff'),
                        orderBy("BusinessName"),
                        orderBy("PropertyName"),
                        
                      )
                    );
                  }
                  else{
                    querySnapshot = await getDocs(
                      query(
                        collection(db, "Shops"),
                        orderBy("PropertyName"),
                        where(filter.dynamicFilter[0],"==",filter.dynamicFilter[1])
                      )
                    );
                  }
                  
                }
                else{
                  querySnapshot = await getDocs(
                    query(
                      collection(db, "Shops"),
                      orderBy("PropertyName"),
                      orderBy("FloorNo"),
                    )
                  );
                }
                
              } else if (propertyId != "allProperties" && floorId == "allFloors") {

                if(filter.dynamicFilter && filter.dynamicFilter[1]!=""){
                    if(filter.dynamicFilter && filter.dynamicFilter[0]=="BusinessName"){
                      querySnapshot = await getDocs(
                        query(
                          collection(db, "Shops"),
                          where("BusinessName", ">=", filter.dynamicFilter[1].toString()), 
                          where("BusinessName", "<=", filter.dynamicFilter[1].toString()+ '\uf8ff'),

                          where("PropertyId", "==", propertyId),
                          orderBy("BusinessName"),
                          orderBy("FloorNo"),
                          orderBy("PropertyName"),
                          
                        )
                      );
                    }
                    else{
                      querySnapshot = await getDocs(
                        query(
                          collection(db, "Shops"),
                          orderBy("PropertyName"),
                          orderBy("FloorNo"),
                          where("PropertyId", "==", propertyId),
                          where(filter.dynamicFilter[0],"==",filter.dynamicFilter[1])
                        )
                      );
                    }
                    
                  }
                  else{
                    querySnapshot = await getDocs(
                        query(
                          collection(db, "Shops"),
                          orderBy("PropertyName"),
                          orderBy("FloorNo"),
                          where("PropertyId", "==", propertyId),
                        )
                      );
                  }

                
              } else {
                if(filter.dynamicFilter && filter.dynamicFilter[1]!=""){
                  if(filter.dynamicFilter && filter.dynamicFilter[0]=="BusinessName"){
                    querySnapshot = await getDocs(
                      query(
                        collection(db, "Shops"),
                        where("BusinessName", ">=", filter.dynamicFilter[1].toString()), 
                        where("BusinessName", "<=", filter.dynamicFilter[1].toString()+ '\uf8ff'),
                        orderBy("BusinessName"),
                        orderBy("PropertyName"),
                        where("PropertyId", "==", propertyId),
                        where("floorId", "==", floorId),
                        
                      )
                    );
                  }
                  else{
                    querySnapshot = await getDocs(
                      query(
                        collection(db, "Shops"),
                        orderBy("PropertyName"),
                        where("PropertyId", "==", propertyId),
                        where("floorId", "==", floorId),
                        where(filter.dynamicFilter[0],"==",filter.dynamicFilter[1])
                      )
                    );
                  }
                  
                }
                else{
                  querySnapshot = await getDocs(
                    query(
                      collection(db, "Shops"),
                      orderBy("PropertyName"),
                      where("PropertyId", "==", propertyId),
                      where("floorId", "==", floorId),
                    )
                  );
                }
                
              }
            
            console.log(`${propertyId} ${floorId}`)
            let arr=[];
            let summ=0;
            let summUtil=0;
            let summSC=0;
            let sumRec=0;
            let sumRecUtil=0;
            let sumRecSC=0;

            let sumPaid=0;
            let sumNotPaid=0;
            let sumParPaid=0;
            let sumPaidUtil=0;
            let sumNotPaidUtil=0;
            let sumParPaidUtil=0;
            let sumPaidSC=0;
            let sumNotPaidSC=0;
            let sumParPaidSC=0;
            querySnapshot.forEach(doc=>{
                if(arr.length!=0 && arr[0].Floor!=doc.data().FloorNo){
                    arr=[];
                }
                if(arr.length!=0 && arr[0].Property!=doc.data().PropertyName){
                    arr=[];
                }
                const subObj={}
                subObj["Property"]=doc.data().PropertyName;
                subObj["Floor"]=doc.data().FloorNo;
                
                let y;
                if(doc.data().Entries.Rent[filter.EndDate]!=undefined){
                    
                    if(doc.data().Entries.Rent[filter.EndDate].Status=="Full"){
                        // y="Paid";
                        sumPaid++;
                    }
                    else{
                        // y="Partially Paid"
                        sumParPaid++;
                    }

                    
                }
                else{
                    // y =("Not paid");
                    sumNotPaid++;
                }

                if(doc.data().Entries.Utility[filter.EndDate]!=undefined){
                    
                    if(doc.data().Entries.Utility[filter.EndDate].Status=="Full"){
                        // y="Paid";
                        sumPaidUtil++;
                    }
                    else{
                        // y="Partially Paid"
                        sumParPaidUtil++;
                    }

                    
                }
                else{
                    // y =("Not paid");
                    sumNotPaidUtil++;
                }

                if(doc.data().Entries.ServiceCharge[filter.EndDate]!=undefined){
                    
                    if(doc.data().Entries.ServiceCharge[filter.EndDate].Status=="Full"){
                        // y="Paid";
                        sumPaidSC++;
                    }
                    else{
                        // y="Partially Paid"
                        sumParPaidSC++;
                    }

                    
                }
                else{
                    // y =("Not paid");
                    sumNotPaidSC++;
                }


                console.log(y);

                let sumReceiveable = 0;
                let sumReceiveableUtil = 0;
                let sumReceiveableSC = 0;
                let sumDue = 0;
                let sumDueUtil = 0;
                let sumDueSC = 0;
                dates.map(date=>{
                    if(doc.data().Entries.Rent[date]!=undefined){
                        sumReceiveable += parseInt(doc.data().Entries.Rent[date].Amount);
                        sumDue+=parseInt(doc.data().Entries.Rent[date].Due);
                    }
                    else{
                        if(doc.data().UnitSpace!=undefined && doc.data().UnitPrice!=undefined){
                            sumDue+=parseInt(doc.data().UnitSpace * doc.data().UnitPrice);
                        }
                    }

                    if(doc.data().Entries.Utility[date]!=undefined){
                        sumReceiveableUtil += parseInt(doc.data().Entries.Utility[date].Amount);
                        sumDueUtil+=parseInt(doc.data().Entries.Utility[date].Due);
                    }
                    else{
                        // sumDue+=parseInt(doc.data().UnitSpace * doc.data().UnitPrice);
                    }

                    if(doc.data().Entries.ServiceCharge[date]!=undefined){
                        sumReceiveableSC += parseInt(doc.data().Entries.ServiceCharge[date].Amount);
                        sumDueSC+=parseInt(doc.data().Entries.ServiceCharge[date].Due);
                    }
                    else{
                        if(doc.data().UnitSpace!=undefined && doc.data().CommonSpace!=undefined && doc.data().ServiceChargeRate!=undefined){
                            sumDueSC+=(parseInt(doc.data().UnitSpace) + parseInt(doc.data().CommonSpace)) * parseInt(doc.data().ServiceChargeRate)

                        }
                    }
                })
                console.log(sumReceiveable);
                summ+=sumReceiveable;
                summUtil+=sumReceiveableUtil;
                summSC+=sumReceiveableSC;
                sumRec+=sumDue;
                sumRecUtil+=sumDueUtil;
                sumRecSC+=sumDueSC;
                arr.push({
                    AmountDue: sumReceiveable,
                    BusinessName: doc.data().BusinessName,
                    id: doc.id,
                    ShopNo: doc.data().ShopNo,
                    Status: y,
                    Month: doc.data().LastPaid,
                    Floor: doc.data().FloorNo,
                    Property: doc.data().PropertyName
                })
                console.log(arr);
                subObj["Shops"]=arr;
                obj[`${doc.data().PropertyName} ${doc.data().FloorNo}`]=subObj;
            })
            setDataa(obj);
            setTotal(summ);
            setTotal2(summUtil);
            setTotal3(summSC);
            setRec(sumRec);
            setRecUtil(sumRecUtil);
            setRecSC(sumRecSC);

            setSumPaid(sumPaid);
            setSumParPaid(sumParPaid);
            setSumNotPaid(sumNotPaid);
            setSumPaidUtil(sumPaidUtil);
            setSumParPaidUtil(sumParPaidUtil);
            setSumNotPaidUtil(sumNotPaidUtil);
            setSumPaidSC(sumPaidSC);
            setSumParPaidSC(sumParPaidSC);
            setSumNotPaidSC(sumNotPaidSC);
            console.log(obj);
        }
        catch(err){
            console.log(err);
        }

    }

    
    
    
    
    

    useEffect(()=>{
        fetchShops();
        if (filter.Property == "All Properties" && filter.Floor == "All Floors") {

        }
        else if(filter.Property != "allProperties" && filter.Floor == "allFloors"){
          setPropertyId(filter.Property);
          setFloorId("allFloors");
        }
        else if(filter.Property != "allProperties" && filter.Floor != "allFloors"){
            setPropertyId(filter.Property);
            setFloorId(filter.Floor);
        }
        else if(filter.Property == "allProperties" && filter.Floor != "allFloors"){
            setPropertyId("allProperties");
            setFloorId("allFloors");
        }
        else if(filter.Property == "allProperties" && filter.Floor == "allFloors"){
            setPropertyId("allProperties");
            setFloorId("allFloors");
        }

        filter.dynamicFilter  && setDynamic(`where("${filter.dynamicFilter[0]}"=="${filter.dynamicFilter[1]}")`);

        console.log(filter);
        console.log(floorId)
    },[filter])

    useEffect(()=>{
        fetchShops();
        // console.log(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
    },[]);

    useEffect(()=>{
        fetchShops();
        
    },[propertyId])

    useEffect(()=>{
        setFilter(prev=>({
            ...prev,
            Property: Object.keys(allProperties)[0],
          }))
    },[allProperties])

    const [rec, setRec] = useState();
    const [recUtil, setRecUtil] = useState();
    const [recSC, setRecSC] = useState();

    return(
        <>
            <PageTemp title="Statistics" subTitle="View all statistics here" page={
                <>
                    {floorId!="" && <TableTop RevenueType={true} all={true} defFloor={floorId} dStartAndEndDates={true} defaultProp={propertyId} filter={filter} handleFilter={handleFilter} dProperty={true} dFloor={true} />}
                    <div className={classes.numbersContainer}>
                        <div className={classes.nR}>
                            <p>Receiveable</p>
                            <h1>{`৳${filter && filter.RevenueType && (filter.RevenueType=="Rent" ? (rec && rec.toLocaleString('en-IN')) : filter.RevenueType=="Utility" ? (recUtil && recUtil.toLocaleString('en-IN')) : recSC && recSC.toLocaleString('en-IN'))}`}</h1>
                        </div>
                        <div className={classes.tR}>
                            <p>Collected</p>
                            <h1>{`৳${filter && filter.RevenueType && (filter.RevenueType=="Rent" ? (total && total.toLocaleString('en-IN')) : filter.RevenueType=="Utility" ? total2 && total2.toLocaleString('en-IN') : total3 && total3.toLocaleString('en-IN')) }`}</h1>
                        </div>
                        {/* <div className={classes.totals}>
                            <p>Total Utility Bill</p>
                            <h1>{`৳${total2 && total2.toLocaleString('en-IN')}`}</h1>
                        </div>
                        <div className={classes.totals}>
                            <p>Total S/C</p>
                            <h1>{`৳${total3 && total3.toLocaleString('en-IN')}`}</h1>
                        </div> */}
                    </div>
                    <br></br>
                    <br></br>
                    <div className={classes.smainn}>
                        <div className={classes.statP} >
                            
                            <div className={classes.titles}>
                                
                                <p>Revenue</p>
                            </div>
                            
                        <Bar
                        style={{transform: "scale(0.75)"}}
                        className={classes.bar}
                        data={data}
                        options={
                        
                            {
                            responsive:true,
                            
                            maintainAspectRatio:false,
                            padding: 0,
                            margin: 0,
                            plugins: {
                                legend: {
                                  display: false
                                },
                                
                            },
                            scales: {
                                y: 
                                {
                                    display: false,
                                    grid: {
                                        display: false
                                    }
                                },
                                x:{
                                    grid:{
                                        display: false,
                                    }
                                }
                             },
                            }
                            
                            
                        }
                        ></Bar>  
                        
                        </div>
                        <div className={classes.statP}>
                        <Pie
                        style={{transform: "scale(0.85)"}}
                        data={data2}
                        options={{
                            
                        }}
                        ></Pie>
                        </div>
                    
                        
                    </div>
                    
                    
                </>
            }/>
        </>
    );
}