import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
  setDoc,
  deleteField,
  arrayUnion,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import Files from "../../assets/icons/Files.svg";
import submitIcon from "../../assets/icons/Save.svg";

import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import QRCode from "react-qr-code";
import { deleteObject, ref } from "firebase/storage";

export default function PendingChangeSecondary() {
  const [secondary, setSecondary] = useState(true);
  const [vacant, setVacant] = useState(false);
  const [toggleOld, setToggleOld] = useState(false);

  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, rolee, bangla } = UserAuth();
  const navigate = useNavigate();
  const { shopId, propertyId, floorId, reqId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const [properties, setProperties] = useState({});
  const [floors, setFloors] = useState();

  const handleDelete = async () => {
    const docRef = await setDoc(doc(db, "PastRequests", reqId), {
      ...data,
      RequestedBy: data.RequestedBy,
      ApprovedBy: user.Name,
      Status: "Declined",
    });

    await deleteDoc(doc(db, `Requests`, reqId))
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = async () => {
    setIsEditing(!isEditing);

    await updateDoc(doc(db, `Requests`, reqId), data).catch((error) => {
      console.log(error);
    });
    getPropertyDetails();
  };

  const getPropertyDetails = async (event) => {
    console.log(propertyId);
    console.log(floorId);
    console.log(shopId);
    try {
      const querySnapshot = await getDoc(doc(db, `Requests`, reqId));
      console.log(querySnapshot.data());
      setData(querySnapshot.data());
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleOnChange = (key, x) => {
    const obj = { ...data };
    obj[key] = x.target.value;
    setData({ ...obj });
    console.log(data);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      await updateDoc(doc(db, "Shops", data.shopId), {
        
        SecondaryTenant: deleteField(),
        SecondaryFatherName: deleteField(),
        SecondaryHomeAddress: deleteField(),
        SecondaryMobile: deleteField(),
        SecondaryNID: deleteField(),
        SecondaryPassportNumber: deleteField(),
        SecondaryTenantRent: deleteField(),
        
        ...data,
        
      });
      await deleteDoc(doc(db, "Requests", reqId));
      const docRef = await setDoc(doc(db, "PastRequests", reqId), {
        ...data,
        RequestedBy: data.RequestedBy,
        ApprovedBy: user.Name,
        Status: "Approved",
      })
      setLoading(false);
      setDone(true);
      setTimeout(()=>{
        navigate(`/`);
      }, 1000);

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };

  const fetchFloors = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, `Properties/${propertyId}/Floors`),
          where("PropertyId", "==", properties[propertyId])
        )
      );

      const obj = {};
      querySnapshot.forEach((doc) => {
        obj[doc.data().FloorNo] = doc.id;
      });
      setFloors(obj);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProperties = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, `Properties`));
      const obj = {};
      querySnapshot.forEach((doc) => {
        obj[doc.data().PropertyName] = doc.id;
      });
      setProperties(obj);
      fetchShop();
    } catch (err) {
      console.log(err);
    }
  };

  const [shopData, setShopData] = useState();

  const fetchShop = async () => {
    try {
        const today= new Date();

      const docRef = await getDoc(doc(db, "Shops", data.shopId));
      setShopData({...docRef.data(), LeaseEnding: `${today.getFullYear()}-${`0${today.getMonth()+1}`.slice(-2)}`});
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(floors);
  }, [floors]);

  useEffect(() => {
    fetchFloors();
  }, [properties]);

  useEffect(() => {
    fetchProperties();
  }, [data]);

  useEffect(() => {
    if (data.TenantType == "Vacant") {
      setVacant(true);
    } else {
      setVacant(false);
    }
  }, [data]);

  const updateDeleteFiles = async(url) => {
    const obj = data.files;
    delete obj[url];
    try{
      await updateDoc(doc(db,"Properties",propertyId),{
        files: obj
      })
      getPropertyDetails();
    }
    catch(err){
      console.log(err);
    }
  }
  
  const deletefile = async (url) => {
    const desertRef = ref(storage, `properties/${propertyId}/${url}`);
    
    deleteObject(desertRef).then(() => {
      updateDeleteFiles(url);
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      <PageTemp
        img={data && data.dp && data.dp}
        title={shopData && shopData.BusinessName}
        subTitle={!isLoading && "Secondary Tenant Request"}
        page={
          isLoading ? (
            <>
              <div style={{ marginLeft: "2rem" }} className={classes2.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              <form action="">
                
                <div className={classes.formGroup}></div>
                
                
                  {/* <div className={classes.detail}>
        <p className={classes.detailHeader}>QR Code</p>
        <div className={classes.subDetail} style={{border: "1px solid #D6D6D6", borderRadius: "2rem"}}>
            <QRCode style={{transform: "scale(0.7)"}} value={`https://www.rentmilloh.com/properties/${propertyId}/${floorId}/shops/${shopId}/rent`}/>
        </div>
        <br />
    </div> */}
                <br></br>
                <br></br>
                {/* <div style={{display: "flex", flexDirection: "column"}}>
                <label style={{fontWeight: "600"}}>
                          {!bangla
                            ? `Position Sale Amount${
                                `: ${data.PositionSaleAmount}`
                              }`
                            : `Please update${
                                `: ${data.PositionSaleAmount}`
                              }`}
                        </label>

                        <label style={{fontWeight: "600"}}>
                          {!bangla
                            ? `Comission On Sale${
                                `: ${data.ComissionOnSale}`
                              }`
                            : `Please update${
                                `: ${data.ComissionOnSale}`
                              }`}
                        </label>
                        </div>
                        <br></br> */}
                <div className={classes.formDetail}>
                  
                  {/* <div className={classes.detail}>
        <p className={classes.detailHeader}><img src={shopDetails} />Unit Details</p>
        <div className={classes.subDetail}>
            
            
        </div>
        <br />
    </div> */}

                  {rolee.editUnitMaster && shopData && (
                    <>
                    
                    <div className={classes.detail}>
                      
                      <p className={classes.detailHeader}>
                        <img src={Location} />
                        {!bangla ? "Location" : "ঠিকানা"}
                      </p>
                      <div className={classes.subDetail}>
                        {
                          <label>
                            {!bangla
                              ? `Property${`: ${shopData.PropertyName}`}`
                              : `প্রপার্টি${`: ${shopData.PropertyName}`}`}
                          </label>
                        }
                        {/* {isEditing && <select onChange={e=>setData({...data, Property: e.target.value})} defaultValue={data.Property}>
                <option >Select</option>    
                {
                    
                    Object.keys(properties).map((key)=>{
                        return <option val={key}>{key}</option>
                    })
                } */}

                        {/* {properties.map((property)=>{
                    return <option val={property.PropertyID}>{property.PropertyName}</option>
                })} */}

                        {/* </select>} */}
                        <>
                          {
                            <label>
                              {!bangla
                                ? `Floor${`: ${shopData.FloorNo}`}`
                                : `ফ্লোর${`: ${shopData.FloorNo}`}`}
                            </label>
                          }
                          {/* {isEditing && floors && Object.keys(floors)?.length>0 && 
            <select onChange={e=>setData({...data, Floor: e.target.value})} defaultValue={data.Floor}>
                {
                    Object.keys(floors).map((key)=>{
                        return <option val={key}>{`${key}`}</option>
                    })
                }
            </select>} */}
                        </>
                        <label>
                          {!bangla
                            ? `Block${`: ${shopData.Block}`}`
                            : `ব্লক${`: ${shopData.Block}`}`}
                        </label>
                        {/* {isEditing && <input defaultValue={data.Block} onChange={e=>setData({...data, Block: e.target.value})} type="text" placeholder="Enter Block" />} */}
                        <label>
                          {!bangla
                            ? `Unit No.${`: ${shopData.ShopNo}`}`
                            : `ইউনিট নং${`: ${shopData.ShopNo}`}`}
                        </label>
                        {/* {isEditing && <input defaultValue={data.ShopNo} onChange={e=>setData({...data, ShopNo: e.target.value})} type="text" placeholder="Enter Shop No." />} */}
                        <label>
                          {!bangla
                            ? `Tenant Type${`: ${shopData.TenantType}`}`
                            : `ভাড়াটে প্রকার${`: ${shopData.TenantType}`}`}
                        </label>
                        {rolee.editUnitMaster && (
                            <>
                              <label>
                                {!bangla
                                  ? `Unit Space${
                                      `: ${shopData.UnitSpace}`
                                    }`
                                  : `ইউনিট সাইজ${
                                      `: ${shopData.UnitSpace}`
                                    }`}
                              </label>
                              
                            </>
                          )}
                        {/* {isEditing && <select onChange={e=>setData({...data, TenantType: e.target.value})} defaultValue={data.TenantType}>
                <option val="Vacant">Vacant</option>
                <option val="Primary">Primary</option>
                <option val="Position">Position</option>
            </select>} */}
                      </div>
                    </div></>
                  )}
                  {/* <div className={classes.detail}>
                    <p className={classes.detailHeader}>
                      <img src={EntryInfo} />
                      {!bangla ? "Entry Info" : "এন্ট্রি তথ্য"}
                    </p>
                    <div className={classes.subDetail}>
                      <label>
                        {!bangla
                          ? `Rent: ${data.hasRent ? data.hasRent : "Off"}`
                          : `ভাড়া: ${data.hasRent ? data.hasRent : "Off"}`}
                      </label>

                      {data && isEditing && (
                        <>
                          <input
                            className={classes.switch}
                            defaultChecked={data.hasRent ? data.hasRent : false}
                            onChange={(e) =>
                              setData({ ...data, hasRent: e.target.checked })
                            }
                            type="checkbox"
                          />
                        </>
                      )}
                      {data && data.hasRent && (
                        <>
                          <label>{`Next Rent Payment Date${
                            !isEditing ? `: ${data.NextPayment}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.NextPayment}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  NextPayment: e.target.value,
                                })
                              }
                              type="month"
                              placeholder="Next Rent Payment"
                            />
                          )}
                          
                          <label>{`Unit rate per sq.${
                            !isEditing ? `: ${data.UnitPrice}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.UnitPrice}
                              onChange={(e) =>
                                setData({ ...data, UnitPrice: e.target.value })
                              }
                              type="text"
                              placeholder="Enter Unit Price"
                            />
                          )}
                          {!isEditing && (
                            <label>{`Rent Due${
                              !isEditing ? `: ${data.RentDue}` : ""
                            }`}</label>
                          )}
                        </>
                      )}

                      <label>
                        {!bangla
                          ? `Utility: ${
                              data.hasUtility ? data.hasUtility : "Off"
                            }`
                          : `ইউটিলিটি: ${
                              data.hasUtility ? data.hasUtility : "Off"
                            }`}
                      </label>

                      {data && isEditing && (
                        <>
                          <input
                            className={classes.switch}
                            defaultChecked={
                              data.hasUtility ? data.hasUtility : false
                            }
                            onChange={(e) =>
                              setData({ ...data, hasUtility: e.target.checked })
                            }
                            type="checkbox"
                          />
                        </>
                      )}

                      {data && data.hasUtility && (
                        <>
                          <label>{`Next Utility Payment Date${
                            !isEditing ? `: ${data.NextPaymentUtility}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.NextPaymentUtility}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  NextPaymentUtility: e.target.value,
                                })
                              }
                              type="month"
                              placeholder="Next Utility Payment"
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Previous Usage${
                                  !isEditing
                                    ? `: ${data.PreviousMeterUsage}`
                                    : ""
                                }`
                              : `মিটার উজেজ${
                                  !isEditing
                                    ? `: ${data.PreviousMeterUsage}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.PreviousMeterUsage}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  PreviousMeterUsage: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Previous Usage"
                                  : "মিটার উজেজ লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Utility Rate${
                                  !isEditing ? `: ${data.MeterRate}` : ""
                                }`
                              : `ইউটিলিটি রেট${
                                  !isEditing ? `: ${data.MeterRate}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.MeterRate}
                              onChange={(e) =>
                                setData({ ...data, MeterRate: e.target.value })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Meter Rate"
                                  : "ইউটিলিটি রেট লিখুন"
                              }
                            />
                          )}
                          {!isEditing && (
                            <label>{`Utility Due${
                              !isEditing ? `: ${data.UtilityDue}` : ""
                            }`}</label>
                          )}
                        </>
                      )}

                      <label>
                        {!bangla
                          ? `Service Charge: ${data.hasSC ? data.hasSC : "Off"}`
                          : `সার্ভিস চার্জ: ${data.hasSC ? data.hasSC : "Off"}`}
                      </label>

                      {data && isEditing && (
                        <>
                          <input
                            className={classes.switch}
                            defaultChecked={data.hasSC ? data.hasSC : false}
                            onChange={(e) =>
                              setData({ ...data, hasSC: e.target.checked })
                            }
                            type="checkbox"
                          />
                        </>
                      )}

                      {data && data.hasSC && (
                        <>
                          <label>{`Next S/C Payment Date${
                            !isEditing
                              ? `: ${data.NextPaymentServiceCharge}`
                              : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.NextPaymentServiceCharge}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  NextPaymentServiceCharge: e.target.value,
                                })
                              }
                              type="month"
                              placeholder="Next S/C Payment"
                            />
                          )}

                          <label>
                            {!bangla
                              ? `Common Space${
                                  !isEditing ? `: ${data.CommonSpace}` : ""
                                }`
                              : `কমন স্পেস${
                                  !isEditing ? `: ${data.CommonSpace}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.CommonSpace}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  CommonSpace: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Common Space"
                                  : "কমন স্পেস লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Service Charge Rate${
                                  !isEditing
                                    ? `: ${data.ServiceChargeRate}`
                                    : ""
                                }`
                              : `সার্ভিস চার্জ রেট${
                                  !isEditing
                                    ? `: ${data.ServiceChargeRate}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.ServiceChargeRate}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  ServiceChargeRate: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Service Charge Rate"
                                  : "সার্ভিস চার্জ রেট লিখুন"
                              }
                            />
                          )}

                          {!isEditing && (
                            <label>{`Service Charge Due${
                              !isEditing ? `: ${data.ServiceChargeDue}` : ""
                            }`}</label>
                          )}
                        </>
                      )}
                      <label>
                          {!bangla
                            ? `Position Sale Amount${
                                `: ${data.PositionSaleAmount}`
                              }`
                            : `Please update${
                                `: ${data.PositionSaleAmount}`
                              }`}
                        </label>

                        <label>
                          {!bangla
                            ? `Comission On Sale${
                                `: ${data.ComissionOnSale}`
                              }`
                            : `Please update${
                                `: ${data.ComissionOnSale}`
                              }`}
                        </label>
                    </div>
                    <br />
                  </div> */}
                </div>
                <br />
                <br />
                
                {!vacant && shopData && (
                  <div className={classes.formDetail}>
                    
                    <div className={classes.detail}>
                      <p className={classes.detailHeader}>
                        <img src={SecondaryTenant} />
                        Old Secondary Tenant Details
                      </p>
                      {isEditing && (
                        <input
                          defaultChecked
                          className={classes.switch}
                          onChange={() => setSecondary(!secondary)}
                          type="checkbox"
                        />
                      )}
                      {secondary && (
                        <div className={classes.subDetail}>
                          <label>
                            {!bangla
                              ? `Business/Tenant's Name${
                                  !isEditing ? `: ${shopData.SecondaryName}` : ""
                                }`
                              : `ব্যবসার/ভাড়াটে নাম${
                                  !isEditing ? `: ${shopData.SecondaryName}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryName}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryName: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Tenant's Name"
                                  : "ভাড়াটে নাম লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Father's Name${
                                  !isEditing
                                    ? `: ${shopData.SecondaryFatherName}`
                                    : ""
                                }`
                              : `পিতার নাম${
                                  !isEditing
                                    ? `: ${shopData.SecondaryFatherName}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryFatherName}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryFatherName: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Father's Name"
                                  : "পিতার নাম লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Home Address${
                                  !isEditing
                                    ? `: ${shopData.SecondaryHomeAddress}`
                                    : ""
                                }`
                              : `বাসার ঠিকানা${
                                  !isEditing
                                    ? `: ${shopData.SecondaryHomeAddress}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryHomeAddress}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryHomeAddress: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Home Address"
                                  : "বাসার ঠিকানা লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Mobile Number${
                                  !isEditing ? `: ${shopData.SecondaryMobile}` : ""
                                }`
                              : `মোবাইল নম্বর${
                                  !isEditing ? `: ${shopData.SecondaryMobile}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryMobile}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryMobile: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Mobile No"
                                  : "মোবাইল নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `National ID${
                                  !isEditing ? `: ${shopData.SecondaryNID}` : ""
                                }`
                              : `এনাইডি নম্বর${
                                  !isEditing ? `: ${shopData.SecondaryNID}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryNID}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryNID: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla ? "Enter NID" : "এনাইডি নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Passport Number${
                                  !isEditing
                                    ? `: ${shopData.SecondaryPassportNumber}`
                                    : ""
                                }`
                              : `পাসপোর্ট নম্বর${
                                  !isEditing
                                    ? `: ${shopData.SecondaryPassportNumber}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryPassportNumber}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryPassportNumber: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Passport No"
                                  : "পাসপোর্ট নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>{`Secondary Tenant Rent${
                            !isEditing ? `: ${shopData.SecondaryTenantRent}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={shopData.SecondaryTenantRent}
                              onChange={(e) =>
                                setData({
                                  ...shopData,
                                  SecondaryTenantRent: e.target.value,
                                })
                              }
                              type="text"
                              placeholder="Enter Rent"
                            />
                          )}
                        </div>
                      )}
                      <br />
                      <br />
                    </div>
                    <div className={classes.detail}>
                      <p className={classes.detailHeader}>
                        <img src={SecondaryTenant} />
                        New Secondary Tenant Details
                      </p>
                      {isEditing && (
                        <input
                          defaultChecked
                          className={classes.switch}
                          onChange={() => setSecondary(!secondary)}
                          type="checkbox"
                        />
                      )}
                      {secondary && (
                        <div className={classes.subDetail}>
                          <label>
                            {!bangla
                              ? `Business/Tenant's Name${
                                  !isEditing ? `: ${data.SecondaryName}` : ""
                                }`
                              : `ব্যবসার/ভাড়াটে নাম${
                                  !isEditing ? `: ${data.SecondaryName}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryName}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryName: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Tenant's Name"
                                  : "ভাড়াটে নাম লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Father's Name${
                                  !isEditing
                                    ? `: ${data.SecondaryFatherName}`
                                    : ""
                                }`
                              : `পিতার নাম${
                                  !isEditing
                                    ? `: ${data.SecondaryFatherName}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryFatherName}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryFatherName: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Father's Name"
                                  : "পিতার নাম লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Home Address${
                                  !isEditing
                                    ? `: ${data.SecondaryHomeAddress}`
                                    : ""
                                }`
                              : `বাসার ঠিকানা${
                                  !isEditing
                                    ? `: ${data.SecondaryHomeAddress}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryHomeAddress}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryHomeAddress: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Home Address"
                                  : "বাসার ঠিকানা লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Mobile Number${
                                  !isEditing ? `: ${data.SecondaryMobile}` : ""
                                }`
                              : `মোবাইল নম্বর${
                                  !isEditing ? `: ${data.SecondaryMobile}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryMobile}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryMobile: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Mobile No"
                                  : "মোবাইল নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `National ID${
                                  !isEditing ? `: ${data.SecondaryNID}` : ""
                                }`
                              : `এনাইডি নম্বর${
                                  !isEditing ? `: ${data.SecondaryNID}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryNID}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryNID: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla ? "Enter NID" : "এনাইডি নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Passport Number${
                                  !isEditing
                                    ? `: ${data.SecondaryPassportNumber}`
                                    : ""
                                }`
                              : `পাসপোর্ট নম্বর${
                                  !isEditing
                                    ? `: ${data.SecondaryPassportNumber}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryPassportNumber}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryPassportNumber: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Passport No"
                                  : "পাসপোর্ট নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>{`Secondary Tenant Rent${
                            !isEditing ? `: ${data.SecondaryTenantRent}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryTenantRent}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryTenantRent: e.target.value,
                                })
                              }
                              type="text"
                              placeholder="Enter Rent"
                            />
                          )}
                        </div>
                      )}
                      <br />
                      <br />
                    </div>
                  </div>
                )}
                <div className={classes.formDetail}>
                  {/* <div className={classes.detail}>
        <p className={classes.detailHeader}>QR Code</p>
        <div className={classes.subDetail} style={{border: "1px solid #D6D6D6", borderRadius: "2rem"}}>
            <QRCode style={{transform: "scale(0.7)"}} value={`https://www.rentmilloh.com/properties/${propertyId}/${floorId}/shops/${shopId}/rent`}/>
        </div>
        <br />
    </div> */}
                </div>
                {
                      data.files && Object.keys(data.files).map((file)=>{
                        return <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}><a style={{appearance: "none"}} href={data.files[file]} target="_blank" rel="noopener"> <button type="button" className="btn_downloadFile" htmlFor="">{`Download ${file}`}</button></a>
                        <button type="button" onClick={()=>deletefile(file)}>Delete</button>
                        </div>

                      })
                    }
                <br />
                <br />

                {rolee && rolee.receiveNewTenantApplication && (
                  <>
                    <div>
                      <button
                        className="btn_edit"
                        type="button"
                        onClick={handleEdit}
                      >
                        {!isEditing ? "Edit" : "Save"}
                      </button>
                      <button
                        className="btn_del"
                        type="button"
                        onClick={handleDelete}
                      >
                        Decline
                      </button>
                    </div>
                    <br />
                    {!done && !loading && <button
                      onClick={handleApprove}
                      type="button"
                      className={classes.approve}
                    >
                      Approve
                    </button>}
                  </>
                )}
                <br />
                <>
                    {loading && <div style={{display: "flex", flexDirection: "row"}} className={classes2.loader}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>}
                </>
                    {done && <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><svg style={{height: "10rem", width: "10rem", transform: "scale(0.8)"}} id="completion" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 101">
  <style>
    {`.st0{fill:#0088C2;fill-opacity:0.4;} .st1{fill:#0088C2;fill-opacity:0.1;} .st2{fill:#0088C2;}`}
  </style>
  <title>
    C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9
  </title>
  <g id="configurator">
    <g id="configurator_completion">
      <g id="stars">
        <circle id="Oval" class="st0" cx="14" cy="18" r="1"/>
        <circle id="Oval-Copy-4" class="st0" cx="27" cy="20" r="1"/>
        <circle id="Oval-Copy-10" class="st0" cx="76" cy="20" r="1"/>
        <circle id="Oval-Copy-2" class="st0" cx="61.5" cy="12.5" r="1.5"/>
        <circle id="Oval-Copy-9" class="st0" cx="94" cy="53" r="1"/>
        <circle id="Oval-Copy-6" class="st0" cx="88" cy="14" r="1"/>
        <circle id="Oval-Copy-7" class="st0" cx="59" cy="1" r="1"/>
        <circle id="Oval_1_" class="st0" cx="43" cy="9" r="2"/>
        <path id="ster-01" class="st0" d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"/>
        <path id="ster-01" class="st0" d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"/>
        <path id="ster-01" class="st0" d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"/>
        <circle id="Oval-Copy-5" class="st0" cx="91" cy="40" r="2"/>
        <circle id="Oval-Copy-3" class="st0" cx="7" cy="36" r="2"/>
        <circle id="Oval-Copy-8" class="st0" cx="7.5" cy="5.5" r=".5"/>
      </g>
    </g>
  </g>
  <g id="cirkel">
    <g id="Mask">
      <path id="path-1_1_" class="st1" d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"/>
    </g>
  </g>
  <path id="check" class="st2" d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"/></svg>
  <h1 style={{color: "#0088C2", margin: "1.5rem", transform: "translate(0, 0.5rem)"}}>Added</h1>
  </div>}
              </form>
              <br></br>

              <br />

              <br />
              <br />

              <br></br>

              <br />

              <br />
              <br />

              {/* <form action="">
            
            <div className={classes.formGroup}>
                <div className={classes.names}>
                    {!isEditing && <label htmlFor="">ID</label>}
                    <label htmlFor="">Shop Name</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("BusinessName",e)} defaultValue={data.BusinessName}></input>}
                    <label htmlFor="">Floor</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Floor",e)} defaultValue={data.Floor}></input>}
                    <label htmlFor="">Property</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Property",e)} defaultValue={data.Property}></input>}
                    
                    
                </div>
                {isEditing==false ? <div className={classes.values}>
                    <label htmlFor="">{data.id}</label>
                    <label htmlFor="">{data.BusinessName}</label>
                    <label htmlFor="">{data.Floor}</label>
                    <label htmlFor="">{data.Property}</label>
                </div>
                :
                <>
                
                </>}
            </div>
            <br></br>
            <div>
              <button className="btn_edit" type="button" onClick={handleEdit}>{!isEditing ? "Edit" : "Save"}</button>
              <button className="btn_del" type="button" onClick={handleDelete}>Delete</button>
            
            </div>
            </form> */}
            </>
          )
        }
      />
    </>
  );
}
