import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";

export default function FloorDetails() {
  const { user, bangla } = UserAuth();
  const navigate = useNavigate();
  const { propertyId, floorId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  // for delete popup
  const [deletePrompt, setDeletePrompt] = useState(false);

  const deleteShop = async (id) => {
    await deleteDoc(doc(db, "Shops", id));
  };

  const handleDelete = async () => {
    const shopRef = await getDocs(
      query(collection(db, "Shops"), where("floorId", "==", floorId))
    );
    shopRef.forEach((doc) => {
      deleteShop(doc.id);
    });

    await deleteDoc(doc(db, `Floors`, floorId))
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
        navigate(`/properties/${propertyId}/floors/`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateShop = async (x) => {
    await updateDoc(doc(db, "Shops", x), {
      FloorNo: data.FloorName,
    });
  };

  const handleEdit = async () => {
    setIsEditing(!isEditing);
    let f;
    if(data.FloorName!=undefined){
      f=data.FloorName
    }
    else{
      f=data.FloorNo;
    }
    await updateDoc(doc(db, "Floors", data.id), {
      Area: data.Area,
      floorName: f,
      floorNo: parseInt(data.FloorNo)
    }).catch((error) => {
      console.log(error);
    });

    const arr = [];
    const docRef = await getDocs(
      query(collection(db, "Shops"), where("floorId", "==", floorId))
    );
    docRef.forEach((doc) => {
      updateShop(doc.id);
    });

    getPropertyDetails();
  };

  const getPropertyDetails = async (event) => {
    try {
      const querySnapshot = await getDoc(doc(db, `Floors`, floorId));
      setData({
        id: querySnapshot.id,
        FloorNo: querySnapshot.data().floorNo,
        id: querySnapshot.id,
        Area: querySnapshot.data().area,
        FloorName: querySnapshot.data().floorName,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleOnChange = (key, x) => {
    const obj = { ...data };
    obj[key] = x.target.value;
    setData({ ...obj });
    console.log(data);
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };

  // const allFLoorNos = async()=>{
  //   const docRef = await getDocs(collection(db, "Floors"));
  //   docRef.forEach(async docc=>{
  //     await updateDoc(doc(db, "Floors", docc.id), {
  //       floorNo: parseInt(docc.data().floorNo)
  //     })
  //   })
  // }

  // useEffect(()=>{
  //   allFLoorNos();
  // },[])

  return (
    <>
      <PageTemp
        title={`Floor ${
          data.FloorName == undefined ? data.FloorNo : data.FloorName
        }`}
        subTitle={!isLoading && "Floor Details"}
        page={
          isLoading ? (
            <>
              <div style={{ marginLeft: "2rem" }} className={classes2.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              {/* deletePopup code  */}
              {deletePrompt && 
                <>
                  <div className="deletePrompt">
                    <div>
                      <label>Are you sure you want to delete?</label>
                      <div>
                      <button onClick={()=>(handleDelete())} style={{backgroundColor: "green"}}>Yes</button>
                      <button onClick={()=>setDeletePrompt(false)} style={{backgroundColor: "red"}}>No</button>
                      </div>
                      
                    </div>
                  </div>
                </>
              }
              <form action="">
                <div className={classes.formGroup}>
                  <div className={classes.names}>
                    {/* {!isEditing && <label htmlFor="">ID</label>} */}
                    <label htmlFor="">Order</label>
                    {isEditing && (
                      <input
                        onChange={(e) => handleOnChange("FloorNo", e)}
                        defaultValue={data.FloorNo}
                      ></input>
                    )}
                    
                    <label htmlFor="">Floor Name</label>
                    {isEditing && (
                      <input
                        onChange={(e) => handleOnChange("FloorName", e)}
                        defaultValue={data.FloorName}
                      ></input>
                    )}
                    <label htmlFor="">Area</label>
                    {isEditing && (
                      <input
                        onChange={(e) => handleOnChange("Area", e)}
                        defaultValue={data.Area}
                      ></input>
                    )}
                  </div>
                  {isEditing == false ? (
                    <div className={classes.values}>
                      {/* <label htmlFor="">{data.id}</label> */}
                      <label htmlFor="">{data.FloorNo || "N/A"}</label>
                      <label htmlFor="">{data.FloorName || "N/A"}</label>
                      <label htmlFor="">{data.Area || "N/A"}</label>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <br></br>
                <div>
                  <button
                    className="btn_edit"
                    type="button"
                    onClick={handleEdit}
                  >
                    {!isEditing ? "Edit" : "Save"}
                  </button>
                  <button
                    className="btn_del"
                    type="button"
                    onClick={()=> setDeletePrompt(true)}
                  >
                    Delete
                  </button>
                </div>
              </form>
            </>
          )
        }
      />
    </>
  );
}
