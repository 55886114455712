// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// test config
// const firebaseConfig = {
//   apiKey: "AIzaSyDPe5t5d4-Jw-YeUHAdDKN2bi-011NiPNs",
//   authDomain: "rentmilloh-test.firebaseapp.com",
//   projectId: "rentmilloh-test",
//   storageBucket: "rentmilloh-test.appspot.com",
//   messagingSenderId: "183266687981",
//   appId: "1:183266687981:web:cee00e3b3a9ff344a3c5e4",
// };

//production config
const firebaseConfig = {
  apiKey: "AIzaSyBww6gZWaDzDHYYvvqbkIEQVV70qzOFtPA",
  authDomain: "rentmilloh.firebaseapp.com",
  projectId: "rentmilloh",
  storageBucket: "rentmilloh.appspot.com",
  messagingSenderId: "186475330208",
  appId: "1:186475330208:web:80be2d0bdc63785fde67c5",
  measurementId: "G-R1YYES38FB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

// Firebase storage reference
export const storage = getStorage(app);
// export default storage;

export default app;
