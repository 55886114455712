import classes from '../styles/Table.module.css'
import greenCircle from '../assets/icons/greencircle.svg';
import redCircle from '../assets/icons/redCircle.svg';
import greyCircle from '../assets/icons/partial.svg';
import editIcon from '../assets/icons/editIcon.png';

export default function TotalRow({headings, total}){
    return(
        <>
            <tr >
                
                {
                    headings.map((h, index)=>{
                        if(index+3==headings.length){
                            return(<td></td>)
                        } 
                        if(index+2==headings.length){
                            return(<td >{`Total: `}</td>)
                        }
                        if(index+1==headings.length){
                            return(<td >{`${total}`}</td>)
                        }
                    })
                }
                 

                
            </tr>
        </>
    );
}