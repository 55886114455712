import classes from '../styles/Table.module.css'

export default function Table({children}){

    return(
        <>
            <div className={`${classes.mainTable} ${classes.hasTitle} `}>
                    <table>
                        <tbody>
                            {children}
                        </tbody>
                    </table>

                    
                </div>
        </>
    );
}