import classes from "../styles/TopBar.module.css";
import notificationIcon from "../assets/icons/notification.svg";
import hamBurgerIcon from "../assets/icons/Hamburger_icon.svg";
import qrIcon from "../assets/icons/qrr.svg";
import mapIcon from "../assets/icons/map.svg";
import backIcon from "../assets/icons/back.svg";
import { Link, useNavigate } from "react-router-dom";
import AnimatedPage from "./AnimatedPage";
import { useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect } from "react";
import { UserAuth } from "../context/AuthContext";

export default function TopBar({
  img,
  title,
  subTitle,
  navToggled,
  setNavToggled,
  noBackBtn,
  map,
  qr,
}) {
  const navigate = useNavigate();
  const { bangla } = UserAuth();
  const subT = {
    "Please enter the following details": "বিস্তারিত লিখুন",
    "View all units here": "এখানে সব ইউনিট দেখুন",
    "View all Floors": "সব ফ্লোর দেখুন",
    "View all properties here": "এখানে সব প্রপার্টি  দেখুন",
    "Send request to add new tenant":
      "নতুন ভাড়াটে যোগ করার জন্য  রিকুয়েস্ট পাঠান",
  };
  const T = {
    "Quick Add Unit": "কুইক অ্যাড ইউনিট",
    "All Units": "সমস্ত ইউনিট",
    "All Floors": "সমস্ত  ফ্লোর",
    "All Properties": "সমস্ত প্রপার্টি",
    "New Tenant Request": "নতুন ভাড়াটে রিকুয়েস্ট",
    "Tenant Complain": "ভাড়াটে অভিযোগ",
    "Property Complain": "প্রপার্টি অভিযোগ",
    "Other Complain": "অন্য অভিযোগ",
    Dashboard: "ড্যাশবোর্ড",
    "Floor View": "ফ্লোর ভিউ",
    "Add User": "ইউসার অ্যাড করুন",
  };

  const [toggleNotif, setToggleNotif] = useState(false);

  const { rolee, user } = UserAuth();
  const [notifData, setNotifData] = useState([]);
  
  const fetchRequests = async () => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "Requests"), orderBy("Time", "desc"))).then(
        console.log("fetched Requests")
      );
      querySnapshot.forEach((doc) => {
        if (
          rolee &&
          rolee.receiveNewTenantApplication &&
          doc.data().RequestTitle == "Move In Request"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveChangeSecondaryTenant &&
          doc.data().RequestTitle == "Change Secondary Tenant"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }else if (
          rolee &&
          rolee.receiveCancelTenantApplication &&
          doc.data().RequestTitle == "Move Out Request"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }
        else if (
          rolee &&
          rolee.receiveChangePrimaryTenant &&
          doc.data().RequestTitle == "Change Primary Tenant"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }
        else if (
          rolee &&
          rolee.receiveChangePositionTenant &&
          doc.data().RequestTitle == "Change Position Tenant"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }
        else if (
          rolee &&
          rolee.receiveTenantComplain &&
          doc.data().RequestTitle == "Tenant Complain"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receivePropertyComplain &&
          doc.data().RequestTitle == "Property Complain"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveOtherComplain &&
          doc.data().RequestTitle == "Other Complain"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setNotifData([]);
    // fetchRequests();
  }, []);

  useEffect(() => {
    // setNotifData([]);
    // fetchRequests();
    if (notifData.length == 0 && rolee!=undefined) {
      fetchRequests();
    }
  }, [rolee]);

  useEffect(() => {}, [notifData]);

  return !navToggled ? (
    <>
      <div className={`${classes.topBar} no-print`}>
        <div className={classes.details}>
          <Link to="/" className={`${classes.logo} no-print`}>
            <p>
              rent<span className={classes.logo_accent}>milloh</span>
            </p>
          </Link>

          <AnimatedPage>
            <div className={classes.pageDetails}>
              {!noBackBtn && (
                <button
                  onClick={() => navigate(-1)}
                  className={classes.backButton}
                >
                  <img src={backIcon}></img>
                </button>
              )}
              {img && <img src={img}></img>}
              <div>
                {!bangla ? (
                  <p>{title}</p>
                ) : title in T ? (
                  <p>{T[title]}</p>
                ) : (
                  <p>{title}</p>
                )}
                {!bangla ? (
                  <p className={classes.subDetails}>{subTitle}</p>
                ) : subTitle in subT ? (
                  <p className={classes.subDetails}>{subT[subTitle]}</p>
                ) : (
                  <p className={classes.subDetails}>{subTitle}</p>
                )}
              </div>
            </div>
          </AnimatedPage>
        </div>

        <div className={`${classes.sideButtons} no-print`}>
          {qr &&
            rolee &&
            (rolee.rentCollection ||
              rolee.utilityCollection ||
              rolee.serviceChargeCollection) && (
              <div className={classes.notifCont}>
                <button
                  onClick={() => navigate("/qr")}
                  className={classes.sideButton}
                >
                  <img className={classes.icon} src={qrIcon} />
                </button>
              </div>
            )}

            
          {map && rolee  && rolee.floorViewAccess && (
            <div className={classes.notifCont}>
              <button
                onClick={() => navigate("/floorView")}
                className={classes.sideButton}
              >
                <img className={classes.icon} src={mapIcon} />
              </button>
            </div>
          )}


          <div className={classes.notifCont}>
            <button
              onClick={() => setToggleNotif(!toggleNotif)}
              className={classes.sideButton}
            >
              <img className={classes.icon} src={notificationIcon} />
            </button>
            {toggleNotif && (
              <div className={classes.notificationBar}>
                <div className={classes.notifAdditional}>
                  {rolee &&
                    (rolee.receiveNewTenantApplication ||
                      rolee.receiveOtherComplain ||
                      rolee.receivePropertyComplain ||
                      rolee.receiveTenantComplain ||
                      rolee.receiveCancelTenantApplication) && (
                      <Link to="/requests/history/all">All Requests</Link>
                    )}
                  <Link to="/requests/history/me">My Requests</Link>
                </div>
                {notifData && notifData.length == 0 && (
                  <p style={{ padding: "3rem", textAlign: "center" }}>
                    No new notifications!
                  </p>
                )}
                {notifData &&
                  notifData.map((data) => {
                    return (
                      <div
                        key={data.id}
                        onClick={() =>
                          navigate(
                            `/requests/pending/${String(data.title).replace(
                              /\s/g,
                              ""
                            )}/${data.id}`
                          )
                        }
                        className={classes.notification}
                      >
                        <p>{data.title}</p>
                        <p className={classes.sub}>{data.sub}</p>
                      </div>
                    );
                  })}

                {/* <div className={classes.notification}>
                                    <p>New Tenant Request </p>
                                    <p className={classes.sub}>Ibnul Toy Shop</p>
                                </div> */}
              </div>
            )}
          </div>
          <button
            onClick={() => setNavToggled(!navToggled)}
            className={`${classes.hamBurger} ${classes.sideButton}`}
          >
            <img className={classes.icon} src={hamBurgerIcon} />
          </button>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
