import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableSubheader from "../TableSubheader";
import TableRow from "../TableRow";
import TableTop from "../TableTop";
import DropDownV2 from "../DropDownV2";
import { useState } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function ViewShops() {
  const { propertyId, floorId } = useParams();
  const { rolee, allProperties } = UserAuth();
  const navigate = useNavigate();
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [dynamic, setDynamic] = useState("");

  useEffect(() => {
    if (rolee && !rolee.viewUnit) {
      navigate("/");
    }
  }, [rolee]);

  const fetchShops = async () => {
    const obj = {};
    // if(!floors.includes(floorId)){
    //   setFilter(prev=>{return {...prev, Floor: floors[0]}});
    // }
    try {
      let querySnapshot;
      if (propertyId == "allProperties" && floorId == "allFloors") {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                orderBy("FloorNo")
              )
            );
          } else if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "ShopNo"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                // orderBy("Block", "asc"),
                where(
                  filter.dynamicFilter[0],
                  "==",
                  parseInt(filter.dynamicFilter[1])
                )
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                // orderBy("Block", "asc"),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("FloorNo"),
              orderBy("Block", "asc"),
              orderBy("ShopNo", "asc")
            )
          );
        }
      } else if (propertyId != "allProperties" && floorId == "allFloors") {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                where("PropertyId", "==", propertyId)
                // where("floorId", "==", floorId),
              )
            );
          } else if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "ShopNo"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                // where("floorId", "==", floorId),
                where(
                  filter.dynamicFilter[0],
                  "==",
                  parseInt(filter.dynamicFilter[1])
                ),

                where("PropertyId", "==", propertyId)
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),

                where("PropertyId", "==", propertyId),
                // where("floorId", "==", floorId),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("FloorNo"),
              orderBy("Block", "asc"),
              orderBy("ShopNo", "asc"),

              where("PropertyId", "==", propertyId)
            )
          );
        }
      } else {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId)
              )
            );
          } else if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "ShopNo"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId),
                where(
                  filter.dynamicFilter[0],
                  "==",
                  parseInt(filter.dynamicFilter[1])
                )
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy(
                  `${filter.dynamicFilter[0] == "Block" ? "ShopNo" : "Block"}`
                ),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("Block", "asc"),
              orderBy("ShopNo", "asc"),

              where("PropertyId", "==", propertyId),
              where("floorId", "==", floorId)
            )
          );
        }
      }

      console.log(`${propertyId} ${floorId}`);
      let arr = [];
      querySnapshot.forEach((doc) => {
        if (arr.length != 0 && arr[0].Floor != doc.data().FloorNo) {
          arr = [];
        }
        if (arr.length != 0 && arr[0].Property != doc.data().PropertyName) {
          arr = [];
        }
        const subObj = {};
        subObj["Property"] = doc.data().PropertyName;
        subObj["Floor"] = doc.data().FloorNo;

        let y;
        if (
          doc.data().Entries.Rent[
            `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
          ] != undefined
        ) {
          if (
            doc.data().Entries.Rent[
              `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
            ].Status == "Full"
          ) {
            y = "Paid";
          } else {
            y = "Partially Paid";
          }
        } else {
          y = "Not paid";
        }
        console.log(y);

        let s;
        let b;

        if (doc.data().ShopNo == undefined) {
          s = "";
        } else {
          s = doc.data().ShopNo;
        }

        if (doc.data().Block == undefined) {
          b = "";
        } else {
          b = doc.data().Block;
        }

        let v;
        if (doc.data().TenantType == undefined) {
          v = "Vacant";
        } else {
          v = doc.data().TenantType;
        }

        let tn;

        if (doc.data().TenantName == undefined) {
          tn = "Unnamed";
        } else {
          tn = doc.data().TenantName;
        }

        arr.push({
          BusinessName: doc.data().BusinessName,
          TenantName: tn,
          id: doc.id,
          ShopNo: s,
          Status: y,
          Month: doc.data().LastPaid,
          Floor: doc.data().FloorNo,
          Property: doc.data().PropertyName,
          Block: b,
          TenantType: v,
        });
        console.log(arr);
        subObj["Shops"] = arr;
        obj[`${doc.data().PropertyName} ${doc.data().FloorNo}`] = subObj;
      });
      setData(obj);
      console.log(obj);
    } catch (err) {
      console.log(err);
    }
  };

  //read filter from this state
  const [filter, setFilter] = useState({
    Property: "allProperties",
    Floor: "All Floors",
    Block: "All Blocks",
    Search: "",
  });

  useEffect(() => {
    if (filter.Property == "allProperties" && filter.Floor == "All Floors") {
    } else if (
      filter.Property != "allProperties" &&
      filter.Floor == "All Floors"
    ) {
      navigate(`/properties/${filter.Property}/floors/allFloors/shops`);
    } else if (
      filter.Property != "allProperties" &&
      filter.Floor != "All Floors"
    ) {
      navigate(`/properties/${filter.Property}/floors/${filter.Floor}/shops`);
    } else if (
      filter.Property == "allProperties" &&
      filter.Floor != "All Floors"
    ) {
      navigate(`/properties/allProperties/floors/allFloors/shops`);
    }

    // else if (filter.Property == undefined && filter.Floor == undefined) {
    //   navigate(`/properties/allProperties/floors/allFloors/shops`);
    // } else if(filter.Floor !="All Properties" && filter.Property!="All Floors"){
    //   navigate(`/properties/${filter.Property}/floors/${filter.Floor}/shops`);
    // }
    // else if(filter.Property=="All Properties" && filter.Floor !="All Floors"){
    //   navigate(`/properties/${propertyId}/floors/${filter.Floor}/shops`);
    // }
    // else
    // {
    //   navigate(`/properties/${filter.Property}/floors/allFloors/shops`);
    // }

    console.log(filter);
    filter.dynamicFilter &&
      setDynamic(
        `where("${filter.dynamicFilter[0]}"=="${filter.dynamicFilter[1]}")`
      );
    fetchShops();
  }, [filter]);

  useEffect(() => {
    fetchShops();
    // console.log(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  }, []);

  useEffect(() => {
    fetchShops();
  }, [propertyId]);

  useEffect(() => {
    fetchShops();
  }, [floorId]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      Property: propertyId,
      Floor: floorId,
    }));
  }, []);

  // const fixShopNo = async () => {
  //   const docRef = await getDocs(collection(db, "Shops"));
  //   docRef.forEach(async(docc)=>{
  //     await updateDoc(doc(db, "Shops", docc.id), {
  //       ShopNo: parseInt(docc.data().ShopNo)
  //     })
  //   })
  // }

  // useEffect(()=>{
  //   fixShopNo();
  // },[])

  //update this state from database
  const [data, setData] = useState({
    // "Ibs Floor2":{
    //     Property: "Ibs",
    //     Floor: "2",
    //     Shops: [
    //         {
    //             BusinessName: "Amin Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //         {
    //             BusinessName: "Mashallah Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //     ]
    // },
    // "Wallahi Floor1":{
    //     Property: "Wallahi",
    //     Floor: "1",
    //     Shops: [
    //         {
    //             BusinessName: "Amin Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //         {
    //             BusinessName: "Mashallah Jewellers",
    //             ShopNo: "239",
    //             Status: "Paid",
    //             Month: "October"
    //         },
    //     ]
    // }
  });

  const handleFilter = (data) => {
    setFilter(data);
    // fetchFloors();
  };

  const handleRowClick = (id) => {
    navigate(`/properties/${propertyId}/floors/${floorId}/shops/${id}`);
  };

  return (
    <>
      <PageTemp
        title="All Units"
        subTitle="View all units here"
        page={
          <>
            <TableTop
              defaultProp={propertyId}
              defFloor={floorId}
              quickAddNewBtn={
                rolee && rolee.addUnit
                  ? `/properties/${propertyId}/floors/${floorId}/shops/quickAdd`
                  : null
              }
              filter={filter}
              handleFilter={handleFilter}
              dProperty={true}
              dFloor={true}
              dBlock={true}
              all={true}
              // dSearch={true}
              // dPropertyAndFloors={true}
            />
            {/* <DropDownV2 /> */}
            <Table>
              <TableHeader
                headings={["Unit Name", "Tenant Name", "Tenant Type"]}
              />

              {data &&
                Object.keys(data).map((building) => {
                  return (
                    <TableSubheader
                      property={
                        data[building].Property &&
                        (data[building].Property.length > 10
                          ? `${data[building].Property.substring(0, 10)}...`
                          : data[building].Property)
                      }
                      floor={`Floor ${data[building].Floor}`}
                    >
                      {data[building].Shops &&
                        data[building].Shops.length > 0 &&
                        data[building].Shops.map((shop) => {
                          return (
                            <TableRow
                              onClick={handleRowClick}
                              id={`${shop.id}`}
                              info={[
                                `${
                                  shop.ShopNo != "" ? `#${shop.ShopNo} ,` : ``
                                } ${
                                  shop.Block != "" ? `Block: ${shop.Block}` : ``
                                }`,
                                shop.BusinessName,
                              ]}
                              normalDetails={[shop.TenantName, shop.TenantType]}
                            />
                          );
                        })}
                    </TableSubheader>
                  );
                })}

              {/* {data.map((d)=>{
                            return(
                                <TableSubheader property={d.PropertyName} floor={d.Floor}>
                                    {d.Shops.map((shop)=>{
                                        return(<TableRow info={[shop.ShopNo,shop.ShopName]} status={shop.Status} normalDetails={[shop.Month]}/>);
                                    })}
                                </TableSubheader>
                            );
                        })} */}
            </Table>
          </>
        }
      />
    </>
  );
}
