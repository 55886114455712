import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import Files from "../../assets/icons/Files.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import UserCtrl from "../../assets/icons/UserCtrl.svg";
import Entss from "../../assets/icons/Entss.svg";
import Props from "../../assets/icons/Props.svg";
import Reqs from "../../assets/icons/Reqs.svg";
import Reps from "../../assets/icons/Reps.svg";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  where,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { ref, getStorage, uploadBytes } from "firebase/storage";
import SubSelect from "../SubSelect";
import { SelectAll } from "@mui/icons-material";

export default function AddRole() {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [data, setData] = useState({
    manageRoles:false,
    addUser: false,
    editUser: false,
    statusControl: false,
    activityLog: false,
    rentCollection: false,
    utilityCollection: false,
    serviceChargeCollection: false,
    viewDue: false,
    addProperty: false,
    viewProperty: false,
    editProperty: false,
    addFloor: false,
    editFloor: false,
    viewFloor: false,
    floorViewAccess: false,
    addUnit: false,
    editUnit: false,
    editUnitMaster: false,
    viewUnit: false,
    securityDepositAccess: false,
    newTenantApplication: false,
    changeSecondaryTenantApplication: false,
    cancelTenantApplication: false,
    tenantComplain: false,
    unitChangeRequest: false,
    propertyComplain: false,
    otherComplain: false,
    receiveNewTenantApplication: false,
    receiveTenantComplain: false,
    receiveChangeSecondaryTenant: false,
    receiveUnitChangeRequest: false,
    receivePropertyComplain: false,
    receiveOtherComplain: false,
    rentEntryHistory: false,
    leaseEnding: false,
    vacantUnits: false,
    meterReadingHistory: false,
    utilityBillCollectionStatus: false,
    serviceChargeStatus: false,
    fRent: false,
    fUtility: false,
    fServiceCharge: false,
    fRentCollected: false,
    fUtilityCollected: false,
    fServiceChargeCollected: false,
    fRentStatement: false,
    fUtilityStatement: false,
    fServiceChargeStatement: false,
    viewStatistics: false
  });

  const selectAll = (arr, check) => {
    setData((prevState) => {
      const newState = { ...prevState };
      arr.forEach((el) => {
        newState[el] = check;
      });
      return newState;
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);
  useEffect(() => {
    console.log(name);
  }, [name]);

  const handleAddRole = async () => {
    try {
      await setDoc(doc(db, "Roles", name), {
        ...data,
      });
      navigate('/roles');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <PageTemp
        title="Add Role"
        subTitle="Please enter the following details"
        page={
          <div>
            <form action="">
              <div className={classes.formGroup}></div>

              <div className={classes.formDetail}>
                <div className="">
                  <div className={classes.subDetail}>
                    <label>Role Name</label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Enter Role Name"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className={classes.formDetail}>
                <div className="">
                  <div className={classes.masterSubDetail}>
                    <div className={classes.subDetail}>
                      {/* <SubSelect> */}
                      <p className={classes.detailHeaderC}>
                        <img src={UserCtrl} />
                        User Control
                        <input
                          onChange={(e) => {
                            selectAll(
                              ["addUser", "editUser", "statusControl", "viewDue", "activityLog"],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      </p>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, addUser: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["addUser"]}
                          type="checkbox"
                        />
                        <label>Add User</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, editUser: e.target.checked });
                          }}
                          id={"UserCtrl"}
                          className={classes.inpCheck}
                          checked={data["editUser"]}
                          type="checkbox"
                        />
                        <label>Edit User</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, manageRoles: e.target.checked });
                          }}
                          id={"UserCtrl"}
                          className={classes.inpCheck}
                          checked={data["manageRoles"]}
                          type="checkbox"
                        />
                        <label>Manage Roles</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              statusControl: e.target.checked,
                            });
                          }}
                          id={"UserCtrl"}
                          className={classes.inpCheck}
                          checked={data["statusControl"]}
                          type="checkbox"
                        />
                        <label>Status Control</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              activityLog: e.target.checked,
                            });
                          }}
                          id={"UserCtrl"}
                          className={classes.inpCheck}
                          checked={data["activityLog"]}
                          type="checkbox"
                        />
                        <label>Activity Log</label>
                      </div>
                      {/* </SubSelect> */}
                    </div>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderC}>
                        <img src={Entss} />
                        Entries
                        <input
                          onChange={(e) => {
                            selectAll(
                              [
                                "rentCollection",
                                "utilityCollection",
                                "serviceChargeCollection",
                              ],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      </p>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              rentCollection: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["rentCollection"]}
                          type="checkbox"
                        />
                        <label>Rent Collection</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              utilityCollection: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["utilityCollection"]}
                          type="checkbox"
                        />
                        <label>Utility Collection</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              serviceChargeCollection: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["serviceChargeCollection"]}
                          type="checkbox"
                        />
                        <label>Service Charge Collection</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              viewDue: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["viewDue"]}
                          type="checkbox"
                        />
                        <label>View Due</label>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              </div>
              <div className={classes.formDetail}>
                <div className="">
                  <p className={classes.detailHeaderC}>
                    <img src={Props} />
                    Properties
                    <input
                      onChange={(e) => {
                        selectAll(
                          [
                            "addProperty",
                            "viewProperty",
                            "editProperty",
                            "addFloor",
                            "viewFloor",
                            "editFloor",
                            "floorViewAccess",
                            "addUnit",
                            "viewUnit",
                            "securityDepositAccess",
                            "viewUnitHistory",
                            "editUnit",
                            "editUnitMaster"
                          ],
                          e.target.checked
                        );
                      }}
                      className={classes.mainInpCheck}
                      type="checkbox"
                    />
                  </p>
                  <div className={classes.masterSubDetail}>
                    <div className={classes.subDetail}>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, addProperty: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["addProperty"]}
                          type="checkbox"
                        />
                        <label>Add Property</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              viewProperty: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["viewProperty"]}
                          type="checkbox"
                        />
                        <label>View Property</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              editProperty: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["editProperty"]}
                          type="checkbox"
                        />
                        <label>Edit Property Details</label>
                      </div>
                    </div>
                    <div className={classes.subDetail}>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, addFloor: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["addFloor"]}
                          type="checkbox"
                        />
                        <label>Add Floors</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, viewFloor: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["viewFloor"]}
                          type="checkbox"
                        />
                        <label>View Floors</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, editFloor: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["editFloor"]}
                          type="checkbox"
                        />
                        <label>Edit Floor Details</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, floorViewAccess: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["floorViewAccess"]}
                          type="checkbox"
                        />
                        <label>Floor View Access</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, floorViewEdit: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["floorViewEdit"]}
                          type="checkbox"
                        />
                        <label>Floor View Edit</label>
                      </div>

                    </div>
                    <div className={classes.subDetail}>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, addUnit: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["addUnit"]}
                          type="checkbox"
                        />
                        <label>Add Units</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, viewUnit: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["viewUnit"]}
                          type="checkbox"
                        />
                        <label>View Units</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, securityDepositAccess: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["securityDepositAccess"]}
                          type="checkbox"
                        />
                        <label>Security Deposit Access</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, viewUnitHistory: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["viewUnitHistory"]}
                          type="checkbox"
                        />
                        <label>View Unit History</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, editUnit: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["editUnit"]}
                          type="checkbox"
                        />
                        <label>Edit Unit Details</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, editUnitMaster: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["editUnitMaster"]}
                          type="checkbox"
                        />
                        <label>Edit Unit Master</label>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              </div>
              <div className={classes.formDetail}>
                <div className="">
                  <p className={classes.detailHeaderC}>
                    <img src={Reqs} />
                    Requests
                    <input
                      onChange={(e) => {
                        selectAll(
                          [
                            "newTenantApplication",
                            "tenantComplain",
                            "unitChangeRequest",
                            "propertyComplain",
                            "otherComplain",
                            "receiveNewTenantApplication",
                            "receiveTenantComplain",
                            "receiveUnitChangeRequest",
                            "receivePropertyComplain",
                            "receiveOtherComplain",
                          ],
                          e.target.checked
                        );
                      }}
                      className={classes.mainInpCheck}
                      type="checkbox"
                    />
                  </p>
                  <div className={classes.masterSubDetail}>
                    <div className={classes.subDetail}>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              newTenantApplication: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["newTenantApplication"]}
                          type="checkbox"
                        />
                        <label>Move In Application</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              changeSecondaryTenantApplication: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["changeSecondaryTenantApplication"]}
                          type="checkbox"
                        />
                        <label>Change Secondary Tenant Application</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              cancelTenantApplication: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["cancelTenantApplication"]}
                          type="checkbox"
                        />
                        <label>Move Out Application</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              tenantComplain: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["tenantComplain"]}
                          type="checkbox"
                        />
                        <label>Tenant Complain</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              unitChangeRequest: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["unitChangeRequest"]}
                          type="checkbox"
                        />
                        <label>Unit Change Request</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              propertyComplain: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["propertyComplain"]}
                          type="checkbox"
                        />
                        <label>Property Complain</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              otherComplain: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["otherComplain"]}
                          type="checkbox"
                        />
                        <label>Other Complain</label>
                      </div>
                    </div>
                    <div className={classes.subDetail}>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveNewTenantApplication: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveNewTenantApplication"]}
                          type="checkbox"
                        />
                        <label>Receive New Tenant Application</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveChangeSecondaryTenant: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveChangeSecondaryTenant"]}
                          type="checkbox"
                        />
                        <label>Receive Change Secondary Tenant</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveChangePositionTenant: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveChangePositionTenant"]}
                          type="checkbox"
                        />
                        <label>Receive Change Position Tenant</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveCancelTenantApplication: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveCancelTenantApplication"]}
                          type="checkbox"
                        />
                        <label>Receive Chancel Tenant Application</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveTenantComplain: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveTenantComplain"]}
                          type="checkbox"
                        />
                        <label>Receive Tenant Complain</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveUnitChangeRequest: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveUnitChangeRequest"]}
                          type="checkbox"
                        />
                        <label>Receive Unit Change Request</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receivePropertyComplain: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receivePropertyComplain"]}
                          type="checkbox"
                        />
                        <label>Receive Property Complain</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              receiveOtherComplain: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["receiveOtherComplain"]}
                          type="checkbox"
                        />
                        <label>Receive Other Complain</label>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              </div>
              <div className={classes.formDetail}>
                <div className="">
                  <div className={classes.masterSubDetail}>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderC}>
                        <img src={Reps} />
                        Rental Reports
                        <input
                          onChange={(e) => {
                            selectAll(
                              [
                                "rentEntryHistory",
                                "leaseEnding",
                                "vacantUnits",
                              ],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      </p>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              rentEntryHistory: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["rentEntryHistory"]}
                          type="checkbox"
                        />
                        <label>Rent Entry History</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, leaseEnding: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["leaseEnding"]}
                          type="checkbox"
                        />
                        <label>Lease Ending</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, vacantUnits: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["vacantUnits"]}
                          type="checkbox"
                        />
                        <label>Vacant Units</label>
                      </div>
                    </div>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderC}>
                        <img src={Reps} />
                        Utility Reports
                        <input
                          onChange={(e) => {
                            selectAll(
                              [
                                "meterReadingHistory",
                                "utilityBillCollectionStatus",
                              ],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      </p>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              meterReadingHistory: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["meterReadingHistory"]}
                          type="checkbox"
                        />
                        <label>Meter Reading History</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              utilityBillCollectionStatus: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["utilityBillCollectionStatus"]}
                          type="checkbox"
                        />
                        <label>Utility Entry History</label>
                      </div>
                    </div>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderC}>
                        <img src={Reps} />
                        S.C Reports
                        <input
                          onChange={(e) => {
                            selectAll(
                              ["serviceChargeStatus"],
                              e.target.checked
                            );
                          }}
                          className={classes.mainInpCheck}
                          type="checkbox"
                        />
                      </p>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              serviceChargeStatus: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["serviceChargeStatus"]}
                          type="checkbox"
                        />
                        <label>S.C Entry History</label>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              </div>
              <div className={classes.formDetail}>
                <div className="">
                  <p className={classes.detailHeaderC}>
                    <img src={Reps} />
                    Financial Reports
                    <input
                      onChange={(e) => {
                        selectAll(
                          [
                            "fRent",
                            "fUtility",
                            "fServiceCharge",
                            "fRentCollected",
                            "fUtilityCollected",
                            "fServiceChargeCollected",
                            "fRentStatement",
                            "fUtilityStatement",
                            "fServiceChargeStatement",
                          ],
                          e.target.checked
                        );
                      }}
                      className={classes.mainInpCheck}
                      type="checkbox"
                    />
                  </p>
                  <div className={classes.masterSubDetail}>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderCC}>
                        Account Receiveable
                      </p>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, fRent: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["fRent"]}
                          type="checkbox"
                        />
                        <label>Rent</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({ ...data, fUtility: e.target.checked });
                          }}
                          className={classes.inpCheck}
                          checked={data["fUtility"]}
                          type="checkbox"
                        />
                        <label>Utility</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fServiceCharge: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fServiceCharge"]}
                          type="checkbox"
                        />
                        <label>Service Charge</label>
                      </div>
                    </div>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderCC}>Balance Sheet</p>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fRentCollected: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fRentCollected"]}
                          type="checkbox"
                        />
                        <label>Rent Collected</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fUtilityCollected: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fUtilityCollected"]}
                          type="checkbox"
                        />
                        <label>Utility Collected</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fServiceChargeCollected: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fServiceChargeCollected"]}
                          type="checkbox"
                        />
                        <label>Service Charge Collected</label>
                      </div>
                    </div>
                    <div className={classes.subDetail}>
                      <p className={classes.detailHeaderCC}>Statements</p>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fRentStatement: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fRentStatement"]}
                          type="checkbox"
                        />
                        <label>Rent Statement</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fUtilityStatement: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fUtilityStatement"]}
                          type="checkbox"
                        />
                        <label>Utility Statement</label>
                      </div>
                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              fServiceChargeStatement: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["fServiceChargeStatement"]}
                          type="checkbox"
                        />
                        <label>Service Charge Statement</label>
                      </div>

                      <div className={classes.subDetailCheck}>
                        <input
                          onChange={(e) => {
                            setData({
                              ...data,
                              viewStatistics: e.target.checked,
                            });
                          }}
                          className={classes.inpCheck}
                          checked={data["viewStatistics"]}
                          type="checkbox"
                        />
                        <label>View Statistics</label>
                      </div>

                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              </div>

              <br />
              <br />
              <button
                onClick={handleAddRole}
                type="button"
                className={classes.submit}
              >
                <img src={SaveIcon} />
                Add Role
              </button>
              <br />
              <br />
            </form>
          </div>
        }
      />
    </>
  );
}
