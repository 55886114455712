import classes from "../../styles/Login.module.css";
import classes2 from "../../styles/NavBar.module.css";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { Password } from "@mui/icons-material";

export default function Login() {
  const [loginDetails, setLoginDetails] = useState({
    Username: "",
    Password: "",
  });

  const [loading, setLoading] = useState(false);

  const { signIn, user, err } = UserAuth();
  const navigate = useNavigate();

  // Login Button
  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await signIn(loginDetails.Username, loginDetails.Password);
      navigate("/");
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(user){
      navigate('/');
    }
  },[])

  const handleKeyPress = (event) => {
    if(event.key=='Enter'){
      handleClick(event);
    }
  }


  return (
    loading==true ? 
    <>
    <div className={classes.loaderDiv}>
      <span className={classes.loader}>
        <span></span>
        <span></span>
      </span>
    </div>
    </> :

    <>
      <div className={classes.mainCont}>
        <a className={classes2.logo}>
          <p>
            rent<span className={classes2.logo_accent}>milloh</span>
          </p>
        </a>
        <input
          onKeyDown={handleKeyPress}
          onChange={(e) =>
            setLoginDetails({ ...loginDetails, Username: e.target.value })
          }
          type="email"
          placeholder="Email"
          pattern=".+@globex\.com"
        />
        <input
          onKeyDown={handleKeyPress}
          onChange={(e) =>
            setLoginDetails({ ...loginDetails, Password: e.target.value })
          }
          type="password"
          placeholder="Password"
        />
        <input className={classes.button} type="submit" onClick={handleClick}/>
        {err && <p className={classes.error}>{err}</p>}
      </div>
    </>
  );
}
