import classes from "../styles/EntryForm.module.css";
import submitIcon from "../assets/icons/Save.svg";
import { useEffect } from "react";
import { UserAuth } from "../context/AuthContext";

export default function EntryForm({
  partial,
  previous,
  isUtility,
  handleSubmit,
  data,
  handleData,
  alreadyPaid,
  setCurrentAmount,
  excess,
  loading,
}) {
  const { bangla } = UserAuth();
  const handleMeterReadingChange = (x) => {
    const newData = data;
    newData.MeterReading = parseInt(x);
    handleData(newData);
  };
  const handleAmountChange = (x) => {
    const newData = data;
    newData.Amount = x;
    handleData(newData);
  };
  const handleMonthChange = (x) => {
    const newData = data;
    newData.Month = x;
    handleData(newData);
  };
  const handlePaymentTypeChange = (x) => {
    const newData = data;
    newData.PaymentType = x;
    handleData(newData);
  };

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }

  const {rolee} = UserAuth();

  return (
    <>
      <div>
        <form action="">
          <div className={classes.formGroup}>
            <div className={classes.names}>
              <label htmlFor="">
                {!bangla ? "Business Name" : "বিজনেস নাম"}
              </label>
              <label htmlFor="">{!bangla ? "Unit No." : "ইউনিট নম্বর"}</label>
              <label htmlFor="">{!bangla ? "Property" : "প্রপার্টি"}</label>
              <label htmlFor="">{!bangla ? "Floor" : "ফ্লোর"}</label>
              <label htmlFor="">{!bangla ? "Block" : "ব্লক"}</label>
              {isUtility == "true" && (
                <label htmlFor="">
                  {!bangla ? "Previous Meter Usage" : "আগের মিটার রিডিং"}
                </label>
              )}
              {rolee && rolee.viewDue && <label htmlFor="">{!bangla ? "Due" : "বাকি"}</label>}
            </div>
            <div className={classes.values}>
              <label htmlFor="">{data.BusinessName}</label>
              <label htmlFor="">{data.ShopNo}</label>
              <label htmlFor="">{data.Property}</label>
              <label htmlFor="">{data.Floor}</label>
              <label htmlFor="">{data.Block}</label>
              {isUtility == "true" && (
                <label htmlFor="">{data.PreviousMeterUsage}</label>
              )}
              {rolee && rolee.viewDue && <label htmlFor="">{data.CalculatedAmount}</label>}
            </div>
          </div>

          <br />
          {isUtility == "true" ? (
            <>
              {!partial && alreadyPaid && !previous && (
                <>
                  <label>{!bangla ? "Meter Reading" : "মিটার রিডিং"}</label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    onChange={(e) => handleMeterReadingChange(e.target.value)}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {/* <br></br> */}
          <label>{!bangla ? "Month" : "মাস"}</label>
          <input
            onChange={(e) => handleMonthChange(e.target.value)}
            type="month"
          ></input>

          {alreadyPaid && !previous && (
            <label>{!bangla ? "Amount Collected" : "মোট কালেকশন"}</label>
          )}
          {alreadyPaid && !previous && (
            <input
              type="text"
              placeholder={!bangla ? "Enter Amount" : "আমাউন্ট লিখুন"}
              onChange={(e) => {
                handleAmountChange(e.target.value);
              }}
            />
          )}

          <br />
          {!alreadyPaid && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <svg
                style={{ transform: "scale(0.7)" }}
                id="completion"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 96 101"
              >
                <style>
                  {`.st0{fill:#3FA700;fill-opacity:0.4;} .st1{fill:#3FA700;fill-opacity:0.1;} .st2{fill:#3FA700;}`}
                </style>
                <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
                <g id="configurator">
                  <g id="configurator_completion">
                    <g id="stars">
                      <circle id="Oval" class="st0" cx="14" cy="18" r="1" />
                      <circle
                        id="Oval-Copy-4"
                        class="st0"
                        cx="27"
                        cy="20"
                        r="1"
                      />
                      <circle
                        id="Oval-Copy-10"
                        class="st0"
                        cx="76"
                        cy="20"
                        r="1"
                      />
                      <circle
                        id="Oval-Copy-2"
                        class="st0"
                        cx="61.5"
                        cy="12.5"
                        r="1.5"
                      />
                      <circle
                        id="Oval-Copy-9"
                        class="st0"
                        cx="94"
                        cy="53"
                        r="1"
                      />
                      <circle
                        id="Oval-Copy-6"
                        class="st0"
                        cx="88"
                        cy="14"
                        r="1"
                      />
                      <circle
                        id="Oval-Copy-7"
                        class="st0"
                        cx="59"
                        cy="1"
                        r="1"
                      />
                      <circle id="Oval_1_" class="st0" cx="43" cy="9" r="2" />
                      <path
                        id="ster-01"
                        class="st0"
                        d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                      />
                      <path
                        id="ster-01"
                        class="st0"
                        d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                      />
                      <path
                        id="ster-01"
                        class="st0"
                        d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                      />
                      <circle
                        id="Oval-Copy-5"
                        class="st0"
                        cx="91"
                        cy="40"
                        r="2"
                      />
                      <circle
                        id="Oval-Copy-3"
                        class="st0"
                        cx="7"
                        cy="36"
                        r="2"
                      />
                      <circle
                        id="Oval-Copy-8"
                        class="st0"
                        cx="7.5"
                        cy="5.5"
                        r=".5"
                      />
                    </g>
                  </g>
                </g>
                <g id="cirkel">
                  <g id="Mask">
                    <path
                      id="path-1_1_"
                      class="st1"
                      d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                    />
                  </g>
                </g>
                <path
                  id="check"
                  class="st2"
                  d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
                />
              </svg>
              <h1
                style={{
                  color: "#3FA700",
                  margin: "1.5rem",
                  transform: "translate(0, 0.5rem)",
                }}
              >
                {!bangla ? "Paid" : "পেইড"}
              </h1>
            </div>
          )}
          {partial && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <svg style={{transform: "scale(0.7)"}} id="completion" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 101">
  <style>
    {`.st0{fill:#E5BF38;fill-opacity:0.4;} .st1{fill:#E5BF38;fill-opacity:0.1;} .st2{fill:#E5BF38;}`}
  </style>
  <title>
    C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9
  </title>
  <g id="configurator">
    <g id="configurator_completion">
      <g id="stars">
        <circle id="Oval" class="st0" cx="14" cy="18" r="1"/>
        <circle id="Oval-Copy-4" class="st0" cx="27" cy="20" r="1"/>
        <circle id="Oval-Copy-10" class="st0" cx="76" cy="20" r="1"/>
        <circle id="Oval-Copy-2" class="st0" cx="61.5" cy="12.5" r="1.5"/>
        <circle id="Oval-Copy-9" class="st0" cx="94" cy="53" r="1"/>
        <circle id="Oval-Copy-6" class="st0" cx="88" cy="14" r="1"/>
        <circle id="Oval-Copy-7" class="st0" cx="59" cy="1" r="1"/>
        <circle id="Oval_1_" class="st0" cx="43" cy="9" r="2"/>
        <path id="ster-01" class="st0" d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"/>
        <path id="ster-01" class="st0" d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"/>
        <path id="ster-01" class="st0" d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"/>
        <circle id="Oval-Copy-5" class="st0" cx="91" cy="40" r="2"/>
        <circle id="Oval-Copy-3" class="st0" cx="7" cy="36" r="2"/>
        <circle id="Oval-Copy-8" class="st0" cx="7.5" cy="5.5" r=".5"/>
      </g>
    </g>
  </g>
  <g id="cirkel">
    <g id="Mask">
      <path id="path-1_1_" class="st1" d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"/>
    </g>
  </g>
  <path id="check" class="st2" d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"/></svg> */}
              <h1
                style={{ color: "#E5BF38", transform: "translate(0, 0.5rem)" }}
              >
                {!bangla ? "Partially Paid" : "আংশিকভাবে পেইড"}
              </h1>
            </div>
          )}
          <br></br>
          {excess && (
            <label className={classes.excess}>
              {!bangla
                ? "Amount Entered is more than expected."
                : "প্রবেশ করা আমাউন্ট প্রত্যাশার চেয়ে বেশি"}
            </label>
          )}

          {alreadyPaid && previous && (
            <label
              className={classes.excess}
            >{`Please enter payment of ${`${getMonthName(
              previous.slice(-2)
            )}, ${previous.substring(0, 4)}`} first`}</label>
          )}
          {!loading && alreadyPaid && !previous && (
            <button
              type="button"
              onClick={handleSubmit}
              className={classes.submit}
            >
              <img src={submitIcon} />
              {!bangla ? "Add Entry" : "এন্ট্রি দিন"}
            </button>
          )}
          <br />
          <br />
        </form>
      </div>
    </>
  );
}
