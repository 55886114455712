import { useState } from "react";
import NavBar from "./NavBar.js"
import TopBar from "./TopBar.js"
import AnimatedPage from "./AnimatedPage.js";

export default function PageTemp({noBackBtn, title, subTitle, page, img, qr, map}){

    const [navToggled, setNavToggled] = useState(false);
    return(
        <>
            
                <TopBar qr={qr} map={map} noBackBtn={noBackBtn} img={img} title={title} subTitle={subTitle} navToggled={navToggled} setNavToggled={setNavToggled}/>
            
                <main className={navToggled ? "mainSolute" : ""}>
                    <NavBar navToggled={navToggled} setNavToggled={setNavToggled}/>
                    <AnimatedPage>
                        {!navToggled && page}
                    </AnimatedPage>
                </main>
                
            
        </>
        
    );
}