import classes from "../styles/TableTop.module.css";
import DropDown from "./DropDown";
import DropDownV2 from "./DropDownV2";
import generateRep from "../assets/icons/generateRep.svg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

export default function TableTop({
  all,
  dMonth,
  quickAddNewBtn,
  handleFilter,
  filter,
  defaultProp,
  defFloor,
  addNewBtn,
  dProperty,
  dFloor,
  dBlock,
  dSearch,
  dPropertyAndFloors,
  dStartAndEndDates,
  genRep,
  RevenueType,
  AddNewProp,
  dStartAndEndExactDates,
  AddNewFloor
}) {
  const { bangla } = UserAuth();
  const subT = {
    "Please enter the following details": "বিস্তারিত লিখুন",
    "View all units here": "এখানে সব ইউনিট দেখুন",
  };

  const navigate = useNavigate();
  const handleSearchChange = (change) => {
    const updatedFilter = filter;
    updatedFilter["Search"] = change;
    handleFilter({
      ...updatedFilter,
    });
  };

  const [dynamicType, setDynamicType] = useState("ShopNo");

  const handleStartExactDateChange = (change) => {
    const updatedFilter = filter;
    updatedFilter["StartDateExact"] = change;
    handleFilter({
      ...updatedFilter,
    });
  };

  const handleEndExactDateChange = (change) => {
    const updatedFilter = filter;
    updatedFilter["EndDateExact"] = change;
    handleFilter({
      ...updatedFilter,
    });
  };

  const handleStartDateChange = (change) => {
    const updatedFilter = filter;
    updatedFilter["StartDate"] = change;
    handleFilter({
      ...updatedFilter,
    });
  };
  const handleMonth = (change) => {
    const updatedFilter = filter;
    updatedFilter["Month"] = change;
    handleFilter({
      ...updatedFilter,
    });
  };
  const handleEndDateChange = (change) => {
    const updatedFilter = filter;
    updatedFilter["EndDate"] = change;
    handleFilter({
      ...updatedFilter,
    });
  };
  const handleDynamicChange = (change) => {
    const updatedFilter = filter;
    updatedFilter["dynamicFilter"] = [dynamicType, change];
    handleFilter({
      ...updatedFilter,
    });
  };

  const handleRevenueType = (change) => {
    const updatedFilter = filter;
    updatedFilter["RevenueType"] =  change;
    handleFilter({
      ...updatedFilter,
    });
  }

  const [properties, setProperties] = useState({});
  const [defaultProperty, setDefaultProperty] = useState();
  const [floors, setFloors] = useState({});
  const [defaultFloor, setDefaultFloor] = useState();

  const getProperties = async (event) => {
    let obj = {};
    try {
      const querySnapshot = await getDocs(collection(db, "Properties"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        obj[doc.data().PropertyName] = doc.id;

        if (doc.id == defaultProp) {
          setDefaultProperty(doc.data().PropertyName);
        }
      });
      if (all) {
        obj["All Properties"] = "allProperties";
      }

      setProperties(obj);
    } catch (err) {
      console.log(err);
    }
  };

  const getFloors = async (prop) => {
    let obj = {};

    try {
      const querySnapshot = await getDocs(
        query(collection(db, "Floors"), where("PropertyId", "==", prop))
      );
      if (all) {
        obj["All Floors"] = "allFloors";
      }
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if(doc.data().floorName==undefined){
          obj[doc.data().floorNo] = doc.id;
        }
        else{
          obj[doc.data().floorName] = doc.id;
        }
        
        setFloors(obj);
        if (doc.id == defFloor) {
          setDefaultFloor(doc.data().floorNo);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(defaultFloor);
  }, [defaultFloor]);

  useEffect(() => {
    getProperties();
    handleStartDateChange(
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
    );
    handleEndDateChange(
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
    );
    handleMonth(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  }, []);

  useEffect(() => {
    getFloors(defaultProp);
  }, [defaultProperty]);

  useEffect(() => {
    getFloors(filter.Property);
    if (filter.PropertyName != null) {
    }
  }, [filter.Property]);

  useEffect(() => {
    console.log(floors);
  }, [floors]);

  return (
    <>
      <form className={`${classes.tableTop} no-print`}>
        {dProperty && defaultProperty && (
          <DropDown
            defaultProp={defaultProperty}
            properties={properties}
            filter={filter}
            handleFilter={handleFilter}
            title={!bangla ? "Property" : "প্রপার্টি"}
            items={Object.keys(properties)}
          ></DropDown>
        )}
        {dFloor && defaultFloor != undefined && (
          <DropDown
            defaultProp={defaultFloor}
            properties={floors}
            filter={filter}
            handleFilter={handleFilter}
            title={!bangla ? "Floor" : "ফ্লোর"}
            items={Object.keys(floors)}
          ></DropDown>
        )}
        {dBlock && (
          <div className={classes.tableTopItem}>
            <label style={{ position: "relative" }}>
              {!bangla ? "Search by" : "অনুসন্ধান"}
              <select
                onChange={(e) => setDynamicType(e.target.value)}
                defaultValue={"ShopNo"}
                style={{
                  border: "none",
                  height: "auto",
                  width: "auto",
                  fontSize: "1.3rem",
                  paddingRight: "2.2rem",
                  position: "absolute",
                  right: "0",
                  color: "#9A9A9A",
                }}
              >
                <option value="ShopNo" selected>
                  {!bangla ? "Unit No" : "ইউনিট নং"}
                </option>
                <option value="Block">{!bangla ? "Block" : "ব্লক"}</option>
                <option value="BusinessName">
                  {!bangla ? "Unit Name" : "ইউনিট নাম"}
                </option>
              </select>
            </label>

            {!bangla ? (
              <input
                style={{ width: "15rem" }}
                onChange={(e) => handleDynamicChange(e.target.value)}
                type="text"
                placeholder={
                  dynamicType == "ShopNo"
                    ? `Enter Unit No.`
                    : dynamicType == "Block"
                    ? `Enter Block`
                    : dynamicType == "BusinessName"
                    ? `Enter Unit Name`
                    : ``
                }
              />
            ) : (
              <input
                style={{ width: "15rem" }}
                onChange={(e) => handleDynamicChange(e.target.value)}
                type="text"
                placeholder={
                  dynamicType == "ShopNo"
                    ? `ইউনিট নং লিখুন`
                    : dynamicType == "Block"
                    ? `ব্লক লিখুন`
                    : dynamicType == "BusinessName"
                    ? `ইউনিট নাম লিখুন`
                    : ``
                }
              />
            )}
          </div>
        )}

        {dSearch && (
          <div className={classes.tableTopItem}>
            <label>{!bangla ? "Search" : "অনুসন্ধান"}</label>
            <input
              onChange={(e) => handleSearchChange(e.target.value)}
              type="text"
              placeholder={
                !bangla ? "Enter a unit's Name" : "ইউনিটের নাম লিখুন"
              }
            />
          </div>
        )}

        {dPropertyAndFloors && <DropDownV2 />}

        {dStartAndEndDates && (
          <>
            <div className={classes.tableTopItem}>
              <label>{!bangla ? "Start Date" : "শুরুর তারিখ"}</label>
              <input
                defaultValue={`${new Date().getFullYear()}-${
                  new Date().getMonth() + 1
                }`}
                onChange={(e) => handleStartDateChange(e.target.value)}
                type="month"
                placeholder="Start: "
              />
            </div>
            <div className={classes.tableTopItem}>
              <label>{!bangla ? "End Date" : "শেষ তারিখ"}</label>
              <input
                defaultValue={`${new Date().getFullYear()}-${
                  new Date().getMonth() + 1
                }`}
                onChange={(e) => handleEndDateChange(e.target.value)}
                type="month"
                placeholder="End: "
              />
            </div>
          </>
        )}

        {dStartAndEndExactDates && (
          <>
            <div className={classes.tableTopItem}>
              <label>{!bangla ? "Start Date" : "শুরুর তারিখ"}</label>
              <input
                defaultValue={`${new Date().getFullYear()}-${
                  new Date().getMonth() + 1
                }`}
                onChange={(e) => handleStartExactDateChange(e.target.value)}
                type="date"
                placeholder="Start: "
              />
            </div>
            <div className={classes.tableTopItem}>
              <label>{!bangla ? "End Date" : "শেষ তারিখ"}</label>
              <input
                defaultValue={`${new Date().getFullYear()}-${
                  new Date().getMonth() + 1
                }`}
                onChange={(e) => handleEndExactDateChange(e.target.value)}
                type="date"
                placeholder="End: "
              />
            </div>
          </>
        )}

        {dMonth && (
          <>
            <div className={classes.tableTopItem}>
              <label>{!bangla ? "Month" : "মাস"}</label>
              <input
                defaultValue={`${new Date().getFullYear()}-${
                  new Date().getMonth() + 1
                }`}
                onChange={(e) => handleMonth(e.target.value)}
                type="month"
                placeholder="Month: "
              />
            </div>
          </>
        )}
        {RevenueType && (
          <div className={classes.tableTopItem}>
            <label>{"Revenue Type"}</label>
            <select onChange={e=>handleRevenueType(e.target.value)}>
                <option value={"Rent"}>Rent</option>
                <option value={"Utility"}>Utility</option>
                <option value={"Service Charge"}>Service Charge</option>
            </select>
          </div>
        )}
      </form>
      {genRep && (
        <button
          className={`${classes.genRep} ${classes.noPrint} no-print`}
          onClick={() => window.print()}
        >
          <img src={generateRep} />
          {!bangla ? "Generate Report" : ""}
        </button>
      )}
      <div className={classes.buttonRow}>
        {addNewBtn && (
          <button
            className={`${classes.NewBtn} ${classes.noPrint}`}
            onClick={() => navigate(addNewBtn)}
          >
            {!bangla ? "Add New Unit" : ""}
          </button>
        )}
        {quickAddNewBtn && (
          <button
            className={`${classes.NewBtn} ${classes.noPrint}`}
            onClick={() => navigate(quickAddNewBtn)}
          >
            {!bangla ? "Quick Add Unit" : "কুইক অ্যাড ইউনিট"}
          </button>
        )}
      </div>
      

      <br />
      <br />
    </>
  );
}
