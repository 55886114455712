import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";
import classes from "../../styles/TableTop.module.css"
import classes2 from "../../styles/Table.module.css"
import { useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export default function ViewUsers(){
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const [users, setUsers]=useState({});


    const getUsers = async(filter)=>{
        setIsLoading(true);
        setUsers();
        try{
            let querySnapshot = await getDocs(collection(db, "Users"));
            
            if(filter!=null){
                querySnapshot = await getDocs(query(collection(db, "Users"), 
                where("Name", ">=", filter.toString()), 
                where("Name", "<=", filter.toString()+ '\uf8ff')));
            }
            // 
            let userObj = {};
            querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
                
                userObj[doc.id]={UserName: `${doc.data().Name.substring(0,9)}...`, Status: doc.data().Status, LastLogin: (doc.data().LastLogin) && (`${doc.data().LastLogin.toDate().toLocaleDateString()} ${doc.data().LastLogin.toDate().toLocaleTimeString()}`) , Role: doc.data().Role, Email: `${doc.data().Email.substring(0,15)}...`,}
                
                setUsers(userObj);
                
                
                setIsLoading(false);
            });
        }
        catch(err){
            console.log(err);
            setIsLoading(false);
        }
    }

    useEffect(()=>{
        getUsers();
        console.log("effect")
    },[])


    const [filter, setFilter] = useState("");

    const handleRowClick=(id)=>{
        navigate(`/users/${id}`);
    }

    return(
        
        <>
            <PageTemp title="View Users" subTitle="View all users here" page={
                
                isLoading ? 
                <>
                    <form className={classes.tableTop}>
                        <div className={classes.tableTopItem}>
                            <label>Search</label>
                            <input onChange={(e)=>
                            {
                                getUsers(e.target.value);
                                
                            }} type="text" placeholder="Type a user's name" />
                        </div>
                    </form>
                    <div className={classes2.loader}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </>
                :
                <>
                <form className={classes.tableTop}>
                    <div className={classes.tableTopItem}>
                        <label>Search</label>
                        <input onChange={(e)=>
                        {
                            getUsers(e.target.value);
                            
                        }} type="text" placeholder="Type a user's name" />
                    </div>
                    <div className={classes.tableTopItem}>
                    <br></br>
                    {/* <button onClick={()=>navigate('/roles')} className={`${classes.EditRoles} ${classes.noPrint}`} >Edit Roles</button> */}
                    </div>
                    
                </form>
                <br />
                <Table>
                
                    <TableHeader headings={["User", "Status", "Email", "Role", "Last Login"]}/>
                    {users && Object.keys(users).map((key,index)=>{
                        return (<TableRow onClick={handleRowClick} id={key} info={["",users[key].UserName]} status={users[key].Status} normalDetails={[users[key].Email, users[key].Role, users[key].LastLogin]}/>)
                    })}
                </Table>
            </>
            }/>
        </>
    );
}