import { UserAuth } from "../context/AuthContext";

export default function FormInput({
  data,
  handleData,
  title,
  placeholder,
  password,
  nolabel,
}) {
  const { bangla } = UserAuth();
  const T = {
    Floor: "ফ্লোর",
    Area: "ফ্লোর সাইজ",
    "Property Name": "প্রপার্টি নাম",
    "Property Size": "প্রপার্টি সাইজ",
    "Property Location": "প্রপার্টি ঠিকানা",
    "No Of Floors": "ফ্লোর এর সংখ্যা",
    "Default Floor Size": "ডিফল্ট ফ্লোর সাইজ",
  };
  const ph = {
    "Floor No": "ফ্লোর নম্বর",
    "Floor Size": "ফ্লোর সাইজ",
    "Enter Name": "নাম লিখুন",
    "Enter Size": "সাইজ লিখুন",
    "Enter Location": "ঠিকানা লিখুন",
    "Enter Number of Floors": "ফ্লোর এর সংখ্যা লিখুন",
    "Enter Default Floor Size": "ডিফল্ট ফ্লোর সাইজ লিখুন",
  };

  const handleInputChange = (x) => {
    const newData = data;
    newData[title.replace(/ /g, "")] = x;
    handleData(newData);
  };

  return (
    <>
      {!nolabel && (
        <label>{!bangla ? title : title in T ? T[title] : title}</label>
      )}
      <input
        onChange={(e) => handleInputChange(e.target.value)}
        type={password ? "password" : "text"}
        placeholder={
          !bangla
            ? placeholder
            : placeholder in ph
            ? ph[placeholder]
            : placeholder
        }
      />
    </>
  );
}
