import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";
import classes from "../../styles/TableTop.module.css";
import classes2 from "../../styles/Table.module.css";
import { useState } from "react";
import { arrayUnion, collection, deleteField, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function UpcomingCancel() {
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const { rolee } = UserAuth();
  useEffect(() => {
    if (rolee && !rolee.manageRoles) {
      navigate("/");
    }
  }, [rolee]);

  const [data, setData] = useState([]);

  const [filter, setFilter] = useState("");

  const handleRowClick = (id) => {
    navigate(`/users/${id}`);
  };

  const cancelTenant = async (d) => {
    await updateDoc(doc(db, "Shops", d.id), {
      BusinessName: deleteField(),
      TenantName: deleteField(),
      SecurityDeposit: 0,
      TenantFatherName: deleteField(),
      TenantHomeAddress: deleteField(),
      TenantMobileNumber: deleteField(),
      TenantNID: deleteField(),
      TenantPassport: deleteField(),
      ManagerName: deleteField(),
      ManagerMobile: deleteField(),
      LeaseStarting: deleteField(),
      LeaseEnding: deleteField(),
      SecondaryName: deleteField(),
      SecondaryFatherName: deleteField(),
      SecondaryHomeAddress: deleteField(),
      SecondaryMobile: deleteField(),
      SecondaryNID: deleteField(),
      SecondaryPassportNumber: deleteField(),
      SecondaryTenantRent: deleteField(),
      EmergencyName: deleteField(),
      EmergencyRelation: deleteField(),
      EmergencyMobile: deleteField(),
      EmergencyAddress: deleteField(),
      dp: deleteField(),
      upcomingCancellationStatus: false,
      TenantType: "Vacant",
      PastTenants: arrayUnion({...d, LeaseEnding: d["UpcomingCancellation"].RequestedLeaseEnding}),
      UpcomingCancellation: deleteField()
    });
    // setUpcomingCancellation(null);
    navigate('/');
  }

  const getShops = async () => {
    try {
      const arr = [];
        
      const querySnapshot = await getDocs(query(collection(db, "Shops"), where("upcomingCancellationStatus", "==", true)));
      querySnapshot.forEach((doc) => {
        arr.push({...doc.data(), id: doc.id});
      });
      setData(arr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getShops();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <PageTemp
        title="Upcoming Cancellations"
        subTitle="View all upcoming cancellations here"
        page={
          isLoading ? (
            <>
              <div className={classes2.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              <form className={classes.tableTop}>
                <div className={classes.tableTopItem}>
                  <br></br>
                  <div style={{display: "flex", flexDirection: "column",}}>
                  {data.map((d) => {
                    return (
                      <>
                      <div style={{display: "flex", flexDirection: "row",}}>
                      <button style={{marginRight: "1rem", width: "30rem", height: "6rem"}}
                        type="button"
                        onClick={()=>navigate(`/properties/${d.PropertyId}/floors/${d.FloorNo}/shops/${d.id}`)}
                        //   onClick={() => navigate(`/roles/${role}`)}
                          className={`${classes.Role} ${classes.noPrint}`}
                        >
                          {d.BusinessName}
                          <br></br>
                          {`Cancelling on: ${d.UpcomingCancellation["RequestedLeaseEnding"]}`}
                        </button>
                        <button
                        style={{width: "10rem", height: "6rem"}}
                        type="button"
                          onClick={() => cancelTenant(d)}
                          className={`${classes.Role} ${classes.noPrint}`}
                        >
                          Cancel
                        </button>
                      </div>
                        
                      </>
                    );
                    
                  })}
                  </div>

                  {/* <button
                    onClick={() => navigate("/roles/add")}
                    
                    className={`${classes.AddRoles} ${classes.noPrint}`}
                  >
                    Add Role
                  </button> */}
                </div>
              </form>
            </>
          )
        }
      />
    </>
  );
}
