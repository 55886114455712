import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";
import classes from "../../styles/Table.module.css";
import classes2 from "../../styles/TableTop.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  collection,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function ViewProperties() {
  const { bangla } = UserAuth();

  //Read database into this state
  const [data, setData] = useState([]);
  const [floorCounts, setFloorCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { rolee, user } = UserAuth();

  useEffect(() => {
    if (rolee && !rolee.viewProperty) {
      navigate("/");
    }
  }, [rolee]);

  const getCountFloors = async (id) => {
    const snapshot = await getCountFromServer(
      query(collection(db, "Floors"), where("PropertyId", "==", id))
    );
    const obj = {};
    obj[id] = snapshot.data().count;
    setFloorCounts((prev) => {
      return { ...prev, ...obj };
    });
  };

  const getProperties = async (event) => {
    const obj = {};
    try {
      const querySnapshot = await getDocs(collection(db, "Properties"));
      querySnapshot.forEach(async (doc) => {
        const subObj = {};

        // doc.data() is never undefined for query doc snapshots
        if (
          (Object.keys(user.PropertyAccess).includes(`${doc.id}`) &&
            user.PropertyAccess[doc.id].length > 0) ||
          user.Role == "Admin"
        ) {
          getCountFloors(doc.id);
          // const snapshot = await getCountFromServer(query(collection(db, "Floors"), where("PropertyId", "==", doc.id)));
          // console.log(snapshot.data().count);
          subObj["PropertyName"] = doc.data().PropertyName;
          // subObj["Floor"]=snapshot.data().count;
          obj[doc.id] = subObj;
        }
      });
      console.log(obj);
      setData(obj);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRowClick = (id) => {
    rolee.viewFloor == true && navigate(`/properties/${id}/floors`);
  };

  const handleEditButtonClick = (id) => {
    navigate(`/properties/${id}`);
  };

  useEffect(() => {
    getProperties();
  }, []);

  // useEffect(()=>{
  //   console.log(data);
  //   // getCountFloors();
  //   if(data[Object.keys(data)[0]] && data[Object.keys(data)[0]].Floor==undefined){
  //     getCountFloors();
  //   }
  // },[data]);

  return (
    <>
      <PageTemp
        title="All Properties"
        subTitle="View all properties here"
        page={
          isLoading ||
          Object.keys(data).length != Object.keys(floorCounts).length ? (
            <>
              {rolee && rolee.addProperty && (
                <button
                  className={`${classes2.NewBtn} ${classes2.noPrint}`}
                  onClick={() => navigate(`/properties/add`)}
                >
                  {!bangla ? "Add New Property" : ""}
                </button>
              )}
              <div style={{ marginLeft: "2rem" }} className={classes.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              {rolee && rolee.addProperty && (
                <button
                  className={`${classes2.NewBtn} ${classes2.noPrint}`}
                  onClick={() => navigate(`/properties/add`)}
                >
                  {!bangla ? "Add New Property" : ""}
                </button>
              )}
              {
                <Table>
                  <TableHeader headings={["Property Name", "Floors"]} />

                  {Object.keys(data).map((d) => {
                    return (
                      <TableRow
                        editBtn={rolee && rolee.editProperty}
                        id={d}
                        onClick2={handleEditButtonClick}
                        onClick={handleRowClick}
                        normalDetails={[data[d].PropertyName, floorCounts[d]]}
                      />
                    );
                  })}
                </Table>
              }
            </>
          )
        }
      />
    </>
  );
}
