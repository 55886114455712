import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";

import submitIcon from "../../assets/icons/Save.svg";
import uploadIcon from "../../assets/icons/Upload.svg";
import classes3 from "../../styles/Table.module.css";
import { useState } from "react";
import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect } from "react";
import classes2 from "../../styles/Collection.module.css";

export default function PropertyComplain() {
  //use this state to update
  const [data, setData] = useState({ files: {} });
  const { user, bangla, rolee } = UserAuth();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (rolee && !rolee.propertyComplain) {
      navigate("/");
    }
  }, [rolee]);

  //submit button
  const handleSubmit = async () => {
    try {
      const preDocRef = collection(db, "Requests");
      const preRef = doc(preDocRef);
      const id = preRef.id;
      file.map((f) => {
        uploadFile(f, id);
      });
      console.log(data.files);
      const docRef = await setDoc(preRef, {
        ...data,
        ...propertyData,
        RequestTitle: "Property Complain",
        RequestedBy: user.Name,
      });
      const log = await addDoc(collection(db, "Logs"), {
        CreatedAt: serverTimestamp(),
        Message: `${user.Name} has filed a Property complain`,
      });
      navigate(`/`);
    } catch (err) {
      console.log(err);
    }
  };

  const [file, setFile] = useState([]);

  const uploadFile = (f, id) => {
    if (!file) {
      // navigate(`/properties/${id}`);
      return;
    }
    const storageRef = ref(storage, `/complains/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          console.log(id);
          handleFile(f.name, url);

          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Requests", id), {
      files: { ...data.files },
    });
    navigate(`/`);
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  const [allProperties, setAllProperties] = useState();
  const [currentProperty, setCurrentProperty] = useState();

  const getProps = async () => {
    const querySnapshot = await getDocs(collection(db, "Properties"));
    const obj = {};
    querySnapshot.forEach((doc) => {
      if (
        (Object.keys(user.PropertyAccess).includes(`${doc.id}`) &&
          user.PropertyAccess[doc.id].length > 0) ||
        user.Role == "Admin"
      ) {
        obj[doc.data().PropertyName] = doc.id;
      }
    });
    setAllProperties(obj);
  };

  useEffect(() => {
    getProps();
  }, []);

  const [propertyData, setPropertyData] = useState();

  const getProperty = async (id) => {
    try {
      const docRef = await getDoc(doc(db, "Properties", id));
      setPropertyData(docRef.data());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProperty(currentProperty);
  }, [currentProperty]);

  return (
    <>
      <PageTemp
        title="Property Complain"
        subTitle="Please enter the following details"
        page={
          <>
            {!currentProperty && (
              <div className={classes2.mainn}>
                <p className={classes2.head}>
                  {!bangla
                    ? "Please Select Property:"
                    : "প্রপার্টি সিলেক্ট  করুন"}
                </p>
                {allProperties &&
                  Object.keys(allProperties).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentProperty(allProperties[key]);
                        }}
                      >
                        {key}
                      </div>
                    );
                  })}
              </div>
            )}

            {currentProperty && (
              <form action="">
                <div className={classes.formGroup}>
                  <div className={classes.names}>
                    <label htmlFor="">Property Name</label>
                    <label htmlFor="">Property Type</label>
                    <label htmlFor="">Property Size</label>
                    <label htmlFor="">Location</label>
                  </div>
                  {propertyData && (
                    <div className={classes.values}>
                      <label htmlFor="">{propertyData.PropertyName}</label>
                      <label htmlFor="">{propertyData.PropertyType}</label>
                      <label htmlFor="">{propertyData.PropertySize}</label>
                      <label htmlFor="">{propertyData.PropertyLocation}</label>
                    </div>
                  )}
                </div>

                <br />

                <label className={classes.upFileButton} htmlFor="fileUpload">
                  <img src={uploadIcon} />
                  {!bangla ? "Upload" : "আপলোড করুন"}
                  <input
                    multiple
                    onChange={fileHandler}
                    id="fileUpload"
                    type="file"
                    style={{ display: "none" }}
                  />
                </label>
                <label>{!bangla ? "Complain" : "অভিযোগ"}</label>
                <textarea
                  onChange={(e) =>
                    setData({ ...data, Complain: e.target.value })
                  }
                  placeholder={!bangla ? "Enter Complain" : "অভিযোগ  লিখুন"}
                ></textarea>
                <br />
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={`${classes.submit} ${classes.red}`}
                >
                  <img src={submitIcon} />
                  {!bangla ? "Submit" : "জমা দিন"}
                </button>
              </form>
            )}
          </>
        }
      />
    </>
  );
}
