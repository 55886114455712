import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";
import classes2 from "../../styles/TableTop.module.css";
import { useState } from "react";
import TableTop from "../TableTop";
import classes from "../../styles/Table.module.css";
import {
  doc,
  getDocs,
  where,
  query,
  collection,
  getDoc,
  orderBy,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function ViewFloors() {
  const navigate = useNavigate();
  const { bangla } = UserAuth();
  const { propertyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  const { rolee, user } = UserAuth();

  useEffect(() => {
    if (rolee && !rolee.viewFloor) {
      navigate("/");
    }
  }, [rolee]);

  const [filter, setFilter] = useState({
    Property: propertyId,
  });

  const [data, setData] = useState([
    // {
    //     floorNo: 1,
    //     area: 300
    // },
    // {
    //     floorNo: 2,
    //     area: 300
    // }
  ]);

  const handleFilter = (data) => {
    setFilter(data);
  };

  const handleNewFloor = async () => {
    navigate(`/properties/${propertyId}/floors/add`);
  };

  const handleRowClick = (id) => {
    rolee.viewUnit == true &&
      navigate(`/properties/${propertyId}/floors/${id}/shops`);
  };

  const handleEditClick = (id) => {
    navigate(`/properties/${propertyId}/floors/${id}`);
  };

  const [countUnits, setCountUnits] = useState({});

  const getCountUnits = async (id) => {
    const snapshot = await getCountFromServer(
      query(collection(db, "Shops"), where("floorId", "==", id))
    );
    const obj = {};
    obj[id] = snapshot.data().count;
    setCountUnits((prev) => {
      return { ...prev, ...obj };
    });
  };

  const getFloors = async (x) => {
    const obj = [];
    try {
      console.log(x);
      const docRef = await getDocs(
        query(
          collection(db, "Floors"),
          where("PropertyId", "==", x),
          orderBy("floorNo", "asc")
        )
      );
      docRef.forEach(async (doc) => {
        // const snapshot = await getCountFromServer(query(collection(db, "Shops"), where("floorId", "==", doc.id)));

        if (
          (Object.keys(user.PropertyAccess).includes(
            `${doc.data().PropertyId}`
          ) &&
            user.PropertyAccess[doc.data().PropertyId].length > 0 &&
            user.PropertyAccess[doc.data().PropertyId].includes(doc.id)) ||
          user.Role == "Admin"
        ) {
          console.log(doc.data());
          const subObj = {};
          getCountUnits(doc.id);
          if (doc.data().floorName != undefined) {
            subObj["floorNo"] = doc.data().floorName;
          } else {
            subObj["floorNo"] = doc.data().floorNo;
          }
          subObj["id"] = doc.id;
          obj.push(subObj);
          setData((prev) => {
            return obj;
          });
        }
      });
      setIsLoading(false);
      // setData(obj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFloors(propertyId);
  }, []);

  useEffect(() => {
    filter.Property != undefined && setIsLoading(true);
    filter.Property != undefined &&
      propertyId != filter.Property &&
      navigate(`/properties/${filter.Property}/floors`);
    console.log(propertyId);
  }, [filter]);

  useEffect(() => {
    getFloors(propertyId);
  }, [propertyId]);

  return (
    <>
      <PageTemp
        title="All Floors"
        subTitle={`View all Floors`}
        page={
          isLoading ||
          Object.keys(data).length != Object.keys(countUnits).length ? (
            <>
              <TableTop
                defaultProp={propertyId}
                filter={filter}
                handleFilter={handleFilter}
                dProperty={true}
              />
              <div style={{ marginLeft: "2rem" }} className={classes.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              <TableTop
                defaultProp={propertyId}
                filter={filter}
                handleFilter={handleFilter}
                dProperty={true}
              />
              {rolee && rolee.addFloor && (
                <button
                  style={{ marginTop: "-2rem" }}
                  className={`${classes2.NewBtn} ${classes2.noPrint}`}
                  onClick={() =>
                    navigate(`/properties/${propertyId}/floors/add`)
                  }
                >
                  {!bangla ? "Add New Floor" : ""}
                </button>
              )}
              <Table>
                <TableHeader headings={["Floor No.", "No Of Units"]} />
                {data.length == 0 && <p>No floors created</p>}
                {data.map((d) => {
                  return (
                    <TableRow
                      onClick2={handleEditClick}
                      editBtn={rolee && rolee.editFloor}
                      id={d.id}
                      onClick={handleRowClick}
                      normalDetails={[d.floorNo, countUnits[d.id]]}
                    />
                  );
                })}
              </Table>
            </>
          )
        }
      />
    </>
  );
}
