import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import classes2 from "../../styles/Table.module.css";

import Files from "../../assets/icons/Files.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  where,
  query,
  orderBy,
  serverTimestamp,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { ref, getStorage, uploadBytes } from "firebase/storage";
import { UserAuth } from "../../context/AuthContext";

export default function QuickShop() {
  const { user, bangla, rolee } = UserAuth();

  //use this state to update database
  const [data, setData] = useState({
    UnitType: "Vacant",
    Block: "",
    ShopNo: "",
    UnitSpace: "",
  });

  useEffect(() => {
    if (rolee && !rolee.addUnit) {
      navigate("/");
    }
  }, [rolee]);

  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vacant, setVacant] = useState(false);
  // const [properties, setProperties] = useState({})
  const navigate = useNavigate();
  // const [floors, setFloors]=useState();
  const [secondary, setSecondary] = useState(true);
  const [properties, setProperties] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [floors, setFloors] = useState();
  const [currentFloor, setCurrentFloor] = useState();

  const { propertyId, floorId } = useParams();

  const handlePropertySelect = (data) => {
    properties &&
      properties.map((property) => {
        if (property.name == data) {
          setCurrentProperty({ name: property.name, id: property.id });
        }
      });
    navigate(`/properties/${currentProperty.id}/floors/undefined/shops/add`);
  };

  function letterToNumber(letter) {
    letter = letter.toUpperCase();
    switch (letter) {
      case 'A':
        return 75;
      case 'B':
        return 150;
      case 'C':
        return 225;
      case 'D':
        return 300;
      case 'E':
        return 375;
      case 'F':
        return 450;
      case 'G':
        return 525;
      case 'H':
        return 600;
      case 'I':
        return 675;
      case 'J':
        return 750;
      case 'K':
        return 825;
      case 'L':
        return 900;
      case 'M':
        return 975;
      case 'N':
        return 1050;
      case 'O':
        return 1125;
      case 'P':
        return 1200;
      case 'Q':
        return 1275;
      case 'R':
        return 1350;
      case 'S':
        return 1425;
      case 'T':
        return 1500;
      case 'U':
        return 1575;
      case 'V':
        return 1650;
      case 'W':
        return 1725;
      case 'X':
        return 1800;
      case 'Y':
        return 1875;
      case 'Z':
        return 1950;
      default:
        return 0;
    }
  }

  

  const handleFloorSelect = (data) => {
    floors &&
      floors.map((floor) => {
        if (floor.floorNo == data) {
          setCurrentFloor({ floorNo: floor.floorNo, id: floor.id });
        }
      });
  };

  useEffect(() => {
    if (data.TenantType == "Vacant") {
      setVacant(true);
    } else {
      setVacant(false);
    }
  }, [data]);

  const [err, setErr] = useState();

  // const [shops, setShops] = useState();

  // const fetchAllShops = async () => {
  //   try {
  //     const obj = {};
  //     const docRef = await getDocs(
  //       query(collection(db, "Shops"), where("floorId", "==", floorId))
  //     );
  //     docRef.forEach((doc) => {
  //       obj[doc.data().ShopNo] = doc.data().Block;
  //     });
  //     console.log(obj);
  //     setShops(obj);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  //submit button here
  const handleSubmit = async () => {
    const docRef = await getCountFromServer(
      query(
        collection(db, "Shops"),
        where("ShopNo", "==", data.ShopNo),
        where("Block", "==", data.Block),
        where("PropertyId", "==", propertyId),
        where("floorId", "==", floorId)
      )
    );

    if (docRef.data().count > 0) {
      setErr("Shop No. already exists");
    } 
    else if(data.Block!=data.Block.toUpperCase()){
      setErr("Block Must be in uppercase")
    }
    else if (data.ShopNo == "") {
      setErr("Shop No. cannot be empty.");
    } else if (/^\d+$/.test(data.ShopNo) == false) {
      setErr("Shop No must be in numbers only");
    } else if (data.UnitSpace == "") {
      setErr("Unit Space cannot be empty.");
    } else if (/^\d+$/.test(data.UnitSpace) == false) {
      setErr("Unit Space must be in numbers only");
    } else {
      setErr("");
      console.log(data);
      const today = new Date().getMonth() + 1;
      // let nextPay;
      // if(today == 12){
      //     nextPay = `${new Date().getFullYear()+1}-01`
      // }
      // else{
      //     nextPay = `${new Date().getFullYear()}-${new Date().getMonth() + 2}`
      // }

      try {
        setLoading(true);
        const docRef = await addDoc(collection(db, "Shops"), {
          ...data,
          Status: "Not Paid",
          LastPaid: "Never",
          // NextPayment: nextPay,
          Entries: {
            Rent: {},
            Utility: {},
            ServiceCharge: {},
          },
          floorData: {
            height: 75,
            width: 75,
            x: 75*data.ShopNo,
            y: letterToNumber(data.Block)
          }
        });
        const log = await addDoc(collection(db, "Logs"), {
          CreatedAt: serverTimestamp(),
          Message: `${user.Name} has added a new Unit: ${data.BusinessName}`,
        });
        setLoading(false);
        setDone(true);
        setTimeout(() => {
          navigate(`/properties/${propertyId}/floors/${floorId}/shops`);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    // fetchAllShops();
  }, []);

  const fetchProperties = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Properties"));
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push({
          id: doc.id,
          name: doc.data().PropertyName,
        });
      });
      // console.log(arr);
      setProperties(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFloors = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, `Floors`),
          where("PropertyId", "==", currentProperty.id),
          orderBy("floorNo")
        )
      );
      const arr = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        arr.push({
          floorNo: doc.data().floorNo,
          area: doc.data().area,
          id: doc.id,
        });
      });
      setFloors(arr);
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchFloors = async()=>{
  //     try{
  //         const querySnapshot= await getDocs(query(collection(db,"Floors"),
  //         where("PropertyId", "==", properties[data.Property])));

  //         const obj={}
  //         querySnapshot.forEach((doc)=>{
  //             obj[doc.data().FloorNo]=doc.id;
  //         })
  //         setFloors(obj);
  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  // const fetchProperties=async()=>{
  //     try{
  //         const querySnapshot = await getDocs(collection(db, "Properties"));
  //         const obj={};
  //         querySnapshot.forEach((doc) => {
  //             obj[doc.data().PropertyName]=doc.id;

  //         });
  //         setProperties(obj);
  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  // useEffect(()=>{
  //     console.log(img);
  // },[img])

  // useEffect(()=>{
  //     console.log(floors);
  // },[floors]);

  useEffect(() => {
    properties &&
      properties.map((property) => {
        if (property.id == propertyId) {
          setCurrentProperty({ name: property.name, id: property.id });
        }
      });
  }, [properties]);

  useEffect(() => {
    if (floorId == "undefined") {
      setCurrentFloor({ floorNo: floors[0].floorNo, id: floors[0].id });
    }
    floors &&
      floors.map((floor) => {
        if (floor.id == floorId) {
          setCurrentFloor({ floorNo: floor.floorNo, id: floor.id });
        }
      });
  }, [floors]);

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    currentProperty != undefined &&
      navigate(
        `/properties/${currentProperty.id}/floors/${floorId}/shops/quickAdd`
      );
    currentProperty != undefined &&
      setData({
        ...data,
        PropertyName: currentProperty.name,
        PropertyId: currentProperty.id,
      });

    fetchFloors();
  }, [currentProperty]);

  useEffect(() => {
    currentFloor &&
      navigate(
        `/properties/${propertyId}/floors/${currentFloor.id}/shops/quickAdd`
      );
    currentFloor &&
      setData({
        ...data,
        FloorNo: currentFloor.floorNo,
        floorId: currentFloor.id,
      });
  }, [currentFloor]);

  return (
    <>
      <PageTemp
        title="Quick Add Unit"
        subTitle="Please enter the following details"
        page={
          <div>
            <form action="">
              <div className={classes.formGroup}></div>
              <div className={classes.formDetail}>
                <div className={classes.detail}>
                  <p className={classes.detailHeader}>
                    <img src={Location} />
                    {!bangla ? "Location" : "ঠিকানা"}
                  </p>
                  <div className={classes.subDetail}>
                    {currentProperty && (
                      <label>
                        {!bangla
                          ? `Property: ${currentProperty.name}`
                          : `প্রপার্টি: ${currentProperty.name}`}
                      </label>
                    )}
                    {/* {currentProperty && <select onChange={e=>handlePropertySelect(e.target.value)} defaultValue={currentProperty.name}>
                                        
                                        
                                        {properties && properties.map((property)=>{
                                            return <option val={property.name}>{property.name}</option>
                                        })}
                                        
                                    </select>} */}
                    {currentFloor && floors && (
                      <>
                        <label>
                          {!bangla
                            ? `Floor: ${currentFloor.floorNo}`
                            : `ফ্লোর: ${currentFloor.floorNo}`}
                        </label>
                        {/* <select onChange={e=>handleFloorSelect(e.target.value)} defaultValue={currentFloor.floorNo}>
                                        {
                                            floors.map((key)=>{
                                                return <option val={key.floorNo}>{`${key.floorNo}`}</option>
                                            })
                                        }
                                    </select> */}
                      </>
                    )}
                    <br></br>
                    <label>{!bangla ? "Block" : "ব্লক"}</label>
                    {!bangla ? (
                      <input
                        onChange={(e) =>
                          setData({ ...data, Block: e.target.value })
                        }
                        type="text"
                        placeholder="Enter Block"
                      />
                    ) : (
                      <input
                        onChange={(e) =>
                          setData({ ...data, Block: e.target.value })
                        }
                        type="text"
                        placeholder="ব্লক লিখুন"
                      />
                    )}
                    <label>{!bangla ? "Unit No." : "ইউনিট নং."}</label>
                    {!bangla ? (
                      <input
                        onChange={(e) =>
                          setData({ ...data, ShopNo: parseInt(e.target.value) })
                        }
                        type="text"
                        placeholder="Enter Unit No."
                      />
                    ) : (
                      <input
                        onChange={(e) =>
                          setData({ ...data, ShopNo: parseInt(e.target.value) })
                        }
                        type="text"
                        placeholder="ইউনিট নং লিখুন"
                      />
                    )}
                    <label>{!bangla ? "Unit Space" : "ইউনিট সাইজ"}</label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, UnitSpace: e.target.value })
                      }
                      type="text"
                      placeholder={
                        !bangla ? "Enter Unit Space" : "ইউনিট সাইজ লিখুন"
                      }
                    />
                    {/* <label>Unit Type</label> */}
                    {/* <select onChange={e=>setData({...data, TenantType: e.target.value})} defaultValue={"Vacant"}>
                                        <option val="Vacant">Vacant</option>
                                        <option val="Primary">Primary</option>
                                        <option val="Position">Position</option>
                                    </select> */}
                  </div>
                  <br />
                </div>

                <div className={classes.detail}>
                  <p className={classes.detailHeader}>
                    <img src={EntryInfo} />
                    {!bangla ? "Entry Info" : "এন্ট্রি তথ্য"}
                  </p>
                  <div className={classes.subDetail}>
                    <label>{!bangla ? "Rent" : "ভাড়া"}</label>
                    <input
                      className={classes.switch}
                      defaultChecked={data.hasRent}
                      onChange={(e) =>
                        setData({ ...data, hasRent: e.target.checked })
                      }
                      type="checkbox"
                    />

                    {data && data.hasRent && (
                      <>
                        <label>
                          {!bangla ? "Rent Start Date" : "ভাড়ার শুরুর তারিখ"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({ ...data, NextPayment: e.target.value })
                          }
                          type="month"
                          placeholder={!bangla ? "Enter Date" : "তারিখ লিখুন"}
                        />

                        <label>
                          {!bangla ? "Unit rate" : "ইউনিট রেট"} per sq.
                        </label>
                        <input
                          onChange={(e) =>
                            setData({ ...data, UnitPrice: e.target.value })
                          }
                          type="text"
                          placeholder={
                            !bangla ? "Enter Unit Price" : "ইউনিট রেট লিখুন"
                          }
                        />
                      </>
                    )}
                    <label>{!bangla ? "Utility" : "ইউটিলিটি"}</label>
                    <input
                      className={classes.switch}
                      defaultChecked={data.hasUtility}
                      onChange={(e) =>
                        setData({ ...data, hasUtility: e.target.checked })
                      }
                      type="checkbox"
                    />
                    {data && data.hasUtility && (
                      <>
                        <label>
                          {!bangla
                            ? "Utility Start Date"
                            : "ইউটিলিটি শুরুর তারিখ"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              NextPaymentUtility: e.target.value,
                            })
                          }
                          type="month"
                          placeholder={!bangla ? "Enter Date" : "তারিখ লিখুন"}
                        />
                        <label>
                          {!bangla ? "Utility Rate" : "ইউটিলিটি রেট"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({ ...data, MeterRate: e.target.value })
                          }
                          type="text"
                          placeholder={
                            !bangla
                              ? "Enter Meter Reading Rate"
                              : "ইউটিলিটি রেট লিখুন"
                          }
                        />
                        <label>
                          {!bangla ? "Previous Usage" : "মিটার উজেজ"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              PreviousMeterUsage: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla
                              ? "Enter Previous Usage"
                              : "মিটার উজেজ লিখুন"
                          }
                        />
                      </>
                    )}

                    <label>
                      {!bangla ? "Service Charge" : "সার্ভিস চার্জ"}
                    </label>
                    <input
                      className={classes.switch}
                      defaultChecked={data.hasSC}
                      onChange={(e) =>
                        setData({ ...data, hasSC: e.target.checked })
                      }
                      type="checkbox"
                    />
                    {data && data.hasSC && (
                      <>
                        <label>
                          {!bangla
                            ? "S/C Start Date"
                            : "সার্ভিস চার্জ শুরুর তারিখ"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              NextPaymentServiceCharge: e.target.value,
                            })
                          }
                          type="month"
                          placeholder={!bangla ? "Enter Date" : "তারিখ লিখুন"}
                        />
                        <label>
                          {!bangla ? "Common Space (if any)" : "কমন স্পেস"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({ ...data, CommonSpace: e.target.value })
                          }
                          type="text"
                          placeholder={
                            !bangla ? "Enter Common Space" : "কমন স্পেস লিখুন"
                          }
                        />
                        <label>
                          {!bangla
                            ? "Service Charge Rate"
                            : "সার্ভিস চার্জ রেট"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              ServiceChargeRate: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla
                              ? "Enter Service Charge Rate"
                              : "সার্ভিস চার্জ রেট লিখুন"
                          }
                        />
                      </>
                    )}
                  </div>
                  <br />
                </div>
              </div>

              <>
                {loading && (
                  <div className={classes2.loader}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </>
              {done && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{
                      height: "10rem",
                      width: "10rem",
                      transform: "scale(0.8)",
                    }}
                    id="completion"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96 101"
                  >
                    <style>
                      {`.st0{fill:#0088C2;fill-opacity:0.4;} .st1{fill:#0088C2;fill-opacity:0.1;} .st2{fill:#0088C2;}`}
                    </style>
                    <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
                    <g id="configurator">
                      <g id="configurator_completion">
                        <g id="stars">
                          <circle id="Oval" class="st0" cx="14" cy="18" r="1" />
                          <circle
                            id="Oval-Copy-4"
                            class="st0"
                            cx="27"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-10"
                            class="st0"
                            cx="76"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-2"
                            class="st0"
                            cx="61.5"
                            cy="12.5"
                            r="1.5"
                          />
                          <circle
                            id="Oval-Copy-9"
                            class="st0"
                            cx="94"
                            cy="53"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-6"
                            class="st0"
                            cx="88"
                            cy="14"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-7"
                            class="st0"
                            cx="59"
                            cy="1"
                            r="1"
                          />
                          <circle
                            id="Oval_1_"
                            class="st0"
                            cx="43"
                            cy="9"
                            r="2"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                          />
                          <circle
                            id="Oval-Copy-5"
                            class="st0"
                            cx="91"
                            cy="40"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-3"
                            class="st0"
                            cx="7"
                            cy="36"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-8"
                            class="st0"
                            cx="7.5"
                            cy="5.5"
                            r=".5"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="cirkel">
                      <g id="Mask">
                        <path
                          id="path-1_1_"
                          class="st1"
                          d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                        />
                      </g>
                    </g>
                    <path
                      id="check"
                      class="st2"
                      d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
                    />
                  </svg>
                  <h1
                    style={{
                      color: "#0088C2",
                      margin: "1.5rem",
                      transform: "translate(0, 0.5rem)",
                    }}
                  >
                    Added
                  </h1>
                </div>
              )}

              <label className={classes.excess}>{err}</label>
              <br></br>
              {!done && !loading && (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={classes.submit}
                >
                  <img src={SaveIcon} />
                  {!bangla ? "Assign Unit" : "এসাইন করুন"}
                </button>
              )}
              <br />
              <br />
            </form>
          </div>
        }
      />
    </>
  );
}
