import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import classes from "../styles/Login.module.css";

import { onSnapshot, collection, doc, query, getDoc, getDocs, where, updateDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';


const UserContext = createContext();


export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [rolee, setRolee] = useState("xDDDDDD");
  const [allProperties, setAllProperties] = useState();
  const [allFloors, setAllFloors] = useState();
  const [err, setErr] = useState();
  const [pending, setPending]=useState(true);
  const navigate = useNavigate();
  const [savedUser, setSavedUser] = useState();
  const [bangla, setBangla] = useState(false);
  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const [morichState, setMorichState] = useState();

  const handleBangla = () => {
    setBangla(!bangla);
  }


  const [em, setEm, removeEm] = useCookies(['email']);
  const [pa, setPa, removePa] = useCookies(['password']);

  const getAllProperties = async () => {
    try{
      const obj = {...allProperties};
      const docRef = await getDocs(collection(db,"Properties")).then(console.log("fetched Properties"));
      docRef.forEach(doc=>{
        obj[doc.id]=doc.data().PropertyName;
      })
      setAllProperties(obj);
    }
    catch(err){
      console.log(err);
    }
  }

  const getMorich = async () => {
    try{
      const unsub = onSnapshot(doc(db, "Logs", "State"), (doc) => {
        console.log("fetched Morich");
        if(doc.data().State==true){
          setMorichState("active");
        }
        else{
            setMorichState("");
        }
      });
      
    }
    catch(err){
      console.log(err);
    }
  }

  const getAllFloors = async () => {
    try{
      const obj = {...allFloors};
      const docRef = await getDocs(collection(db,"Floors")).then(console.log("fetched Floors"));
      docRef.forEach(doc=>{
        obj[doc.id]=doc.data().floorNo;
      })
      setAllFloors(obj);
    }
    catch(err){
      console.log(err);
    }
  }
  

  const signInNow = async (email, password) => {
    try{
      setErr(null);
      setPending(true);
      const q = query(collection(db, "Users"),where("Email","==",email));
      const snapshot = await getDocs(query(collection(db,"Users"),where("Email","==",email))).then(console.log("fetched Users"));
      snapshot.forEach(doce=>{
        updateDoc(doc(db,"Users",doce.id),{LastLogin: serverTimestamp()});
      })
      const unsubscribe = await onSnapshot(q, (querySnapshot) => {
        

        console.log("fetched Users")
        if(email!=em){
          unsubscribe();
        }

        if(!querySnapshot.exists){
          logout();
          setErr("Wrong email/password")
          console.log("wth");
          // unsubscribe();
          setPending(false);
        }

        querySnapshot.forEach(docc=>{
          if(docc.data().Status!="Active"){
            logout();
            setErr("Wrong email/password")
            
            unsubscribe();
            setPending(false);
          }

          if(docc.data().Email == email && docc.data().Password == password){
            console.log("yes");

            setEm('email', email, { path: '/' });
            setPa('password', password, { path: '/' });
            
            setUser({...docc.data(), id: docc.id});
            
          }
          else{
            logout();
            setErr("Wrong email/password")
            
            unsubscribe();
            setPending(false);
          }
        })
      });
      }
      catch(err){
        console.log(err);
      }
  }



  const signIn = async (email, password) => {
    


    
    signInNow(email, password);     
     
    
      

      // const q = query(collection(db, "Logs"), orderBy("CreatedAt", "desc"), limit(5));
      //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
      //       const data = [];
      //       querySnapshot.forEach((doc) => {
      //           console.log(doc.data());
      //           data.push(`${doc.data().Message}`);
      //       });
      //       setLogs(data);
      //       console.log(data);
      //     });
    
  };

  useEffect(()=>{
    if(user!=null){
      const unsub = onSnapshot(doc(db, "Roles", user.Role), (doc) => {
        console.log("fetched Roles")
        setRolee(doc.data());

    });
    }
    // console.log(user);
    setPending(false);

    if(user && user.Status!="Inactive"){
      // console.log("wth");
    }
    else{
      setRolee(null);
      setUser(null);
      if(!em && !pa){
        navigate("/login");
      }
      
    }
  },[user])
  
  useEffect(()=>{
    if(em.email && pa.password){
      signInNow(em.email,pa.password);
    }
    getAllProperties();
    getAllFloors();
    getMorich();
  },[])

  const logout = () => {
    removeEm('email' ,{ path: '/' });
    removePa('password' ,{ path: '/' });
    setUser(null);
    setErr("");
  };

  useEffect(()=>{
  },[allProperties])


  useEffect(()=>{
    console.log(err);
  },[err]);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     console.log(currentUser);
  //     setUser(currentUser);
  //     setPending(false)
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  if(pending){
    return <>
    <div className={classes.loaderDiv}>
      <span className={classes.loader}>
        <span></span>
        <span></span>
      </span>
    </div>
    </>
  }

  return (
    <UserContext.Provider value={{ createUser, user, rolee, err, allProperties, allFloors, morichState, bangla, handleBangla, logout, signIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
