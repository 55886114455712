import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";
import { useState } from "react";
import TableTop from "../TableTop";
import classes from "../../styles/Table.module.css"
import { doc, getDocs, where, query, collection, getDoc, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import classes2 from "../../styles/FloorView.module.css";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";


export default function RequestHistory(){

    const navigate = useNavigate();
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [properties, setProperties] = useState([]);

    const {rolee, user} = UserAuth();

    const [filter, setFilter] = useState({
        // Property: propertyId
    });

    useEffect(()=>{
        getRequests();
    },[id])

    const [data, setData] = useState([
        // {
        //     floorNo: 1,
        //     area: 300
        // },
        // {
        //     floorNo: 2,
        //     area: 300
        // }
    ]);

    

    const handleFilter = (data) => {
        setFilter(data);
    }


    const handleNewFloor = async() => {
        // navigate(`/properties/${propertyId}/floors/add`);
    }



    const handleRowClick=(id)=>{

        // rolee.viewUnit==true && navigate(`/properties/${propertyId}/floors/${id}/shops`);
    }

    const handleEditClick=(id)=>{
        // navigate(`/properties/${propertyId}/floors/${id}`);
    }

    const getRequests = async() => {
        setData([]);
        const obj = [];
        try{
            let docRef;
            if(id=="me"){
                docRef = await getDocs(query(collection(db,"Requests"), where("RequestedBy","==",user.Name), orderBy("Time", "desc")));
            }
            else{
                docRef = await getDocs(query(collection(db,"Requests"), orderBy("Time", "desc")));
            }
            
            docRef.forEach((doc)=>{
                const subObj = {};
                subObj["title"]=doc.data().RequestTitle;
                subObj["by"]=doc.data().RequestedBy;
                if(doc.data().ApprovedBy!=undefined){
                    subObj["ApprovedBy"]=doc.data().ApprovedBy;
                }
                else{
                    subObj["ApprovedBy"]="N/A"
                }
                if(doc.data().BusinessName!=undefined){
                    subObj["BusinessName"]=doc.data().BusinessName;
                }
                else{
                    subObj["BusinessName"]="N/A";
                }
                
                
                subObj["Status"]="Pending";
                subObj["id"]=doc.id;
                obj.push(subObj);
                
            });

            let docRef2;

            if(id=="me"){
                docRef2 = await getDocs(query(collection(db,"PastRequests"), where("RequestedBy","==",user.Name), orderBy("Time", "desc")));
            }
            else{
                docRef2 = await getDocs(query(collection(db,"PastRequests"), orderBy("Time", "desc")));
            }
            
            docRef2.forEach((doc)=>{
                const subObj = {};
                subObj["title"]=doc.data().RequestTitle;
                subObj["by"]=doc.data().RequestedBy;
                subObj["Status"]=doc.data().Status;
                subObj["id"]=doc.id;
                if(doc.data().ApprovedBy!=undefined){
                    subObj["ApprovedBy"]=doc.data().ApprovedBy;
                }
                else{
                    subObj["ApprovedBy"]="N/A"
                }
                if(doc.data().BusinessName!=undefined){
                    subObj["BusinessName"]=doc.data().BusinessName;

                }
                else{
                    subObj["BusinessName"]="N/A";
                }
                obj.push(subObj);
                
            });


            setIsLoading(false);
            setData(obj);
        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getRequests();
    },[]);

    useEffect(()=>{
        getRequests();
    },[id])

    useEffect(()=>{
        console.log("xD");
        filter.Property!=undefined && setIsLoading(true);
        // filter.Property!=undefined && propertyId!=filter.Property && navigate(`/properties/${filter.Property}/floors`);
        // console.log(propertyId);
    },[filter]);

    const [status, setStatus] = useState("All");

    
    return(
        <>
            <PageTemp title="Request History" subTitle={`View Past Requests`} page={
                isLoading ? 
                <>
                    <TableTop filter={filter} handleFilter={handleFilter} dProperty={true} />
                    <div style={{marginLeft: "2rem"}} className={classes.loader}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </>
                :
                <>
                    {/* <TableTop  filter={filter} handleFilter={handleFilter} dProperty={true} /> */}
                    <div className={classes2.top}>
                        <div className={classes2.topItem}>
                            <label>{`Status`}</label>
                            <select onChange={(e) => setStatus(e.target.value)}>
                            <option value={"All"}>All</option>;
                            <option value={"Pending"}>Pending</option>;
                            <option value={"Approved"}>Approved</option>;
                            <option value={"Declined"}>Declined</option>;
                            </select>
                        </div>
                    </div>
                    <br></br>
                    <Table>
                        <TableHeader headings={["Request Type","Business Name","Requested By","Approved By", "Status"]}/>
                        {data.length==0 && <p>No Past Requests</p>}
                        {data.map(d=>{
                            if(d.Status==status || status=="All"){
                                return(
                                    <TableRow id={d.id} onClick={()=>{
                                        if(d.Status=="Pending"){
                                            navigate(`/requests/pending/${String(d.title).replace(/\s/g, '')}/${d.id}`)
                                        }
                                        else{
                                            navigate(`/pastRequest/${d.id}`)
                                        }
                                    }
                                    } normalDetails={[d.title, d.BusinessName, d.by, d.ApprovedBy, d.Status]}/>
                                );
                            }
                            
                        })}
                        
                    </Table>
                    {/* {rolee && rolee.addFloor && <div onClick={handleNewFloor} className={classes.newBtn}>+ Add New Floor</div>} */}
                </>
            }/>
            
        </>
    );
}