import "./App.css";
import Dashboard from "./components/pages/Dashboard";
import RentEntry from "./components/pages/RentEntry";
import UtilityEntry from "./components/pages/UtilityEntry";
import ServiceChargeEntry from "./components/pages/ServiceChargeEntry";
import { Routes, Route } from "react-router-dom";
import AddProperty from "./components/pages/AddProperty";
import ViewProperties from "./components/pages/ViewProperties";
import AddShop from "./components/pages/AddShop";
import ViewShops from "./components/pages/ViewShops";
import ViewFloors from "./components/pages/ViewFloors";
import AddUser from "./components/pages/AddUser";
import ViewUsers from "./components/pages/ViewUsers";
import NewTenantRequest from "./components/pages/NewTenantRequest";
import UnitChangeRequest from "./components/pages/PositionTenantRequest";
import TenantComplain from "./components/pages/TenantComplain";
import PropertyComplain from "./components/pages/PropertyComplain";
import OtherComplain from "./components/pages/OtherComplain";
import RentEntryHistory from "./components/pages/RentEntryHistory";
import RentStatus from "./components/pages/RentStatus";
import LeaseEnding from "./components/pages/LeaseEnding";
import VacantUnits from "./components/pages/VacantUnits";
import MeterReadingHistory from "./components/pages/MeterReadingHistory";
import UtilityBillCollectionStatus from "./components/pages/UtilityBillCollectionStatus";
import ServiceChargeStatus from "./components/pages/ServiceChargeStatus";
import Login from "./components/pages/Login";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import UserDetails from "./components/pages/UserDetails";
import PropertyDetails from "./components/pages/PropertyDetails";
import FloorDetails from "./components/pages/FloorDetails";
import AddFloor from "./components/pages/AddFloor";
import ShopDetails from "./components/pages/ShopDetails";
import Collection from "./components/pages/Collection";
import Statistics from "./components/pages/Statistics";
import Settings from "./components/pages/Settings";
import AddRole from "./components/pages/AddRole";
import ViewRoles from "./components/pages/ViewRoles";
import RoleDetails from "./components/pages/RoleDetails";
import QuickShop from "./components/pages/QuickAddShop";
import RentReceiveable from "./components/pages/RentReceiveable";
import ServiceChargeReceiveable from "./components/pages/ServiceChargeReceiveable";
import TotalRentCollected from "./components/pages/TotalRentCollected";
import RentStatement from "./components/pages/RentStatement";
import ServiceChargeStatement from "./components/pages/ServiceChargeStatement";
import TotalServiceChargeCollected from "./components/pages/TotalServiceChargeCollected";
import ViewEntries from "./components/pages/ViewEntries";
import EntryDetails from "./components/pages/EntryDetails";
import FloorView from "./components/pages/FloorView";
import Morich from "./components/pages/Morich";
import PendingRequest from "./components/pages/PendingNewTenantRequest";
import PendingNewTenantRequest from "./components/pages/PendingNewTenantRequest";
import PositionTenantRequest from "./components/pages/PositionTenantRequest";
import PendingPositionTenantRequest from "./components/pages/PendingPositionTenantRequest";
import PendingOtherComplain from "./components/pages/PendingOtherComplain";
import PendingPropertyComplain from "./components/pages/PendingPropertyComplain";
import PendingTenantComplain from "./components/pages/PendingTenantComplain";
import QrScanner from "./components/pages/QrScanner";
import RequestHistory from "./components/pages/RequestHistory";
import ChangeTenantRequest from "./components/pages/ChangeTenantRequest";
import CancelTenantRequest from "./components/pages/CancelTenantRequest";
import UtiltyRecieveable from "./components/pages/UtilityRecieveable";
import TotalUtilityCollected from "./components/pages/TotalUtilityCollected";
import UtilityStatement from "./components/pages/UtilityStatement";
import PendingCancelTenantRequest from "./components/pages/PendingCancelTenantRequest";
import PendingChangeTenantRequest from "./components/pages/PendingChangeTenantRequest";
import ChangePositionTenantRequest from "./components/pages/ChangePositionRequest";
import PastTenant from "./components/pages/PastTenant";
import UpcomingCancel from "./components/pages/UpcomingCancel";
import PastRequest from "./components/pages/PastRequest";
import ChangeSecondaryTenantRequest from "./components/pages/ChangeSecondaryTenantRequest";
import PendingChangeSecondary from "./components/pages/PendingChangeSecondary";

function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/statistics"
          element={
            <ProtectedRoute>
              <Statistics/>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/qr"
          element={
            <ProtectedRoute>
              <QrScanner/>
            </ProtectedRoute>
          }
        />

<Route
          exact
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings></Settings>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/entries/rent/add"
          element={
            <ProtectedRoute>
              <Collection type="rent"/>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/entries/utility/add"
          element={
            <ProtectedRoute>
              <Collection type="utility"/>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/entries/ServiceCharge/add"
          element={
            <ProtectedRoute>
              <Collection type="serviceCharge"/>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/properties/:propertyId/:floorId/shops/:shopId/rent"
          element={
            <ProtectedRoute>
              <RentEntry />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/:floorId/shops/:shopId/utility"
          element={
            <ProtectedRoute>
              <UtilityEntry />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/:floorId/shops/:shopId/serviceCharge"
          element={
            <ProtectedRoute>
              <ServiceChargeEntry />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/properties/add"
          element={
            <ProtectedRoute>
              <AddProperty />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/"
          element={
            <ProtectedRoute>
              <ViewProperties />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId"
          element={
            <ProtectedRoute>
              <PropertyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/floorView"
          element={
            <ProtectedRoute>
              <FloorView />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors"
          element={
            <ProtectedRoute>
              <ViewFloors />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/add"
          element={
            <ProtectedRoute>
              <AddFloor />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId"
          element={
            <ProtectedRoute>
              <FloorDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops/add"
          element={
            <ProtectedRoute>
              <AddShop />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops/quickAdd"
          element={
            <ProtectedRoute>
              <QuickShop />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops/:shopId"
          element={
            <ProtectedRoute>
              <ShopDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops/:shopId/history"
          element={
            <ProtectedRoute>
              <ViewEntries />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops/:shopId/history/:month/:type"
          element={
            <ProtectedRoute>
              <EntryDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops"
          element={
            <ProtectedRoute>
              <ViewShops />
            </ProtectedRoute>
          }
        />

<Route
          exact
          path="/properties/:propertyId/floors/:floorId/shops/:shopId/pastTenants/:index"
          element={
            <ProtectedRoute>
              <PastTenant />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/users/add"
          element={
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/roles/add"
          element={
            <ProtectedRoute>
              <AddRole />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/roles/"
          element={
            <ProtectedRoute>
              <ViewRoles />
            </ProtectedRoute>
          }
        />

<Route
          exact
          path="/upcomingCancel/"
          element={
            <ProtectedRoute>
              <UpcomingCancel />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/roles/:roleId"
          element={
            <ProtectedRoute>
              <RoleDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/users/"
          element={
            <ProtectedRoute>
              <ViewUsers />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/users/:userId"
          element={<ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/MoveInRequest/:reqId"
          element={
            <ProtectedRoute>
              <PendingNewTenantRequest />
            </ProtectedRoute>
          }
        />
        
        <Route
          exact
          path="/requests/pending/MoveOutRequest/:reqId"
          element={
            <ProtectedRoute>
              <PendingCancelTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/ChangeSecondaryTenant/:reqId"
          element={
            <ProtectedRoute>
              <PendingChangeSecondary />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/SecondaryTenantRequest/"
          element={
            <ProtectedRoute>
              <ChangeSecondaryTenantRequest />
            </ProtectedRoute>
          }
        />

<Route
          exact
          path="/pastRequest/:reqId"
          element={
            <ProtectedRoute>
              <PastRequest />
            </ProtectedRoute>
          }
        />


        <Route
          exact
          path="/requests/pending/ChangePrimaryTenant/:reqId"
          element={
            <ProtectedRoute>
              <PendingChangeTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/ChangePositionTenant/:reqId"
          element={
            <ProtectedRoute>
              <PendingPositionTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/history/:id"
          element={
            <ProtectedRoute>
              <RequestHistory />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/PositionTenantRequest/:reqId"
          element={
            <ProtectedRoute>
              <PendingPositionTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/newTenant"
          element={
            <ProtectedRoute>
              <NewTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/changeTenant"
          element={
            <ProtectedRoute>
              <ChangeTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/changePositionTenant"
          element={
            <ProtectedRoute>
              <ChangePositionTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/cancelTenant"
          element={
            <ProtectedRoute>
              <CancelTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/positionTenantRequest"
          element={
            <ProtectedRoute>
              <PositionTenantRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/tenantComplain"
          element={
            <ProtectedRoute>
              <TenantComplain />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/TenantComplain/:reqId"
          element={
            <ProtectedRoute>
              <PendingTenantComplain />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/propertyComplain"
          element={
            <ProtectedRoute>
              <PropertyComplain />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/PropertyComplain/:reqId"
          element={
            <ProtectedRoute>
              <PendingPropertyComplain />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/otherComplain"
          element={
            <ProtectedRoute>
              <OtherComplain />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/requests/pending/OtherComplain/:reqId"
          element={
            <ProtectedRoute>
              <PendingOtherComplain />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/reports/rentEntryHistory"
          element={
            <ProtectedRoute>
              <RentEntryHistory />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reports/rentStatus"
          element={
            <ProtectedRoute>
              <RentStatus />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reports/leaseEnding"
          element={
            <ProtectedRoute>
              <LeaseEnding />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reports/vacantUnits"
          element={
            <ProtectedRoute>
              <VacantUnits />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reports/meterReadingHistory"
          element={
            <ProtectedRoute>
              <MeterReadingHistory />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reports/utilityBillCollectionStatus"
          element={
            <ProtectedRoute>
              <UtilityBillCollectionStatus />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reports/serviceChargeStatus"
          element={
            <ProtectedRoute>
              <ServiceChargeStatus />
            </ProtectedRoute>
          }
        />
      <Route
          exact
          path="/finances/rent"
          element={
            <ProtectedRoute>
              <RentReceiveable />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finances/utility"
          element={
            <ProtectedRoute>
              <UtiltyRecieveable />
            </ProtectedRoute>
          }
        />
        
      <Route
          exact
          path="/finances/serviceCharge"
          element={
            <ProtectedRoute>
              <ServiceChargeReceiveable />
            </ProtectedRoute>
          }
        />



      <Route
          exact
          path="/finances/totalRentCollected"
          element={
            <ProtectedRoute>
              <TotalRentCollected />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finances/totalUtilityCollected"
          element={
            <ProtectedRoute>
              <TotalUtilityCollected />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finances/totalServiceChargeCollected"
          element={
            <ProtectedRoute>
              <TotalServiceChargeCollected />
            </ProtectedRoute>
          }
        />

      <Route
          exact
          path="/finances/rentStatement"
          element={
            <ProtectedRoute>
              <RentStatement />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finances/utilityStatement"
          element={
            <ProtectedRoute>
              <UtilityStatement />
            </ProtectedRoute>
          }
        />

      <Route
          exact
          path="/finances/serviceChargeStatement"
          element={
            <ProtectedRoute>
              <ServiceChargeStatement />
            </ProtectedRoute>
          }
        />
      

      <Route
          exact
          path="/morich"
          element={
            <ProtectedRoute>
              <Morich />
            </ProtectedRoute>
          }
        />
      
      </Routes>

    </AuthContextProvider>
  );
}

export default App;
