import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableRow from "../TableRow";
import classes from "../../styles/TableTop.module.css";
import classes2 from "../../styles/Table.module.css";
import { useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function ViewRoles() {
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const { rolee } = UserAuth();
  useEffect(() => {
    if (rolee && !rolee.manageRoles) {
      navigate("/");
    }
  }, [rolee]);

  const [data, setData] = useState([]);

  const [filter, setFilter] = useState("");

  const handleRowClick = (id) => {
    navigate(`/users/${id}`);
  };

  const getRoles = async () => {
    try {
      const arr = [];
      const querySnapshot = await getDocs(collection(db, "Roles"));
      querySnapshot.forEach((doc) => {
        arr.push(doc.id);
      });
      setData(arr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <PageTemp
        title="View Roles"
        subTitle="View all Roles here"
        page={
          isLoading ? (
            <>
              <div className={classes2.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              <form className={classes.tableTop}>
                <div className={classes.tableTopItem}>
                  <br></br>
                  {data.map((role) => {
                    return (
                      <>
                        <button
                          onClick={() => navigate(`/roles/${role}`)}
                          className={`${classes.Role} ${classes.noPrint}`}
                        >
                          {role}
                        </button>
                      </>
                    );
                  })}

                  <button
                    onClick={() => navigate("/roles/add")}
                    className={`${classes.AddRoles} ${classes.noPrint}`}
                  >
                    Add Role
                  </button>
                </div>
              </form>
            </>
          )
        }
      />
    </>
  );
}
