import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import classes from "../styles/TableTop.module.css";
import SubDropDownV2 from "./SubDropDownV2";

export default function DropDownV2({ handleData, filter }) {
  const [active, setActive] = useState(false);
  const { allProperties, bangla } = UserAuth();

  // const [propertiesAndFloors, setPropertiesAndFloors] = useState([
  //   {
  //     Property: "Building1",
  //     Floors: ["Floor1", "Floor2", "Floor3", "Floor4"],
  //   },
  //   {
  //     Property: "Building2",
  //     Floors: ["Floor1", "Floor5", "Floor3", "Floor4"],
  //   },
  // ]);

  const getFloors = async () => {
    try {
      const obj = {};
      let subObj = {};
      const docRef = await getDocs(
        query(
          collection(db, "Floors"),
          orderBy("PropertyId"),
          orderBy("floorNo")
        )
      );
      docRef.forEach((doc) => {
        // if(Object.keys(obj).includes(doc.data().PropertyId!=true)){
        //   arr=[];
        // }

        if (Object.keys(obj).includes(doc.data().PropertyId) == false) {
          subObj = {};
        }
        subObj[doc.data().floorNo] = doc.id;
        //  subObj["PropertyId"]=doc.data().PropertyId;
        obj[doc.data().PropertyId] = subObj;
        //  console.log(obj);
        setPropertiesAndFloors(obj);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [propertiesAndFloors, setPropertiesAndFloors] = useState({
    // "Building1": {
    //   "Floor1":"asdjjdsadsadsa",
    //   "Floor2":"asdjjdsadsa",
    //   "Floor3":"ajdsadsadsa",
    //   "Floor4":"asdjjdsadsad"
    // },
    // "Building2": {Floors: ["Floor1", "Floor5", "Floor3", "Floor4"]}
  });

  useEffect(() => {
    getFloors();
  }, []);

  return (
    <>
      <div className={classes.tableTopItem}>
        <label>{!bangla ? "Property Access" : "প্রপার্টি অ্যাক্সেস"}</label>
        <div
          className={`${classes.dropdownCheckList} ${
            active && classes.visible
          }`}
          tabIndex="100"
        >
          <span
            onClick={() => {
              setActive(!active);
            }}
            className={classes.anchor}
          >
            {!bangla ? "Select Property" : "প্রপার্টি সিলেক্ট করুন"}
          </span>
          <ul style={{zIndex: "2"}} className={classes.items}>
            {Object.keys(propertiesAndFloors).map((data) => {
              return (
                <SubDropDownV2
                  filter={filter}
                  handleData={handleData}
                  id={allProperties[data]}
                  title={data}
                  floors={propertiesAndFloors[data]}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
