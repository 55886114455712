import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";
import submitIcon from "../../assets/icons/Save.svg";
import FormInput from "../FormInput";
import { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useEffect } from "react";
import DropDownV2 from "../DropDownV2";

export default function AddUser() {
  const [data, setData] = useState({
    PropertyAccess: {},
  });
  const [creating, setCreating] = useState(true);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState({});
  const [propAccObj, setPropAccObj] = useState({});
  const { createUser, user, bangla, rolee } = UserAuth();
  const {allProperties, allFloors} = UserAuth();

  useEffect(() => {
    if (rolee && !rolee.addUser) {
      navigate("/");
    }
  }, [rolee]);

  const navigate = useNavigate();

  const getAllRoles = async () => {
    try {
      const obj = {};
      const docref = await getDocs(collection(db, "Roles"));
      docref.forEach((doc) => {
        console.log(doc.id);

        obj[doc.id] = doc.id;
      });
      setRoles(obj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllRoles();
    getAllUsers();
  }, []);

  const handleSubmit = async (event) => {
    console.log("yes");
    if (err == "") {
      event.preventDefault();
      console.log(data);

      try {
        setLoading(true);
        const docRef = await addDoc(collection(db, "Users"), {
          Name: data.Name,
          Password: data.Password,
          Designation: data.Designation,
          Mobile: data.Mobile,
          Email: data.Email,
          Role: data.Role,
          PropertyAccess: data.PropertyAccess,
          Status: "Active",
        });

        const log = await addDoc(collection(db, "Logs"), {
          CreatedAt: serverTimestamp(),
          Message: `${user.Name} has added a new User: ${data.Name}`,
        });

        setLoading(false);
        setDone(true);
        setTimeout(() => {
          navigate(`/`);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    } else {
      setButtonStyle(!buttonStyle);
    }
  };

  const handleData = (d) => {
    console.log(data);
    setData(d);
    if (
      data.Email != undefined &&
      allUsers &&
      allUsers.includes(data.Email.toLowerCase())
    ) {
      setErr("Email already Exists");
    } else if (/^\d+$/.test(data.Mobile) == false) {
      setErr(
        !bangla
          ? "Mobile number must be in numbers only."
          : "মোবাইল নম্বর শুধুমাত্র নম্বরে  হতে হবে"
      );
    } else if (data.Mobile.length != 11) {
      setErr("Mobile number must be 11 digit");
    } else if (data.Email == "" || data.Email == undefined) {
      setErr("Email cannot be Empty");
    } else if (data.Name == "" || data.Name == undefined) {
      setErr("Name cannot be Empty");
    } else if (data.Password == "" || data.Password == undefined) {
      setErr("Password cannot be Empty");
    } else if (allUsersMobile && allUsersMobile.includes(data.Mobile)) {
      setErr("User with same Phone already Exists");
    } else {
      setErr("");
    }
  };

  const handlePropertyAccess = (title, arr) => {
    const newPropAccObj = propAccObj;
    newPropAccObj[title] = arr;
    setPropAccObj({ ...newPropAccObj });
  };

  const [allUsers, setAllUsers] = useState();
  const [allUsersMobile, setAllUsersMobile] = useState();
  const [err, setErr] = useState();

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    setData((prevData) => {
      return { ...prevData, PropertyAccess: propAccObj };
    });
    console.log(data);
  }, [propAccObj]);

  const getAllUsers = async () => {
    const arr = [];
    const arr2 = [];

    try {
      const docRef = await getDocs(collection(db, "Users"));
      docRef.forEach((doc) => {
        arr.push(doc.data().Email.toLowerCase());
        arr2.push(doc.data().Mobile);
      });
      setAllUsers(arr);
      setAllUsersMobile(arr2);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(err);
  }, [err]);

  useEffect(() => {
    console.log(allUsers);
  }, [allUsers]);

  const [buttonStyle, setButtonStyle] = useState();

  return (
    <>
      <PageTemp
        title="Add User"
        subTitle="Please enter the following details"
        page={
          <div>
            <form action="">
              <FormInput
                data={data}
                handleData={handleData}
                title={!bangla ? "Name" : "নাম"}
                placeholder={!bangla ? "Enter Name" : "নাম লিখুন"}
              />
              <FormInput
                data={data}
                handleData={handleData}
                title={!bangla ? "Designation" : "উপাধি"}
                placeholder={!bangla ? "Enter Designation" : "উপাধি লিখুন"}
              />
              <FormInput
                data={data}
                handleData={handleData}
                title={!bangla ? "Mobile" : "মোবাইল নম্বর"}
                placeholder={!bangla ? "Enter Mobile No" : "নম্বর লিখুন"}
              />
              <FormInput
                data={data}
                handleData={handleData}
                title={!bangla ? "Email" : "ই-মেইল"}
                placeholder={!bangla ? "Enter Email" : "ই-মেইল লিখুন"}
              />
              <FormInput
                password={true}
                data={data}
                handleData={handleData}
                title={!bangla ? "Password" : "পাসওয়ার্ড"}
                placeholder={!bangla ? "Enter password" : "পাসওয়ার্ড লিখুন"}
              />
              <DropDownV2
                handleData={handlePropertyAccess}
                filter={data}
              ></DropDownV2>

{<br></br>}
              { <label for="">{data.PropertyAccess && Object.keys(data.PropertyAccess).map(prop=>{
                      return <p>{data.PropertyAccess[prop].length>0 && `${allProperties[prop]} :`} {data.PropertyAccess[prop].map(floor=>{
                        return <>{data.PropertyAccess[prop].length>0 && `${allFloors[floor]} ,`}</>
                      })} </p>
                    })} </label>}
                    {<br></br>}
              <label>{!bangla ? "Role" : "ভূমিকা"}</label>
              {roles && (
                <select
                  onChange={(e) => setData({ ...data, Role: e.target.value })}
                >
                  <option>
                    {!bangla ? "Select Role" : "ভূমিকা নির্বাচন করুন"}
                  </option>
                  {Object.keys(roles).map((roleeee) => (
                    <>
                      <option value={roleeee}>{roleeee}</option>
                    </>
                  ))}
                  {/* <option value={"Admin"}>Admin</option>
                <option value={"Manager"}>Manager</option>
                <option value={"Accountant"}>Accountant</option> */}
                </select>
              )}

              <br />
              <>
                {loading && (
                  <div className={classes2.loader}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </>
              {done && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ transform: "scale(0.8)" }}
                    id="completion"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96 101"
                  >
                    <style>
                      {`.st0{fill:#0088C2;fill-opacity:0.4;} .st1{fill:#0088C2;fill-opacity:0.1;} .st2{fill:#0088C2;}`}
                    </style>
                    <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
                    <g id="configurator">
                      <g id="configurator_completion">
                        <g id="stars">
                          <circle id="Oval" class="st0" cx="14" cy="18" r="1" />
                          <circle
                            id="Oval-Copy-4"
                            class="st0"
                            cx="27"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-10"
                            class="st0"
                            cx="76"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-2"
                            class="st0"
                            cx="61.5"
                            cy="12.5"
                            r="1.5"
                          />
                          <circle
                            id="Oval-Copy-9"
                            class="st0"
                            cx="94"
                            cy="53"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-6"
                            class="st0"
                            cx="88"
                            cy="14"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-7"
                            class="st0"
                            cx="59"
                            cy="1"
                            r="1"
                          />
                          <circle
                            id="Oval_1_"
                            class="st0"
                            cx="43"
                            cy="9"
                            r="2"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                          />
                          <circle
                            id="Oval-Copy-5"
                            class="st0"
                            cx="91"
                            cy="40"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-3"
                            class="st0"
                            cx="7"
                            cy="36"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-8"
                            class="st0"
                            cx="7.5"
                            cy="5.5"
                            r=".5"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="cirkel">
                      <g id="Mask">
                        <path
                          id="path-1_1_"
                          class="st1"
                          d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                        />
                      </g>
                    </g>
                    <path
                      id="check"
                      class="st2"
                      d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
                    />
                  </svg>
                  <h1
                    style={{
                      color: "#0088C2",
                      margin: "1.5rem",
                      transform: "translate(0, 0.5rem)",
                    }}
                  >
                    Added
                  </h1>
                </div>
              )}
              <label className={classes.excess}>{err}</label>
              <br></br>
              <div style={{ position: "relative" }}>
                {!done && !loading && (
                  <button
                    type="button"
                    onClick={handleSubmit}
                    onMouseEnter={() => {
                      err != "" && setButtonStyle(!buttonStyle);
                    }}
                    className={`${classes.qsubmit} ${
                      buttonStyle ? classes.fun : ""
                    }`}
                  >
                    <img src={submitIcon} />
                    {!bangla ? "Add User" : "ইউসার অ্যাড করুন"}
                  </button>
                )}
              </div>
              <br></br>
              <br />
              <br />
            </form>
          </div>
        }
      />
    </>
  );
}
