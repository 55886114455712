import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import Files from "../../assets/icons/Files.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  where,
  query,
  orderBy,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import {
  ref,
  getStorage,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { UserAuth } from "../../context/AuthContext";

export default function AddShop() {
  const { user, bangla, rolee } = UserAuth();

  useEffect(() => {
    if (rolee && !rolee.addUnit) {
      navigate("/");
    }
  }, [rolee]);

  //use this state to update database
  const [data, setData] = useState({ TenantType: "Vacant", files: {} });
  const [vacant, setVacant] = useState(true);
  // const [properties, setProperties] = useState({})
  const navigate = useNavigate();
  // const [floors, setFloors]=useState();
  const [secondary, setSecondary] = useState(true);
  const [properties, setProperties] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [floors, setFloors] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [file, setFile] = useState([]);
  const [pic, setPic] = useState();

  const { propertyId, floorId } = useParams();

  const handlePropertySelect = (data) => {
    properties &&
      properties.map((property) => {
        if (property.name == data) {
          setCurrentProperty({ name: property.name, id: property.id });
        }
      });
    navigate(`/properties/${currentProperty.id}/floors/undefined/shops/add`);
  };

  const handleFloorSelect = (data) => {
    floors &&
      floors.map((floor) => {
        if (floor.floorNo == data) {
          setCurrentFloor({ floorNo: floor.floorNo, id: floor.id });
        }
      });
  };

  useEffect(() => {
    if (data.TenantType == "Vacant") {
      setVacant(true);
    } else {
      setVacant(false);
    }
  }, [data]);

  //submit button here
  const handleSubmit = async () => {
    console.log(data);
    const today = new Date().getMonth() + 1;
    // let nextPay;
    // if(today == 12){
    //     nextPay = `${new Date().getFullYear()+1}-01`
    // }
    // else{
    //     nextPay = `${new Date().getFullYear()}-${new Date().getMonth() + 2}`
    // }

    try {
      const preDocRef = collection(db, "Shops");
      const preRef = doc(preDocRef);
      const id = preRef.id;
      file.map((f) => {
        uploadFile(f, id);
      });
      uploadDp(id);
      const docRef = await setDoc(preRef, {
        ...data,
        Status: "Not Paid",
        LastPaid: "Never",
        LastPaidUtility: "Never",
        LastPaidServiceCharge: "Never",
        // NextPayment: nextPay,
        Entries: {
          Rent: {},
          Utility: {},
          ServiceCharge: {},
        },
      });
      const log = await addDoc(collection(db, "Logs"), {
        CreatedAt: serverTimestamp(),
        Message: `${user.Name} has added a new Unit: ${data.BusinessName}`,
      });
      navigate(`/properties/${propertyId}/floors/${floorId}/shops`);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProperties = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Properties"));
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push({
          id: doc.id,
          name: doc.data().PropertyName,
        });
      });
      // console.log(arr);
      setProperties(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFloors = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, `Floors`),
          where("PropertyId", "==", currentProperty.id),
          orderBy("floorNo")
        )
      );
      const arr = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        arr.push({
          floorNo: doc.data().floorNo,
          area: doc.data().area,
          id: doc.id,
        });
      });
      setFloors(arr);
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchFloors = async()=>{
  //     try{
  //         const querySnapshot= await getDocs(query(collection(db,"Floors"),
  //         where("PropertyId", "==", properties[data.Property])));

  //         const obj={}
  //         querySnapshot.forEach((doc)=>{
  //             obj[doc.data().FloorNo]=doc.id;
  //         })
  //         setFloors(obj);
  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  // const fetchProperties=async()=>{
  //     try{
  //         const querySnapshot = await getDocs(collection(db, "Properties"));
  //         const obj={};
  //         querySnapshot.forEach((doc) => {
  //             obj[doc.data().PropertyName]=doc.id;

  //         });
  //         setProperties(obj);
  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  // useEffect(()=>{
  //     console.log(img);
  // },[img])

  // useEffect(()=>{
  //     console.log(floors);
  // },[floors]);

  useEffect(() => {
    properties &&
      properties.map((property) => {
        if (property.id == propertyId) {
          setCurrentProperty({ name: property.name, id: property.id });
        }
      });
  }, [properties]);

  useEffect(() => {
    if (floorId == "undefined") {
      setCurrentFloor({ floorNo: floors[0].floorNo, id: floors[0].id });
    }
    floors &&
      floors.map((floor) => {
        if (floor.id == floorId) {
          setCurrentFloor({ floorNo: floor.floorNo, id: floor.id });
        }
      });
  }, [floors]);

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    currentProperty != undefined &&
      navigate(`/properties/${currentProperty.id}/floors/${floorId}/shops/add`);
    currentProperty != undefined &&
      setData({
        ...data,
        PropertyName: currentProperty.name,
        PropertyId: currentProperty.id,
      });
    fetchFloors();
  }, [currentProperty]);

  useEffect(() => {
    currentFloor &&
      navigate(`/properties/${propertyId}/floors/${currentFloor.id}/shops/add`);
    currentFloor &&
      setData({
        ...data,
        FloorNo: currentFloor.floorNo,
        floorId: currentFloor.id,
      });
  }, [currentFloor]);

  //filesystem
  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  const uploadFile = (f, id) => {
    if (!file) {
      navigate("/properties");
      return;
    }
    const storageRef = ref(storage, `/shops/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleFile(f.name, url);
          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Shops", id), {
      files: { ...data.files },
    });
    navigate(`/properties/${id}`);
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  //dp
  const uploadDp = (id) => {
    if (!pic) {
      navigate("/properties");
      return;
    }

    const storageRef = ref(storage, `/shops/${id}/${pic.name}`);

    const uploadTask = uploadBytesResumable(storageRef, pic);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleDp(url);
          updateDp(id);
        });
      }
    );
  };

  const updateDp = async (id) => {
    await updateDoc(doc(db, "Shops", id), {
      dp: data.dp,
    });
    navigate(`/properties/${id}`);
  };

  const handleDp = (url) => {
    const obj = data;
    console.log(url);
    obj["dp"] = url;
    setData(obj);
  };

  return (
    <>
      <PageTemp
        title="Add Unit"
        subTitle="Please enter the following details"
        page={
          <div>
            <form action="">
              <div className={classes.formGroup}></div>
              <div className={classes.formDetail}>
                {/* <div className={classes.detail}>
                                <p className={classes.detailHeader}><img src={shopDetails} />Unit Details</p>
                                <div className={classes.subDetail}>
                                    
                                    
                                </div>
                                <br />
                            </div> */}

                <div className={classes.detail}>
                  <p className={classes.detailHeader}>
                    <img src={Location} />
                    {!bangla ? "Location" : "ঠিকানা"}
                  </p>
                  <div className={classes.subDetail}>
                    <label>{!bangla ? "Property" : "প্রপার্টি"}</label>
                    {currentProperty && (
                      <select
                        onChange={(e) => handlePropertySelect(e.target.value)}
                        defaultValue={currentProperty.name}
                      >
                        {properties &&
                          properties.map((property) => {
                            return (
                              <option val={property.name}>
                                {property.name}
                              </option>
                            );
                          })}
                      </select>
                    )}
                    {currentFloor && floors && (
                      <>
                        <label>{!bangla ? "Floor" : "ফ্লোর"}</label>
                        <select
                          onChange={(e) => handleFloorSelect(e.target.value)}
                          defaultValue={currentFloor.floorNo}
                        >
                          {floors.map((key) => {
                            return (
                              <option
                                val={key.floorNo}
                              >{`${key.floorNo}`}</option>
                            );
                          })}
                        </select>
                      </>
                    )}
                    <label>{!bangla ? "Block" : "ব্লক"}</label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, Block: e.target.value })
                      }
                      type="text"
                      placeholder={!bangla ? "Enter Block" : "ব্লক লিখুন"}
                    />
                    <label>{!bangla ? "Unit No." : "ইউনিট নং."}</label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, ShopNo: e.target.value })
                      }
                      type="text"
                      placeholder={
                        !bangla ? "Enter Unit No." : "ইউনিট নং লিখুন"
                      }
                    />
                    <label>{!bangla ? "Tenant Type" : "ভাড়াটে প্রকার"}</label>
                    <select
                      onChange={(e) =>
                        setData({ ...data, TenantType: e.target.value })
                      }
                      defaultValue={"Vacant"}
                    >
                      <option selected val="Vacant">
                        Vacant
                      </option>
                      <option val="Primary">Primary</option>
                      <option val="Position">Position</option>
                    </select>
                  </div>
                  <br />
                </div>
                <div className={classes.detail}>
                  <p className={classes.detailHeader}>
                    <img src={EntryInfo} />
                    {!bangla ? "Entry Info" : "এন্ট্রি তথ্য"}
                  </p>
                  <div className={classes.subDetail}>
                    <label>Payment Start Date</label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          NextPayment: e.target.value,
                          NextPaymentUtility: e.target.value,
                          NextPaymentServiceCharge: e.target.value,
                        })
                      }
                      type="month"
                      placeholder="Enter Date"
                    />
                    <label>{!bangla ? "Unit Space" : "ইউনিট সাইজ"}</label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, UnitSpace: e.target.value })
                      }
                      type="text"
                      placeholder={
                        !bangla ? "Enter Unit Space" : "ইউনিট সাইজ লিখুন"
                      }
                    />
                    <label>Unit Price</label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, UnitPrice: e.target.value })
                      }
                      type="text"
                      placeholder="Enter Unit Price"
                    />
                    <label>Meter Reading Rate</label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, MeterRate: e.target.value })
                      }
                      type="text"
                      placeholder="Enter Meter Reading Rate"
                    />
                    <label>{!bangla ? "Previous Usage" : "মিটার উজেজ"}</label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          PreviousMeterUsage: e.target.value,
                        })
                      }
                      type="text"
                      placeholder={
                        !bangla ? "Enter Previous Usage" : "মিটার উজেজ লিখুন"
                      }
                    />
                    <label>
                      {!bangla ? "Common Space (if any)" : "কমন স্পেস"}
                    </label>
                    <input
                      onChange={(e) =>
                        setData({ ...data, CommonSpace: e.target.value })
                      }
                      type="text"
                      placeholder={
                        !bangla ? "Enter Common Space" : "কমন স্পেস লিখুন"
                      }
                    />
                    <label>
                      {!bangla ? "Service Charge Rate" : "সার্ভিস চার্জ রেট"}
                    </label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          ServiceChargeRate: e.target.value,
                        })
                      }
                      type="text"
                      placeholder={
                        !bangla
                          ? "Enter Service Charge Rate"
                          : "সার্ভিস চার্জ রেট লিখুন"
                      }
                    />
                  </div>
                  <br />
                </div>
              </div>
              <br />
              <br />
              {!vacant && (
                <div className={classes.formDetail}>
                  <div className={classes.detail}>
                    <p className={classes.detailHeader}>
                      <img src={TenantDetails} />
                      Tenant Details
                    </p>
                    <div className={classes.subDetail}>
                      <label>{!bangla ? "Business Name" : "ব্যবসার নাম"}</label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            BusinessName: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter Business Name" : "ব্যবসার নাম লিখুন"
                        }
                      />
                      <label>{!bangla ? "Tenant's Name" : "ভাড়াটে নাম"}</label>
                      <input
                        onChange={(e) =>
                          setData({ ...data, TenantName: e.target.value })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter Tenant's Name" : "ভাড়াটে নাম লিখুন"
                        }
                      />

                      {/* <label>Name</label>
                                    <input onChange={e=>setData({...data, TenantName: e.target.value})} type="text" placeholder="Enter Tenant's Name" /> */}
                      <label>{!bangla ? "Father's Name" : "পিতার নাম"}</label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            TenantFatherName: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter Father's Name" : "পিতার নাম লিখুন"
                        }
                      />
                      <label>{!bangla ? "Home Address" : "বাসার ঠিকানা"}</label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            TenantHomeAddress: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter Home Address" : "বাসার ঠিকানা লিখুন"
                        }
                      />
                      <label>
                        {!bangla ? "Mobile Number" : "মোবাইল নম্বর"}
                      </label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            TenantMobileNumber: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter Mobile No" : "মোবাইল নম্বর লিখুন"
                        }
                      />
                      <label>{!bangla ? "National ID" : "এনাইডি নম্বর"}</label>
                      <input
                        onChange={(e) =>
                          setData({ ...data, TenantNID: e.target.value })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter NID" : "এনাইডি নম্বর লিখুন"
                        }
                      />
                      <label>
                        {!bangla ? "Passport Number" : "পাসপোর্ট নম্বর"}
                      </label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            TenantPassport: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla ? "Enter Passport No" : "পাসপোর্ট নম্বর লিখুন"
                        }
                      />
                      <label>
                        {!bangla ? "Manager Name" : "ম্যানেজারের নাম"}
                      </label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            ManagerName: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla
                            ? "Enter Manager Name"
                            : "ম্যানেজারের নাম লিখুন"
                        }
                      />
                      <label>
                        {!bangla
                          ? "Manager Mobile Number"
                          : "ম্যানেজারের মোবাইল নম্বর"}
                      </label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            ManagerMobile: e.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          !bangla
                            ? "Enter Manager's No."
                            : "ম্যানেজারের মোবাইল নম্বর লিখুন"
                        }
                      />
                      {data && data.TenantType != "Position" && (
                        <>
                          <label>
                            {!bangla ? "Lease Starting" : "লিজ সুরু তারিক"}
                          </label>
                          <input
                            onChange={(e) =>
                              setData({
                                ...data,
                                LeaseStarting: e.target.value,
                              })
                            }
                            type="month"
                          />
                          <label>
                            {!bangla ? "Lease Ending" : "লিজ শেষ তারিক"}
                          </label>
                          <input
                            onChange={(e) =>
                              setData({
                                ...data,
                                LeaseEnding: e.target.value,
                              })
                            }
                            type="month"
                          />
                        </>
                      )}
                    </div>
                    <br />
                    <br />
                  </div>
                  <div className={classes.detail}>
                    <p className={classes.detailHeader}>
                      <img src={SecondaryTenant} />
                      Secondary Tenant Details
                    </p>
                    <input
                      defaultChecked
                      className={classes.switch}
                      onChange={() => setSecondary(!secondary)}
                      type="checkbox"
                    />
                    {secondary && (
                      <div className={classes.subDetail}>
                        <label>
                          {!bangla
                            ? "Business/Tenant's Name"
                            : "ব্যবসার/ভাড়াটে নাম"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryName: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla
                              ? "Enter Business/Tenant's Name"
                              : "ব্যবসার/ভাড়াটে নাম লিখুন"
                          }
                        />
                        <label>{!bangla ? "Father's Name" : "পিতার নাম"}</label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryFatherName: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla ? "Enter Father's Name" : "পিতার নাম লিখুন"
                          }
                        />
                        <label>
                          {!bangla ? "Home Address" : "বাসার ঠিকানা"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryHomeAddress: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla
                              ? "Enter Home Address"
                              : "বাসার ঠিকানা লিখুন"
                          }
                        />
                        <label>
                          {!bangla ? "Mobile Number" : "মোবাইল নম্বর"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryMobile: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla ? "Enter Mobile No" : "মোবাইল নম্বর লিখুন"
                          }
                        />
                        <label>
                          {!bangla ? "National ID" : "এনাইডি নম্বর"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryNID: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla ? "Enter NID" : "এনাইডি নম্বর লিখুন"
                          }
                        />
                        <label>
                          {!bangla ? "Passport Number" : "পাসপোর্ট নম্বর"}
                        </label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryPassportNumber: e.target.value,
                            })
                          }
                          type="text"
                          placeholder={
                            !bangla
                              ? "Enter Passport No"
                              : "পাসপোর্ট নম্বর লিখুন"
                          }
                        />
                        <label>Secondary Tenant Rent</label>
                        <input
                          onChange={(e) =>
                            setData({
                              ...data,
                              SecondaryTenantRent: e.target.value,
                            })
                          }
                          type="text"
                          placeholder="Enter Rent"
                        />
                      </div>
                    )}
                    <br />
                    <br />
                  </div>
                </div>
              )}
              <div className={classes.formDetail}>
                <div className={classes.detail}>
                  <p className={classes.detailHeader}>
                    <img src={EmergencyContact} />
                    {!bangla ? `Emergency Contact (Tenant)` : `জরুরী যোগাযোগ`}
                  </p>
                  <div className={classes.subDetail}>
                    <label>{!bangla ? "Name" : "নাম"}</label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          EmergencyName: e.target.value,
                        })
                      }
                      type="text"
                      placeholder={!bangla ? "Enter Name" : "নাম লিখুন"}
                    />
                    <label>{!bangla ? "Relation" : "সম্পর্ক"}</label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          EmergencyRelation: e.target.value,
                        })
                      }
                      type="text"
                      placeholder={!bangla ? "Enter Relation" : "সম্পর্ক লিখুন"}
                    />
                    <label>{!bangla ? "Address" : "ঠিকানা"}</label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          EmergencyAddress: e.target.value,
                        })
                      }
                      type="text"
                      placeholder={!bangla ? "Enter Address" : "ঠিকানা লিখুন"}
                    />
                    <label>{!bangla ? "Mobile Number" : "মোবাইল নম্বর"}</label>
                    <input
                      onChange={(e) =>
                        setData({
                          ...data,
                          EmergencyMobile: e.target.value,
                        })
                      }
                      type="text"
                      placeholder={
                        !bangla ? "Enter Mobile No" : "মোবাইল নম্বর লিখুন"
                      }
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className={classes.formDetail}>
                <div className={classes.detail}>
                  <p className={`${classes.detailHeader} ${classes.black}`}>
                    <img src={Files} />
                    {!bangla ? "Files" : "ফাইল"}
                  </p>
                  <div className={classes.subDetail}>
                    <label
                      className={classes.upFileButton}
                      htmlFor="fileUpload"
                    >
                      <img src={UploadIcon} />
                      {!bangla ? "Add Image" : "ছবি যোগ করুন"}
                      <input
                        onChange={(e) => setPic(e.target.files[0])}
                        id="fileUpload"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </label>
                    {
                      file.map((f) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "1rem",
                            }}
                          >
                            {f.name}
                          </div>
                        );
                      })
                      //   data.files && Object.keys(data.files).map((file)=>{
                      //     return <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}><a style={{appearance: "none"}} href={data.files[file]} target="_blank" rel="noopener"> <button type="button" className="btn_downloadFile" htmlFor="">{`Download ${file}`}</button></a>
                      //     {/* <button onClick={()=>deletefile(data.files[file])}>Delete</button> */}
                      //     </div>

                      //   })
                    }
                    <label
                      className={classes.upFileButton}
                      htmlFor="fileUpload2"
                    >
                      <img src={UploadIcon} />
                      {!bangla ? "Add File" : "ফাইল যুক্ত করুন"}
                      <input
                        multiple
                        onChange={fileHandler}
                        id="fileUpload2"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                  <br />
                </div>
              </div>

              <br />
              <br />
              <button
                type="button"
                onClick={handleSubmit}
                className={classes.submit}
              >
                <img src={SaveIcon} />
                Add Unit
              </button>
              <br />
              <br />
            </form>
          </div>
        }
      />
    </>
  );
}
