import { useEffect, useState } from 'react';
import classes from '../styles/TableTop.module.css';


export default function DropDown({defaultProp, filter, handleFilter, title, items, properties}){

    const [defaultVal, setDefaultVal] = useState();

    useEffect(()=>{
        console.log(defaultProp);
        setDefaultVal(defaultProp);
    },[])

    const handleChange = (change) => {
        const updatedFilter = filter;

        updatedFilter[title]=properties[change];
        handleFilter(
            {
                ...updatedFilter
            }
        )
    }
    

    return(
        <>
            {items.length!=0 && defaultVal!=null && 
            <div className={classes.tableTopItem}>
                <label>{title}</label>
                <select id={title} defaultValue={defaultVal} onChange={(e)=>handleChange(e.target.value)}>
                    
                    {items.map(item=>{
                        return(<option value={item} key={item}>{item.length>11 ? `${item.substring(0,11)}...` : item}</option>);
                    })}

                    {/* {allP && <option value={`allProperties`}>{`All Properties`}</option>}
                    {allF && <option value={`allFloors`}>{`All Floors`}</option>} */}
                    
                    {/* {items.length!=0 && <option value={`All ${title}`}>{`All ${title}`}</option>} */}
                </select>
            </div>}
        </>
    );
}