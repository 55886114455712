import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { doc, getDoc, deleteDoc, updateDoc, getDocs, collection, where, query } from "firebase/firestore";
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";
import uploadIcon from "../../assets/icons/Upload.svg"
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable, getStorage, deleteObject } from "firebase/storage";


export default function PropertyDetails() {
    const { user, allProperties } = UserAuth();
    const navigate = useNavigate();
    const {propertyId} = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [isEditing, setIsEditing] = useState(false);
    const [prog, setProg] = useState(0);
    const storage = getStorage();
    
    const deleteShop = async (id) => {
      await deleteDoc(doc(db, "Shops", id));
    }

    const deleteFloor = async (id) => {
      await deleteDoc(doc(db, "Floors", id));
    }

    const handleDelete = async() => {

        const shopRef = await getDocs(query(collection(db, "Shops"), where("PropertyId", "==", propertyId)));
        shopRef.forEach(doc=>{
          deleteShop(doc.id);
        })

        const floorRef = await getDocs(query(collection(db, "Floors"), where("PropertyId", "==", propertyId)));
        floorRef.forEach(doc=>{
          deleteFloor(doc.id);
        })

        data.files && Object.keys(data.files).map((file)=>{
          deletefile(file);
        });

        await deleteDoc(doc(db, "Properties", data.id)).then(() => {
            console.log("Entire Document has been deleted successfully.")
            navigate('/properties');
        })
        .catch(error => {
            console.log(error);
        });

        
    }

    const [err, setErr] = useState("");

    const handleEdit = async() =>{
      // if(allProps.includes(data.PropertyName) && data.PropertyName!=allProperties[propertyId]){
      //   setErr("Property Name already exists");
      // }
      if(data.PropertyName==""){
          setErr("Property Name cannot be empty.");
      }
      else if(/^\d+$/.test(data.PropertySize)==false && data.PropertySize!=""){
          setErr("PropertySize must be in numbers only");
      }
      else{
        setErr("");
        setIsEditing(!isEditing);
        await updateDoc(doc(db, "Properties", data.id),{
          ...data
        })
  
        .catch(error => {
            console.log(error);
        });
      }
      
    }

    const getPropertyDetails = async(event)=>{
        try{
            const querySnapshot = await getDoc(doc(db, "Properties", propertyId));
            setData({id: querySnapshot.id, PropertyName: querySnapshot.data().PropertyName, PropertyType: querySnapshot.data().PropertyType, PropertySize: querySnapshot.data().PropertySize, PropertyLocation: querySnapshot.data().PropertyLocation, files: querySnapshot.data().files})
            setIsLoading(false);
        }
        catch(err){
            console.log(err);
            setIsLoading(false);
        }
    }

    const handleOnChange=(key, x)=>{

      const obj={...data}
      obj[key]=x.target.value;
      setData({...obj});
      console.log(data);
    }


useEffect(()=>{
    getPropertyDetails();
},[])

const updateDeleteFiles = async(url) => {
  const obj = data.files;
  delete obj[url];
  try{
    await updateDoc(doc(db,"Properties",propertyId),{
      files: obj
    })
    getPropertyDetails();
  }
  catch(err){
    console.log(err);
  }
}

const deletefile = async (url) => {
  const desertRef = ref(storage, `properties/${propertyId}/${url}`);
  
  deleteObject(desertRef).then(() => {
    updateDeleteFiles(url);
  }).catch((error) => {
    console.log(error);
  });
}

useEffect(()=>{
  isEditing==false && getPropertyDetails();
},[isEditing])



  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };

  

  useEffect(()=>{
    if(data.files){
      console.log(data.files);
    }
    
    
  },[data])

  const [file, setFile] = useState([]);
    
    const uploadFile = (f,id) => {
        if(!file){

            navigate(`/properties/${id}`);
            return;
        };
        const storageRef = ref(storage, `/properties/${id}/${f.name}`)
        const uploadTask = uploadBytesResumable(storageRef,f);
        uploadTask.on("state_changed" , (snapshot)=>{
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
        },
        (err)=>console.log(err),
        ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
                handleFile(f.name,url);
                updateFile(id);
            });
        }
        )
    }

    const updateFile = async(id) => {
        await updateDoc(doc(db, "Properties", id),{
            files: {...data.files}
        })
        navigate(`/properties/${id}`)
    }

    const handleFile = (name, url) => {
        const obj = data;
        obj.files[name]=url;
        setData(obj);
    }

    const fileHandler = (e) => {
      for (let i=0; i<e.target.files.length; i++){
          const newImage = e.target.files[i];
          newImage["id"]=Math.random();

          setFile(prev => [...prev, newImage]);
          
      }
  }

  useEffect(()=>{
    console.log(file);
    if(file!=null){
      file.map(f=>{
        uploadFile(f,propertyId);
      });
    }
  },[file])

  const [allProps, setAllProps] = useState([]);
    
    const getAllProps = async () => {
        try{
            const arr=[];
            const docRef = await getDocs(collection(db, "Properties"));
            docRef.forEach(doc=>{
                arr.push(doc.data().PropertyName);
            })
            setAllProps(arr);
        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getAllProps();
    },[]);
  

  return (
    
    <>
      <PageTemp
        title={data.PropertyName}
        subTitle={!isLoading && "Property Details"}
        page={
          isLoading ? 
                <>
                    <div style={{marginLeft: "2rem"}} className={classes2.loader}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </>
                :
          <>
            
            <form action="">
            
            <div className={classes.formGroup}>
                <div className={classes.names}>
                    <label htmlFor="">Property Name</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("PropertyName",e)} defaultValue={data.PropertyName}></input>}
                    <label>Property Type</label>
                    {isEditing && <select onChange={(e)=>handleOnChange("PropertyType",e)} defaultValue={data.PropertyType}>
                        <option selected value="Commercial">Commercial</option>
                        <option value="Residential">Residential</option>
                        <option value="Industrial">Industrial</option>
                        <option value="Commercial & Residential">Commercial & Residential</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Store">Store</option>
                        <option value="Warehouse">Warehouse</option>
                        <option value="Others">Others</option>
                    </select>}
                    {/* <br></br> */}
                    {data.PropertyType=="Others" && <label htmlFor="">Other Property Type</label>}
                    {data.PropertyType=="Others" && <input onChange={(e)=>handleOnChange("PropertyType",e)} defaultValue={data.PropertyType!="Others" ? data.PropertyType : ""}></input>}
                    {/* <br></br> */}
                    {/* <label htmlFor="">Property Type</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("PropertyType",e)} defaultValue={data.PropertyType} ></input>} */}
                    <label htmlFor="">Property Size</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("PropertySize",e)} defaultValue={data.PropertySize} ></input>}
                    <label htmlFor="">Property Location</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("PropertyLocation",e)} defaultValue={data.PropertyLocation} ></input>}
                    
                </div>
                {isEditing==false ? <div className={classes.values}>
                    <label htmlFor="">{data.PropertyName}</label>
                    <label htmlFor="">{data.PropertyType}</label>
                    <label htmlFor="">{data.PropertySize}</label>
                    <label htmlFor="">{data.PropertyLocation}</label>
                    
                    
                </div>
                :
                <>
                
                </>}
                
            </div>
            <br></br>
            {
                      data.files && Object.keys(data.files).map((file)=>{
                        return <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}><a style={{appearance: "none"}} href={data.files[file]} target="_blank" rel="noopener"> <button type="button" className="btn_downloadFile" htmlFor="">{`Download ${file}`}</button></a>
                        <button type="button" onClick={()=>deletefile(file)}>Delete</button>
                        </div>

                      })
                    }
            <br></br>
            {/* {isEditing && <><label className={classes.upFileButton} htmlFor="fileUpload">
                <img src={uploadIcon} />
                Add File
                <input onChange={(e)=>uploadFile(e.target.files[0])} id="fileUpload" type="file" style={{display: "none"}} />
            </label>
            {prog!=0 && <p>Progress: {prog}</p>}
            </>
            } */}
            <label className={classes.upFileButton} htmlFor="fileUpload">
                        <img src={uploadIcon} />
                        Add File
                        <input multiple onChange={fileHandler} id="fileUpload" type="file" style={{display: "none"}} />
                    </label>
            <br></br>
            <label className={classes.excess}>{err}</label>
  <br></br>
            <div>
              <button className="btn_edit" type="button" onClick={handleEdit}>{!isEditing ? "Edit" : "Save"}</button>
              <button className="btn_del" type="button" onClick={handleDelete}>Delete</button>
            
            </div>
            </form>
          </>
        }
      />
    </>
  );
}
