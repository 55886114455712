
import classes from '../styles/NavBar.module.css';
import dropIcon from '../assets/icons/drop.svg'
import React, { useEffect, useState } from 'react';

export default function NavItem({children, icon, title, classF}){
    const [toggled, setToggled] = useState(false);

    useEffect(()=>{
        console.log(React.Children.toArray(children).length)
    },[])

    return(
        <>
            {React.Children.toArray(children).length!=0 && <li className={classF} onClick={()=>{setToggled(!toggled)}}><a className={classes.navItem}><img className={classes.icon} src={icon} />{title}</a><img className={`${classes.dropIcon} ${classes.icon}`} src={dropIcon}/></li>}
            {React.Children.toArray(children).length!=0 && <ul className={toggled ? classes.liActive : ""}>
                {children}
            </ul>}
        </>
    );
}