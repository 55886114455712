import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import Files from "../../assets/icons/Files.svg";
import uploadIcon from "../../assets/icons/Upload.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import QRCode from "react-qr-code";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

export default function PastTenant() {
  const [secondary, setSecondary] = useState(true);
  const [vacant, setVacant] = useState(false);
  const [pic, setPic] = useState();
  const { user, rolee, bangla } = UserAuth();
  const navigate = useNavigate();
  const { shopId, propertyId, floorId, index } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const [properties, setProperties] = useState({});
  const [floors, setFloors] = useState();

  const handleDelete = async () => {
    data.files &&
      Object.keys(data.files).map((file) => {
        deletefile(file);
      });

    await deleteDoc(doc(db, `Shops`, shopId))
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
        navigate(`/properties/${propertyId}/floors/${floorId}/shops/`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAllShops = async () => {
    try {
      const obj = {};
      const docRef = await getDocs(
        query(collection(db, "Shops"), where("floorId", "==", floorId))
      );
      docRef.forEach((doc) => {
        obj[doc.data().ShopNo] = doc.data().Block;
      });
      console.log(obj);
      setShops(obj);
    } catch (err) {
      console.log(err);
    }
  };

  const [shops, setShops] = useState();

  useEffect(() => {
    // fetchAllShops();
    console.log(shops);
  }, [shops]);

  const handleEdit = async () => {
    const docRef = await getDocs(query(collection(db, "Shops"), where("ShopNo","==",data.ShopNo), where("Block","==",data.Block), where("PropertyId","==",propertyId), where("floorId","==",floorId)));


    const arr=[];
    docRef.forEach(doc=>{
      if(doc.id!=shopId){
        arr.push(doc.id);
      }
    })

    if (isEditing) {
      if(arr.length>0){
          setErr("Shop No. already exists");
      }
      else if (
        /^\d+$/.test(data.ManagerMobile) == false &&
        data.ManagerMobile != undefined &&
        data.ManagerMobile != ""
      ) {
        setErr("Manager Mobile must be in numbers only.");
      } else if (
        data.ManagerMobile != undefined &&
        data.ManagerMobile != "" &&
        data.ManagerMobile.length != 11
      ) {
        setErr("Mobile Number must be 11 digits");
      } else if (
        data.LeaseEnding != undefined &&
        data.LeaseStarting != undefined &&
        data.LeaseStarting > data.LeaseEnding
      ) {
        setErr("Lease Starting cannot be after Lease Ending");
      } else if (
        /^\d+$/.test(data.UnitSpace) == false &&
        data.UnitSpace != undefined &&
        data.UnitSpace != ""
      ) {
        setErr("Unit Space must be in numbers only.");
      } else if (
        /^\d+$/.test(data.UnitPrice) == false &&
        data.UnitPrice != undefined &&
        data.UnitPrice != ""
      ) {
        setErr("Unit Price must be in numbers only.");
      } else if (
        /^\d+$/.test(data.MeterRate) == false &&
        data.MeterRate != undefined &&
        data.MeterRate != ""
      ) {
        setErr("Meter Rate must be in numbers only.");
      } else if (
        /^\d+$/.test(data.PreviousMeterUsage) == false &&
        data.PreviousMeterUsage != undefined &&
        data.PreviousMeterUsage != ""
      ) {
        setErr("Previous Meter Usage must be in numbers only.");
      } else if (
        /^\d+$/.test(data.CommonSpace) == false &&
        data.CommonSpace != undefined &&
        data.CommonSpace != ""
      ) {
        setErr("Common Space must be in numbers only.");
      } else if (
        /^\d+$/.test(data.ServiceChargeRate) == false &&
        data.ServiceChargeRate != undefined &&
        data.ServiceChargeRate != ""
      ) {
        setErr("Service Charge Rate must be in numbers only.");
      } else if (
        /^\d+$/.test(data.EmergencyMobile) == false &&
        data.EmergencyMobile != undefined &&
        data.EmergencyMobile != ""
      ) {
        setErr("Emergency Mobile must be in numbers only.");
      } else if (
        /^\d+$/.test(data.SecondaryMobile) == false &&
        data.SecondaryMobile != undefined &&
        data.SecondaryMobile != ""
      ) {
        setErr("Secondary Mobile must be in numbers only.");
      } else if (
        data.EmergencyMobile != undefined &&
        data.EmergencyMobile != "" &&
        data.EmergencyMobile.length != 11
      ) {
        setErr("Emergency Mobile Number must be 11 digits");
      } else if (
        data.SecondaryMobile != undefined &&
        data.SecondaryMobile != "" &&
        data.SecondaryMobile.length != 11
      ) {
        setErr("Secondary Mobile Number must be 11 digits");
      } else {
        setIsEditing(!isEditing);
        setErr("");
        await updateDoc(doc(db, `Shops`, shopId), data).catch((error) => {
          console.log(error);
        });
        getPropertyDetails();
      }
    } else {
      setIsEditing(!isEditing);
    }
  };

  const getPropertyDetails = async (event) => {
    console.log(propertyId);
    console.log(floorId);
    console.log(shopId);
    try {
      const querySnapshot = await getDoc(doc(db, `Shops`, shopId));
      console.log(querySnapshot.data());
      setData(querySnapshot.data().PastTenants[index]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleOnChange = (key, x) => {
    const obj = { ...data };
    obj[key] = x.target.value;
    setData({ ...obj });
    console.log(data);
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  const updateDeleteFiles = async (url) => {
    const obj = data.files;
    delete obj[url];
    try {
      await updateDoc(doc(db, "Shops", shopId), {
        files: obj,
      });
      getPropertyDetails();
    } catch (err) {
      console.log(err);
    }
  };

  const deletefile = async (url) => {
    const desertRef = ref(storage, `shops/${shopId}/${url}`);

    deleteObject(desertRef)
      .then(() => {
        updateDeleteFiles(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };

  const fetchFloors = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, `Properties/${propertyId}/Floors`),
          where("PropertyId", "==", properties[propertyId])
        )
      );

      const obj = {};
      querySnapshot.forEach((doc) => {
        obj[doc.data().FloorNo] = doc.id;
      });
      setFloors(obj);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProperties = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, `Properties`));
      const obj = {};
      querySnapshot.forEach((doc) => {
        obj[doc.data().PropertyName] = doc.id;
      });
      setProperties(obj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(floors);
  }, [floors]);

  useEffect(() => {
    fetchFloors();
  }, [properties]);

  useEffect(() => {
    fetchProperties();
    fetchAllShops();
  }, [data]);

  useEffect(() => {
    if (data.TenantType == "Vacant") {
      setVacant(true);
    } else {
      setVacant(false);
    }
  }, [data]);

  useEffect(() => {
    fetchAllShops();
  }, []);

  const [file, setFile] = useState([]);

  const uploadFile = (f, id) => {
    if (!file) {
      navigate(`/properties/${id}`);
      return;
    }
    const storageRef = ref(storage, `/shops/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          handleFile(f.name, url);
          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Shops", id), {
      files: { ...data.files },
    });
    navigate(`/properties/${propertyId}/floors/${floorId}/shops/${id}`);
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  useEffect(() => {
    console.log(file);
    if (file != null) {
      file.map((f) => {
        uploadFile(f, shopId);
      });
    }
  }, [file]);

  useEffect(() => {
    if (pic != null) {
      uploadDp(shopId);
    }
  }, [pic]);

  //dp
  const uploadDp = (id) => {
    if (!pic) {
      // navigate('/properties');
      return;
    }

    const storageRef = ref(storage, `/shops/${id}/${pic.name}`);

    const uploadTask = uploadBytesResumable(storageRef, pic);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleDp(url);
          updateDp(id);
        });
      }
    );
  };

  const updateDp = async (id) => {
    await updateDoc(doc(db, "Shops", id), {
      dp: data.dp,
    });
    navigate(`/properties/${propertyId}/floors/${floorId}/shops/${id}`);
  };

  const handleDp = (url) => {
    const obj = data;
    console.log(url);
    obj["dp"] = url;
    setData(obj);
  };

  const [err, setErr] = useState();

  return (
    <>
      <PageTemp
        title={data.BusinessName}
        subTitle={!isLoading && "Tenant Details"}
        img={data.dp}
        page={
          isLoading ? (
            <>
              <div style={{ marginLeft: "2rem" }} className={classes2.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
              <form action="">
                <div className={classes.formGroup}></div>

                
                
                <div className={classes.formDetail}>
                  {/* <div className={classes.detail}>
        <p className={classes.detailHeader}><img src={shopDetails} />Unit Details</p>
        <div className={classes.subDetail}>
            
            
        </div>
        <br />
    </div> */}
    
                  {
                  rolee.editUnitMaster && (
                    <div className={classes.detail}>
                      {/* <p className={classes.detailHeader}>
                        <img src={Location} />
                        {!bangla ? "Location" : "ঠিকানা"}
                      </p> */}
                      <div className={classes.subDetail}>
                        {/* {!isEditing && (
                          <label>
                            {!bangla
                              ? `Property${
                                  !isEditing ? `: ${data.PropertyName}` : ""
                                }`
                              : `প্রপার্টি${
                                  !isEditing ? `: ${data.PropertyName}` : ""
                                }`}
                          </label>
                        )} */}

                        {/* </select>} */}
                        <>
                          {/* {!isEditing && (
                            <label>
                              {!bangla
                                ? `Floor${
                                    !isEditing ? `: ${data.FloorNo}` : ""
                                  }`
                                : `ফ্লোর${
                                    !isEditing ? `: ${data.FloorNo}` : ""
                                  }`}
                            </label>
                          )} */}
                        </>
                        {/* <label>
                          {!bangla
                            ? `Block${!isEditing ? `: ${data.Block}` : ""}`
                            : `ব্লক${!isEditing ? `: ${data.Block}` : ""}`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.Block}
                            onChange={(e) =>
                              setData({ ...data, Block: e.target.value })
                            }
                            type="text"
                            placeholder="Enter Block"
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Unit No${!isEditing ? `: ${data.ShopNo}` : ""}`
                            : `ইউনিট নং${!isEditing ? `: ${data.ShopNo}` : ""}`}
                        </label> */}
                        {/* {isEditing && (
                          <input
                            defaultValue={data.ShopNo}
                            onChange={(e) =>
                              setData({
                                ...data,
                                ShopNo: parseInt(e.target.value),
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla ? "Enter Unit No." : "ইউনিট নম্বর লিখুন"
                            }
                          />
                        )} */}
                        {/* <label>
                          {!bangla
                            ? `Tenant Type${
                                !isEditing
                                  ? `: ${
                                      data.TenantType != undefined
                                        ? data.TenantType
                                        : "Vacant"
                                    }`
                                  : ""
                              }`
                            : `ভাড়াটে প্রকার${
                                !isEditing
                                  ? `: ${
                                      data.TenantType != undefined
                                        ? data.TenantType
                                        : "Vacant"
                                    }`
                                  : ""
                              }`}
                        </label> */}
                        {/* {isEditing && (
                          <select
                            onChange={(e) =>
                              setData({ ...data, TenantType: e.target.value })
                            }
                            defaultValue={data.TenantType}
                          >
                            <option val="Vacant">Vacant</option>
                            <option val="Primary">Primary</option>
                            <option val="Position">Position</option>
                          </select>
                        )} */}
                        {rolee.editUnitMaster && (
                            <>
                              {/* <label>
                                {!bangla
                                  ? `Unit Space${
                                      !isEditing ? `: ${data.UnitSpace}` : ""
                                    }`
                                  : `ইউনিট সাইজ${
                                      !isEditing ? `: ${data.UnitSpace}` : ""
                                    }`}
                              </label>
                              {isEditing && (
                                <input
                                  defaultValue={data.UnitSpace}
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      UnitSpace: e.target.value,
                                    })
                                  }
                                  type="text"
                                  placeholder={
                                    !bangla
                                      ? "Enter Unit Space"
                                      : "ইউনিট সাইজ লিখুন"
                                  }
                                />
                              )} */}
                            </>
                          )}
                      </div>
                    </div>
                  )}
                  <div className={classes.detail}>
                    {/* <p className={classes.detailHeader}>
                      <img src={EntryInfo} />
                      {!bangla ? "Entry Info" : "এন্ট্রি তথ্য"}
                    </p> */}
                    <div className={classes.subDetail}>
                      {/* <label>
                        {!bangla
                          ? `Rent: ${data.hasRent ? data.hasRent : "Off"}`
                          : `ভাড়া: ${data.hasRent ? data.hasRent : "Off"}`}
                      </label> */}

                      {/* {data && isEditing && (
                        <>
                          <input
                            className={classes.switch}
                            defaultChecked={data.hasRent ? data.hasRent : false}
                            onChange={(e) =>
                              setData({ ...data, hasRent: e.target.checked })
                            }
                            type="checkbox"
                          />
                        </>
                      )} */}
                      {data && data.hasRent && (
                        <>
                          {/* <label>{`Next Rent Payment Date${
                            !isEditing ? `: ${data.NextPayment}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.NextPayment}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  NextPayment: e.target.value,
                                })
                              }
                              type="month"
                              placeholder="Next Rent Payment"
                            />
                          )} */}
                          
                          {/* <label>{`Unit rate per sq.${
                            !isEditing ? `: ${data.UnitPrice}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.UnitPrice}
                              onChange={(e) =>
                                setData({ ...data, UnitPrice: e.target.value })
                              }
                              type="text"
                              placeholder="Enter Unit Price"
                            />
                          )}
                          {!isEditing && (
                            <label>{`Rent Due${
                              !isEditing ? `: ${data.RentDue}` : ""
                            }`}</label>
                          )} */}
                        </>
                      )}

                      {/* <label>
                        {!bangla
                          ? `Utility: ${
                              data.hasUtility ? data.hasUtility : "Off"
                            }`
                          : `ইউটিলিটি: ${
                              data.hasUtility ? data.hasUtility : "Off"
                            }`}
                      </label>

                      {data && isEditing && (
                        <>
                          <input
                            className={classes.switch}
                            defaultChecked={
                              data.hasUtility ? data.hasUtility : false
                            }
                            onChange={(e) =>
                              setData({ ...data, hasUtility: e.target.checked })
                            }
                            type="checkbox"
                          />
                        </>
                      )} */}

                      {/* {data && data.hasUtility && (
                        <>
                          <label>{`Next Utility Payment Date${
                            !isEditing ? `: ${data.NextPaymentUtility}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.NextPaymentUtility}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  NextPaymentUtility: e.target.value,
                                })
                              }
                              type="month"
                              placeholder="Next Utility Payment"
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Previous Usage${
                                  !isEditing
                                    ? `: ${data.PreviousMeterUsage}`
                                    : ""
                                }`
                              : `মিটার উজেজ${
                                  !isEditing
                                    ? `: ${data.PreviousMeterUsage}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.PreviousMeterUsage}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  PreviousMeterUsage: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Previous Usage"
                                  : "মিটার উজেজ লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Utility Rate${
                                  !isEditing ? `: ${data.MeterRate}` : ""
                                }`
                              : `ইউটিলিটি রেট${
                                  !isEditing ? `: ${data.MeterRate}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.MeterRate}
                              onChange={(e) =>
                                setData({ ...data, MeterRate: e.target.value })
                              }
                              type="text"
                              placeholder="Enter Meter Rate"
                            />
                          )}
                          {!isEditing && (
                            <label>{`Utility Due${
                              !isEditing ? `: ${data.UtilityDue}` : ""
                            }`}</label>
                          )}
                        </>
                      )} */}

                      {/* <label>
                        {!bangla
                          ? `Service Charge: ${data.hasSC ? data.hasSC : "Off"}`
                          : `সার্ভিস চার্জ: ${data.hasSC ? data.hasSC : "Off"}`}
                      </label> */}

                      {/* {data && isEditing && (
                        <>
                          <input
                            className={classes.switch}
                            defaultChecked={data.hasSC ? data.hasSC : false}
                            onChange={(e) =>
                              setData({ ...data, hasSC: e.target.checked })
                            }
                            type="checkbox"
                          />
                        </>
                      )} */}

                      {/* {data && data.hasSC && (
                        <>
                          <label>{`Next S/C Payment Date${
                            !isEditing
                              ? `: ${data.NextPaymentServiceCharge}`
                              : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.NextPaymentServiceCharge}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  NextPaymentServiceCharge: e.target.value,
                                })
                              }
                              type="month"
                              placeholder="Next S/C Payment"
                            />
                          )}

                          <label>
                            {!bangla
                              ? `Common Space${
                                  !isEditing ? `: ${data.CommonSpace}` : ""
                                }`
                              : `কমন স্পেস${
                                  !isEditing ? `: ${data.CommonSpace}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.CommonSpace}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  CommonSpace: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Common Space"
                                  : "কমন স্পেস লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Service Charge Rate${
                                  !isEditing
                                    ? `: ${data.ServiceChargeRate}`
                                    : ""
                                }`
                              : `সার্ভিস চার্জ রেট${
                                  !isEditing
                                    ? `: ${data.ServiceChargeRate}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.ServiceChargeRate}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  ServiceChargeRate: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Service Charge Rate"
                                  : "সার্ভিস চার্জ রেট লিখুন"
                              }
                            />
                          )}

                          {!isEditing && (
                            <label>{`Service Charge Due${
                              !isEditing ? `: ${data.ServiceChargeDue}` : ""
                            }`}</label>
                          )}
                        </>
                      )} */}

                      <br></br>
                      {/* <div className={classes.buttonConttt}>
                        <button className="btn_edit" type="button">
                          Add Entry
                        </button>
                        <button
                          className="btn_edit"
                          type="button"
                          onClick={() =>
                            navigate(
                              `/properties/${propertyId}/floors/${floorId}/shops/${shopId}/history`
                            )
                          }
                        >
                          See History
                        </button>
                      </div> */}
                    </div>
                    {/* <br /> */}
                  </div>
                </div>
                {/* <br />
                <br /> */}
                {!vacant && (
                  <div className={classes.formDetail}>
                    <div className={classes.detail}>
                      <p className={classes.detailHeader}>
                        <img src={TenantDetails} />
                        Tenant Details
                      </p>
                      <div className={classes.subDetail}>
                        <label>
                          {!bangla
                            ? `Business Name${
                                !isEditing ? `: ${data.BusinessName}` : ""
                              }`
                            : `ব্যবসার নাম${
                                !isEditing ? `: ${data.BusinessName}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.BusinessName}
                            onChange={(e) =>
                              setData({ ...data, BusinessName: e.target.value })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Business Name"
                                : "ব্যবসার নাম লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Tenant Name${
                                !isEditing ? `: ${data.TenantName}` : ""
                              }`
                            : `ভাড়াটে নাম${
                                !isEditing ? `: ${data.TenantName}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.TenantName}
                            onChange={(e) =>
                              setData({ ...data, TenantName: e.target.value })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Tenant's Name"
                                : "ভাড়াটে নাম লিখুন"
                            }
                          />
                        )}

                        {/* <label>{`Name${!isEditing ? `: ${data.TenantName}`: ""}`}</label>
            {isEditing && <input defaultValue={data.TenantName} onChange={e=>setData({...data, TenantName: e.target.value})} type="text" placeholder="Enter Tenant's Name" />} */}
                        <label>
                          {!bangla
                            ? `Father's Name${
                                !isEditing ? `: ${data.TenantFatherName}` : ""
                              }`
                            : `পিতার নাম${
                                !isEditing ? `: ${data.TenantFatherName}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.TenantFatherName}
                            onChange={(e) =>
                              setData({
                                ...data,
                                TenantFatherName: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Father's Name"
                                : "পিতার নাম লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Home Address${
                                !isEditing ? `: ${data.TenantHomeAddress}` : ""
                              }`
                            : `বাসার ঠিকানা${
                                !isEditing ? `: ${data.TenantHomeAddress}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.TenantHomeAddress}
                            onChange={(e) =>
                              setData({
                                ...data,
                                TenantHomeAddress: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Home Address"
                                : "বাসার ঠিকানা লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Mobile Number${
                                !isEditing ? `: ${data.TenantMobileNumber}` : ""
                              }`
                            : `মোবাইল নম্বর${
                                !isEditing ? `: ${data.TenantMobileNumber}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.TenantMobileNumber}
                            onChange={(e) =>
                              setData({
                                ...data,
                                TenantMobileNumber: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla ? "Enter Mobile No" : "মোবাইল নম্বর লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `National ID${
                                !isEditing ? `: ${data.TenantNID}` : ""
                              }`
                            : `এনাইডি নম্বর${
                                !isEditing ? `: ${data.TenantNID}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.TenantNID}
                            onChange={(e) =>
                              setData({ ...data, TenantNID: e.target.value })
                            }
                            type="text"
                            placeholder={
                              !bangla ? "Enter NID" : "এনাইডি নম্বর লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Passport Number${
                                !isEditing ? `: ${data.TenantPassport}` : ""
                              }`
                            : `পাসপোর্ট নম্বর${
                                !isEditing ? `: ${data.TenantPassport}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.TenantPassport}
                            onChange={(e) =>
                              setData({
                                ...data,
                                TenantPassport: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Passport No"
                                : "পাসপোর্ট নম্বর লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Manager Name${
                                !isEditing ? `: ${data.ManagerName}` : ""
                              }`
                            : `ম্যানেজারের নাম${
                                !isEditing ? `: ${data.ManagerName}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.ManagerName}
                            onChange={(e) =>
                              setData({ ...data, ManagerName: e.target.value })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Manager Name"
                                : "ম্যানেজারের নাম লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Manager Mobile Number${
                                !isEditing ? `: ${data.ManagerMobile}` : ""
                              }`
                            : `ম্যানেজারের মোবাইল নম্বর${
                                !isEditing ? `: ${data.ManagerMobile}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.ManagerMobile}
                            onChange={(e) =>
                              setData({
                                ...data,
                                ManagerMobile: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla
                                ? "Enter Manager's No."
                                : "ম্যানেজারের মোবাইল নম্বর লিখুন"
                            }
                          />
                        )}
                        {
                          <>
                            <label>
                              {!bangla
                                ? `Lease Starting${
                                    !isEditing ? `: ${data.LeaseStarting}` : ""
                                  }`
                                : `লিজ সুরু তারিক${
                                    !isEditing ? `: ${data.LeaseStarting}` : ""
                                  }`}
                            </label>
                            {isEditing && (
                              <input
                                defaultValue={data.LeaseStarting}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    LeaseStarting: e.target.value,
                                  })
                                }
                                type="month"
                                placeholder="Lease Starting"
                              />
                            )}
                          </>
                        }
                        {data && data.TenantType != "Position" && (
                          <>
                            <label>
                              {!bangla
                                ? `Lease Ending${
                                    !isEditing ? `: ${data.LeaseEnding}` : ""
                                  }`
                                : `লিজ শেষ তারিক${
                                    !isEditing ? `: ${data.LeaseEnding}` : ""
                                  }`}
                            </label>
                            {isEditing && (
                              <input
                                defaultValue={data.LeaseEnding}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    LeaseEnding: e.target.value,
                                  })
                                }
                                type="month"
                                placeholder="Lease Ending"
                              />
                            )}
                          </>
                        )}
                        {data && data.TenantType == "Primary" && (
                          <>
                            <label>
                              {!bangla
                                ? `Security Deposit${
                                    !isEditing ? `: ${data.SecurityDeposit}` : ""
                                  }`
                                : `লিজ শেষ তারিক${
                                    !isEditing ? `: ${data.SecurityDeposit}` : ""
                                  }`}
                            </label>
                            {isEditing && (
                              <input
                                defaultValue={data.SecurityDeposit}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    SecurityDeposit: e.target.value,
                                  })
                                }
                                placeholder="Enter Security Deposit"
                              />
                            )}
                          </>
                        )}
                      </div>
                      <br />
                      <br />
                    </div>
                    <div className={classes.detail}>
                      <p className={classes.detailHeader}>
                        <img src={SecondaryTenant} />
                        Secondary Tenant Details
                      </p>
                      {isEditing && (
                        <input
                          defaultChecked
                          className={classes.switch}
                          onChange={() => setSecondary(!secondary)}
                          type="checkbox"
                        />
                      )}
                      {secondary && (
                        <div className={classes.subDetail}>
                          <label>
                            {!bangla
                              ? `Business/Tenant's Name${
                                  !isEditing ? `: ${data.SecondaryName}` : ""
                                }`
                              : `ব্যবসার/ভাড়াটে নাম${
                                  !isEditing ? `: ${data.SecondaryName}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryName}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryName: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Tenant's Name"
                                  : "ভাড়াটে নাম লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Father's Name${
                                  !isEditing
                                    ? `: ${data.SecondaryFatherName}`
                                    : ""
                                }`
                              : `পিতার নাম${
                                  !isEditing
                                    ? `: ${data.SecondaryFatherName}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryFatherName}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryFatherName: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Father's Name"
                                  : "পিতার নাম লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Home Address${
                                  !isEditing
                                    ? `: ${data.SecondaryHomeAddress}`
                                    : ""
                                }`
                              : `বাসার ঠিকানা${
                                  !isEditing
                                    ? `: ${data.SecondaryHomeAddress}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryHomeAddress}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryHomeAddress: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Home Address"
                                  : "বাসার ঠিকানা লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Mobile Number${
                                  !isEditing ? `: ${data.SecondaryMobile}` : ""
                                }`
                              : `মোবাইল নম্বর${
                                  !isEditing ? `: ${data.SecondaryMobile}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryMobile}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryMobile: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Mobile No"
                                  : "মোবাইল নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `National ID${
                                  !isEditing ? `: ${data.SecondaryNID}` : ""
                                }`
                              : `এনাইডি নম্বর${
                                  !isEditing ? `: ${data.SecondaryNID}` : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryNID}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryNID: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla ? "Enter NID" : "এনাইডি নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>
                            {!bangla
                              ? `Passport Number${
                                  !isEditing
                                    ? `: ${data.SecondaryPassportNumber}`
                                    : ""
                                }`
                              : `পাসপোর্ট নম্বর${
                                  !isEditing
                                    ? `: ${data.SecondaryPassportNumber}`
                                    : ""
                                }`}
                          </label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryPassportNumber}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryPassportNumber: e.target.value,
                                })
                              }
                              type="text"
                              placeholder={
                                !bangla
                                  ? "Enter Passport No"
                                  : "পাসপোর্ট নম্বর লিখুন"
                              }
                            />
                          )}
                          <label>{`Secondary Tenant Rent${
                            !isEditing ? `: ${data.SecondaryTenantRent}` : ""
                          }`}</label>
                          {isEditing && (
                            <input
                              defaultValue={data.SecondaryTenantRent}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SecondaryTenantRent: e.target.value,
                                })
                              }
                              type="text"
                              placeholder="Enter Rent"
                            />
                          )}
                        </div>
                      )}
                      <br />
                      <br />
                    </div>
                  </div>
                )}

                

                <div className={classes.formDetail}>
                  {!vacant && (
                    <div className={classes.detail}>
                      <p className={classes.detailHeader}>
                        <img src={EmergencyContact} />
                        {!bangla
                          ? `Emergency Contact (Tenant)`
                          : `জরুরী যোগাযোগ`}
                      </p>
                      <div className={classes.subDetail}>
                        <label>
                          {!bangla
                            ? `Name${
                                !isEditing ? `: ${data.EmergencyName}` : ""
                              }`
                            : `নাম${
                                !isEditing ? `: ${data.EmergencyName}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.EmergencyName}
                            onChange={(e) =>
                              setData({
                                ...data,
                                EmergencyName: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={!bangla ? "Enter Name" : "নাম লিখুন"}
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Relation${
                                !isEditing ? `: ${data.EmergencyRelation}` : ""
                              }`
                            : `সম্পর্ক${
                                !isEditing ? `: ${data.EmergencyRelation}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.EmergencyRelation}
                            onChange={(e) =>
                              setData({
                                ...data,
                                EmergencyRelation: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla ? "Enter Relation" : "সম্পর্ক লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Address${
                                !isEditing ? `: ${data.EmergencyAddress}` : ""
                              }`
                            : `ঠিকানা${
                                !isEditing ? `: ${data.EmergencyAddress}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.EmergencyAddress}
                            onChange={(e) =>
                              setData({
                                ...data,
                                EmergencyAddress: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla ? "Enter Address" : "ঠিকানা লিখুন"
                            }
                          />
                        )}
                        <label>
                          {!bangla
                            ? `Mobile Number${
                                !isEditing ? `: ${data.EmergencyMobile}` : ""
                              }`
                            : `মোবাইল নম্বর${
                                !isEditing ? `: ${data.EmergencyMobile}` : ""
                              }`}
                        </label>
                        {isEditing && (
                          <input
                            defaultValue={data.EmergencyContact}
                            onChange={(e) =>
                              setData({
                                ...data,
                                EmergencyMobile: e.target.value,
                              })
                            }
                            type="text"
                            placeholder={
                              !bangla ? "Enter Mobile No" : "মোবাইল নম্বর লিখুন"
                            }
                          />
                        )}
                      </div>

                      <br></br>
                    </div>
                  )}
                  <div className={classes.detail}>
                      {/* <p className={classes.detailHeader}> */}
                        {/* <img src={Location} /> */}
                        {/* {!bangla ? "Past Tenants" : "Update Please"} */}
                      {/* </p> */}
                      {/* {data.PastTenants!=undefined && <div className={classes.subDetail}>
                            {data.PastTenants.map((p, index)=>{
                              return <p 
                              onClick={
                                ()=>
                                navigate(`/properties/${propertyId}/floors/${floorId}/shops/${shopId}/pastTenants/${index}`)
                              } 
                              className={classes.pastTenant}>{p.BusinessName}</p>
                            }
                              
                            )}
                      </div>} */}
                      {/* {
                        data.PastTenants==undefined && <div className={classes.subDetail}>
                        <p>No Past Tenants</p>
                        </div>
                      } */}
                  </div>
                  
                  
                </div>
                <br></br>
                {/* <div className={classes.formDetail}>
                <div className={classes.detail}>
                    <p className={classes.detailHeader}>QR Code</p>

                    <div
                      className={classes.subDetail}
                      style={{
                        border: "1px solid #D6D6D6",
                        borderRadius: "2rem",
                      }}
                    >
                      <QRCode
                        style={{ transform: "scale(0.7)" }}
                        value={`https://www.rentmilloh.com/properties/${propertyId}/${floorId}/shops/${shopId}/rent`}
                      />
                    </div>

                    <br />
                    {data.files &&
                      Object.keys(data.files).map((file) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "1rem",
                            }}
                          >
                            <a
                              style={{ appearance: "none" }}
                              href={data.files[file]}
                              target="_blank"
                              rel="noopener"
                            >
                              {" "}
                              <button
                                type="button"
                                className="btn_downloadFile"
                                htmlFor=""
                              >{`Download ${file}`}</button>
                            </a>
                            <button
                              type="button"
                              onClick={() => deletefile(file)}
                            >
                              Delete
                            </button>
                          </div>
                        );
                      })}

                    <br></br>
                    <label
                      className={classes.upFileButton}
                      htmlFor="fileUpload"
                    >
                      <img src={uploadIcon} />
                      {!bangla ? "Add File" : "ফাইল যুক্ত করুন"}
                      <input
                        multiple
                        onChange={fileHandler}
                        id="fileUpload"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </label>
                    <label
                      className={classes.upFileButton}
                      htmlFor="fileUpload2"
                    >
                      <img src={uploadIcon} />
                      Change Pic
                      <input
                        onChange={(e) => setPic(e.target.files[0])}
                        id="fileUpload2"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                </div> */}

                <br />
                <br />
                <label className={classes.excess}>{err}</label>
                <br></br>
                {/* {rolee && rolee.editUnit && (
                  <div>
                    <button
                      className="btn_edit"
                      type="button"
                      onClick={handleEdit}
                    >
                      {!isEditing ? "Edit" : "Save"}
                    </button>
                    <button
                      className="btn_del"
                      type="button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                )} */}
                <br />
                <br />
              </form>
              <br></br>

              <br />

              <br />
              <br />

              <br></br>

              <br />

              <br />
              <br />

              {/* <form action="">
            
            <div className={classes.formGroup}>
                <div className={classes.names}>
                    {!isEditing && <label htmlFor="">ID</label>}
                    <label htmlFor="">Shop Name</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("BusinessName",e)} defaultValue={data.BusinessName}></input>}
                    <label htmlFor="">Floor</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Floor",e)} defaultValue={data.Floor}></input>}
                    <label htmlFor="">Property</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Property",e)} defaultValue={data.Property}></input>}
                    
                    
                </div>
                {isEditing==false ? <div className={classes.values}>
                    <label htmlFor="">{data.id}</label>
                    <label htmlFor="">{data.BusinessName}</label>
                    <label htmlFor="">{data.Floor}</label>
                    <label htmlFor="">{data.Property}</label>
                </div>
                :
                <>
                
                </>}
            </div>
            <br></br>
            <div>
              <button className="btn_edit" type="button" onClick={handleEdit}>{!isEditing ? "Edit" : "Save"}</button>
              <button className="btn_del" type="button" onClick={handleDelete}>Delete</button>
            
            </div>
            </form> */}
            </>
          )
        }
      />
    </>
  );
}
