// import PageTemp from "../PageTemp";
// import classes from "../../styles/FloorView.module.css"
// // import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import { useState } from "react";
// import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
// import { db } from "../../firebase";
// import { useEffect } from "react";
// import TableTop from "../TableTop";
// import { UserAuth } from "../../context/AuthContext";
// import Draggable, { DraggableCore } from 'react-draggable';
// import { Resizable, ResizableBox } from 'react-resizable';

// export default function FloorView() {

//     const {allProperties, allFloors} = UserAuth();

//     const [props, setProps] = useState();
//     const [currentProp, setCurrentProp] = useState();
//     const [floors, setFloors] = useState();
//     const [currentFloor, setCurrentFloor] = useState();

//     const fetchProps = async () => {
//         try{
//             const obj={};
//             const docRef = await getDocs(collection(db, "Properties"));
//             docRef.forEach(doc=>{
//                 obj[doc.data().PropertyName] = doc.id;
//             })
//             setProps(obj);
//         }
//         catch(err){
//             console.log(err);
//         }

//     }

//     const fetchFloors = async () => {
//         try{
//             const obj={};
//             const docRef = await getDocs(query(collection(db, "Floors"), where("PropertyId", "==", currentProp)));
//             docRef.forEach(doc=>{
//                 obj[doc.data().floorNo] = doc.id;
//             })
//             setFloors(obj);
//         }
//         catch(err){
//             console.log(err);
//         }

//     }

//     useEffect(()=>{
//         fetchProps();
//     },[])

//     // useEffect(()=>{
//     //     console.log(shops);

//     // },[shops]);

//     useEffect(()=>{
//         // fetchShops(currentFloor);
//     },[currentFloor])

//     useEffect(()=>{
//         fetchFloors();
//     },[currentProp])

//     const [pos, setPos] = useState(
//         {
//             "Shop1": {x: 0, y: 0},
//             "Shop2": {x: 0, y: 0}
//         }
//     );

//     const [resizing, setResizing] = useState(false);

//     const handleStart = ()=>{
//         console.log("here");
//     }
//     const handleStop = (event, dragElement) => {
//         const obj = {};
//         const subObj={};

//         subObj["x"]=dragElement.x;
//         subObj["y"]=dragElement.y;
//         obj[dragElement.node.outerText]=subObj;
//         console.log(obj);

//         setPos(prev=>{return {...prev, ...obj}})
//         // setPos(obj);
//         // console.log(dragElement.node.outerText);
//         // console.log(event);
//     }

//     useEffect(()=>{
//         console.log(pos);
//     },[pos])

//     const onResizeStart = () => {

//         setResizing(true);
//     }
//     const onResizeEnd = () => {
//         setResizing(false);

//     }

//     useEffect(()=>{
//         console.log(resizing);
//     },[resizing]);

//     return (
//     <>
//       <PageTemp
//         title="Floor View"
//         subTitle="Map"
//         page={
//             <>
//             <div className={classes.top}>
//                 <div className={classes.topItem}>
//                     <label>Property</label>
//                     <select onChange={e=>setCurrentProp(e.target.value)}>
//                         <option>Select Property</option>
//                         {props && Object.keys(props).map(p=>{
//                             return <option value={props[p]}>{p}</option>
//                         })}
//                     </select>
//                 </div>
//                 {floors && <div className={classes.topItem}>
//                     <label>Floor</label>
//                     <select onChange={e=>setCurrentFloor(e.target.value)}>
//                     <option>Select Floor</option>
//                         {floors && Object.keys(floors).map(f=>{
//                             return <option value={floors[f]}>{f}</option>
//                         })}
//                     </select>
//                 </div>}
//             </div>
//             {pos &&

//             <div className={classes.mainView}>
//                 <>{Object.keys(pos).map(p=>{
//                     return <>

//                     <Draggable
//                     grid={[25, 25]}
//                     defaultPosition={{x: pos[p].x, y: pos[p].y}}
//                     onStop={handleStop}
//                     >
//                         <ResizableBox
//                         className={classes.shop}
//                         width={50}
//                         height={50}
//                         onResizeStart={onResizeStart}
//                         onResizeStop={onResizeEnd}
//                         >
//                             <div>
//                                 {p}
//                             </div>
//                         </ResizableBox>
//                     </Draggable>

//                     </>
//                 })}</>

//             {/* <ResizableBox width={200} height={200}>
//                         <div
//                         style={{ backgroundColor: 'green', width: '100%', height: '100%' }}
//                         >
//                         123
//                         </div>
//                     </ResizableBox> */}
//             </div>
//             }

//             </>
//         }
//       />
//     </>
//   );
// }

import PageTemp from "../PageTemp";
import classes from "../../styles/FloorView.module.css";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import TableTop from "../TableTop";
import { UserAuth } from "../../context/AuthContext";
import Draggable, { DraggableCore } from "react-draggable";
import { Resizable, ResizableBox } from "react-resizable";
import { Rnd } from "react-rnd";
import { useNavigate } from "react-router-dom";
import stairIcon from "../../assets/icons/Stairs.svg";
import liftIcon from "../../assets/icons/Lift.svg";
import washroomIcon from "../../assets/icons/washroom.svg";
import deleteIcon from "../../assets/icons/dlt.svg";
import corridorIcon from "../../assets/icons/corridor.svg";
import openSpaceIcon from "../../assets/icons/openspace.svg";

export default function FloorView() {
  const { allProperties, allFloors, bangla, rolee } = UserAuth();

  const navigate = useNavigate();

  const [props, setProps] = useState();
  const [currentProp, setCurrentProp] = useState();
  const [floors, setFloors] = useState();
  const [currentFloor, setCurrentFloor] = useState();

  

  const fetchProps = async () => {
    try {
      const obj = {};
      const docRef = await getDocs(collection(db, "Properties"));
      docRef.forEach((doc) => {
        obj[doc.data().PropertyName] = doc.id;
      });
      setProps(obj);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFloors = async () => {
    setPos({});
    try {
      const obj = {};
      const docRef = await getDocs(
        query(collection(db, "Floors"), where("PropertyId", "==", currentProp))
      );
      docRef.forEach((doc) => {
        if(doc.data().floorName==undefined){
          obj[doc.data().floorNo] = doc.id;
        }
        else{
          obj[doc.data().floorName] = doc.id;
        }
        
      });
      setFloors(obj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProps();
  }, []);

  // useEffect(()=>{
  //     console.log(shops);

  // },[shops]);

  useEffect(() => {
    setPos({});
    fetchShops();
  }, [currentFloor]);

  useEffect(() => {
    setPos({});
    fetchFloors();
  }, [currentProp]);

  const fetchShops = async () => {
    try {
      const docRef = await getDocs(
        query(collection(db, "Shops"), where("floorId", "==", currentFloor))
      );
      docRef.forEach((doc) => {
        const obj = pos;
        if (doc.data().floorData != undefined) {
          obj[doc.id] = {
            x: doc.data().floorData["x"],
            y: doc.data().floorData["y"],
            width: doc.data().floorData["width"],
            height: doc.data().floorData["height"],
            id: doc.id,
            shopNo: doc.data().ShopNo,
            shopName: doc.data().BusinessName,
            block: doc.data().Block,
          };
        } else {
          obj[doc.id] = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            id: doc.id,
            shopNo: doc.data().ShopNo,
            shopName: doc.data().BusinessName,
            block: doc.data().Block,
          };
        }

        console.log(doc.data().BusinessName);
        setPos((prev) => {
          return { ...prev, ...obj };
        });
      });

      const docRef2 = await getDocs(
        query(
          collection(db, "floorItems"),
          where("floorId", "==", currentFloor)
        )
      );
      docRef2.forEach((doc) => {
        const obj = pos;
        if (doc.data().floorData != undefined) {
          obj[doc.id] = {
            x: doc.data().floorData["x"],
            y: doc.data().floorData["y"],
            width: doc.data().floorData["width"],
            height: doc.data().floorData["height"],
            id: doc.id,
            shopName: doc.data().type,
          };
        } else {
          obj[doc.id] = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            id: doc.id,
            shopName: doc.data().type,
          };
        }

        // console.log(doc.data().BusinessName);
        setPos((prev) => {
          return { ...prev, ...obj };
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  async function deleteInstance(obj, name) {
    if (obj.hasOwnProperty(name)) {
      console.log(name)
      delete obj[name];
    }
    return obj;
  }

  const deleteFloorObject = async(id) => {
    console.log(pos);
    let o= await deleteInstance(pos,id);
    console.log(o);
    await deleteDoc(doc(db, "floorItems", id));

    setPos(o);
    
    fetchShops();
  }

  const addFloorItem = async (item) => {
    
    try {
      const docRef = await addDoc(collection(db, "floorItems"), {
        type: item,
        floorId: currentFloor,
      }).then(fetchShops());
    } catch (err) {
      console.log(err);
    }
  };

  // const deleteItem = async (id)=> {
  //     try{
  //         await deleteDoc(doc(db, "floorItems", id)).then(()=>{
  //             fetchShops();
  //         });

  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  const updateShopFloorData = async (id, x, y, width, height, name) => {
    try {
      if (name != "lift" && name != "stair" && name!="corridor" && name!="washroom" && name!="openSpace") {
        const obj = {};
        obj["x"] = x;
        obj["y"] = y;
        obj["width"] = width;
        obj["height"] = height;
        console.log(obj);
        await updateDoc(doc(db, "Shops", id), {
          floorData: obj,
        });
      } else {
        const obj = {};
        obj["x"] = x;
        obj["y"] = y;
        obj["width"] = width;
        obj["height"] = height;
        console.log(obj);
        await updateDoc(doc(db, "floorItems", id), {
          floorData: obj,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [pos, setPos] = useState({});

  const [resizing, setResizing] = useState(false);

  const handleStart = () => {
    console.log("here");
  };
  const handleStop = (event, dragElement) => {
    const obj = {};
    const subObj = {};

    subObj["x"] = dragElement.x;
    subObj["y"] = dragElement.y;
    obj[dragElement.node.outerText] = subObj;
    console.log(obj);

    setPos((prev) => {
      return { ...prev, ...obj };
    });
    // setPos(obj);
    // console.log(dragElement.node.outerText);
    // console.log(event);
  };

  useEffect(() => {
    console.log(pos);
  }, [pos]);

  const onResizeStart = () => {
    setResizing(true);
  };
  const onResizeEnd = () => {
    setResizing(false);
  };

  useEffect(() => {
    console.log(resizing);
  }, [resizing]);

  const [search, setSearch] = useState();

  useEffect(() => {
    console.log(search);
  }, [search]);

  const [addObject, setAddObject] = useState("lift");

  

  return (
    <>
      <PageTemp
        title="Floor View"
        subTitle="Map"
        page={
          <>
            <div className={classes.top}>
              <div className={classes.topItem}>
                <label>{!bangla ? "Property" : "প্রপার্টি"}</label>
                <select onChange={(e) => setCurrentProp(e.target.value)}>
                  <option>
                    {!bangla ? "Select Property" : "প্রপার্টি সিলেক্ট করুন"}
                  </option>
                  {props &&
                    Object.keys(props).map((p) => {
                      return <option value={props[p]}>{p}</option>;
                    })}
                </select>
              </div>
              {floors && (
                <div className={classes.topItem}>
                  <label>{!bangla ? "Floor" : "ফ্লোর"}</label>
                  <select
                    onChange={(e) => {
                      setPos({});
                      setCurrentFloor(e.target.value);
                    }}
                  >
                    <option>
                      {!bangla ? "Select Floor" : "ফ্লোর সিলেক্ট করুন"}
                    </option>
                    {floors &&
                      Object.keys(floors).map((f) => {
                        return <option value={floors[f]}>{f}</option>;
                      })}
                  </select>
                </div>
              )}
              {rolee && rolee.floorViewEdit && pos && Object.keys(pos).length > 0 && (
                <>
                  <div className={classes.topItem}>
                    <br></br>
                    <button
                      className="btn_edit"
                      onClick={() => setResizing(!resizing)}
                    >
                      {!resizing ? "Edit" : "Save"}
                    </button>
                  </div>
                </>
              )}
              {pos && resizing && Object.keys(pos).length > 0 && <div className={classes.topItem}>
                  <label>{"Add Object"}</label>
                  <select
                    onChange={(e) => {
                      setAddObject(e.target.value);
                    }}
                  >
                    {/* <option>
                      {"Select Object"}
                    </option> */}
                    <option value={"lift"}>Lift</option>
                    <option value={"stair"}>Stair</option>
                    <option value={"corridor"}>Corridor</option>
                    <option value={"washroom"}>Washroom</option>
                    <option value={"openSpace"}>Open Space</option>
                  </select>
                </div>}
              {pos && resizing && Object.keys(pos).length > 0 && (
                <>
                  <div className={classes.topItem}>
                    <br></br>
                    <button
                      className="btn_edit"
                      onClick={() => addFloorItem(addObject)}
                    >{`Add Object`}</button>
                  </div>
                </>
              )}
              {/* {pos && resizing && Object.keys(pos).length > 0 && (
                <>
                  <div className={classes.topItem}>
                    <br></br>
                    <button
                      className="btn_edit"
                      onClick={() => addFloorItem("stair")}
                    >{`Add Stairway`}</button>
                  </div>
                </>
              )} */}
            </div>
            {pos && Object.keys(pos).length > 0 && (
              <div className={classes.top}>
                <div className={classes.topItem}>
                  <label>{!bangla ? "Search" : "অনুসন্ধান"}</label>
                  <input onChange={(e) => setSearch(e.target.value)}></input>
                </div>
              </div>
            )}

            <br></br>

            {pos && (
              <div
                style={{
                  overflow: "auto",
                  width: "72.5vw",
                  height: "500px",
                  border: "1px solid #C5C5C5",
                  marginLeft: "5rem",
                  borderRadius: "0.8rem",
                }}
              >
                <div
                  className={classes.mainView}
                  style={{ position: "relative" }}
                >
                  <>
                    {Object.keys(pos).map((p) => {
                      return (
                        <div>
                          <Rnd
                            onClick={() => {
                              if (
                                !resizing &&
                                pos[p].shopName != "stair" &&
                                pos[p].shopName != "lift" &&
                                pos[p].shopName != "washroom" &&
                                pos[p].shopName != "corridor" &&
                                pos[p].shopName != "openSpace"
                              ) {
                                navigate(
                                  `/properties/${currentProp}/floors/${currentFloor}/shops/${pos[p].id}`
                                );
                              }
                            }}
                            className={`${classes.shop} ${
                              search != "" &&
                              search != undefined &&
                              pos[p].shopNo == search &&
                              classes.green
                            }`}
                            disableDragging={!resizing}
                            enableResizing={resizing}
                            dragGrid={[10, 10]}
                            resizeGrid={[10, 10]}
                            bounds={`.${classes.mainView}`}
                            position={{ x: pos[p].x, y: pos[p].y }}
                            default={{
                              x: pos[p].x,
                              y: pos[p].y,
                              width: pos[p].width,
                              height: pos[p].height,
                            }}
                            minWidth={50}
                            minHeight={50}
                            onDragStop={(e, d) => {
                              const obj = {};
                              const subObj = pos[p];

                              subObj["x"] = d.x;
                              subObj["y"] = d.y;
                              obj[p] = subObj;
                              console.log(obj);
                              console.log(pos[p].width);
                              updateShopFloorData(
                                pos[p].id,
                                d.x,
                                d.y,
                                pos[p].width,
                                pos[p].height,
                                pos[p].shopName
                              );
                              setPos((prev) => {
                                return { ...prev, ...obj };
                              });
                            }}
                            onResizeStop={(
                              e,
                              direction,
                              ref,
                              delta,
                              position
                            ) => {
                              const obj = {};
                              const subObj = pos[p];

                              subObj["width"] = ref.style.width;
                              subObj["height"] = ref.style.height;
                              obj[p] = { ...subObj, ...position };
                              console.log(obj);

                              updateShopFloorData(
                                pos[p].id,
                                pos[p].x,
                                pos[p].y,
                                ref.style.width,
                                ref.style.height,
                                pos[p].shopName
                              );
                              setPos((prev) => {
                                return { ...prev, ...obj };
                              });
                            }}
                          >
                            {/* {(pos[p].shopName=="lift" || pos[p].shopName=="stair") && resizing && <div onClick={()=>deleteItem(pos[p].id)} style={{position: "absolute", right: "0.1rem", top: "0.1rem", borderRadius: "1rem", color: "white", backgroundColor: "red" , padding: "0.005rem 0.3rem 0.005rem 0.3rem", cursor: "pointer"}}>X</div>} */}
                            
                            <div className={classes.shopDet}>
                              {pos[p].shopName != "lift" &&
                                pos[p].shopName != "stair" &&
                                pos[p].shopName != "washroom" &&
                                pos[p].shopName != "corridor" &&
                                pos[p].shopName != "openSpace" &&
                                `#${pos[p].shopNo}`}
                                {pos[p].shopName == "openSpace" && <div>Open Space</div>
                                // <img
                                //   style={{ height: "3rem", opacity: "0.4" }}
                                //   src={openSpaceIcon}
                                // ></img>
                                
                              }
                              <br></br>
                              
                              {pos[p].shopName != "lift" &&
                                pos[p].shopName != "stair" &&
                                pos[p].shopName != "washroom" &&
                                pos[p].shopName != "corridor" &&
                                pos[p].shopName != "openSpace" &&
                                `Block: ${pos[p].block}`}
                              <br></br>
                              {pos[p].shopName == "lift" && (
                                <img
                                  style={{ height: "3rem" }}
                                  src={liftIcon}
                                ></img>
                              )}
                              {pos[p].shopName == "stair" && (
                                <img
                                  style={{ height: "3rem" }}
                                  src={stairIcon}
                                ></img>
                              )}
                              {pos[p].shopName == "corridor" && (
                                <img
                                  style={{ height: "3rem" }}
                                  src={corridorIcon}
                                ></img>
                              )}
                              {pos[p].shopName == "washroom" && (
                                <img
                                  style={{ height: "3rem", opacity: "0.4" }}
                                  src={washroomIcon}
                                ></img>
                              )}
                              
                              {pos[p].shopName != "lift" &&
                                pos[p].shopName != "stair" &&
                                pos[p].shopName != "washroom" &&
                                pos[p].shopName != "corridor" &&
                                pos[p].shopName != "openSpace"&&
                                `${pos[p].shopName}`}

                              {(pos[p].shopName == "lift" ||
                                pos[p].shopName == "stair" ||
                                pos[p].shopName == "washroom" ||
                                pos[p].shopName == "corridor" ||
                                pos[p].shopName == "openSpace") &&
                                resizing &&

                                <button className={`${classes.dltIcon}`} onClick={()=>deleteFloorObject(pos[p].id)}><img src={deleteIcon}></img></button>}
                            </div>
                          </Rnd>
                        </div>
                      );
                    })}
                  </>

                  {/* <ResizableBox width={200} height={200}>
                        <div
                        style={{ backgroundColor: 'green', width: '100%', height: '100%' }}
                        >
                        123
                        </div>
                    </ResizableBox> */}
                </div>
              </div>
            )}
            <br></br>
            <br></br>
            <br></br>
          </>
        }
      />
    </>
  );
}
