import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { doc, getDoc, getDocs, collection, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import DropDownV2 from "../DropDownV2";

export default function UserDetails() {
    const navigate = useNavigate();
    const {userId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [ownUser, setOwnUser] = useState(false);
    const { user, logout, rolee } = UserAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [roles, setRoles] = useState({});
    const [propAccObj, setPropAccObj] = useState({});

    const {allProperties, allFloors} = UserAuth();

    const getAllRoles = async() => {
    
      try{
        const obj = {};
        const docref= await getDocs(collection(db,"Roles"));
        docref.forEach((doc)=>{
          console.log(doc.id);
          
          obj[doc.id] = doc.id;
          
        })
        setRoles(obj);
      }
      catch(err){
        console.log(err);
      }
    }
  
    useEffect(()=>{
      getAllRoles();
    },[])

    const handleDelete = async() => {
        await deleteDoc(doc(db, "Users", data.id)).then(() => {
            console.log("Entire Document has been deleted successfully.")
            navigate('/users');
        })
        .catch(error => {
            console.log(error);
        });
            
        
    }

    const handlePropertyAccess = (title, arr) => {
      const newPropAccObj = propAccObj;
      newPropAccObj[title] = arr;
      setPropAccObj({ ...newPropAccObj });
    };

    const handleOnChange=(key, x)=>{

      const obj={...data}
      obj[key]=x.target.value;
      setData({...obj});
      console.log(data);
    }

    const handleEdit = async() =>{
      setIsEditing(!isEditing);
      
      await updateDoc(doc(db, "Users", data.id),{
        Name: data.UserName,
        Role: data.Role,
        Designation: data.Designation,
        Email: data.Email,
        Status: data.Status,
        Mobile: data.Mobile,
        PropertyAccess: data.PropertyAccess
      })
      .catch(error => {
          console.log(error);
      });
    }

    const getUserDetails = async(event)=>{
        console.log(user.id);
        try{
            const querySnapshot = await getDoc(doc(db, "Users", userId));
            setData({id: querySnapshot.id, UserName: querySnapshot.data().Name, Role: querySnapshot.data().Role, Designation: querySnapshot.data().Designation, Email: querySnapshot.data().Email, Status: querySnapshot.data().Status, Mobile: querySnapshot.data().Mobile, dp: querySnapshot.data().dp, PropertyAccess: querySnapshot.data().PropertyAccess})
            console.log(querySnapshot.data());
            console.log(querySnapshot.id);
            if(querySnapshot.id == user.id){
              setOwnUser(true);
            }
        }
        catch(err){
            console.log(err);
        }
    }

useEffect(()=>{
    getUserDetails();
},[])

  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };

  useEffect(() => {
    setData((prevData) => {
      return { ...prevData, PropertyAccess: propAccObj };
    });
    console.log(data);
  }, [propAccObj]);

  

  return (
    <>
      <PageTemp
        title={data.UserName}
        img={data.dp}
        subTitle={"User Details"}
        page={
          <>
            <form action="">

            <div className={classes.formGroup}>
                <div className={classes.names}>
                    <label for="">Name</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("UserName",e)} defaultValue={data.UserName}></input>}
                    <label for="">Designation</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Designation",e)} defaultValue={data.Designation}></input>}
                    <label for="">Email</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Email",e)} defaultValue={data.Email}></input>}
                    <label for="">Mobile</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Mobile",e)} defaultValue={data.Mobile}></input>}
                    {rolee && rolee.statusControl && <label for="">Status</label>}
                    {isEditing && rolee &&  rolee.statusControl && <select onChange={e=>setData({...data, Status: e.target.value})} defaultValue={data.Status}>
                        <option val="Active">Active</option>
                        <option val="Inactive">Inactive</option>
                    </select>}
                    
                    
                    {isEditing && data && <DropDownV2
                    
                handleData={handlePropertyAccess}
                filter={data}
              ></DropDownV2>}
              {isEditing && <br></br>}
              {isEditing && <label for="">{data.PropertyAccess && Object.keys(data.PropertyAccess).map(prop=>{
                      return <p>{data.PropertyAccess[prop].length>0 && `${allProperties[prop]} :`} {data.PropertyAccess[prop].map(floor=>{
                        return <>{data.PropertyAccess[prop].length>0 && `${allFloors[floor]} ,`}</>
                      })} </p>
                    })} </label>}
                    {isEditing && <br></br>}
                    <label for="">Role</label>
                    {isEditing && <select onChange={(e) => setData({ ...data, Role: e.target.value })} defaultValue={data.Role}>
                    {
                  Object.keys(roles).map(roleeee => <>
                    <option value={roleeee}>{roleeee}</option>
                  </>)
                }
                    </select>}
                    {!isEditing && <label for="">Property Access</label>}
                    
                    
                </div>
                <div className={classes.values}>
                    {!isEditing && <label for="">{data.UserName}</label>}
                    {!isEditing && <label for="">{data.Designation}</label>}
                    {!isEditing && <label for="">{data.Email?.length>20 ? `${data.Email?.substring(0,20) || ''}...` : data.Email}</label>}
                    {!isEditing && <label for="">{data.Mobile}</label>}
                    {!isEditing &&  <label for="">{data.Status}</label>}
                    {!isEditing && rolee && rolee.statusControl && <label for="">{data.Role}</label>}
                    {!isEditing && <label for="">{data.PropertyAccess && Object.keys(data.PropertyAccess).map(prop=>{
                      return <p>{data.PropertyAccess[prop].length>0 && `${allProperties[prop]} :`} {data.PropertyAccess[prop].map(floor=>{
                        return <>{data.PropertyAccess[prop].length>0 && `${allFloors[floor]} ,`}</>
                      })} </p>
                    })} </label>}
                </div>
            </div>
            <br></br>
            <div className="detailButtons">
              <button className="btn_edit" type="button" onClick={handleEdit}>{!isEditing ? "Edit" : "Save"}</button>
              {!ownUser && <button className="btn_del" type="button" onClick={handleDelete}>Delete</button>}
            </div>
            
            
            </form>
          </>
        }
      />
    </>
  );
}
