import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableSubheader from "../TableSubheader";
import TableRow from "../TableRow";
import TableTop from "../TableTop";

export default function RentStatus(){
    return(
        <>
            <PageTemp title="Rent Status" subTitle="Report View" page={
                <>
                    <TableTop dPropertyAndFloors={true} genRep={true}/>
                    <Table>
                        <TableHeader headings={["Shop Name", "Rent Status"]}/>
                        <TableSubheader property={"Building1"} floor={"Floor1"}>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                        </TableSubheader>
                        <TableSubheader property={"Building1"} floor={"Floor1"}>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                            <TableRow info={["#239","Amin Jewellers"]} status="Paid"/>
                        </TableSubheader>
                    </Table>
                </>
            }/>
        </>
    );
}