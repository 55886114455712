import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Login from "./pages/Login";

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth(null);



  if (!user) {
    return <Navigate to="/login" />;
  }
  else{
    return children;
  }
  
  
};

export default ProtectedRoute;
