import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";

import submitIcon from "../../assets/icons/Save.svg";
import uploadIcon from "../../assets/icons/Upload.svg";
import classes3 from "../../styles/Table.module.css";
import { useState } from "react";
import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect } from "react";

export default function PendingOtherComplain() {
  //use this state to update
  const [data, setData] = useState({ files: {} });
  const { user, bangla, rolee } = UserAuth();
  const navigate = useNavigate();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rolee && !rolee.otherComplain) {
      navigate("/");
    }
  }, [rolee]);

  //submit button
  const handleSubmit = async () => {
    try {
      const preDocRef = collection(db, "Requests");
      const preRef = doc(preDocRef);
      const id = preRef.id;
      file.map((f) => {
        uploadFile(f, id);
      });
      console.log(data.files);
      const docRef = await setDoc(preRef, {
        ...data,
        RequestTitle: "Other Complain",
        RequestedBy: user.Name,
      });
      const log = await addDoc(collection(db, "Logs"), {
        CreatedAt: serverTimestamp(),
        Message: `${user.Name} has filed a complain`,
      });
      navigate(`/`);
    } catch (err) {
      console.log(err);
    }
  };

  const [file, setFile] = useState([]);

  const uploadFile = (f, id) => {
    if (!file) {
      // navigate(`/properties/${id}`);
      return;
    }
    const storageRef = ref(storage, `/complains/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          console.log(id);
          handleFile(f.name, url);

          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Requests", id), {
      files: { ...data.files },
    });
    navigate(`/`);
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <PageTemp
        title="Other Complain"
        subTitle="Please enter the following details"
        page={
          <>
            <form action="">
              <label className={classes.upFileButton} htmlFor="fileUpload">
                <img src={uploadIcon} />
                {!bangla ? "Upload" : "আপলোড করুন"}
                <input
                  multiple
                  onChange={fileHandler}
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                />
              </label>
              <label>{!bangla ? "Complain" : "অভিযোগ"}</label>
              <textarea
                onChange={(e) => setData({ ...data, Complain: e.target.value })}
                placeholder={!bangla ? "Enter Complain" : "অভিযোগ  লিখুন"}
              ></textarea>
              <br />
              <button
                type="button"
                onClick={handleSubmit}
                className={`${classes.submit} ${classes.red}`}
              >
                <img src={submitIcon} />
                {!bangla ? "Submit" : "জমা দিন"}
              </button>
            </form>
          </>
        }
      />
    </>
  );
}
