import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableSubheader from "../TableSubheader";
import TableRow from "../TableRow";
import TableTop from "../TableTop";
import { useState } from "react";
import { collection, getDocs, where, query, orderBy, getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import classes from "../../styles/FloorView.module.css";


export default function ViewEntries(){

    const {propertyId, floorId, shopId} = useParams();
    const {rolee} = UserAuth();
    
    const navigate = useNavigate();
    const [floors, setFloors] = useState([]);
    const [properties, setProperties] = useState([]);

    const [type, setType] = useState("Rent");

    // const fetchShops = async()=>{
    //     const obj={};
    //     try{
    //         let querySnapshot;
    //         querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), orderBy("FloorNo")));
    //         // if(propertyId=="allProperties" && floorId=="allFloors"){
    //         //     querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), orderBy("FloorNo")));
    //         // }
    //         // else if(propertyId!="allProperties" && floorId=="allFloors"){
    //         //     querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), where("PropertyId","==",propertyId)));
    //         // }
    //         // else{
    //         //     querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), where("PropertyId","==",propertyId), where("floorId","==",floorId)));
    //         // }
            
    //         console.log(`${propertyId} ${floorId}`)
    //         let arr=[];
    //         querySnapshot.forEach(doc=>{
    //             if(arr.length!=0 && arr[0].Floor!=doc.data().FloorNo){
    //                 arr=[];
    //             }
    //             if(arr.length!=0 && arr[0].Property!=doc.data().PropertyName){
    //                 arr=[];
    //             }
    //             const subObj={}
    //             subObj["Property"]=doc.data().PropertyName;
    //             subObj["Floor"]=doc.data().FloorNo;
                
    //             let y;
    //             if(doc.data().Entries.Rent[filter.Month]!=undefined){
    //                 if(doc.data().Entries.Rent[filter.Month].Status=="Full"){
    //                     y="Paid";
    //                 }
    //                 else{
    //                     y="Partially Paid"
    //                 }
    //             }
    //             else{
    //                 y =("Not paid")
    //             }
    //             console.log(y);
    //             arr.push({
                    
    //                 BusinessName: doc.data().BusinessName,
    //                 id: doc.id,
    //                 ShopNo: doc.data().ShopNo,
    //                 Status: y,
    //                 Month: doc.data().LastPaid,
    //                 Floor: doc.data().FloorNo,
    //                 Property: doc.data().PropertyName
    //             })
    //             console.log(arr);
    //             subObj["Shops"]=arr;
    //             obj[`${doc.data().PropertyName} ${doc.data().FloorNo}`]=subObj;
    //         })
    //         setData(obj);
    //         console.log(obj);
    //     }
    //     catch(err){
    //         console.log(err);
    //     }

    // }

    const fetchEntries = async() => {
        const docRef = await getDoc(doc(db, "Shops", shopId));
        const arr=[];
        Object.keys(docRef.data().Entries[type]).map(m=>{
            const obj={};
            obj[m]=docRef.data().Entries.Rent[m];
            arr.push(obj);
        })
        arr.sort((a,b)=>{
            if(Object.keys(a)[0] > Object.keys(b)[0]){
                return 1;
            }
            else if(Object.keys(a)[0] < Object.keys(b)[0])
            {
                return -1;
            }
            return 0;
        });
        const obj2={}
        arr.map(m=>{
            obj2[Object.keys(m)[0]]=m[Object.keys(m)[0]];
        })
        setData(obj2);
        // setData(docRef.data().Entries["Rent"]);
    }  

    
    

    
    

    //read filter from this state
    const [filter, setFilter] = useState(
        {
            Property: "All Properties",
            Floor: "All Floors",
            Block: "All Blocks",
            Search: ""
        }
    )

    // useEffect(()=>{
    //     fetchShop();
    //     if(filter.Property=="All Properties"){

    //     }
    //     else if(filter.Property==undefined){
    //         navigate(`/properties/allProperties/floors/allFloors/shops`)
    //     }
    //     else{
    //         navigate(`/properties/${filter.Property}/floors/allFloors/shops`)
    //     }

    //     console.log(filter);
    // },[filter])




    useEffect(()=>{
        fetchEntries();
        // console.log(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
    },[]);

    
    //update this state from database
    const [data, setData] = useState(
        {
            // "Ibs Floor2":{
            //     Property: "Ibs",
            //     Floor: "2",
            //     Shops: [
            //         {
            //             BusinessName: "Amin Jewellers",
            //             ShopNo: "239",
            //             Status: "Paid",
            //             Month: "October"
            //         },
            //         {
            //             BusinessName: "Mashallah Jewellers",
            //             ShopNo: "239",
            //             Status: "Paid",
            //             Month: "October"
            //         },
            //     ]
            // },
            // "Wallahi Floor1":{
            //     Property: "Wallahi",
            //     Floor: "1",
            //     Shops: [
            //         {
            //             BusinessName: "Amin Jewellers",
            //             ShopNo: "239",
            //             Status: "Paid",
            //             Month: "October"
            //         },
            //         {
            //             BusinessName: "Mashallah Jewellers",
            //             ShopNo: "239",
            //             Status: "Paid",
            //             Month: "October"
            //         },
            //     ]
            // }
            
        }
        );
        


        useEffect(()=>{
            console.log(data);
        },[data])

        useEffect(()=>{
            fetchEntries();
        },[type])


    const handleFilter = (data) => {
        setFilter
        (
            data
        );
        // fetchFloors();
    }


    const handleRowClick=(id)=>{
        navigate(`/properties/${propertyId}/floors/${floorId}/shops/${shopId}/history/${id}/${type}`);
    }

    return(
        <>
            <PageTemp  title="View Entries" subTitle="View all entries here" page={
                <>
                    <form>
                    {/* <TableTop defaultProp={propertyId} filter={filter} handleFilter={handleFilter} /> */}
                        {floors && (
                    <div className={classes.topItem}>
                        <label>Type</label>
                        <select
                        onChange={(e) => {
                            setType(e.target.value);
                            
                        }}
                        >
                        <option value="Rent">Rent</option>
                        <option value="Utility">Utility</option>
                        <option value="ServiceCharge">Service Charge</option>
                        
                        </select>
                    </div>
                    
                    )}
                    </form>
                    <br></br>
                    <Table>
                        <TableHeader headings={["Entry Month", "Status", `${rolee && rolee.fRentCollected==true ? "Amount" : ""}`, `${rolee && rolee.fRent==true ? "Due" : ""}`]}/>
                        {Object.keys(data).map((key,index)=>{
                            return (<TableRow onClick={handleRowClick} id={key} info={["",key]} status={data[key].Status} normalDetails={[`${rolee && rolee.fRentCollected==true ? data[key].Amount : ""}`, `${rolee && rolee.fRent==true ? data[key].Due : ""}`]}/>)
                        })}
                        {

                            // data && Object.keys(data).map((building)=>{
                            //     return <TableSubheader property={`${data[building].Property.substring(0,10)}...`} floor={`Floor ${data[building].Floor}`}>
                            //         {
                            //             data[building].Shops && data[building].Shops.length>0 && data[building].Shops.map((shop)=>{
                            //                 return(<TableRow onClick={handleRowClick} id={`${shop.id}`} info={[`#${shop.ShopNo}`,shop.BusinessName]} status={shop.Status} normalDetails={[shop.Month]}/>); 
                            //             })
                            //         }
                            //     </TableSubheader>
                            // })
                        }

                        {/* {data.map((d)=>{
                            return(
                                <TableSubheader property={d.PropertyName} floor={d.Floor}>
                                    {d.Shops.map((shop)=>{
                                        return(<TableRow info={[shop.ShopNo,shop.ShopName]} status={shop.Status} normalDetails={[shop.Month]}/>);
                                    })}
                                </TableSubheader>
                            );
                        })} */}

                        
                    </Table>
                </>
            }/>
        </>
    );
}