import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import classes from "../styles/TableTop.module.css";
import dropIcon from '../assets/icons/drop.svg'


export default function SubDropDownV2({ title, id, floors, handleData, filter }) {
  const [toggled, setToggled] = useState(false);
  //   const [selectAll, setSelectAll] = useState(false);

  const [propertiesNfloors, setPropertiesNfloors] = useState({
    Property: "",
    Floors: [],
  });

  const {allProperties} = UserAuth();

  const [data, setData] = useState();

  const selectAll = (check) => {
    setData((prevState) => {
      const newState = { ...prevState };
      Object.keys(floors).forEach((el) => {
        newState[floors[el]] = check;
      });
      return newState;
    });
  };

  useEffect(() => {
    if(data!=undefined){
      const arr = [];
      Object.keys(data).forEach((el) => {
        if (data[el] == true) {
          arr.push(el);
        }
      });
      handleData(title, arr);
    }
    
  }, [data]);

  const [allCheck, setAllCheck] = useState();

  useEffect(()=>{
    const floorsObj = {};
    let allCheck = true;
    Object.keys(floors).forEach((element) => {
      if(filter && filter.PropertyAccess[title] && filter.PropertyAccess[title].includes(floors[element])){
        floorsObj[floors[element]] = true;
        
      }
      else{
        floorsObj[floors[element]] = false;
        allCheck=false;
      }

    });

    setData(floorsObj);
    setAllCheck(allCheck);
    
  },[])

  return (
    <>
      {allCheck!=undefined && <li style={{display: "flex"}} key={"allChecked"}>
        <input onChange={(e) => selectAll(e.target.checked)} type="checkbox" defaultChecked={allCheck}/>
        <span style={{marginRight: "1rem"}} onClick={() => setToggled(!toggled)}>{id}</span>
        
        <img className={`${classes.dropIcon} ${classes.icon}`} src={dropIcon}/>
      </li>}
      {toggled && data &&
        Object.keys(floors).map((floor) => {
          return (
            <li key={floors[floor]}>
              <input
                onChange={(e) => {
                  setData({ ...data, [floors[floor]]: e.target.checked });
                }}
                checked={data[floors[floor]]}
                className={classes.subCheck}
                type="checkbox"
              />
              {floor}{" "}
            </li>
          );
        })}
    </>
  );
}
