import PageTemp from "../PageTemp";
import EntryForm from "../EntryForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { doc, getDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";


export default function EntryDetails() {
    const today = new Date();

    const { user } = UserAuth();
    const navigate = useNavigate();
    const {propertyId, floorId, shopId, month, type} = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [isEditing, setIsEditing] = useState(false);

    const handleDelete = async() => {
        await deleteDoc(doc(db, `Floors`, floorId)).then(() => {
            console.log("Entire Document has been deleted successfully.")
            navigate(`/properties/${propertyId}/floors/`);
        })
        .catch(error => {
            console.log(error);
        });
    }

    

    const handleEdit = async() =>{
      setIsEditing(!isEditing);
      
      await updateDoc(doc(db, "Shops", data.id),{
        Area: data.Area,
      })
      .catch(error => {
          console.log(error);
      });
      getEntryDetails();
    }

    const getEntryDetails = async(event)=>{
            
        try{
            const querySnapshot = await getDoc(doc(db, `Shops`, shopId));
            console.log(querySnapshot.data().BusinessName);
            let bn="N/A";
            let tn;
            if(querySnapshot.data().TenantName!=undefined){
              tn=querySnapshot.data().TenantName
            }
            else{
              tn="N/A";
            }
            
            
            
            console.log(bn);
            setData({...querySnapshot.data().Entries[type][month], PropertyName: querySnapshot.data().PropertyName, floorId: querySnapshot.data().FloorNo, shopNo: querySnapshot.data().ShopNo, TenantType: querySnapshot.data().TenantType});
            setIsLoading(false);
            
        }
        catch(err){
            console.log(err);
            setIsLoading(false);
        }
    }

    const handleOnChange=(key, x)=>{

      const obj={...data}
      obj[key]=x.target.value;
      setData({...obj});
      console.log(data);
    }

useEffect(()=>{
    getEntryDetails();
},[])

  const [data, setData] = useState({});

  const handleData = (d) => {
    setData(d);
  };


  useEffect(()=>{
    if(Object.keys(data).length!=0){
      console.log(data);
      window.print();

    }
  },[data]);

  function convertDateFormat(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const [year, month] = dateString.split("-").map(Number);
    const monthName = months[month - 1];
    return `${monthName}, ${year}`;
  }
  

  return (
    
    <>
      <PageTemp
        title={`${month}`}
        subTitle={!isLoading && "Floor Details"}
        page={
          isLoading ? 
                <>
                    <div style={{marginLeft: "2rem"}} className={classes2.loader}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </>
                :
          <>
            
            <form action="">
            <div style={{textAlign: "center", fontSize: "20px", fontWeight: "bold"}} className="onlyPrint">Rent Milloh</div>
            {/* <br></br> */}
            <div style={{textAlign: "center", fontSize: "16px"}} className="onlyPrint">{`${data.PropertyName} `}</div>
            {/* <br></br> */}
            <div style={{textAlign: "center", fontSize: "16px"}} className="onlyPrint">{data.EntryKind ? `(${data.EntryKind})` : "" }</div>
            <br></br>
            <div style={{textAlign: "center", fontSize: "16px",}} className="onlyPrint">{convertDateFormat(month)}</div>
            <br></br>
            
            
            <div className="onlyPrint">--------------------------</div>
            
            <div className={classes.formGroup}>
                <div className={classes.names}>

                  
                  {/* <label  className="posPrint" htmlFor="">Property</label> */}
                  <label  className="posPrint" htmlFor="">Floor</label>
                  <label  className="posPrint" htmlFor="">Unit No</label>
                  <label  className="posPrint" htmlFor="">Business</label>
                  <label  className="posPrint" htmlFor="">Name</label>
                  {/* <label  className="posPrint" htmlFor="">Type</label> */}
                    <label className="posPrint" htmlFor="">Collected</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Area",e)} defaultValue={data.Amount}></input>}
                    <label className="posPrint" htmlFor="">Status</label>
                    <label className="posPrint" htmlFor="">Due</label>
                    {isEditing && <input onChange={(e)=>handleOnChange("Area",e)} defaultValue={data.Due}></input>}
                    <label className="posPrint" htmlFor="">Collector</label>
                    {/* <br></br> */}
                    
                    {/* <label className="posPrint" htmlFor="">Date</label> */}
                    
                </div>
                {isEditing==false ? <div className={classes.values}>
                    
                    
                    <label className="posPrint" htmlFor="">{data.floorId}</label>
                    <label className="posPrint" htmlFor="">{data.shopNo}</label>
                    <label className="posPrint" htmlFor="">{data.BusinessName}</label>
                    <label className="posPrint" htmlFor="">{data.TenantName ? data.TenantName : "N/A"}</label>
                    {/* <label className="posPrint" htmlFor="">{data.TenantType}</label> */}
                    <label className="posPrint" htmlFor="">{data.Amount}</label>
                    <label className="posPrint" htmlFor="">{data.Status}</label>
                    <label className="posPrint" htmlFor="">{data.Due}</label>
                    <label className="posPrint" htmlFor="">{data.CollectedBy}</label>
                    {/* <label className="posPrint" htmlFor="">{data.EntriedOn}</label> */}
                    
                </div>
                :
                <>
                
                </>}
                
            </div>
            {/* <button style={{marginBottom: "16px"}} className="dontPrintButton" onClick={()=>{window.print()}}>Print</button> */}
            <br></br>
            <div>
              {/* <button className="btn_edit" type="button" onClick={handleEdit}>{!isEditing ? "Edit" : "Save"}</button>
              <button className="btn_del" type="button" onClick={handleDelete}>Delete</button> */}
            
            </div>
            <div className="onlyPrint">--------------------------</div>
            <br></br>
            <div style={{textAlign: "center"}} className="posPrint onlyPrint">Thank you!</div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="onlyPrint">__________</div>
            <div style={{textAlign: "left", fontSize: "16px",}} className="onlyPrint">{today.toLocaleDateString()}</div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div>.</div>
            </form>
          </>
        }
      />
    </>
  );
}
