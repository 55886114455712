import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase";

export default function Morich(){
    const [morichState, setMorichState]=useState("");
    const {user} = UserAuth();
    const navigate = useNavigate();

    const getMorich = async () => {
        try{
            const docRef = await getDoc(doc(db,"Logs","State"));
            if(docRef.data().State==true){
                setMorichState("active");
            }
            else{
                setMorichState("");
            }
        }
        catch(err){
            console.log(err);
        }
    }

    const handleClick = () => {
        if(morichState==""){
            setMorichState("active");
            updateDoc(doc(db,"Logs","State"),{
                State: true
            })
        }
        else{
            setMorichState("");
            updateDoc(doc(db,"Logs","State"),{
                State: false
            })
        }
        getMorich();
        
    }

    useEffect(()=>{
        getMorich();
        
    },[morichState])

    useEffect(()=>{
        if(user.Role!="Admin"){
            navigate("/");
        }
        
    },[])

    return(
        <>
        <div style={{height: "100vh", display:"flex", justifyContent:"center"}}>
            <div style={{margin: "auto"}} className={`toggle ${morichState}`} onClick={handleClick}></div>
        </div>
        
        </>
    );
}