import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css"

import submitIcon from "../../assets/icons/Save.svg"
import uploadIcon from "../../assets/icons/Upload.svg"
import { useState } from "react";
import { doc, addDoc, collection, serverTimestamp, setDoc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect } from "react";

export default function OtherComplain(){

    //use this state to update
    const [data, setData] = useState({files:{}})
    const {user, rolee} = UserAuth();
    const navigate = useNavigate();
    const {reqId} = useParams();
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);

    //submit button
    const handleSubmit = async () => {
        try{
            await deleteDoc(doc(db, "Requests", reqId));
            const docRef = await setDoc(doc(db,"Complains",reqId),{...data,
                RequestTitle: "Other Complain",
                RequestedBy: data.RequestedBy,
                ApprovedBy: user.Name,
            }).then(navigate('/'));
        }
        catch(err){
            console.log(err);
        }
    }

    const [file, setFile] = useState([]);

    const getComplain = async () => {
        try{
            const docRef = await getDoc(doc(db, "Requests", reqId));
            setData(docRef.data());
        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getComplain();
    },[])
    
    // const uploadFile = (f,id) => {
    //     if(!file){

    //         // navigate(`/properties/${id}`);
    //         return;
    //     };
    //     const storageRef = ref(storage, `/complains/${id}/${f.name}`);
    //     const uploadTask = uploadBytesResumable(storageRef,f);
    //     uploadTask.on("state_changed" , (snapshot)=>{
    //         const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
    //     },
    //     (err)=>console.log(err),
    //     ()=>{
    //         getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
    //             console.log(url);
    //             console.log(id);
    //             handleFile(f.name,url);
                
    //             updateFile(id);
    //         });
    //     }
    //     )
    // }

    // const updateFile = async(id) => {
    //     await updateDoc(doc(db, "Requests", id),{
    //         files: {...data.files}
    //     })
    //     navigate(`/`);
    // }

    // const handleFile = (name, url) => {
    //     const obj = data;
    //     obj.files[name]=url;
    //     setData(obj);
    // }

    // const fileHandler = (e) => {
    //     for (let i=0; i<e.target.files.length; i++){
    //         const newImage = e.target.files[i];
    //         newImage["id"]=Math.random();

    //         setFile(prev => [...prev, newImage]);
    //     }
    // }

    // useEffect(()=>{
    //     console.log(data);
    // },[data])

    return(
        <>
            <PageTemp title="Other Complain" subTitle="Complain details" page={
                <>
                    <form action="">

                        

                        {/* <label className={classes.upFileButton} htmlFor="fileUpload">
                            <img src={uploadIcon} />
                            Upload
                            <input multiple onChange={fileHandler} id="fileUpload" type="file" style={{display: "none"}} />
                        </label> */}
                        <label>Complain</label>
                        <textarea disabled onChange={e=>setData({...data, Complain: e.target.value})} defaultValue={data.Complain} placeholder="Enter Complain"></textarea>
                        <br />
                        {data.files && Object.keys(data.files).map((file)=>{
                        return <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}><a style={{appearance: "none"}} href={data.files[file]} target="_blank" rel="noopener"> <button type="button" className="btn_downloadFile" htmlFor="">{`Download ${file}`}</button></a>
                        
                        </div>

                      })}
                      <br></br>

                        {rolee && rolee.receiveOtherComplain && <button type="button" onClick={handleSubmit} className={`${classes.submit}`}><img src={submitIcon} />Resolved</button >}
                    </form>
                </>
            }/>
        </>
    );
}