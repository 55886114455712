import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import submitIcon from "../../assets/icons/Save.svg";
import uploadIcon from "../../assets/icons/Upload.svg";
import classes2 from "../../styles/Table.module.css";
import FormInput from "../FormInput";
import { useState } from "react";

import {
  collection,
  doc,
  getDoc,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function AddFloor() {
  const { bangla, rolee } = UserAuth();

  const [data, setData] = useState({ Area: "" });
  const [property, setProperty] = useState([]);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const { propertyId } = useParams();

  useEffect(() => {
    if (rolee && !rolee.addFloor) {
      navigate("/");
    }
  }, [rolee]);

  useEffect(() => {
    fetchProperty();
  }, []);

//   useEffect(() => {
//     console.log(data);
//   }, [data]);

  const navigate = useNavigate();

  const handleData = (d) => {
    console.log(d);
    // console.log();
    setData(d);
  };

//   useEffect(()=>{
//     console.log(data);
//   },[data])

  const fetchProperty = async () => {
    try {
      const querySnapshot = await getDoc(doc(db, "Properties", propertyId));
      setProperty(querySnapshot.data().PropertyName);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleOnChange = (e) => {
  //     const propertyId=properties.findIndex((x)=>{
  //         return x.name==e
  //     })
  //     setData((prev)=>({...prev, propertyId: properties[propertyId].id}));
  // }

  const [floors, setFloors] = useState();

  const getFloors = async () => {
    const docRef = await getDocs(
      query(collection(db, "Floors"), where("PropertyId", "==", propertyId))
    );
    const arr = [];
    docRef.forEach((doc) => {
      arr.push(doc.data().floorNo);
    });
    setFloors(arr);
    
  };

  useEffect(() => {
    getFloors();
  }, []);
  
  useEffect(()=>{
    console.log(floors);
  },[floors])

  useEffect(()=>{
    console.log(data);
  },[data]);

  const [err, setErr] = useState();

  //Add Floor
  const handleSubmit = async (event) => {
    if (data.Order == "" || data.Order == undefined) {
      setErr("Order cannot be empty");
    }
    // console.log(floors);
    // console.log(floors.includes(parseInt(data.Floor)));
    if (floors.includes(parseInt(data.Order))) {
      setErr("Order already exists");
    } else if (/^\d+$/.test(data.Area) == false && data.Area != undefined) {
      setErr("Area must be in numbers only");
    } else {
      setErr("");
      event.preventDefault();
      try {
        setLoading(true);
        await addDoc(collection(db, "Floors"), {
          PropertyName: property,
          PropertyId: propertyId,
          floorNo: parseInt(data.Order),
          floorName: data.Floor,
          area: data.Area,
        });
        setLoading(false);
        setDone(true);
        setTimeout(() => {
          navigate(`/properties/${propertyId}/floors`);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <PageTemp
        title="Add Floor"
        subTitle={`in ${property}`}
        page={
          <div>
            <form action="">
              {/* <label>Property</label>
                    <select onChange={e=>handleOnChange(e.target.value)} defaultValue={""}>
                        {!data && <option >Select</option>}  
                        {
                            
                            properties.map((key)=>{
                                return <option val={key.name}>{key.name}</option>
                            })
                        }
                        
                        
                        
                    </select> */}
              <FormInput
                data={data}
                handleData={handleData}
                title="Order"
                placeholder="Floor No"
              />
              <FormInput
                data={data}
                handleData={handleData}
                title="Floor"
                placeholder="Floor Name"
              />
              <FormInput
                data={data}
                handleData={handleData}
                title="Area"
                placeholder="Floor Size"
              />
              <br />
              <label className={classes.excess}>{err}</label>
              <>
                {loading && (
                  <div className={classes2.loader}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </>
              {done && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ transform: "scale(0.8)" }}
                    id="completion"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96 101"
                  >
                    <style>
                      {`.st0{fill:#0088C2;fill-opacity:0.4;} .st1{fill:#0088C2;fill-opacity:0.1;} .st2{fill:#0088C2;}`}
                    </style>
                    <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
                    <g id="configurator">
                      <g id="configurator_completion">
                        <g id="stars">
                          <circle id="Oval" class="st0" cx="14" cy="18" r="1" />
                          <circle
                            id="Oval-Copy-4"
                            class="st0"
                            cx="27"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-10"
                            class="st0"
                            cx="76"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-2"
                            class="st0"
                            cx="61.5"
                            cy="12.5"
                            r="1.5"
                          />
                          <circle
                            id="Oval-Copy-9"
                            class="st0"
                            cx="94"
                            cy="53"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-6"
                            class="st0"
                            cx="88"
                            cy="14"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-7"
                            class="st0"
                            cx="59"
                            cy="1"
                            r="1"
                          />
                          <circle
                            id="Oval_1_"
                            class="st0"
                            cx="43"
                            cy="9"
                            r="2"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                          />
                          <circle
                            id="Oval-Copy-5"
                            class="st0"
                            cx="91"
                            cy="40"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-3"
                            class="st0"
                            cx="7"
                            cy="36"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-8"
                            class="st0"
                            cx="7.5"
                            cy="5.5"
                            r=".5"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="cirkel">
                      <g id="Mask">
                        <path
                          id="path-1_1_"
                          class="st1"
                          d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                        />
                      </g>
                    </g>
                    <path
                      id="check"
                      class="st2"
                      d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
                    />
                  </svg>
                  <h1
                    style={{
                      color: "#0088C2",
                      margin: "1.5rem",
                      transform: "translate(0, 0.5rem)",
                    }}
                  >
                    Added
                  </h1>
                </div>
              )}
              {/* <br></br> */}
              {/* <label className={classes.excess}>{err}</label> */}
              <br></br>
              {!done && !loading && (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={classes.submit}
                >
                  <img src={submitIcon} />
                  Add Floor
                </button>
              )}{" "}
              <br />
              <br />
            </form>
          </div>
        }
      />
    </>
  );
}
