import classes from "../styles/NavBar.module.css";
import classes2 from "../styles/EntryForm.module.css";
import closeIcon from "../assets/icons/close-398.svg";
import dashboardIcon from "../assets/icons/dashboard.svg";
import settingsIcon from "../assets/icons/settings.svg";
import logoutIcon from "../assets/icons/logout.svg";
import dp from "../assets/icons/279582667_3277972282448346_1332919313273844657_n.jpg";
import propertiesIcon from "../assets/icons/properties.svg";
import entriesIcon from "../assets/icons/Entries.svg";
import NavItem from "./NavItem";
import usersIcon from "../assets/icons/users.svg";
import requestsIcon from "../assets/icons/Requests.svg";
import reportsIcon from "../assets/icons/Reports.svg";
import financesIcon from "../assets/icons/statistics.svg";
import { Link, Navigate } from "react-router-dom";

import { db, storage } from "../firebase";
import blackDash from "../assets/icons/blackDashboard.svg";
import bEntries from "../assets/icons/bEntries.svg";
import bStats from "../assets/icons/bStatistics.svg";
import bReports from "../assets/icons/bReports.svg";
import bRequests from "../assets/icons/bRequests.svg";
import bProperties from "../assets/icons/bProperties.svg";
import bUsers from "../assets/icons/bUsers.svg";

import NavSubitem from "./NavSubitem";

import DashboardIcon from "@mui/icons-material/Dashboard";
import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { UserAuth } from "../context/AuthContext";
import { useEffect } from "react";

export default function NavBar({ navToggled, setNavToggled }) {
  const { user, rolee, logout, morichState, bangla, handleBangla } = UserAuth();
  const [dp, setDp] = useState(
    "https://monstar-lab.com/global/wp-content/uploads/sites/11/2019/04/male-placeholder-image.jpeg"
  );
  const navigate = useNavigate();

  //account details here
  const [accountDetails, setAccountDetails] = useState({
    Name: user.Name,
    Role: user.Role,
    Designation: user.Designation,
  });

  const getDp = () => {
    // const imgRef = ref(storage, `${user.id}/dp`)
    // getDownloadURL(imgRef).then((url)=>{
    //     setDp(url);
    // })
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
      console.log("You are logged out.");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getDp();
    console.log(user);
    console.log(rolee);
  }, []);

  return (
    <>
      <div
        className={
          navToggled
            ? `${classes.navBar} ${classes.navActive} no-print`
            : `${classes.navBar} no-print`
        }
      >
        <nav>
          <div
            className={
              !navToggled
                ? classes.menuText
                : `${classes.menuText} ${classes.menuActive}`
            }
          >
            <p>
              rent<span className={classes.logo_accent}>milloh</span>
            </p>
          </div>
          <button
            onClick={() => {
              setNavToggled(!navToggled);
            }}
            className={
              navToggled
                ? `${classes.sideButton} ${classes.closeNav} ${classes.closeNavActive}`
                : `${classes.closeNav} ${classes.sideButton}`
            }
          >
            <img className={classes.icon} src={closeIcon} />
          </button>
          <ul>
            <li className={useLocation().pathname == "/" ? classes.active : ""}>
              <Link className={classes.navItem} to="/">
                <img
                  className={classes.icon}
                  src={
                    useLocation().pathname == "/" ? dashboardIcon : blackDash
                  }
                />
                {!bangla ? (
                  "Dashboard"
                ) : (
                  <span className="bangla">{"ড্যাশবোর্ড"}</span>
                )}
              </Link>
            </li>

            <NavItem
              classF={
                useLocation().pathname.includes("/entries")
                  ? classes.active
                  : ""
              }
              title={
                !bangla ? (
                  "Entries"
                ) : (
                  <span className="bangla">{"এন্ট্রিস"}</span>
                )
              }
              icon={
                useLocation().pathname.includes("/entries")
                  ? bEntries
                  : entriesIcon
              }
            >
              {rolee && rolee.rentCollection && (
                <li>
                  <Link to="/entries/rent/add">
                    {!bangla ? (
                      "Rent Collection"
                    ) : (
                      <span className="bangla">{"রেন্ট কালেকশন"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.utilityCollection && (
                <li>
                  <Link to="/entries/utility/add">
                    {!bangla ? (
                      "Utility Collection"
                    ) : (
                      <span className="bangla">{"ইউটিলিটি কালেকশন"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.serviceChargeCollection && (
                <li>
                  <Link to="/entries/serviceCharge/add">
                    {!bangla ? (
                      "Service Charge Collection"
                    ) : (
                      <span className="bangla">{"সার্ভিস চার্জ কালেকশন"}</span>
                    )}
                  </Link>
                </li>
              )}
            </NavItem>

            <NavItem
              classF={
                useLocation().pathname.includes("/properties")
                  ? classes.active
                  : ""
              }
              title={
                !bangla ? (
                  "Properties"
                ) : (
                  <span className="bangla">{"প্রোপারটিস"}</span>
                )
              }
              icon={
                useLocation().pathname.includes("/properties")
                  ? bProperties
                  : propertiesIcon
              }
            >
              {rolee && rolee.addProperty && (
                <li>
                  <Link to="/properties/add">
                    {!bangla ? (
                      "Add Properties"
                    ) : (
                      <span className="bangla">{"অ্যাড প্রোপারটিস"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.viewProperty && (
                <li>
                  <Link to="/properties/">
                    {!bangla ? (
                      "View Properties"
                    ) : (
                      <span className="bangla">{"ভিউ প্রোপারটিস"}</span>
                    )}
                  </Link>
                </li>
              )}
            </NavItem>

            <NavItem
              classF={
                useLocation().pathname.includes("/users") ? classes.active : ""
              }
              title={
                !bangla ? "Users" : <span className="bangla">{"উজার্স"}</span>
              }
              icon={
                useLocation().pathname.includes("/users") ? bUsers : usersIcon
              }
            >
              {rolee && rolee.addUser && (
                <li>
                  <Link to="/users/add">
                    {!bangla ? (
                      "Add User"
                    ) : (
                      <span className="bangla">{"অ্যাড ইউসার"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.editUser && (
                <li>
                  <Link to="/users/">
                    {!bangla ? (
                      "View Users"
                    ) : (
                      <span className="bangla">{"ভিউ উজার্স"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.manageRoles && (
                <li>
                  <Link to="/roles/">
                    {!bangla ? (
                      "Manage Roles"
                    ) : (
                      <span className="bangla">{"মানাগে রোলেস"}</span>
                    )}
                  </Link>
                </li>
              )}
            </NavItem>
            <NavItem
              classF={
                useLocation().pathname.includes("/requests")
                  ? classes.active
                  : ""
              }
              title={
                !bangla ? (
                  "Requests"
                ) : (
                  <span className="bangla">{"রেকুএস্টস"}</span>
                )
              }
              icon={
                useLocation().pathname.includes("/requests")
                  ? bRequests
                  : requestsIcon
              }
            >
              {rolee && rolee.newTenantApplication && (
                <li>
                  <Link to="/requests/newTenant">
                    {!bangla ? (
                      "Move In"
                    ) : (
                      <span className="bangla">{"নিউ টেনান্ট এপ্লিকেশন"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.cancelTenantApplication && <li>
                <Link to="/requests/cancelTenant">{!bangla ? "Move Out" : <span className="bangla">{"নিউ টেনান্ট এপ্লিকেশন"}</span>}</Link>
              </li>}
              {/* {rolee && rolee.changePrimaryTenant && <li>
                <Link to="/requests/changeTenant">{!bangla ? "Change Primary Tenant Application" : <span className="bangla">{"নিউ টেনান্ট এপ্লিকেশন"}</span>}</Link>
              </li>} */}
              {rolee && rolee.changeSecondaryTenant && <li>
                <Link to="/requests/SecondaryTenantRequest">{!bangla ? "Change Secondary Tenant Application" : <span className="bangla">{"নিউ টেনান্ট এপ্লিকেশন"}</span>}</Link>
              </li>}
              {rolee && rolee.changePositionTenant && <li>
                <Link to="/requests/changePositionTenant">{!bangla ? "Change Position Tenant Application" : <span className="bangla">{"নিউ টেনান্ট এপ্লিকেশন"}</span>}</Link>
              </li>}
              
              {/* {rolee && rolee.unitChangeRequest && <li>
                <Link to="/requests/positionTenantRequest">
                {!bangla ? "Position Tenant Request" : <span className="bangla">{"ইউনিট পসিশন চেঞ্জ"}</span>}
                </Link>
              </li>} */}
              {rolee && rolee.tenantComplain && (
                <li>
                  <Link to="/requests/tenantComplain">
                    {!bangla ? (
                      "Tenant Complain"
                    ) : (
                      <span className="bangla">{"টেনান্ট কমপ্লেইন"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.propertyComplain && (
                <li>
                  <Link to="/requests/propertyComplain">
                    {!bangla ? (
                      "Property Complain"
                    ) : (
                      <span className="bangla">{"প্রপার্টি কমপ্লেইন"}</span>
                    )}
                  </Link>
                </li>
              )}
              {rolee && rolee.otherComplain && (
                <li>
                  <Link to="/requests/otherComplain">
                    {!bangla ? (
                      "Other Complain"
                    ) : (
                      <span className="bangla">{"অন্য কমপ্লেইন"}</span>
                    )}
                  </Link>
                </li>
              )}

{rolee && rolee.receiveCancelTenantApplication && (
                <li>
                  <Link to="/UpcomingCancel">
                    {!bangla ? (
                      "Upcoming Cancellations"
                    ) : (
                      <span className="bangla">{"অন্য কমপ্লেইন"}</span>
                    )}
                  </Link>
                </li>
              )}
            </NavItem>
            <NavItem
              classF={
                useLocation().pathname.includes("/reports")
                  ? classes.active
                  : ""
              }
              title={
                !bangla ? (
                  "Reports"
                ) : (
                  <span className="bangla">{"রিপোর্টস"}</span>
                )
              }
              icon={
                useLocation().pathname.includes("/reports")
                  ? bReports
                  : reportsIcon
              }
            >
              {rolee &&
                !morichState &&
                (rolee.rentEntryHistory ||
                  rolee.leaseEnding ||
                  rolee.vacantUnits) && (
                  <>
                    <NavSubitem
                      title={
                        !bangla ? (
                          "Rental Reports"
                        ) : (
                          <span className="bangla">{"ভাড়া রিপোর্ট"}</span>
                        )
                      }
                    >
                      {rolee && rolee.rentEntryHistory && (
                        <li>
                          <Link to="/reports/rentEntryHistory/">
                            Rent Collected
                          </Link>
                        </li>
                      )}
                      {rolee && rolee.leaseEnding && (
                        <li>
                          <Link to="/reports/leaseEnding">Lease Ending</Link>
                        </li>
                      )}
                      {rolee && rolee.vacantUnits && (
                        <li>
                          <Link to="/reports/vacantUnits">Vacant Units</Link>
                        </li>
                      )}
                    </NavSubitem>
                  </>
                )}

              {rolee &&
                !morichState &&
                (rolee.meterReadingHistory ||
                  rolee.utilityBillCollectionStatus) && (
                  <>
                    <NavSubitem
                      title={
                        !bangla ? (
                          "Utility Reports"
                        ) : (
                          <span className="bangla">{"ইউটিলিটি রিপোর্ট"}</span>
                        )
                      }
                    >
                      {rolee && rolee.meterReadingHistory && (
                        <li>
                          <Link to="/reports/meterReadingHistory">
                            Meter Reading History
                          </Link>
                        </li>
                      )}
                      {rolee && rolee.utilityBillCollectionStatus && (
                        <li>
                          <Link to="/reports/utilityBillCollectionStatus">
                            Utility Bill Collection Status
                          </Link>
                        </li>
                      )}
                    </NavSubitem>
                  </>
                )}

              {rolee && !morichState && rolee.serviceChargeStatus && (
                <>
                  <NavSubitem
                    title={
                      !bangla ? (
                        "Service Charge Reports"
                      ) : (
                        <span className="bangla">
                          {"সার্ভিস চার্জ রিপোর্ট"}
                        </span>
                      )
                    }
                  >
                    {rolee && rolee.serviceChargeStatus && (
                      <li>
                        <Link to="/reports/serviceChargeStatus">
                          Service Charge Status
                        </Link>
                      </li>
                    )}
                  </NavSubitem>
                </>
              )}
            </NavItem>

            <NavItem
              classF={
                useLocation().pathname.includes("/finances")
                  ? classes.active
                  : ""
              }
              title={
                !bangla ? (
                  "Finances"
                ) : (
                  <span className="bangla">{"ফিনান্সেস"}</span>
                )
              }
              icon={
                useLocation().pathname.includes("/finances")
                  ? bStats
                  : financesIcon
              }
            >
              {rolee &&
                !morichState &&
                (rolee.fRent || rolee.fUtility || rolee.fServiceCharge) && (
                  <>
                    <NavSubitem
                      title={
                        !bangla ? (
                          "Accounts Receiveable"
                        ) : (
                          <span className="bangla">{"হিসাব গ্রহণযোগ্য"}</span>
                        )
                      }
                    >
                      {rolee && rolee.fRent && (
                        <li>
                          <Link to="/finances/rent">Rent</Link>
                        </li>
                      )}
                      {rolee && rolee.fUtility && (
                        <li>
                          <Link to="/finances/utility">Utility</Link>
                        </li>
                      )}
                      {rolee && rolee.fServiceCharge && (
                        <li>
                          <Link to="/finances/serviceCharge">
                            Service Charge
                          </Link>
                        </li>
                      )}
                    </NavSubitem>
                  </>
                )}

              {rolee &&
                !morichState &&
                (rolee.fRentCollected ||
                  rolee.fUtilityCollected ||
                  rolee.fServiceChargeCollected) && (
                  <>
                    <NavSubitem
                      title={
                        !bangla ? (
                          "Balance Sheet"
                        ) : (
                          <span className="bangla">{"ব্যালেন্স শীট"}</span>
                        )
                      }
                    >
                      {rolee && rolee.fRentCollected && (
                        <li>
                          <Link to="/finances/totalRentCollected">
                            Total Rent Collected
                          </Link>
                        </li>
                      )}
                      {rolee && rolee.fUtilityCollected && (
                        <li>
                          <Link to="/finances/totalUtilityCollected">
                            Total Utility Collected
                          </Link>
                        </li>
                      )}
                      {rolee && rolee.fServiceChargeCollected && (
                        <li>
                          <Link to="/finances/totalServiceChargeCollected">
                            Total Service Charge Collected
                          </Link>
                        </li>
                      )}
                    </NavSubitem>
                  </>
                )}

              {rolee &&
                !morichState &&
                (rolee.fRentStatement ||
                  rolee.fUtilityStatement ||
                  rolee.fServiceChargeStatement) && (
                  <>
                    <NavSubitem
                      title={
                        !bangla ? (
                          "Statements"
                        ) : (
                          <span className="bangla">{"বিবৃতি"}</span>
                        )
                      }
                    >
                      {rolee && rolee.fRentStatement && (
                        <li>
                          <Link to="/finances/rentStatement">
                            Rent Statement
                          </Link>
                        </li>
                      )}
                      {rolee && rolee.fUtilityStatement && (
                        <li>
                          <Link to="/finances/utilityStatement">
                            Utility Statement
                          </Link>
                        </li>
                      )}
                      {rolee && rolee.fServiceChargeStatement && (
                        <li>
                          <Link to="/finances/serviceChargeStatement">
                            Service Charge Statement
                          </Link>
                        </li>
                      )}
                    </NavSubitem>
                  </>
                )}
            </NavItem>
          </ul>
        </nav>
        <div className={classes.account}>
          <div className={classes.act}>
            <img
              className={classes.actPic}
              src={
                user.dp
                  ? user.dp
                  : "https://monstar-lab.com/global/wp-content/uploads/sites/11/2019/04/male-placeholder-image.jpeg"
              }
              alt=""
            />
            <div className={classes.accountDetails}>
              <p>
                {accountDetails.Name?.length > 15
                  ? `${accountDetails.Name?.substring(0, 15) || ""}...`
                  : accountDetails.Name}
              </p>
              <p className={classes.accountRole}>
                {accountDetails.Designation}
              </p>
            </div>
          </div>
          <div className={classes.accountButtons}>
            <button onClick={() => navigate("/settings")}>
              <img src={settingsIcon} />
              {!bangla ? "Settings" : "সেটিংস"}
            </button>
            <button onClick={handleLogout}>
              <img src={logoutIcon} />
              {!bangla ? "Logout" : "লগ আউট"}
            </button>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1rem" }}
          >
            <p style={{ margin: "0.5rem", color: "grey" }}>EN</p>
            <input
              defaultChecked={bangla}
              onChange={handleBangla}
              style={{ margin: "0.5rem" }}
              className={classes2.switch}
              type="checkbox"
            />
            <p style={{ margin: "0.5rem", color: "grey" }}>BN</p>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}
