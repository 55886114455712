
import classes from '../styles/NavBar.module.css';
import dropIcon from '../assets/icons/drop.svg'
import React, { useEffect, useState } from 'react';

export default function NavSubitem({children, title}){
    const [subToggled, setSubToggled] = useState(false);
    
    

    return(
        <>
            {React.Children.toArray(children).length!=0 && <p onClick={()=>setSubToggled(!subToggled)}>{title}</p>}
            {React.Children.toArray(children).length!=0 && subToggled && children}
        </>
    );
}