import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import classes2 from "../../styles/Table.module.css";
import submitIcon from "../../assets/icons/Save.svg";
import FormInput from "../FormInput";
import { useState } from "react";

import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import uploadIcon from "../../assets/icons/Upload.svg";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect } from "react";

export default function AddProperty() {
  const { user, rolee } = UserAuth();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const [data, setData] = useState({
    PropertyName: "",
    PropertyType: "Commercial",
    PropertySize: "",
    PropertyLocation: "",
    NoOfFloors: "",
    DefaultFloorSize: "",
    files: {},
  });

  useEffect(() => {
    if (rolee && !rolee.addProperty) {
      navigate("/");
    }
  }, [rolee]);

  const [file, setFile] = useState([]);

  const uploadFile = (f, id) => {
    if (!file) {
      // navigate(`/properties/${id}`);
      return;
    }
    const storageRef = ref(storage, `/properties/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          handleFile(f.name, url);
          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Properties", id), {
      files: { ...data.files },
    });
    // navigate(`/properties/${id}`)
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  const navigate = useNavigate();

  const handleData = (d) => {
    setData(d);
  };

  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  useEffect(() => {
    console.log(file);
  }, [file]);

  const addFloors = async (x, Proid) => {
    for (let i = 0; i < x; i++) {
      try {
        const preDocRef = collection(db, "Floors");
        const preRef = doc(preDocRef);
        const id = preRef.id;
        const docRef = await setDoc(preRef, {
          PropertyName: data.PropertyName,
          PropertyId: Proid,
          floorNo: i,
          area: data.DefaultFloorSize,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [allProps, setAllProps] = useState([]);

  const getAllProps = async () => {
    try {
      const arr = [];
      const docRef = await getDocs(collection(db, "Properties"));
      docRef.forEach((doc) => {
        arr.push(doc.data().PropertyName);
      });
      setAllProps(arr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllProps();
  }, []);

  //Add Property
  const handleSubmit = async (event) => {
    if (allProps.includes(data.PropertyName)) {
      setErr("Property Name already exists");
    } else if (data.PropertyName == "") {
      setErr("Property Name cannot be empty.");
    } else if (data.NoOfFloors == "") {
      setErr("No of Floors cannot be empty.");
    } else if (
      /^\d+$/.test(data.PropertySize) == false &&
      data.PropertySize != ""
    ) {
      setErr("Property Size must be in numbers only");
    } else if (/^\d+$/.test(data.NoOfFloors) == false) {
      setErr("No Of Floors must be in numbers only");
    } else if (/^\d+$/.test(data.DefaultFloorSize) == false) {
      setErr("Default Floor Size must be in numbers only");
    } else {
      setErr("");
      try {
        setLoading(true);
        const preDocRef = collection(db, "Properties");
        const preRef = doc(preDocRef);
        const id = preRef.id;
        file.map((f) => {
          uploadFile(f, id);
        });

        const docRef = await setDoc(preRef, {
          PropertyName: data.PropertyName,
          PropertyType: data.PropertyType,
          PropertySize: data.PropertySize,
          PropertyLocation: data.PropertyLocation,
          files: data.files,
        });
        addFloors(data.NoOfFloors, id);

        // activity log code here 
        const log = await addDoc(collection(db, "Logs"), {
          CreatedAt: serverTimestamp(),
          Message: `${user.Name} has added a new Property: ${data.PropertyName}`,
        });
        
        
        setLoading(false);
        setDone(true);
        setTimeout(() => {
          navigate(`/properties/`);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <PageTemp
        title="Add Property"
        subTitle="Please enter the following details."
        page={
          <div>
            <form action="">
              <FormInput
                data={data}
                handleData={handleData}
                title="Property Name"
                placeholder="Enter Name"
              />

              {/* <FormInput data={data} handleData={handleData} title="Property Type" placeholder="Enter Type"/> */}
              <FormInput
                data={data}
                handleData={handleData}
                title="Property Size"
                placeholder="Enter Size"
              />
              <FormInput
                data={data}
                handleData={handleData}
                title="Property Location"
                placeholder="Enter Location"
              />
              <FormInput
                data={data}
                handleData={handleData}
                title="No Of Floors"
                placeholder="Enter Number of Floors"
              />
              <FormInput
                data={data}
                handleData={handleData}
                title="Default Floor Size"
                placeholder="Enter Default Floor Size"
              />

              <label>Property Type</label>
              <select
                onChange={(e) => {
                  setData({ ...data, PropertyType: e.target.value });
                }}
              >
                <option selected value="Commercial">
                  Commercial
                </option>
                <option value="Residential">Residential</option>
                <option value="Industrial">Industrial</option>
                <option value="Commercial & Residential">
                  Commercial & Residential
                </option>
                <option value="Apartment">Apartment</option>
                <option value="Store">Store</option>
                <option value="Warehouse">Warehouse</option>
                <option value="Others">Others</option>
              </select>
              {
                file.map((f) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1rem",
                      }}
                    >
                      {f.name}
                    </div>
                  );
                })
                //   data.files && Object.keys(data.files).map((file)=>{
                //     return <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}><a style={{appearance: "none"}} href={data.files[file]} target="_blank" rel="noopener"> <button type="button" className="btn_downloadFile" htmlFor="">{`Download ${file}`}</button></a>
                //     {/* <button onClick={()=>deletefile(data.files[file])}>Delete</button> */}
                //     </div>

                //   })
              }
              <br></br>

              <label className={classes.upFileButton} htmlFor="fileUpload">
                <img src={uploadIcon} />
                Add File
                <input
                  multiple
                  onChange={fileHandler}
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                />
              </label>

              <>
                {loading && (
                  <div className={classes2.loader}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </>
              {done && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ transform: "scale(0.8)" }}
                    id="completion"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96 101"
                  >
                    <style>
                      {`.st0{fill:#0088C2;fill-opacity:0.4;} .st1{fill:#0088C2;fill-opacity:0.1;} .st2{fill:#0088C2;}`}
                    </style>
                    <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
                    <g id="configurator">
                      <g id="configurator_completion">
                        <g id="stars">
                          <circle id="Oval" class="st0" cx="14" cy="18" r="1" />
                          <circle
                            id="Oval-Copy-4"
                            class="st0"
                            cx="27"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-10"
                            class="st0"
                            cx="76"
                            cy="20"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-2"
                            class="st0"
                            cx="61.5"
                            cy="12.5"
                            r="1.5"
                          />
                          <circle
                            id="Oval-Copy-9"
                            class="st0"
                            cx="94"
                            cy="53"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-6"
                            class="st0"
                            cx="88"
                            cy="14"
                            r="1"
                          />
                          <circle
                            id="Oval-Copy-7"
                            class="st0"
                            cx="59"
                            cy="1"
                            r="1"
                          />
                          <circle
                            id="Oval_1_"
                            class="st0"
                            cx="43"
                            cy="9"
                            r="2"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                          />
                          <path
                            id="ster-01"
                            class="st0"
                            d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                          />
                          <circle
                            id="Oval-Copy-5"
                            class="st0"
                            cx="91"
                            cy="40"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-3"
                            class="st0"
                            cx="7"
                            cy="36"
                            r="2"
                          />
                          <circle
                            id="Oval-Copy-8"
                            class="st0"
                            cx="7.5"
                            cy="5.5"
                            r=".5"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="cirkel">
                      <g id="Mask">
                        <path
                          id="path-1_1_"
                          class="st1"
                          d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                        />
                      </g>
                    </g>
                    <path
                      id="check"
                      class="st2"
                      d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
                    />
                  </svg>
                  <h1
                    style={{
                      color: "#0088C2",
                      margin: "1.5rem",
                      transform: "translate(0, 0.5rem)",
                    }}
                  >
                    Added
                  </h1>
                </div>
              )}
              {/* <br></br> */}
              <label className={classes.excess}>{err}</label>
              <br></br>
              {!done && !loading && (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={classes.submit}
                >
                  <img src={submitIcon} />
                  Add Entry
                </button>
              )}
              <br />
              <br />
            </form>
          </div>
        }
      />
    </>
  );
}
