import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";

import submitIcon from "../../assets/icons/Save.svg";
import classes3 from "../../styles/Table.module.css";
import uploadIcon from "../../assets/icons/Upload.svg";
import { useState } from "react";
import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect } from "react";
import classes2 from "../../styles/Collection.module.css";

export default function TenantComplain() {
  //use this state to update
  const [data, setData] = useState({ files: {} });
  const { user, allProperties, allFloors, rolee } = UserAuth();
  const navigate = useNavigate();
  const { bangla } = UserAuth();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rolee && !rolee.tenantComplain) {
      navigate("/");
    }
  }, [rolee]);

  //submit button
  const handleSubmit = async () => {
    try {
      const preDocRef = collection(db, "Requests");
      const preRef = doc(preDocRef);
      const id = preRef.id;
      file.map((f) => {
        uploadFile(f, id);
      });
      console.log(data.files);
      const docRef = await setDoc(preRef, {
        BusinessName: propertyData.BusinessName,
        ShopNo: propertyData.ShopNo,
        floorId: propertyData.floorId,
        PropertyId: propertyData.PropertyId,
        Block: propertyData.Block,
        ...data,
        RequestTitle: "Tenant Complain",
        RequestedBy: user.Name,
      });
      const log = await addDoc(collection(db, "Logs"), {
        CreatedAt: serverTimestamp(),
        Message: `${user.Name} has filed a Tenant complain`,
      });
      navigate(`/`);
    } catch (err) {
      console.log(err);
    }
  };

  const [file, setFile] = useState([]);

  const uploadFile = (f, id) => {
    if (!file) {
      // navigate(`/properties/${id}`);
      return;
    }
    const storageRef = ref(storage, `/complains/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          console.log(id);
          handleFile(f.name, url);

          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Requests", id), {
      files: { ...data.files },
    });
    navigate(`/`);
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  const [allPropertiess, setAllProperties] = useState();
  const [allFloorss, setAllFloors] = useState();
  const [allShops, setAllShops] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [currentShop, setCurrentShop] = useState();

  const getShops = async (x, y) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Shops`),
        where("PropertyId", "==", currentProperty),
        where("floorId", "==", currentFloor)
      )
    );
    const obj = {};
    querySnapshot.forEach((doc) => {
      obj[doc.data().BusinessName] = doc.id;
    });
    setAllShops(obj);
  };

  const getFloors = async (x) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Floors`),
        where("PropertyId", "==", currentProperty),
        orderBy("floorNo", "asc")

      )
    );
    const obj = {};
    querySnapshot.forEach((doc) => {
      if (
        (Object.keys(user.PropertyAccess).includes(
          `${doc.data().PropertyId}`
        ) &&
          user.PropertyAccess[doc.data().PropertyId].length > 0 &&
          user.PropertyAccess[doc.data().PropertyId].includes(doc.id)) ||
        user.Role == "Admin"
      ) {
        if(doc.data().floorName==undefined){
          obj[doc.id] = doc.data().floorNo;
        }
        else{
          obj[doc.id] = doc.data().floorName;
        }
      }
    });
    console.log(obj);
    setAllFloors(obj);
  };

  const getProps = async () => {
    const querySnapshot = await getDocs(collection(db, "Properties"));
    const obj = {};
    querySnapshot.forEach((doc) => {
      if (
        (Object.keys(user.PropertyAccess).includes(`${doc.id}`) &&
          user.PropertyAccess[doc.id].length > 0) ||
        user.Role == "Admin"
      ) {
        obj[doc.data().PropertyName] = doc.id;
      }
    });
    setAllProperties(obj);
  };

  useEffect(() => {
    getProps();
  }, []);

  useEffect(() => {
    console.log(currentProperty);
    currentProperty && getFloors(currentProperty);
  }, [currentProperty]);

  useEffect(() => {
    console.log(currentFloor);
    currentFloor && getShops(currentProperty, currentFloor);
  }, [currentFloor]);

  const [propertyData, setPropertyData] = useState();

  const getProperty = async (id) => {
    try {
      const docRef = await getDoc(doc(db, "Shops", id));
      setPropertyData(docRef.data());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProperty(currentShop);
  }, [currentShop]);

  return (
    <>
      <PageTemp
        title="Tenant Complain"
        subTitle="Please enter the following details"
        page={
          <>
            {!currentShop && (
              <div className={classes2.mainn}>
                {!allFloorss && (
                  <p className={classes2.head}>
                    {!bangla
                      ? "Please Select Property:"
                      : "প্রপার্টি সিলেক্ট  করুন"}
                  </p>
                )}
                {!allShops && allFloorss && (
                  <p className={classes2.head}>
                    {!bangla ? "Please select floor:" : "ফ্লোর সিলেক্ট  করুন"}
                  </p>
                )}
                {allShops && (
                  <p className={classes2.head}>
                    {!bangla ? "Please select unit:" : "ইউনিট সিলেক্ট  করুন"}
                  </p>
                )}
                {!allFloorss &&
                  allPropertiess &&
                  Object.keys(allPropertiess).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentProperty(allPropertiess[key]);
                        }}
                      >
                        {key}
                      </div>
                    );
                  })}

                {!allShops &&
                  allFloorss &&
                  Object.keys(allFloorss).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentFloor(key);
                        }}
                      >
                        {allFloorss[key]}
                      </div>
                    );
                  })}

                {allShops &&
                  Object.keys(allShops).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentShop(allShops[key]);
                        }}
                      >
                        {key}
                      </div>
                    );
                  })}
              </div>
            )}

            {currentShop && (
              <form action="">
                <div className={classes.formGroup}>
                  <div className={classes.names}>
                    <label htmlFor="">
                      {!bangla ? "Business Name" : "ব্যবসার নাম"}
                    </label>
                    <label htmlFor="">
                      {!bangla ? "Unit No." : "ইউনিট নং"}
                    </label>
                    <label htmlFor="">
                      {!bangla ? "Property" : "প্রপার্টি"}
                    </label>
                    <label htmlFor="">{!bangla ? "Floor" : "ফ্লোর"}</label>
                    <label htmlFor="">{!bangla ? "Block" : "ব্লক"}</label>
                  </div>
                  {propertyData && (
                    <div className={classes.values}>
                      <label htmlFor="">{propertyData.BusinessName}</label>
                      <label htmlFor="">{propertyData.ShopNo}</label>
                      <label htmlFor="">
                        {allProperties[propertyData.PropertyId]}
                      </label>
                      <label htmlFor="">
                        {allFloors[propertyData.floorId]}
                      </label>
                      <label htmlFor="">{propertyData.Block}</label>
                    </div>
                  )}
                </div>

                <br />

                <label className={classes.upFileButton} htmlFor="fileUpload">
                  <img src={uploadIcon} />
                  {!bangla ? "Upload" : "আপলোড করুন"}
                  <input
                    multiple
                    onChange={fileHandler}
                    id="fileUpload"
                    type="file"
                    style={{ display: "none" }}
                  />
                </label>
                <label>{!bangla ? "Complain" : "অভিযোগ"}</label>
                <textarea
                  onChange={(e) =>
                    setData({ ...data, Complain: e.target.value })
                  }
                  placeholder={!bangla ? "Enter Complain" : "অভিযোগ  লিখুন"}
                ></textarea>
                <br />
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={`${classes.submit} ${classes.red}`}
                >
                  <img src={submitIcon} />
                  {!bangla ? "Submit" : "জমা দিন"}
                </button>
              </form>
            )}
          </>
        }
      />
    </>
  );
}
