import classes from '../styles/Table.module.css'
import greenCircle from '../assets/icons/greencircle.svg';
import redCircle from '../assets/icons/redCircle.svg';
import greyCircle from '../assets/icons/partial.svg';
import editIcon from '../assets/icons/editIcon.png';

export default function TableRow({date, onClick, onClick2, id, normalDetails,info,status, editBtn}){
    return(
        <>
            <tr >
                {date && date.map(data=>{
                    if(data!=undefined && data.length!=0){
                        return <td onClick={()=>onClick(id)} key={data}>{data}</td>
                    }
                    
                })}
                {
                    info && 
                        <td onClick={()=>onClick(id)} key={info[0]}>
                            <p className='ld'>
                                <span className={`${classes.lightDetail}`}>{info[0]}</span>
                                <span className={classes.boldDetail}>{info[0]!="" && <br />}{info[1]}</span>
                            </p>
                        </td>
                }

                {
                    status && <td onClick={()=>onClick(id)} key={status}><img className={classes.statIcon} src={status=="Paid" || status=="Active" || status=="Full" ? greenCircle : status=="Partially Paid" || status=="Partial" ? greyCircle : redCircle} />{status}</td>
                }
                
                {normalDetails && normalDetails.map(data=>{
                    if(data!=undefined && data.length!=0){
                        return <td onClick={()=>onClick(id)} key={data}>{data}</td>
                    }
                    
                })}

                {editBtn && <td ><img className={classes.hover} onClick={()=>onClick2(id)} style={{width: "2rem", height: "2rem"}} src={editIcon}></img></td>}
            </tr>
        </>
    );
}