import PageTemp from "../PageTemp";
import classes from "../../styles/EntryForm.module.css";
import shopDetails from "../../assets/icons/ShopDetails.svg";
import Location from "../../assets/icons/Location.svg";
import TenantDetails from "../../assets/icons/TenantDetails.svg";
import SecondaryTenant from "../../assets/icons/SecondaryTenant.svg";
import EmergencyContact from "../../assets/icons/EmergencyContact.svg";
import Files from "../../assets/icons/Files.svg";
import EntryInfo from "../../assets/icons/EntryInfo.svg";
import classes3 from "../../styles/Table.module.css";
import UploadIcon from "../../assets/icons/Upload.svg";
import SaveIcon from "../../assets/icons/Save.svg";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  where,
  query,
  orderBy,
  serverTimestamp,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { UserAuth } from "../../context/AuthContext";

import classes2 from "../../styles/Collection.module.css";

export default function CancelTenantRequest() {
  const { user, allProperties, allFloors, bangla, rolee } = UserAuth();

  useEffect(() => {
    if (rolee && !rolee.cancelTenantApplication) {
      navigate("/");
    }
  }, [rolee]);

  //use this state to update database
  const [data, setData] = useState({ TenantType: "Vacant" });
  const [vacant, setVacant] = useState(true);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [properties, setProperties] = useState({})
  const navigate = useNavigate();
  // const [floors, setFloors]=useState();
  const [secondary, setSecondary] = useState(true);
  const [properties, setProperties] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [floors, setFloors] = useState();
  const [currentFloor, setCurrentFloor] = useState();

  const { propertyId, floorId } = useParams();

  const handlePropertySelect = (data) => {
    properties &&
      properties.map((property) => {
        if (property.name == data) {
          setCurrentProperty({ name: property.name, id: property.id });
        }
      });
    // navigate(`/properties/${currentProperty.id}/floors/undefined/shops/add`);
  };

  const handleFloorSelect = (data) => {
    floors &&
      floors.map((floor) => {
        if (floor.floorNo == data) {
          setCurrentFloor({ floorNo: floor.floorNo, id: floor.id });
        }
      });
  };

  useEffect(() => {
    if (data.TenantType == "Vacant") {
      setVacant(true);
    } else {
      setVacant(false);
    }
  }, [data]);

  const [err, setErr] = useState();

  //submit button here
  const handleSubmit = async () => {
    // else if(data.ShopNo==""){
    //     setErr("Shop No. cannot be empty.");
    // }
    // else if(/^\d+$/.test(data.ShopNo)==false){
    //     setErr("Shop No must be in numbers only");
    // }
    // else if(data.UnitSpace==""){
    //     setErr("Unit Space cannot be empty.");
    // }
    // else if(/^\d+$/.test(data.UnitSpace)==false){
    //     setErr("Unit Space must be in numbers only");
    // }
    setErr("");

    console.log(data);
    const today = new Date().getMonth() + 1;
    // let nextPay;
    // if(today == 12){
    //     nextPay = `${new Date().getFullYear()+1}-01`
    // }
    // else{
    //     nextPay = `${new Date().getFullYear()}-${new Date().getMonth() + 2}`
    // }

    try {
      const preDocRef = collection(db, "Requests");
      const preRef = doc(preDocRef);
      const id = preRef.id;
      const obj = {};
      obj[propertyData.BusinessName] = propertyData;
      setLoading(true);
      const docRef = await setDoc(preRef, {
        // ...propertyData,
        BusinessName: propertyData.BusinessName,
        Floor: propertyData.floorId,
        PropertyName: propertyData.PropertyName,
        shopId: currentShop,
        RequestTitle: "Move Out Request",
        RequestedBy: user.Name,
        Time: serverTimestamp(),
        RequestedLeaseEnding: data.LeaseEnding,
      });
      const log = await addDoc(collection(db, "Logs"), {
        CreatedAt: serverTimestamp(),
        Message: `${user.Name} has requested to cancel Tenant: ${propertyData.BusinessName}`,
      });
      setLoading(false);
      setDone(true);
      setTimeout(() => {
        navigate(`/`);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProperties = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Properties"));
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push({
          id: doc.id,
          name: doc.data().PropertyName,
        });
      });
      // console.log(arr);
      setProperties(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFloors = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, `Floors`),
          where("PropertyId", "==", currentProperty.id),
          orderBy("floorNo")
        )
      );
      const arr = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        arr.push({
          floorNo: doc.data().floorNo,
          area: doc.data().area,
          id: doc.id,
        });
      });
      console.log(arr);
      setFloors(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const [allPropertiess, setAllProperties] = useState();
  const [allFloorss, setAllFloors] = useState();
  const [allShops, setAllShops] = useState();
  const [currentPropertyy, setCurrentPropertyy] = useState();
  const [currentFloorr, setCurrentFloorr] = useState();
  const [currentShop, setCurrentShop] = useState();
  const [currentBlock, setCurrentBlock] = useState();
  const [allBlocks, setAllBlocks] = useState();

  const getShops = async (x, y) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Shops`),
        orderBy("Block"),
        orderBy("ShopNo"),
        where("PropertyId", "==", currentPropertyy),
        where("floorId", "==", currentFloorr)
      )
    );

    const obj = {};
    const obj2 = {};
    querySnapshot.forEach((doc) => {
      if (doc.data().TenantType == "Primary") {
        obj[doc.id] = { ShopNo: doc.data().ShopNo, Block: doc.data().Block };
        obj2[doc.data().Block] = "";
      }
    });
    setAllShops(obj);
    setAllBlocks(obj2);
  };

  const getFloors = async (x) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Floors`),
        where("PropertyId", "==", currentPropertyy),
        orderBy("floorNo", "asc")

      )
    );
    const obj = {};
    querySnapshot.forEach((doc) => {
      if (
        (Object.keys(user.PropertyAccess).includes(
          `${doc.data().PropertyId}`
        ) &&
          user.PropertyAccess[doc.data().PropertyId].length > 0 &&
          user.PropertyAccess[doc.data().PropertyId].includes(doc.id)) ||
        user.Role == "Admin"
      ) {
        if(doc.data().floorName==undefined){
          obj[doc.id] = doc.data().floorNo;
        }
        else{
          obj[doc.id] = doc.data().floorName;
        }
      }
    });
    console.log(obj);
    setAllFloors(obj);
  };

  const getProps = async () => {
    const querySnapshot = await getDocs(collection(db, "Properties"));
    const obj = {};
    querySnapshot.forEach((doc) => {
      if (
        (Object.keys(user.PropertyAccess).includes(`${doc.id}`) &&
          user.PropertyAccess[doc.id].length > 0) ||
        user.Role == "Admin"
      ) {
        obj[doc.data().PropertyName] = doc.id;
      }
    });
    setAllProperties(obj);
  };

  useEffect(() => {
    getProps();
  }, []);

  useEffect(() => {
    console.log(currentPropertyy);
    currentPropertyy && getFloors(currentPropertyy);
  }, [currentPropertyy]);

  useEffect(() => {
    console.log(currentFloorr);
    currentFloorr && getShops(currentProperty, currentFloorr);
  }, [currentFloorr]);

  const [propertyData, setPropertyData] = useState();

  const getProperty = async (id) => {
    try {
      const docRef = await getDoc(doc(db, "Shops", id));
      setPropertyData(docRef.data());
      setData({ ...data, UnitSpace: docRef.data().UnitSpace });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProperty(currentShop);
  }, [currentShop]);

  // const fetchFloors = async()=>{
  //     try{
  //         const querySnapshot= await getDocs(query(collection(db,"Floors"),
  //         where("PropertyId", "==", properties[data.Property])));

  //         const obj={}
  //         querySnapshot.forEach((doc)=>{
  //             obj[doc.data().FloorNo]=doc.id;
  //         })
  //         setFloors(obj);
  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  // const fetchProperties=async()=>{
  //     try{
  //         const querySnapshot = await getDocs(collection(db, "Properties"));
  //         const obj={};
  //         querySnapshot.forEach((doc) => {
  //             obj[doc.data().PropertyName]=doc.id;

  //         });
  //         setProperties(obj);
  //     }
  //     catch(err){
  //         console.log(err);
  //     }
  // }

  // useEffect(()=>{
  //     console.log(img);
  // },[img])

  // useEffect(()=>{
  //     console.log(floors);
  // },[floors]);

  useEffect(() => {
    properties &&
      properties.map((property) => {
        if (property.id == propertyId) {
          setCurrentProperty({ name: property.name, id: property.id });
        }
      });
  }, [properties]);

  useEffect(() => {
    if (floorId == "undefined") {
      setCurrentFloor({ floorNo: floors[0].floorNo, id: floors[0].id });
    }
    floors &&
      floors.map((floor) => {
        if (floor.id == floorId) {
          setCurrentFloor({ floorNo: floor.floorNo, id: floor.id });
        }
      });
  }, [floors]);

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    // currentProperty!=undefined && navigate(`/properties/${currentProperty.id}/floors/${floorId}/shops/add`);
    currentProperty != undefined &&
      setData({
        ...data,
        PropertyName: currentProperty.name,
        PropertyId: currentProperty.id,
      });

    fetchFloors();
  }, [currentProperty]);

  useEffect(() => {
    // currentFloor && navigate(`/properties/${propertyId}/floors/${currentFloor.id}/shops/add`)
    currentFloor &&
      setData({
        ...data,
        FloorNo: currentFloor.floorNo,
        floorId: currentFloor.id,
      });
  }, [currentFloor]);

  const [file, setFile] = useState([]);
  const [pic, setPic] = useState();

  //filesystem
  const fileHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();

      setFile((prev) => [...prev, newImage]);
    }
  };

  const uploadFile = (f, id) => {
    if (!file) {
      navigate("/properties");
      return;
    }
    const storageRef = ref(storage, `/shops/${id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleFile(f.name, url);
          updateFile(id);
        });
      }
    );
  };

  const updateFile = async (id) => {
    await updateDoc(doc(db, "Requests", id), {
      files: { ...data.files },
    });
    // navigate(`/properties/${id}`);
  };

  const handleFile = (name, url) => {
    const obj = data;
    obj.files[name] = url;
    setData(obj);
  };

  //dp
  const uploadDp = (id) => {
    if (!pic) {
      // navigate("/properties");
      return;
    }

    const storageRef = ref(storage, `/shops/${id}/${pic.name}`);

    const uploadTask = uploadBytesResumable(storageRef, pic);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleDp(url);
          updateDp(id);
        });
      }
    );
  };

  const updateDp = async (id) => {
    await updateDoc(doc(db, "Requests", id), {
      dp: data.dp,
    });
    navigate(`/properties/${id}`);
  };

  const handleDp = (url) => {
    const obj = data;
    console.log(url);
    obj["dp"] = url;
    setData(obj);
  };

  return (
    <>
      <PageTemp
        title="Move Out"
        subTitle="Send request to Cancel tenant"
        page={
          <>
            {!currentShop && (
              <div className={classes2.mainn}>
                {!allFloorss && (
                  <p className={classes2.head}>
                    {!bangla
                      ? "Please Select Property:"
                      : "প্রপার্টি সিলেক্ট  করুন"}
                  </p>
                )}
                {!allShops && allFloorss && (
                  <p className={classes2.head}>
                    {!bangla ? "Please select floor:" : "ফ্লোর সিলেক্ট  করুন"}
                  </p>
                )}
                {allShops && (
                  <p className={classes2.head}>
                    {!bangla ? "Please select unit:" : "ইউনিট সিলেক্ট  করুন"}
                  </p>
                )}
                {!allFloorss &&
                  allPropertiess &&
                  Object.keys(allPropertiess).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentPropertyy(allPropertiess[key]);
                        }}
                      >
                        {key}
                      </div>
                    );
                  })}

                {!allShops && allFloorss && (
                  <div
                    className="reddd"
                    onClick={() => {
                      setCurrentPropertyy(undefined);
                      setAllFloors(undefined);
                    }}
                  >
                    Back to Property
                  </div>
                )}
                {!allShops &&
                  allFloorss &&
                  Object.keys(allFloorss).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentFloorr(key);
                        }}
                      >
                        {allFloorss[key]}
                      </div>
                    );
                  })}

                {!currentBlock && allBlocks && allShops && (
                  <div
                    className="reddd"
                    onClick={() => {
                      setCurrentFloorr(undefined);
                      setAllShops(undefined);
                      // setCurrentBlock(undefined)
                    }}
                  >
                    Back to Floors
                  </div>
                )}

                {!currentBlock &&
                  allBlocks &&
                  allShops &&
                  Object.keys(allBlocks).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentBlock(key);
                        }}
                      >
                        {`Block: ${key}`}
                      </div>
                    );
                  })}

                {currentBlock && (
                  <div
                    className="reddd"
                    onClick={() => {
                      // setCurrentFloorr(undefined);
                      // setAllShops(undefined);
                      setCurrentBlock(undefined);
                    }}
                  >
                    Back to Blocks
                  </div>
                )}

                {currentBlock &&
                  allShops &&
                  Object.keys(allShops).map((key) => {
                    if (allShops[key].Block == currentBlock) {
                      return (
                        <div
                          onClick={(e) => {
                            setCurrentShop(key);
                          }}
                        >{`Shop ${allShops[key].ShopNo}, Block ${
                          allShops[key].Block != undefined
                            ? allShops[key].Block
                            : ""
                        }`}</div>
                      );
                    }
                  })}
              </div>
            )}

            {currentShop && propertyData && (
              <div>
                <form action="">
                  <div className={classes.formGroup}></div>
                  <div className={classes.formDetail}>
                    {/* <div className={classes.detail}>
                                <p className={classes.detailHeader}><img src={shopDetails} />Unit Details</p>
                                <div className={classes.subDetail}>
                                    
                                    
                                </div>
                                <br />
                            </div> */}

                    <div className={classes.detail}>
                      <p className={classes.detailHeader}>
                        <img src={Location} />
                        Location
                      </p>
                      <div className={classes.subDetail}>
                        <label>{`Property: ${allProperties[currentPropertyy]}`}</label>
                        {/* <select onChange={e=>handlePropertySelect(e.target.value)}>
                                        {properties && properties.map((property)=>{
                                            return <option val={property.name}>{property.name}</option>
                                        })}
                                    </select> */}

                        <>
                          <label>{`Floor: ${allFloors[currentFloorr]}`}</label>
                          {/* <select onChange={e=>handleFloorSelect(e.target.value)}>
                                        {
                                            floors.map((key)=>{
                                                return <option val={key.floorNo}>{`${key.floorNo}`}</option>
                                            })
                                        }
                                    </select> */}
                        </>
                        <label>{`Block: ${propertyData.Block}`}</label>
                        {/* <input onChange={e=>setData({...data, Block: e.target.value})} type="text" placeholder="Enter Block" /> */}
                        <label>{`Shop No: ${propertyData.ShopNo}`}</label>
                        <label>{`Business Name: ${propertyData.BusinessName}`}</label>
                        <label>{`Tenant Type: ${propertyData.TenantType}`}</label>
                        <label
                          style={{ fontWeight: "600" }}
                        >{`Current Lease Ending: ${propertyData.LeaseEnding}`}</label>
                        <br></br>
                        <label>Lease Ending</label>
                        <input
                          onChange={(e) =>
                            setData({ ...data, LeaseEnding: e.target.value })
                          }
                          type="month"
                        />
                        {/* <input onChange={e=>setData({...data, ShopNo: e.target.value})} type="text" placeholder="Enter Shop No." /> */}
                        <br></br>
                        {/* <label>Tenant Type</label>
                                    <select onChange={e=>setData({...data, TenantType: e.target.value})} defaultValue={"Vacant"}>
                                        <option selected val="Vacant">Select Type</option>
                                        <option val="Primary">Primary</option>
                                        <option val="Position">Position</option>
                                    </select> */}
                      </div>
                      <>
                        {loading && (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            className={classes3.loader}
                          >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        )}
                      </>
                      {done && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            style={{
                              height: "10rem",
                              width: "10rem",
                              transform: "scale(0.8)",
                            }}
                            id="completion"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 96 101"
                          >
                            <style>
                              {`.st0{fill:#0088C2;fill-opacity:0.4;} .st1{fill:#0088C2;fill-opacity:0.1;} .st2{fill:#0088C2;}`}
                            </style>
                            <title>C1DBEBC0-CF7A-42D3-B615-1AB5DE73C3E9</title>
                            <g id="configurator">
                              <g id="configurator_completion">
                                <g id="stars">
                                  <circle
                                    id="Oval"
                                    class="st0"
                                    cx="14"
                                    cy="18"
                                    r="1"
                                  />
                                  <circle
                                    id="Oval-Copy-4"
                                    class="st0"
                                    cx="27"
                                    cy="20"
                                    r="1"
                                  />
                                  <circle
                                    id="Oval-Copy-10"
                                    class="st0"
                                    cx="76"
                                    cy="20"
                                    r="1"
                                  />
                                  <circle
                                    id="Oval-Copy-2"
                                    class="st0"
                                    cx="61.5"
                                    cy="12.5"
                                    r="1.5"
                                  />
                                  <circle
                                    id="Oval-Copy-9"
                                    class="st0"
                                    cx="94"
                                    cy="53"
                                    r="1"
                                  />
                                  <circle
                                    id="Oval-Copy-6"
                                    class="st0"
                                    cx="88"
                                    cy="14"
                                    r="1"
                                  />
                                  <circle
                                    id="Oval-Copy-7"
                                    class="st0"
                                    cx="59"
                                    cy="1"
                                    r="1"
                                  />
                                  <circle
                                    id="Oval_1_"
                                    class="st0"
                                    cx="43"
                                    cy="9"
                                    r="2"
                                  />
                                  <path
                                    id="ster-01"
                                    class="st0"
                                    d="M28.5 3.8L26 6l2.2-2.5L26 1l2.5 2.2L31 1l-2.2 2.5L31 6z"
                                  />
                                  <path
                                    id="ster-01"
                                    class="st0"
                                    d="M3.5 50.9l-2.1 2.4 1.7-2.7-2.9-1.2 3.1.8.2-3.2.2 3.2 3.1-.8-2.9 1.2 1.7 2.7z"
                                  />
                                  <path
                                    id="ster-01"
                                    class="st0"
                                    d="M93.5 27.8L91 30l2.2-2.5L91 25l2.5 2.2L96 25l-2.2 2.5L96 30z"
                                  />
                                  <circle
                                    id="Oval-Copy-5"
                                    class="st0"
                                    cx="91"
                                    cy="40"
                                    r="2"
                                  />
                                  <circle
                                    id="Oval-Copy-3"
                                    class="st0"
                                    cx="7"
                                    cy="36"
                                    r="2"
                                  />
                                  <circle
                                    id="Oval-Copy-8"
                                    class="st0"
                                    cx="7.5"
                                    cy="5.5"
                                    r=".5"
                                  />
                                </g>
                              </g>
                            </g>
                            <g id="cirkel">
                              <g id="Mask">
                                <path
                                  id="path-1_1_"
                                  class="st1"
                                  d="M49 21c22.1 0 40 17.9 40 40s-17.9 40-40 40S9 83.1 9 61s17.9-40 40-40z"
                                />
                              </g>
                            </g>
                            <path
                              id="check"
                              class="st2"
                              d="M31.3 64.3c-1.2-1.5-3.4-1.9-4.9-.7-1.5 1.2-1.9 3.4-.7 4.9l7.8 10.4c1.3 1.7 3.8 1.9 5.3.4L71.1 47c1.4-1.4 1.4-3.6 0-5s-3.6-1.4-5 0L36.7 71.5l-5.4-7.2z"
                            />
                          </svg>
                          <h1
                            style={{
                              color: "#0088C2",
                              margin: "1.5rem",
                              transform: "translate(0, 0.5rem)",
                            }}
                          >
                            Added
                          </h1>
                        </div>
                      )}
                      <label className={classes.excess}>{err}</label>
                      <br></br>
                      {!done && !loading && (
                        <button
                          style={{ width: "20rem" }}
                          type="button"
                          onClick={handleSubmit}
                          className={classes.submit}
                        >
                          <img src={SaveIcon} />
                          {!bangla ? "Send Request" : "রিকুয়েস্ট পাঠান"}
                        </button>
                      )}
                      {/* <br /> */}
                    </div>

                    {/* <div className={classes.detail}>
                                <p className={classes.detailHeader}><img src={EntryInfo} />Entry Info</p>
                                <div className={classes.subDetail}>
                                    <label>Rent</label>
                                    <input className={classes.switch} defaultChecked={data.hasRent} onChange={e=>setData({...data, hasRent: e.target.checked})} type="checkbox" />
                                    
                                    
                                    {data && data.hasRent && <><label>Rent Start Date</label>
                                    <input onChange={e=>setData({...data, NextPayment: e.target.value})} type="month" placeholder="Enter Date" />
                                    <label>Unit Space</label>
                                    <input defaultValue={propertyData.UnitSpace} onChange={e=>setData({...data, UnitSpace: e.target.value})} type="text" placeholder="Enter Unit Space" />
                                    <label>Unit rate per sq.</label>
                                    <input onChange={e=>setData({...data, UnitPrice: e.target.value})} type="text" placeholder="Enter Unit Price" />
                                    </>}
                                    <label>Utility</label>
                                    <input className={classes.switch} defaultChecked={data.hasUtility} onChange={e=>setData({...data, hasUtility: e.target.checked})} type="checkbox" />
                                    {data && data.hasUtility && <>
                                        <label>Utility Start Date</label>
                                        <input onChange={e=>setData({...data, NextPaymentUtility: e.target.value})} type="month" placeholder="Enter Date" />
                                        <label>Utility Rate</label>
                                        <input onChange={e=>setData({...data, MeterRate: e.target.value})} type="text" placeholder="Enter Meter Reading Rate" />
                                        <label>Previous Usage</label>
                                        <input onChange={e=>setData({...data, PreviousMeterUsage: e.target.value})} type="text" placeholder="Enter Previous Usage" />
                                    </>}
                                    
                                    
                                    
                                    <label>Service Charge</label>
                                    <input className={classes.switch} defaultChecked={data.hasSC} onChange={e=>setData({...data, hasSC: e.target.checked})} type="checkbox" />
                                    {data && data.hasSC && <>
                                        <label>S/C Start Date</label>
                                    <input onChange={e=>setData({...data, NextPaymentServiceCharge: e.target.value})} type="month" placeholder="Enter Date" />
                                    <label>Common Space (if any)</label>
                                    <input onChange={e=>setData({...data, CommonSpace: e.target.value})} type="text" placeholder="Enter Common Space" />
                                    <label>Service Charge Rate</label>
                                    <input onChange={e=>setData({...data, ServiceChargeRate: e.target.value})} type="text" placeholder="Enter Service Charge Rate" /></>}
                                </div>
                                <br />
                            </div> */}
                  </div>
                  <br />
                  <br />
                  {!vacant && (
                    <div className={classes.formDetail}>
                      <div className={classes.detail}>
                        {/* <p className={classes.detailHeader}><img src={TenantDetails} />Tenant Details</p> */}
                        <div className={classes.subDetail}>
                          {/* <label>Business Name</label>
                                    <input onChange={e=>setData({...data, BusinessName: e.target.value})} type="text" placeholder="Enter Business Name" />
                                    <label>Tenant Name</label>
                                    <input onChange={e=>setData({...data, TenantName: e.target.value})} type="text" placeholder="Enter Tenant Name" /> */}

                          {/* <label>Name</label>
                                    <input onChange={e=>setData({...data, TenantName: e.target.value})} type="text" placeholder="Enter Tenant's Name" /> */}
                          {/* <label>Father' Name</label>
                                    <input onChange={e=>setData({...data, TenantFatherName: e.target.value})} type="text" placeholder="Enter Father's Name" />
                                    <label>Home Address</label>
                                    <input onChange={e=>setData({...data, TenantHomeAddress: e.target.value})} type="text" placeholder="Enter Home Address" />
                                    <label>Mobile Number</label>
                                    <input onChange={e=>setData({...data, TenantMobileNumber: e.target.value})} type="text" placeholder="Enter Mobile No" />
                                    <label>National ID</label>
                                    <input onChange={e=>setData({...data, TenantNID: e.target.value})} type="text" placeholder="Enter NID" />
                                    <label>Passport Number</label>
                                    <input onChange={e=>setData({...data, TenantPassport: e.target.value})} type="text" placeholder="Enter Passport No" />
                                    <label>Manager Name</label>
                                    <input onChange={e=>setData({...data, ManagerName: e.target.value})} type="text" placeholder="Enter Manager Name" />
                                    <label>Manager Mobile Number</label>
                                    <input onChange={e=>setData({...data, ManagerMobile: e.target.value})} type="text" placeholder="Enter Manager's No." /> */}
                          {/* {data && data.TenantType!="Position" && <><label>Lease Starting</label>
                                    <input onChange={e=>setData({...data, LeaseStarting: e.target.value})} type="month" />
                                    <label>Lease Ending</label>
                                    <input onChange={e=>setData({...data, LeaseEnding: e.target.value})} type="month" />
                                    </>
                                    } */}
                        </div>
                        {/* <br /><br /> */}
                      </div>
                      <div className={classes.detail}>
                        {/* <p className={classes.detailHeader}><img src={SecondaryTenant} />Secondary Tenant Details</p>
                                <input defaultChecked className={classes.switch} onChange={()=>setSecondary(!secondary)} type="checkbox" />
                                {secondary && <div className={classes.subDetail}>
                                    <label>Business/Tenant Name</label>
                                    <input onChange={e=>setData({...data, SecondaryName: e.target.value})} type="text" placeholder="Enter Tenant's Name" />
                                    <label>Father' Name</label>
                                    <input onChange={e=>setData({...data, SecondaryFatherName: e.target.value})} type="text" placeholder="Enter Father's Name" />
                                    <label>Home Address</label>
                                    <input onChange={e=>setData({...data, SecondaryHomeAddress: e.target.value})} type="text" placeholder="Enter Home Address" />
                                    <label>Mobile Number</label>
                                    <input onChange={e=>setData({...data, SecondaryMobile: e.target.value})} type="text" placeholder="Enter Mobile No" />
                                    <label>National ID</label>
                                    <input onChange={e=>setData({...data, SecondaryNID: e.target.value})} type="text" placeholder="Enter NID" />
                                    <label>Passport Number</label>
                                    <input onChange={e=>setData({...data, SecondaryPassportNumber: e.target.value})} type="text" placeholder="Enter Passport No" />
                                    <label>Secondary Tenant Rent</label>
                                    <input onChange={e=>setData({...data, SecondaryTenantRent: e.target.value})} type="text" placeholder="Enter Rent" />
                                </div>}
                                <br /><br /> */}
                      </div>
                    </div>
                  )}
                  <div className={classes.formDetail}>
                    {/* <div className={classes.detail}>
                                <p className={classes.detailHeader}><img src={EmergencyContact} />{`Emergency Contact (Tenant)`}</p>
                                <div className={classes.subDetail}>
                                    <label>Name</label>
                                    <input onChange={e=>setData({...data, EmergencyName: e.target.value})} type="text" placeholder="Enter Name" />
                                    <label>Relation</label>
                                    <input onChange={e=>setData({...data, EmergencyRelation: e.target.value})} type="text" placeholder="Enter Relation" />
                                    <label>Address</label>
                                    <input onChange={e=>setData({...data, EmergencyAddress: e.target.value})} type="text" placeholder="Enter Address" />
                                    <label>Mobile Number</label>
                                    <input onChange={e=>setData({...data, EmergencyMobile: e.target.value})} type="text" placeholder="Enter Tenant's Name" />
                                </div>
                                <br />
                            </div> */}
                  </div>

                  <div className={classes.formDetail}>
                    <div className={classes.detail}>
                      {/* <p className={`${classes.detailHeader} ${classes.black}`}><img src={Files} />Files</p> */}
                      <div className={classes.subDetail}>
                        {/* <label className={classes.upFileButton} htmlFor="fileUpload">
                                        <img src={UploadIcon} />
                                        Add Image
                                        <input onChange={e=>setPic(e.target.files[0])} id="fileUpload" type="file" style={{display: "none"}} />
                                    </label> */}
                        {
                          // file.map(f=>{
                          //     return <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}>{f.name}</div>
                          // })
                        }
                        {/* <label className={classes.upFileButton} htmlFor="fileUpload2">
                                        <img src={UploadIcon} />
                                        Add File
                                        <input multiple onChange={fileHandler} id="fileUpload2" type="file" style={{display: "none"}} />
                                    </label> */}
                      </div>
                      <br />
                    </div>
                  </div>

                  <br />
                  <br />

                  <br />
                  <br />
                </form>
              </div>
            )}
          </>
        }
      />
    </>
  );
}
