import PageTemp from "../PageTemp";
import classes from "../../styles/Collection.module.css";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import classes2 from "../../styles/Collection.module.css";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function Collection({ type }) {
  
  const [allPropertiess, setAllProperties] = useState();
  const [allFloorss, setAllFloors] = useState();
  const [currentPropertyy, setCurrentPropertyy] = useState();
  const [currentFloorr, setCurrentFloorr] = useState();
  const [currentShop, setCurrentShop] = useState();
  const [currentBlock, setCurrentBlock] = useState();
  const [allBlocks, setAllBlocks] = useState();
  const [allShops, setAllShops] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const { user, allProperties, allFloors, bangla, rolee } = UserAuth();


  const navigate = useNavigate();

  const getShops = async (x, y) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Shops`),
        orderBy("Block"),
        orderBy("ShopNo"),
        where("PropertyId", "==", currentPropertyy),
        where("floorId", "==", currentFloorr)
      )
    );

    const obj = {};
    const obj2 = {};
    querySnapshot.forEach((doc) => {
      if (doc.data().TenantType == "Primary") {
        obj[doc.id] = { ShopNo: doc.data().ShopNo, Block: doc.data().Block, id: doc.id };
        obj2[doc.data().Block] = "";
      }
    });
    setAllShops(obj);
    setAllBlocks(obj2);
  };

  const getFloors = async (x) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Floors`),
        where("PropertyId", "==", currentPropertyy),
        orderBy("floorNo", "asc")
      )
    );
    
    const obj = {};
    querySnapshot.forEach((doc) => {
      
      if (
        (Object.keys(user.PropertyAccess).includes(
          `${doc.data().PropertyId}`
        ) &&
          user.PropertyAccess[doc.data().PropertyId].length > 0 &&
          user.PropertyAccess[doc.data().PropertyId].includes(doc.id)) ||
        user.Role == "Admin"
      ) {
        if(doc.data().floorName==undefined){
          obj[doc.id] = doc.data().floorNo;
        }
        else{
          obj[doc.id] = doc.data().floorName;
        }
        
      }
    });
    console.log(obj);
    setAllFloors(obj);
  };

  const getProps = async () => {
    const querySnapshot = await getDocs(collection(db, "Properties"));
    const obj = {};
    querySnapshot.forEach((doc) => {
      if (
        (Object.keys(user.PropertyAccess).includes(`${doc.id}`) &&
          user.PropertyAccess[doc.id].length > 0) ||
        user.Role == "Admin"
      ) {
        obj[doc.data().PropertyName] = doc.id;
      }
    });
    setAllProperties(obj);
  };

  useEffect(() => {
    getProps();
  }, []);

  useEffect(() => {
    console.log(currentPropertyy);
    currentPropertyy && getFloors(currentPropertyy);
  }, [currentPropertyy]);

  useEffect(() => {
    console.log(currentFloorr);
    currentFloorr && getShops(currentProperty, currentFloorr);
  }, [currentFloorr]);

  useEffect(() => {
    if (
      rolee &&
      !rolee.rentCollection &&
      !rolee.utilityCollection &&
      !rolee.serviceChargeCollection
    ) {
      navigate("/");
    }
  }, [rolee]);

  return (
    <>
      <PageTemp
        title="Select Unit"
        subTitle="Select unit to add an entry"
        page={
          <>
            {!currentShop && (
              <div className={classes2.mainn}>
                {!allFloorss && (
                  <p className={classes2.head}>
                    {!bangla
                      ? "Please Select Property:"
                      : "প্রপার্টি সিলেক্ট  করুন"}
                  </p>
                )}
                {!allShops && allFloorss && (
                  <p className={classes2.head}>
                    {!bangla ? "Please select floor:" : "ফ্লোর সিলেক্ট  করুন"}
                  </p>
                )}
                {allShops && (
                  <p className={classes2.head}>
                    {!bangla ? "Please select unit:" : "ইউনিট সিলেক্ট  করুন"}
                  </p>
                )}
                {!allFloorss &&
                  allPropertiess &&
                  Object.keys(allPropertiess).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentPropertyy(allPropertiess[key]);
                        }}
                      >
                        {key}
                      </div>
                    );
                  })}

                {!allShops && allFloorss && (
                  <div
                    className="reddd"
                    onClick={() => {
                      setCurrentPropertyy(undefined);
                      setAllFloors(undefined);
                    }}
                  >
                    Back to Property
                  </div>
                )}
                {!allShops &&
                  allFloorss &&
                  Object.keys(allFloorss).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentFloorr(key);
                        }}
                      >
                        {allFloorss[key]}
                      </div>
                    );
                  })}

                {!currentBlock && allBlocks && allShops && (
                  <div
                    className="reddd"
                    onClick={() => {
                      setCurrentFloorr(undefined);
                      setAllShops(undefined);
                      // setCurrentBlock(undefined)
                    }}
                  >
                    Back to Floors
                  </div>
                )}

                {!currentBlock &&
                  allBlocks &&
                  allShops &&
                  Object.keys(allBlocks).map((key) => {
                    return (
                      <div
                        onClick={(e) => {
                          setCurrentBlock(key);
                        }}
                      >
                        {`Block: ${key}`}
                      </div>
                    );
                  })}

                {currentBlock && (
                  <div
                    className="reddd"
                    onClick={() => {
                      // setCurrentFloorr(undefined);
                      // setAllShops(undefined);
                      setCurrentBlock(undefined);
                    }}
                  >
                    Back to Blocks
                  </div>
                )}

                {currentBlock &&
                  allShops &&
                  Object.keys(allShops).map((key) => {
                    if (allShops[key].Block == currentBlock) {
                      return (
                        <div
                        onClick={() =>
                          navigate(
                            `/properties/${currentPropertyy}/${currentFloorr}/shops/${allShops[key].id}/${type}`
                          )
                        }
                        >{`Shop ${allShops[key].ShopNo}, Block ${
                          allShops[key].Block != undefined
                            ? allShops[key].Block
                            : ""
                        }`}</div>
                      );
                    }
                  })}
              </div>
            )}
          </>
        }
      />
    </>
  );
}
