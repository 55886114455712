import { useEffect } from "react";
import {
  onSnapshot,
  collection,
  limit,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import PageTemp from "../PageTemp";
import classes from "../../styles/Dashboard.module.css";
import { useState } from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

export default function Dashboard() {
  const [logs, setLogs] = useState();
  const navigate = useNavigate();
  const { user, morichState, rolee } = UserAuth();
  const [month, setMonth] = useState();
  const [total, setTotal] = useState();
  const [rev, setRev] = useState({});
  const [revData, setRevData] = useState();
  const { bangla } = UserAuth();

  const [monthData, setMonthData] = useState();

  const getMonths = async () => {
    let today = new Date();
    let thisMonth;
    const arr = [];
    const arr2 = [];
    for (let i = 0; i < 5; i++) {
      thisMonth = today.getMonth() - 4 + i;
      let date = new Date();
      date.setMonth(thisMonth);
      arr.push(`${date.toLocaleString([], { month: "short" })}`);
      arr2.push(
        `${date.toLocaleString([], { year: "numeric" })}-${String(
          `0${date.toLocaleString([], { month: "numeric" })}`
        ).slice(-2)}`
      );
    }
    setMonthData(arr2);
    setMonth(arr);
    getRevenue();
  };

  const getRevenue = async () => {
    let total = 0;
    try {
      let rev = {};
      const arr = [];
      const docRef = await getDocs(collection(db, "Shops")).then(
        console.log("fetched Shops")
      );
      docRef.forEach((doc) => {
        Object.keys(doc.data()["Entries"].Rent).length != 0 &&
          arr.push(doc.data()["Entries"].Rent);
      });
      arr.map((a) => {
        Object.keys(a).map((o) => {
          total += parseInt(a[o].Amount);
          if (rev[o]) {
            rev[o] += parseInt(a[o].Amount);
          } else {
            rev[o] = parseInt(a[o].Amount);
          }
        });
      });
      setRev(rev);
      setTotal(total);
    } catch (err) {
      console.log(err);
    }
  };

  const getLog = async () => {
    try {
      const q = query(
        collection(db, "Logs"),
        orderBy("CreatedAt", "desc"),
        limit(6)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        // const data = [];
        const obj = {};
        querySnapshot.forEach((doc) => {
          obj[doc.id] = `${doc.data().Message}`;
        });
        setLogs(obj);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [notifData, setNotifData] = useState([]);

  const fetchRequests = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Requests")).then(
        console.log("fetched Requests")
      );
      querySnapshot.forEach((doc) => {
        if (
          rolee &&
          rolee.receiveNewTenantApplication &&
          doc.data().RequestTitle == "Move In Request"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveCancelTenantApplication &&
          doc.data().RequestTitle == "Move Out Request"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }else if (
          rolee &&
          rolee.receiveChangeSecondaryTenant &&
          doc.data().RequestTitle == "Change Secondary Tenant"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveChangePrimaryTenant &&
          doc.data().RequestTitle == "Change Primary Tenant"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveChangePositionTenant &&
          doc.data().RequestTitle == "Change Position Tenant"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveTenantComplain &&
          doc.data().RequestTitle == "Tenant Complain"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receivePropertyComplain &&
          doc.data().RequestTitle == "Property Complain"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        } else if (
          rolee &&
          rolee.receiveOtherComplain &&
          doc.data().RequestTitle == "Other Complain"
        ) {
          setNotifData((prev) => {
            return [
              ...prev,
              {
                title: doc.data().RequestTitle,
                sub: doc.data().RequestedBy,
                id: doc.id,
              },
            ];
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setNotifData([]);
    // fetchRequests();
  }, []);

  useEffect(() => {
    if (notifData.length == 0 && rolee != undefined) {
      fetchRequests();
    }
  }, [rolee]);

  useEffect(() => {
    getLog();
    getMonths();
  }, []);

  useEffect(() => {
    const arr = [];
    monthData &&
      monthData.map((m) => {
        arr.push(rev[m]);
      });
    setRevData(arr);
  }, [rev]);

  const data = {
    labels: month ? [...month] : ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Total Revenue",
        data: revData ? [...revData] : ["0", "0", "0", "0", "0"],
        backgroundColor: "#0088C2",
        borderColor: "black",
        barPercentage: 0.7,
        categoryPercentage: 0.8,
        borderRadius: 7,
      },
    ],
  };

  // const numberWithCommas =(x) =>{
  //     if(x==undefined){
  //         return
  //     }
  //     x = x.toString();
  //     var pattern = /(-?\d+)(\d{3})/;
  //     while (pattern.test(x))
  //         x = x.replace(pattern, "$1,$2");
  //     return x;
  // }

  return (
    <>
      <PageTemp
        qr={true}
        map={true}
        noBackBtn={true}
        title="Dashboard"
        subTitle={
          !bangla
            ? `Welcome, ${user.Name}`
            : `স্বাগতম ${user.Name}`
        }
        page={
          <>
            {rolee && (
              <div>
                <div className={classes.smainn}>
                  {!morichState &&
                    (rolee.viewStatistics) && (
                      <div className={classes.boxStat}>
                        <div className={classes.titles}>
                          <p>{!bangla ? "Total Revenue" : "মোট রেভিনিউ"}</p>
                          <p className={classes.rev}>{`৳${
                            total && total.toLocaleString("en-IN")
                          }`}</p>
                        </div>

                        <div className={classes.chartWrapper}>
                          <Bar
                            className={classes.bar}
                            data={data}
                            options={{
                              responsive: true,

                              maintainAspectRatio: false,
                              padding: 0,
                              margin: 0,
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                y: {
                                  display: false,
                                  grid: {
                                    display: false,
                                  },
                                },
                                x: {
                                  grid: {
                                    display: false,
                                  },
                                },
                              },
                            }}
                          ></Bar>
                          <div className={classes.buttonHolderStat}>
                            <button onClick={() => navigate("/statistics")}>
                              Show statistics
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className={classes.boxNotif}>
                    {
                      <div className={classes.titles2}>
                        <p>{!bangla ? `Requests` : "রিকুয়েস্ট"}</p>
                        <div>
                          <span>{`${notifData.length}`}</span>
                          <p className={classes.subNot}>New Requests</p>
                        </div>
                        <div className={classes.buttonHolder}>
                          <button
                            onClick={() => navigate("/requests/history/me")}
                          >
                            Past Requests
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <br></br>
                {rolee && rolee.activityLog && (
                  <div className={classes.mainn}>
                    <div className={classes.boxActivity}>
                      <p className={classes.boxTitle}>
                        {!bangla ? "Activity Log" : "অ্যাক্টিভিটি লগ"}
                      </p>
                      <div className={classes.logsContainer}>
                        {logs &&
                          Object.keys(logs).map((log) => {
                            return (
                              <p key={log} className={classes.log}>
                                {logs[log]}
                              </p>
                            );
                          })}
                      </div>

                      <div className={classes.buttonHolder}>
                        {/* <button>Show all</button> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* <br></br> */}
              </div>
            )}
          </>
        }
      />
    </>
  );
}
