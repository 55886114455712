// import PageTemp from "../PageTemp";
// import Table from "../Table";
// import TableHeader from "../TableHeader";
// import TableSubheader from "../TableSubheader";
// import TableRow from "../TableRow";
// import TableTop from "../TableTop";
// import { useState } from "react";
// import { collection, getDocs, where, query, orderBy, getDoc, doc } from "firebase/firestore";
// import { db } from "../../firebase";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";

// export default function RentStatement(){

//     const {propertyId, floorId} = useParams();

//     const navigate = useNavigate();
//     const [floors, setFloors] = useState([]);
//     const [properties, setProperties] = useState([]);

//     const loopThroughDates = (start, end) => {
//         const arr=[];
//         if(start==end){
//             arr.push(start);
//         }
//         else if(start>end){
//             console.log("Invalid")
//         }
//         else{
//             let x=start;
//             arr.push(x);
//             while(x<end){

//                 if(x.slice(-2)!=12){
//                     x=`${x.substring(0,4)}-${("0"+String(parseInt(x.slice(-2))+1)).slice(-2)}`
//                 }
//                 else{
//                     x=`${parseInt(x.substring(0,4))+1}-01`
//                 }
//                 arr.push(x);
//             }
//         }
//         return arr;
//     }

//     const fetchShops = async()=>{

//         const dates=loopThroughDates(filter.StartDate, filter.EndDate)
//         console.log(dates);
//         const obj={};
//         try{
//             let querySnapshot;
//             querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), orderBy("FloorNo")));
//             // if(propertyId=="allProperties" && floorId=="allFloors"){
//             //     querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), orderBy("FloorNo")));
//             // }
//             // else if(propertyId!="allProperties" && floorId=="allFloors"){
//             //     querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), where("PropertyId","==",propertyId)));
//             // }
//             // else{
//             //     querySnapshot = await getDocs(query(collection(db, "Shops"), orderBy("PropertyName"), where("PropertyId","==",propertyId), where("floorId","==",floorId)));
//             // }

//             console.log(`${propertyId} ${floorId}`)
//             let arr=[];

//             setData(obj);
//             console.log(obj);
//         }
//         catch(err){
//             console.log(err);
//         }

//     }

//     //read filter from this state
//     const [filter, setFilter] = useState(
//         {
//             Property: "All Properties",
//             Floor: "All Floors",
//             Block: "All Blocks",
//             Search: ""
//         }
//     )

//     useEffect(()=>{
//         setData(null);
//         fetchShops();
//         if(filter.Property=="All Properties"){

//         }
//         else if(filter.Property==undefined){
//             navigate(`/properties/allProperties/floors/allFloors/shops`)
//         }
//         else{
//             navigate(`/properties/${filter.Property}/floors/allFloors/shops`)
//         }

//         console.log(filter);
//     },[filter])

//     useEffect(()=>{
//         fetchShops();
//         // console.log(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
//     },[]);

//     useEffect(()=>{
//         fetchShops();
//     },[propertyId])

//     //update this state from database
//     const [data, setData] = useState(
//         {
//             // "Ibs Floor2":{
//             //     Property: "Ibs",
//             //     Floor: "2",
//             //     Shops: [
//             //         {
//             //             BusinessName: "Amin Jewellers",
//             //             ShopNo: "239",
//             //             Status: "Paid",
//             //             Month: "October"
//             //         },
//             //         {
//             //             BusinessName: "Mashallah Jewellers",
//             //             ShopNo: "239",
//             //             Status: "Paid",
//             //             Month: "October"
//             //         },
//             //     ]
//             // },
//             // "Wallahi Floor1":{
//             //     Property: "Wallahi",
//             //     Floor: "1",
//             //     Shops: [
//             //         {
//             //             BusinessName: "Amin Jewellers",
//             //             ShopNo: "239",
//             //             Status: "Paid",
//             //             Month: "October"
//             //         },
//             //         {
//             //             BusinessName: "Mashallah Jewellers",
//             //             ShopNo: "239",
//             //             Status: "Paid",
//             //             Month: "October"
//             //         },
//             //     ]
//             // }

//         }
//         );

//     const handleFilter = (data) => {
//         setFilter
//         (
//             data
//         );
//         // fetchFloors();
//     }

//     useEffect(()=>{
//         console.log(data);
//     },[data])

//     const handleRowClick=(id)=>{
//         navigate(`/properties/${propertyId}/floors/${floorId}/shops/${id}`);
//     }

//     return(
//         <>
//             <PageTemp  title="Rent Statement" subTitle="Report View" page={
//                 <>

//                     <TableTop dStartAndEndDates={true} genRep={true} defaultProp={propertyId} filter={filter} handleFilter={handleFilter} dProperty={true} dFloor={true} dBlock={true} dSearch={true}/>
//                     <Table>

//                     </Table>
//                 </>
//             }/>
//         </>
//     );
// }

import PageTemp from "../PageTemp";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableSubheader from "../TableSubheader";
import TableRow from "../TableRow";
import TableTop from "../TableTop";
import { useState } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function ServiceChargeStatement() {
  const navigate = useNavigate();
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);

  const [propertyId, setPropertyId] = useState("allProperties");
  const [floorId, setFloorId] = useState("");

  const { allProperties, allFloors, rolee } = UserAuth();
  useEffect(() => {
    if (rolee && !rolee.fServiceChargeStatement) {
      navigate("/");
    }
  }, [rolee]);

  const [dynamic, setDynamic] = useState("");

  const getFirstFloor = async (id) => {
    const docRef = await getDocs(
      query(collection(db, "Floors"), where("PropertyId", "==", id))
    );
    console.log("um");
    const arr = [];
    docRef.forEach((doc) => {
      arr.push(doc.id);
    });
    setFloorId(arr[0]);
    setFilter((prev) => ({
      ...prev,
      Floor: arr[0],
    }));
    console.log(arr);
  };

  useEffect(() => {
    setPropertyId(Object.keys(allProperties)[0]);
  }, [allProperties]);

  useEffect(() => {
    console.log(propertyId);
    getFirstFloor(propertyId);
    fetchShops();
  }, [propertyId]);

  useEffect(() => {
    fetchShops();
  }, [floorId]);

  const loopThroughDates = (start, end) => {
    const arr = [];
    if (start == end) {
      arr.push(start);
    } else if (start > end) {
      console.log("Invalid");
    } else {
      let x = start;
      arr.push(x);
      while (x < end) {
        if (x.slice(-2) != 12) {
          x = `${x.substring(0, 4)}-${(
            "0" + String(parseInt(x.slice(-2)) + 1)
          ).slice(-2)}`;
        } else {
          x = `${parseInt(x.substring(0, 4)) + 1}-01`;
        }
        arr.push(x);
      }
    }
    return arr;
  };

  const fetchEntries = async() => {
    const docRef = await getDocs(collection(db, "Shops"));
    docRef.forEach(doc=>{
      const arr=[];
      Object.keys(doc.data().Entries["ServiceCharge"]).map(m=>{
          const obj={};
          obj[m]=doc.data().Entries.ServiceCharge[m];
          arr.push(obj);
      })
      arr.sort((a,b)=>{
          if(Object.keys(a)[0] > Object.keys(b)[0]){
              return 1;
          }
          else if(Object.keys(a)[0] < Object.keys(b)[0])
          {
              return -1;
          }
          return 0;
      });
      const obj2={}
      arr.map(m=>{
          obj2[Object.keys(m)[0]]=m[Object.keys(m)[0]];
      })
      setData(prev=> {return {...prev, ...obj2}});
      // setData(docRef.data().Entries["Rent"]);
    })
    
  }  

  function sortByDate(array, dateProp) {
    return array.sort((a, b) => {
      return new Date(b[dateProp]).getTime() - new Date(a[dateProp]).getTime();
    });
  }


  const fetchShops = async () => {
    setData([]);
    const arr = [];
    const arr2 = [];
    const obj = {};
    let querySnapshot;
      if (propertyId == "allProperties" && floorId == "allFloors") {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName")
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("FloorNo")
            )
          );
        }
      } else if (propertyId != "allProperties" && floorId == "allFloors") {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),

                where("PropertyId", "==", propertyId),
                orderBy("BusinessName"),
                orderBy("FloorNo"),
                orderBy("PropertyName")
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                orderBy("FloorNo"),
                where("PropertyId", "==", propertyId),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              orderBy("FloorNo"),
              where("PropertyId", "==", propertyId)
            )
          );
        }
      } else {
        if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
          if (
            filter.dynamicFilter &&
            filter.dynamicFilter[0] == "BusinessName"
          ) {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
                where(
                  "BusinessName",
                  "<=",
                  filter.dynamicFilter[1].toString() + "\uf8ff"
                ),
                orderBy("BusinessName"),
                orderBy("PropertyName"),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId)
              )
            );
          } else {
            querySnapshot = await getDocs(
              query(
                collection(db, "Shops"),
                orderBy("PropertyName"),
                where("PropertyId", "==", propertyId),
                where("floorId", "==", floorId),
                where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
              )
            );
          }
        } else {
          querySnapshot = await getDocs(
            query(
              collection(db, "Shops"),
              orderBy("PropertyName"),
              where("PropertyId", "==", propertyId),
              where("floorId", "==", floorId)
            )
          );
        }
      }
    querySnapshot.forEach(doc=>{
      arr.push({...doc.data(), id: doc.id});
    })

    arr.map(a=>{
      // console.log(a.Entries["Rent"])
      Object.keys(a.Entries["ServiceCharge"]).map(m=>{
        if(a.Entries.ServiceCharge[m]["partialData"]==undefined){
          arr2.push({
            BusinessName: a.BusinessName,
            Block: a.Block,
            ShopNo: a.ShopNo,
            CollectedBy: a.Entries.ServiceCharge[m]["CollectedBy"],
            Amount: a.Entries.ServiceCharge[m]["AmountCollected"],
            Status: a.Entries.ServiceCharge[m]["Status"],
            id: a.id,
            On: (a.Entries.ServiceCharge[m]["EntriedOn"]),
            month: m
          })
        }
        else{
          arr2.push({
            BusinessName: a.BusinessName,
            Block: a.Block,
            ShopNo: a.ShopNo,
            CollectedBy: a.Entries.ServiceCharge[m]["CollectedBy"],
            Amount: a.Entries.ServiceCharge[m]["AmountCollected"],
            Status: a.Entries.ServiceCharge[m]["Status"],
            id: a.id,
            On: (a.Entries.ServiceCharge[m]["EntriedOn"]),
            month: m
          })
        }
        


        if(a.Entries.ServiceCharge[m]["partialData"]!=undefined){
          a.Entries.ServiceCharge[m]["partialData"].map(c=>{
            // console.log(c);
            arr2.push({
              BusinessName: a.BusinessName,
              Block: a.Block,
              ShopNo: a.ShopNo,
              CollectedBy: c["CollectedBy"],
              Amount:  c["AmountCollected"],
              Status: c["Status"],
              id: a.id,
              On: (c["EntriedOn"]),
              month: m
            })
          })
        }
      })
    })
    const arr3= sortByDate(arr2, "On");
    // console.log(arr2);
    setData(arr3);
  }


  // const fetchShops = async () => {
  //   setData({});
  //   const dates = loopThroughDates(filter.StartDate, filter.EndDate);

  //   const obj = {};
  //   try {
  //     let querySnapshot;
  //     if (propertyId == "allProperties" && floorId == "allFloors") {
  //       if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
  //         if (
  //           filter.dynamicFilter &&
  //           filter.dynamicFilter[0] == "BusinessName"
  //         ) {
  //           querySnapshot = await getDocs(
  //             query(
  //               collection(db, "Shops"),
  //               where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
  //               where(
  //                 "BusinessName",
  //                 "<=",
  //                 filter.dynamicFilter[1].toString() + "\uf8ff"
  //               ),
  //               orderBy("BusinessName"),
  //               orderBy("PropertyName")
  //             )
  //           );
  //         } else {
  //           querySnapshot = await getDocs(
  //             query(
  //               collection(db, "Shops"),
  //               orderBy("PropertyName"),
  //               where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
  //             )
  //           );
  //         }
  //       } else {
  //         querySnapshot = await getDocs(
  //           query(
  //             collection(db, "Shops"),
  //             orderBy("PropertyName"),
  //             orderBy("FloorNo")
  //           )
  //         );
  //       }
  //     } else if (propertyId != "allProperties" && floorId == "allFloors") {
  //       if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
  //         if (
  //           filter.dynamicFilter &&
  //           filter.dynamicFilter[0] == "BusinessName"
  //         ) {
  //           querySnapshot = await getDocs(
  //             query(
  //               collection(db, "Shops"),
  //               where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
  //               where(
  //                 "BusinessName",
  //                 "<=",
  //                 filter.dynamicFilter[1].toString() + "\uf8ff"
  //               ),

  //               where("PropertyId", "==", propertyId),
  //               orderBy("BusinessName"),
  //               orderBy("FloorNo"),
  //               orderBy("PropertyName")
  //             )
  //           );
  //         } else {
  //           querySnapshot = await getDocs(
  //             query(
  //               collection(db, "Shops"),
  //               orderBy("PropertyName"),
  //               orderBy("FloorNo"),
  //               where("PropertyId", "==", propertyId),
  //               where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
  //             )
  //           );
  //         }
  //       } else {
  //         querySnapshot = await getDocs(
  //           query(
  //             collection(db, "Shops"),
  //             orderBy("PropertyName"),
  //             orderBy("FloorNo"),
  //             where("PropertyId", "==", propertyId)
  //           )
  //         );
  //       }
  //     } else {
  //       if (filter.dynamicFilter && filter.dynamicFilter[1] != "") {
  //         if (
  //           filter.dynamicFilter &&
  //           filter.dynamicFilter[0] == "BusinessName"
  //         ) {
  //           querySnapshot = await getDocs(
  //             query(
  //               collection(db, "Shops"),
  //               where("BusinessName", ">=", filter.dynamicFilter[1].toString()),
  //               where(
  //                 "BusinessName",
  //                 "<=",
  //                 filter.dynamicFilter[1].toString() + "\uf8ff"
  //               ),
  //               orderBy("BusinessName"),
  //               orderBy("PropertyName"),
  //               where("PropertyId", "==", propertyId),
  //               where("floorId", "==", floorId)
  //             )
  //           );
  //         } else {
  //           querySnapshot = await getDocs(
  //             query(
  //               collection(db, "Shops"),
  //               orderBy("PropertyName"),
  //               where("PropertyId", "==", propertyId),
  //               where("floorId", "==", floorId),
  //               where(filter.dynamicFilter[0], "==", filter.dynamicFilter[1])
  //             )
  //           );
  //         }
  //       } else {
  //         querySnapshot = await getDocs(
  //           query(
  //             collection(db, "Shops"),
  //             orderBy("PropertyName"),
  //             where("PropertyId", "==", propertyId),
  //             where("floorId", "==", floorId)
  //           )
  //         );
  //       }
  //     }

  //     console.log(`${propertyId} ${floorId}`);
  //     let arr = [];
  //     querySnapshot.forEach((doc) => {
  //       if (arr.length != 0 && arr[0].Floor != doc.data().FloorNo) {
  //         arr = [];
  //       }
  //       if (arr.length != 0 && arr[0].Property != doc.data().PropertyName) {
  //         arr = [];
  //       }
  //       const subObj = {};
  //       subObj["Property"] = doc.data().PropertyName;
  //       subObj["Floor"] = doc.data().FloorNo;

  //       let y;
  //       if (doc.data().Entries.Rent[filter.EndDate] != undefined) {
  //         if (doc.data().Entries.Rent[filter.EndDate].Status == "Full") {
  //           y = "Paid";
  //         } else {
  //           y = "Partially Paid";
  //         }
  //       } else {
  //         y = "Not paid";
  //       }
  //       // console.log(y);

  //       let sumDue = 0;
  //       let sumCollected = 0;
  //       dates.map((date) => {
  //         if (doc.data().Entries.Rent[date] != undefined) {
  //           sumDue += parseInt(doc.data().Entries.Rent[date].Due);
  //           sumCollected += parseInt(doc.data().Entries.Rent[date].Amount);
  //         } else {
  //           sumDue += parseInt(doc.data().UnitSpace * doc.data().UnitPrice);
  //         }
  //       });

  //       const e = doc.data().Entries.Rent;
  //       const arr4=[];
  //         Object.keys(e).map(m=>{
  //           const obj3={};
  //           obj3[m]=e[m];
  //           arr4.push(obj3);
  //       })
  //       arr4.sort((a,b)=>{
  //           if(Object.keys(a)[0] > Object.keys(b)[0]){
  //               return 1;
  //           }
  //           else if(Object.keys(a)[0] < Object.keys(b)[0])
  //           {
  //               return -1;
  //           }
  //           return 0;
  //       });
  //       const obj4={}
  //       arr4.map(m=>{
  //           obj4[Object.keys(m)[0]]=m[Object.keys(m)[0]];
  //       })
        
  //       arr.push({
  //         AmountCollected: parseInt(sumCollected),
  //         AmountDue: parseInt(sumDue),
  //         BusinessName: doc.data().BusinessName,
  //         id: doc.id,
  //         ShopNo: doc.data().ShopNo,
  //         Status: y,
  //         Month: doc.data().LastPaid,
  //         Floor: doc.data().FloorNo,
  //         Property: doc.data().PropertyName,
  //         Entries: obj4
  //       });
  //       // console.log(doc.data().Entries.Rent);
  //       // console.log(arr);
  //       subObj["Shops"] = arr;
  //       obj[`${doc.data().PropertyName} ${doc.data().FloorNo}`] = subObj;
  //     });
  //     setData(obj);
  //     console.log(obj);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  //read filter from this state
  const [filter, setFilter] = useState({
    Property: "All Properties",
    Floor: "All Floors",
    Block: "All Blocks",
    Search: "",
  });

  function isWithinRange(dateString, startDateString, endDateString) {
    const date = new Date(dateString);
    console.log(date);
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    return date >= startDate && date <= endDate;
  }

  function convertDateFormat(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const [year, month] = dateString.split("-").map(Number);
    const monthName = months[month - 1];
    return `${monthName}, ${year}`;
  }

  useEffect(() => {
    fetchShops();
    if (filter.Property == "All Properties" && filter.Floor == "All Floors") {
    } else if (
      filter.Property != "allProperties" &&
      filter.Floor == "allFloors"
    ) {
      setPropertyId(filter.Property);
      setFloorId("allFloors");
    } else if (
      filter.Property != "allProperties" &&
      filter.Floor != "allFloors"
    ) {
      setPropertyId(filter.Property);
      setFloorId(filter.Floor);
    } else if (
      filter.Property == "allProperties" &&
      filter.Floor != "allFloors"
    ) {
      setPropertyId("allProperties");
      setFloorId("allFloors");
    } else if (
      filter.Property == "allProperties" &&
      filter.Floor == "allFloors"
    ) {
      setPropertyId("allProperties");
      setFloorId("allFloors");
    }

    filter.dynamicFilter &&
      setDynamic(
        `where("${filter.dynamicFilter[0]}"=="${filter.dynamicFilter[1]}")`
      );

    console.log(filter);
    console.log(floorId);
  }, [filter]);

  useEffect(() => {
    fetchShops();
    // console.log(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  }, []);

  useEffect(() => {
    fetchShops();
  }, [propertyId]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      Property: Object.keys(allProperties)[0],
    }));
  }, [allProperties]);

  //update this state from database
  const [data, setData] = useState([]);

  const handleFilter = (data) => {
    setFilter(data);
    // fetchFloors();
  };

  function simplifyDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    };
    return date.toLocaleString("en-US", options);
  }

  

  const handleRowClick = (id, m) => {
    navigate(`/properties/${propertyId}/floors/${floorId}/shops/${id}/history/${m}`);
  };

  return (
    <>
      <PageTemp
        title="Service Charge Statement"
        subTitle="Report View"
        page={
          <>
            {floorId != "" && (
              <TableTop
                all={true}
                defFloor={floorId}
                // dStartAndEndDates={true}
                dStartAndEndExactDates={true}
                genRep={true}
                defaultProp={propertyId}
                filter={filter}
                handleFilter={handleFilter}
                dProperty={true}
                dFloor={true}
                dBlock={true}
              />
            )}
            <Table>
              <TableHeader
                headings={["Entry Date", "Unit Name", "Collected by", "Amount", "Type", "Payment Month"]}
              />

              {
                data && data.map(shop=>{
                  if(isWithinRange(shop.On, filter.StartDateExact, filter.EndDateExact)){
                    return (
                      <TableRow
                        onClick={()=>handleRowClick(shop.id, `${shop.month}/ServiceCharge`)}
                        date={[
                          simplifyDate(shop.On),
                        ]}
                        info={[
                          `${
                            shop.ShopNo != "" ? `#${shop.ShopNo} ,` : ``
                          } ${
                            shop.Block != "" && shop.Block!=undefined ? `Block: ${shop.Block}` : ``
                          }`,
                          shop.BusinessName,
                          
                        ]}
                        normalDetails={[
                          `${shop.CollectedBy ? shop.CollectedBy : "N/A"}`,
                          `${shop.Amount ? shop.Amount : "N/A"}`,
                          shop.Status,
                          
                          convertDateFormat(shop.month)
                        ]}
                      />
                    )
                  }
                  
                })
              }

              {/* {data &&
                Object.keys(data).map((building) => {
                  return (

                      data[building].Shops &&
                        data[building].Shops.length > 0 &&
                        data[building].Shops.map((shop) => {
                          if(Object.keys(shop.Entries).length!=0){
                            return Object.keys(shop.Entries).map(e=>{
                              return (
                                
                              );
                            })
                            
                          }
                          // Object.keys(shop.Entries).map((e)=>{

                            
                          // })
                          
                        })
                  );
                })
                } */}

              {/* {data.map((d)=>{
                            return(
                                <TableSubheader property={d.PropertyName} floor={d.Floor}>
                                    {d.Shops.map((shop)=>{
                                        return(<TableRow info={[shop.ShopNo,shop.ShopName]} status={shop.Status} normalDetails={[shop.Month]}/>);
                                    })}
                                </TableSubheader>
                            );
                        })} */}
            </Table>
          </>
        }
      />
    </>
  );
}
