import { UserAuth } from "../context/AuthContext";

export default function TableHeader({ headings }) {
  const head = {
    "Floor No.": "ফ্লোর নং",
    Area: "সাইজ",
    "Property Name": " প্রপার্টির নাম",
    Floors: "ফ্লোর",
    "Unit Name": "ইউনিট নাম",
    "Tenant Name": "ভাড়াটিয়ার নাম",
    "Tenant Type": "ভাড়াটিয়ার টাইপ",
    "Rent Status": "",
    "Paid till": "",
    "Lease Ending": "",
    "Vacant From": "",
    Status: "",
    "Monthly Reading": "",
    "Current Reading": "",
    "Amount Due": "",
    "Amount Collected": "",
    Due: "",
    Collected: "",
  };
  const { bangla } = UserAuth();
  return (
    <>
      <tr>
        {headings.map((heading) => {
          if (heading != "") {
            return heading in head ? (
              !bangla ? (
                <th key={heading}>{heading}</th>
              ) : (
                <th key={heading}>{head[heading]}</th>
              )
            ) : (
              <th key={heading}>{heading}</th>
            );
          }
        })}
      </tr>
    </>
  );
}
{
  /* <th key={heading}>{heading}</th> */
}
